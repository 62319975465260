import React, { useEffect, useState } from 'react'
import { Typography, Grid, Paper } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls'
import { isEmptyObject } from '../../../../../../applications/UtilFunctions'

function AdministrativeDelimitation(props) {
    const { styles, administrativeDelimitationData, setNeedLoad } = props
    const [defaultOption, setDefaultOption] = useState([])
    const [regions, setRegions] = useState([])
    const [communes, setCommunes] = useState([])
    const [villages, setVillages] = useState([])
    const [districts, setDistricts] = useState([])
    const [fkts, setFkts] = useState([])
    const [sites, setSites] = useState([])


    useEffect(() => {
        setNeedLoad(true)
        if(administrativeDelimitationData){
            setRegions([{id: administrativeDelimitationData.region, name: administrativeDelimitationData.region}])
            setCommunes([{id: administrativeDelimitationData.commune, name: administrativeDelimitationData.commune}])
            setVillages([{id: administrativeDelimitationData.village, name: administrativeDelimitationData.village}])
            setDistricts([{id: administrativeDelimitationData.district, name: administrativeDelimitationData.district}])
            setFkts([{id: administrativeDelimitationData.fkt, name: administrativeDelimitationData.fkt}])
            setSites([{id: administrativeDelimitationData.site, name: administrativeDelimitationData.site}])
            setNeedLoad(false)
        }
        
    }, [administrativeDelimitationData, setNeedLoad])
  
    return (
        <>
            <Typography sx={styles.form_title}>Délimitation administrative</Typography>

            <Grid container>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        <Controls.CustomSelect
                            label="Région"
                            name="region"
                            value={administrativeDelimitationData?.region || ''}
                            options={regions}
                            labelKey={'name'}
                            valueKey={'id'}
                            isDisabled={true}
                        />
                        <Controls.CustomSelect
                            label="Commune"
                            name="commune"
                            value={administrativeDelimitationData?.commune || ''}
                            options={communes}
                            labelKey={'name'}
                            valueKey={'id'}
                            isDisabled={true}
                        />
                        <Controls.CustomSelect
                            label="Village"
                            name="village"
                            value={administrativeDelimitationData?.village || ''}
                            options={villages}
                            labelKey={'name'}
                            valueKey={'id'}
                            isDisabled={true}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        <Controls.CustomSelect
                            label="District"
                            name="district"
                            value={administrativeDelimitationData?.district || ''}
                            options={districts}
                            labelKey={'name'}
                            valueKey={'id'}
                            isDisabled={true}
                        />
                        <Controls.CustomSelect
                            label="Fokontany"
                            name="fkt"
                            value={administrativeDelimitationData?.fkt || ''}
                            options={fkts}
                            labelKey={'name'}
                            valueKey={'id'}
                            isDisabled={true}
                        />
                        <Controls.CustomSelect
                            label="Site"
                            name="site"
                            value={administrativeDelimitationData?.site || ''}
                            options={sites}
                            labelKey={'name'}
                            valueKey={'id'}
                            isDisabled={true}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default AdministrativeDelimitation
