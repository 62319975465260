export const planterModel = {
    label: 'Planteur mangrove',
    tableName: 'mangroPlanter',
    properties: [
        {
            label: 'Code groupe',
            id: 'mangroGroup',
            isForeignKey: true,
            column_name: 'name',
            table_column: 'group_id',
            type: 'text',
            required: true,
        },
        {
            label: 'Nom',
            id: 'lastname',
            type: 'text',
            required: true,
        },
        {
            label: 'Prénom',
            id: 'firstname',
            type: 'text',
            required: true,
        },
        {
            label: 'Genre',
            id: 'genre',
            type: 'text',
            required: true,
        },
        {
            label: 'Telephone',
            id: 'phone',
            type: 'text',
            required: true,
        },
        {
            label: "Date d'adhesion",
            id: 'member_since',
            type: 'date',
            required: true,
        },
        {
            label: 'Début de collaboration',
            id: 'collaboration_beginning',
            type: 'date',
            required: true,
        },
        {
            label: 'Date de naissance',
            id: 'dob',
            type: 'date',
            required: true,
        },
        {
            label: 'CIN',
            id: 'cin_number',
            type: 'text',
            required: true,
        },
        {
            label: 'Activité',
            id: 'activity',
            type: 'text',
            required: true,
        },
        {
            label: 'Adresse',
            id: 'address',
            type: 'text',
            required: true,
        },
        {
            label: 'Catégorie',
            id: 'category',
            type: 'text',
            required: true,
        }
    ]
}
