import React from 'react'
import { Box } from '@mui/material'

function TextViewer(props) {
    const { value, size = '100%', height = '200px', title } = props
    return (
        <>
            <h3>{title}</h3>
            <Box
                sx={{
                    width: size,
                    maxHeight: height, // Limite la hauteur à 300px
                    // marginTop: '10px',
                    overflowY: 'auto', // Active le défilement vertical
                    p: 2, // Ajoute du padding pour espacer le texte des bords
                    border: '1px solid #ddd', // Optionnel : ajoute une bordure
                    borderRadius: 1, // Optionnel : arrondit les coins
                    backgroundColor: '#f9f9f9' // Optionnel : change la couleur de fond
                }}
            >
                {/* Texte ou contenu du conteneur */}
                <p>{value}</p>
                {/* Ajoutez autant de texte que nécessaire pour tester le défilement */}
            </Box>
        </>
    )
}

export default TextViewer
