import { Container, Grid, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import Controls from '../../../../../components/Entry/Controls'
import { FaTrashAlt } from 'react-icons/fa'
import Notification from '../../../../../layouts/Notification'

function PopupTrackingForm(props) {
    const { setPopupValues, setUserHaveToTrackYoungPlant } = props
    const [dataList, setDataList] = useState([])
    const [leafNumber, setLeafNumber] = useState('')
    const [isRefilled, setIsRefilled] = useState(false)
    const [height, setHeight] = useState('')
    const [deadPlantsNumber, setDeadPlantsNumber] = useState(0)

    const refillData = [
        { id: true, title: 'Oui' },
        { id: false, title: 'Non' }
    ]

    const addData = () => {
        console.log(leafNumber, height)
        if (leafNumber !== '' && height !== '') {
            setDataList([
                ...dataList,
                {
                    id: dataList[dataList.length - 1]?.id + 1 || 1,
                    leafNumber: parseInt(leafNumber) || 0,
                    height: parseInt(height) || 0,
                    isRefilled: isRefilled
                }
            ])
            setLeafNumber('')
            setHeight('')
            setIsRefilled(false)
        } else {
            new Notification('Veuillez renseigner les champs', 0)
        }
    }

    const addDataDead = () => {
        setDeadPlantsNumber(deadPlantsNumber + 1)
    }

    const handleSubmit = () => {
        console.log(dataList)
        setPopupValues({
            averageNumber: (dataList.reduce((a, b) => a + b.leafNumber, 0) / dataList.length).toFixed(0),
            averageHeight: (dataList.reduce((a, b) => a + b.height, 0) / dataList.length).toFixed(0),
            nbr_death: deadPlantsNumber,
            nbr_refilled: dataList.reduce((a, b) => a + (b.isRefilled ? 1 : 0), 0)
        })
        setUserHaveToTrackYoungPlant(false)
    }
    return (
        <div style={{ width: '500px' }}>
            <Typography variant="p" component="div" style={{ marginBottom: '10px' }}>
                <b>
                    <i>Jeune plant : {dataList.length + 1}</i>
                </b>
            </Typography>
            <Grid container>
                <Grid item xs={6}>
                    <Container>
                        <Controls.Input
                            label="Nombre de feuilles"
                            type="number"
                            value={leafNumber}
                            onChange={(e) => setLeafNumber(e.target.value)}
                            isRequired={true}
                        />
                        <Controls.RadioGroup
                            name="isRefilled"
                            label="Regarnie"
                            items={refillData}
                            value={isRefilled}
                            onChange={(e) => setIsRefilled(e.target.value)}
                        />
                    </Container>
                </Grid>
                <Grid item xs={6}>
                    <Container>
                        <Controls.Input
                            label="Hauteur (cm)"
                            type="number"
                            value={height}
                            onChange={(e) => setHeight(e.target.value)}
                            isRequired={true}
                        />
                    </Container>
                </Grid>
            </Grid>
            <Container>
                <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                    <Controls.Button text="Ajouter" onClick={addData} />
                    <Controls.Button text="Mort" onClick={addDataDead} color="error" />
                </div>
                <Paper style={{ padding: '10px', marginBottom: '10px' }}>
                    <Typography variant="p" component="div" style={{ marginBottom: '10px' }} fontSize={12}>
                        Jeunes plants suivis :
                    </Typography>
                    <div style={{ height: '200px', overflowY: 'scroll' }}>
                        {dataList
                            .map((data) => (
                                <div key={data.id}>
                                    <Typography
                                        variant="p"
                                        component="div"
                                        style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}
                                        fontSize={12}
                                    >
                                        <i>
                                            {' '}
                                            {data.id} - {data.leafNumber} feuilles - {data.height} cm
                                        </i>{' '}
                                        <FaTrashAlt
                                            style={{ cursor: 'pointer', color: 'red', alignSelf: 'center' }}
                                            onClick={() => setDataList(dataList.filter((v) => v.id !== data.id))}
                                        />
                                    </Typography>
                                </div>
                            ))
                            .reverse()}
                    </div>
                    <Typography variant="p" component="div" style={{ marginBottom: '10px' }} fontSize={12}>
                        <b>Totale : </b>
                        {dataList.length + deadPlantsNumber} plants
                        <br />
                        <b>Nombre moyenne de feuilles : </b>
                        {dataList.length > 0
                            ? (dataList.reduce((a, b) => a + b.leafNumber, 0) / dataList.length).toFixed(2)
                            : 0}
                        <br />
                        <b>Hauteur moyenne : </b>
                        {dataList.length > 0
                            ? (dataList.reduce((a, b) => a + b.height, 0) / dataList.length).toFixed(2)
                            : 0}{' '}
                        cm
                        <br />
                        <b style={{ color: '#ff000063' }}>Plants morts : {deadPlantsNumber}</b>
                    </Typography>
                </Paper>
                <div className="flex justify-end items-end">
                    <Controls.Button text="Soumettre" onClick={handleSubmit} size="large" />
                </div>
            </Container>
        </div>
    )
}

export default PopupTrackingForm
