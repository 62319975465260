import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'

export function useForm(initialFValues, validateOnChange = false, validate) {
    const [values, setValues] = useState(initialFValues)
    const [errors, setErrors] = useState({})

    const handleInputChange = (e) => {
        const { name, value } = e.target

        setValues({
            ...values,
            [name]: value
        })
        if (e.target.content) {
            setValues({
                ...values,
                [name]: e.target.content
            })
        }
        if (validateOnChange) validate({ [name]: value })
    }

    const resetForm = () => {
        setValues(initialFValues)
        setErrors({})
    }
    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFormControl-root': {
            width: '100%',
            margin: theme.spacing(0)
        }
    }
}))

export function Form(props) {
    const classes = useStyles()
    const { children, sx, ...other } = props
    return (
        <form className={classes.root} autoComplete="off" {...other} style={{ ...sx }}>
            {props.children}
        </form>
    )
}
