import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import ContentView from '../../../../components/PageView/Content'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import PlatDrainService from '../services/plat_drain'
import { PlatDrainForm } from './PlatDrainForm'
import { PlatDrainTrackingList } from './PlatDrainTrackingList'
import PlatDrainContext from '../utils/hooks/PlatDrainContext'

function PlatDrainView(props) {
    const { addReturnButton, recordForEdit } = props

    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const platDrainService = PlatDrainService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(true)
    const [userHaveToAdd, setUserHaveToAdd] = useState(false)
    useEffect(() => {
        if (location?.state?.userHaveToAdd) setUserHaveToAdd(location?.state?.userHaveToAdd)
        else setUserHaveToAdd(false)
    }, [location])
    return (
        <>
            <PlatDrainContext userHaveToAdd={userHaveToAdd}>
                <ContentView
                    dataId={recordForEdit ? recordForEdit.id : dataId}
                    addReturnButton={addReturnButton}
                    title={recordForEdit ? '' : 'Nouvelle plate bande'}
                    service={platDrainService}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={PlatDrainForm}
                    needLoadCode={needLoadCode}
                />
                {/* {dataId !== 'new' && (
                    <ContentView
                        dataId={recordForEdit ? recordForEdit.id : dataId}
                        service={platDrainService}
                        haveAccessTo={haveAccessTo}
                        setNeedLoad={setNeedLoad}
                        needLoad={needLoad}
                        Form={PlatDrainTrackingList}
                        needLoadCode={needLoadCode}
                    />
                )}
                {dataId !== 'new' && (
                    <ContentView
                        dataId={recordForEdit ? recordForEdit.id : dataId}
                        service={platDrainService}
                        haveAccessTo={haveAccessTo}
                        setNeedLoad={setNeedLoad}
                        needLoad={needLoad}
                        Form={PlatDrainTrackingList}
                        needLoadCode={needLoadCode}
                    />
                )} */}
            </PlatDrainContext>
        </>
    )
}

export default PlatDrainView
