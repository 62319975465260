export const calculateAverageAgeByGenderAndAgeRange = (peasants) => {
    const initialData = {
        homme: { range1: 0, range2: 0, range3: 0, range4: 0, count: 0 },
        femme: { range1: 0, range2: 0, range3: 0, range4: 0, count: 0 }
    }

    const { homme, femme } = peasants.reduce((acc, peasant) => {
        const age = parseInt(peasant.age)
        if (peasant.genre === 'Homme') {
            acc.homme.count++
            if (age >= 18 && age < 25) {
                acc.homme.range1 += age
            } else if (age >= 25 && age < 35) {
                acc.homme.range2 += age
            } else if (age >= 35 && age < 50) {
                acc.homme.range3 += age
            } else if (age >= 50) {
                acc.homme.range4 += age
            }
        } else if (peasant.genre === 'Femme') {
            acc.femme.count++
            if (age >= 18 && age < 25) {
                acc.femme.range1 += age
            } else if (age >= 25 && age < 35) {
                acc.femme.range2 += age
            } else if (age >= 35 && age < 50) {
                acc.femme.range3 += age
            } else if (age >= 50) {
                acc.femme.range4 += age
            }
        }
        return acc
    }, initialData)

    const hommeSeries = [
        homme.range4 / homme.count || 0,
        homme.range3 / homme.count || 0,
        homme.range2 / homme.count || 0,
        homme.range1 / homme.count || 0
    ]

    const femmeSeries = [
        femme.range4 / femme.count || 0,
        femme.range3 / femme.count || 0,
        femme.range2 / femme.count || 0,
        femme.range1 / femme.count || 0
    ]

    return [
        { data: hommeSeries, name: 'Homme' },
        { data: femmeSeries, name: 'Femme' }
    ]
}
