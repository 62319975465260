import React, { createContext, useEffect, useState } from 'react'
import LandService from '../../../services/Land'
import Loader from '../../../../../components/Entry/Loading'

export const Context = createContext({})

function LandDataContext({ children, dataCode, needLoad, setNeedLoad }) {
    const landService = LandService.load()
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState({})

    useEffect(() => {
        if (dataCode) {
            const getLandData = async () => {
                setLoading(true)
                const landData = await landService.getByCode(dataCode)
                if (landData) {
                    setValue(landData.data)
                }
            }
            if (needLoad) {
                getLandData()
                setLoading(false)
                setNeedLoad(false)
            }
        }
    }, [dataCode, landService, needLoad, setNeedLoad])

    return (
        <>
            <Loader isLoading={loading} />
            <Context.Provider value={value}>{children}</Context.Provider>
        </>
    )
}

export default LandDataContext
