// import { Http } from '../../../applications/Http.js'

import { Http } from '../../../../applications/Http'
import Service from '../../../../applications/Service'
import moment from 'moment'
import Notification from '../../../../layouts/Notification'

class PlantMaterialService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/plant_material')
        return this
    }

    static async getByCode(id) {
        return await Http.get([this.url, id].join('/'), {})
    }

    static async addPlantMaterial(data) {
        try {
            const response = await Http.put(this.url, data)
            return response.data
        } catch (error) {
            throw new Error("Erreur lors de l'ajout ")
        }
    }

    static async addPlantMaterialItem(data) {
        try {
            data.date_purchase = moment(data.date_purchase).format()
            const response = await Http.post([this.url, 'item'].join('/'), data)
            return response.data
        } catch (error) {
            throw new Error("Erreur lors de l'envoi des données !")
        }
    }

    static async updatePlantMaterialItem(id, data) {
        try {
            const response = await Http.patch([this.url, id].join('/'), data)
            return response.data
        } catch (error) {
            new Notification(this.messages.update_error, 0)
            throw new Error(this.messages.update_error)
        }
    }

    static async getNuseryPlantMaterial(id) {
        try {
            const url = [this.url, `byIdNursery`, id].join('/')
            console.log(url)
            const response = await Http.get(url, {})
            return response?.data
        } catch (error) {
            console.error(error)
            new Notification(this.messages.update_error, 0)
        }
    }

    static async getNuseryPlantMaterialBySpecie(nurseryId, specieId, unity) {
        try {
            console.log(' id nursery : ', nurseryId)
            const url = [this.url, `bySpecieId`].join('/')
            const data = {
                nursery_id: nurseryId,
                specie_id: specieId,
                unity: unity
            }
            console.log('data est : ', data)
            const response = await Http.get(url, data)
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async verifyIfSpecieExistInPlantMaterial(nurseryId, specieId) {
        try {
            const url = [this.url, `verifySpecie`].join('/')
            const data = {
                nursery_id: nurseryId,
                specie_id: specieId
            }
            console.log(url)
            const response = await Http.get(url, data)
            console.log(response.data)
            return response.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async updatePlantMaterial(data) {
        try {
            const response = await Http.patch([this.url, 'update'].join('/'), data)
            return response.data
        } catch (error) {
            throw new Error(this.messages.update_error)
        }
    }
}

export default PlantMaterialService
