import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class ProvinceService extends Service {
    static messages = {
        update_success: 'Modification formulaire avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création formulaire avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/provinces')
        return this
    }

    static async getProvinces() {
        return await Http.get(['api/provinces'].join('/'), {})
    }

    //MANGRO REGION
    static async getRegions(regions) {
        return await Http.get(['api/provinces/Regions', regions].join('/'), {})
    }
    static async getRegionsChild(regions) {
        return await Http.get(['api/provinces/District', regions, 'child'].join('/'), {})
    }
    static async updateRegions(id, params) {
        return await Http.patch(['api/provinces/Regions', id].join('/'), params)
    }
    static async addRegions(data) {
        return await Http.put(['api/provinces/Regions'].join('/'), data)
    }
    static async deleteRegions(id) {
        return await Http.delete(['api/provinces/Regions', id].join('/'))
    }

    //DISTRICT
    static async getDistrict(districts) {
        return await Http.get(['api/provinces/District', districts].join('/'), {})
    }
    static async getDistrictChild(districts) {
        return await Http.get(['api/provinces/Commune', districts, 'child'].join('/'), {})
    }
    static async updateDistrict(id, params) {
        return await Http.patch(['api/provinces/District', id].join('/'), params)
    }
    static async addDistrict(data) {
        return await Http.put(['api/provinces/District'].join('/'), data)
    }
    static async deleteDistrict(id) {
        return await Http.delete(['api/provinces/District', id].join('/'))
    }

    //MANGRO COMMUNE
    static async getCommune(towns) {
        return await Http.get(['api/provinces/Commune', towns].join('/'), {})
    }
    static async getCommuneChild(towns) {
        return await Http.get(['api/provinces/Fokontany', towns, 'child'].join('/'), {})
    }
    static async updateCommune(id, params) {
        return await Http.patch(['api/provinces/Commune', id].join('/'), params)
    }
    static async addCommune(data) {
        return await Http.put(['api/provinces/Commune'].join('/'), data)
    }
    static async deleteCommune(id) {
        return await Http.delete(['api/provinces/Commune', id].join('/'))
    }

    //MANGRO FOKONTANY
    static async getFokontany(fkt) {
        return await Http.get(['api/provinces/Fokontany', fkt].join('/'), {})
    }
    static async getFokontanyChild(fkt) {
        return await Http.get(['api/provinces/Village', fkt, 'child'].join('/'), {})
    }
    static async updateFokontany(id, params) {
        return await Http.patch(['api/provinces/Fokontany', id].join('/'), params)
    }
    static async addFokontany(data) {
        return await Http.put(['api/provinces/Fokontany'].join('/'), data)
    }
    static async deleteFokontany(id) {
        return await Http.delete(['api/provinces/Fokontany', id].join('/'))
    }

    //MANGRO VILLAGE
    static async getVillages(village) {
        return await Http.get(['api/provinces/Village', village].join('/'), {})
    }
    static async updateVillages(id, params) {
        return await Http.patch(['api/provinces/Village', id].join('/'), params)
    }
    static async addVillages(data) {
        return await Http.put(['api/provinces/Village'].join('/'), data)
    }
    static async deleteVillages(id) {
        return await Http.delete(['api/provinces/Village', id].join('/'))
    }

    //AGRO SITES
    static async getAgroSites(site) {
        return await this.getVillages(site)
    }

    //AGRO NURSERY
    static async getAgroCenters(site, modPep = false) {
        // modPep pour distinguer si l'appel est dans l'ajout pepinière (un seul central accordé)
        return await Http.get(['api/provinces/center'].join('/'), site !== 'all' ? { site: site, modPep: modPep } : {})
    }

    static async getAgroCentersCom(site) {
        return await Http.get(['api/provinces/center'].join('/'), site !== 'all' ? { site: site, com: true } : {})
    }

    static async updateAgroCenters(id, params) {
        return await Http.patch(['api/provinces/center', id].join('/'), params)
    }
    static async addAgroCenters(data) {
        return await Http.put(['api/provinces/center'].join('/'), data)
    }
    static async deleteAgroCenters(id) {
        return await Http.delete(['api/provinces/center', id].join('/'))
    }

    //MANGRO GROUPE
    static async getMangroGroupe(village) {
        return await Http.get(['api/provinces/mangroGroup', village, 'child'].join('/'), {})
    }
    static async getAgroGroupe(village) {
        return await Http.get(['api/provinces/AgroGroups', village, 'child'].join('/'), {})
    }

    //user region
    static async getUserRegion(user) {
        return await Http.get(['api/provinces/', user].join('/'), {})
    }

    static async getAllVillages() {
        try {
            return await Http.get('api/provinces/all-villages', {})
        } catch (error) {
            console.log(error)
        }
    }

    static async addVillageSigle(data) {
        try {
            return await Http.post('api/provinces/add-village-sigle', data)
        } catch (error) {
            console.log(error)
            return null
        }
    }

    static async updateVillageSigle(data) {
        try {
            return await Http.post('api/provinces/update-village-sigle', data)
        } catch (error) {
            console.log(error)
            return null
        }
    }
}

export default ProvinceService
