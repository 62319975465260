/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Container, Grid } from '@mui/material'
import Controls from '../../../../components/Entry/Controls'
import { useForm, Form } from '../../../../applications/hooks/UseForm'
import RoleService from '../../services/Role'
import ProvinceService from '../../../provinces/services/Provinces'

const initialFValues = {
    id: 0,
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    roleId: ''
}

export default function UserForm(props) {
    const { addOrEdit, recordForEdit } = props
    const [rolesOptions, setRolesOptions] = useState([])
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    const [regionsList, setRegionsList] = useState([])

    const province = ProvinceService.load()

    useEffect(() => {
        // Récupérer les sites
        province.getProvinces().then((response) => {
            if (response?.data) {
                setRegionsList(response.data.regions)
            }
        })
    }, [])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('firstname' in fieldValues) temp.firstname = fieldValues.firstname ? '' : 'Ce champ est requis.'
        if ('lastname' in fieldValues) temp.lastname = fieldValues.lastname ? '' : 'Ce champ est requis.'
        if ('email' in fieldValues) temp.email = /$^|.+@.+..+/.test(fieldValues.email) ? '' : "L'email est invalide."
        if ('password' in fieldValues) temp.password = fieldValues.password ? '' : 'Ce champ est requis.'
        if ('roleId' in fieldValues) temp.roleId = fieldValues.roleId.length != 0 ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialFValues,
        true,
        validate
    )

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm, setUserHaveToPost)
        }
    }

    async function getRoles() {
        const service = RoleService.load()
        service.getAll().then((res) => {
            let response = []
            res?.data?.map((item) => {
                response.push({ id: item.id, title: item.name })
            })
            setRolesOptions(response)
        })
    }

    useEffect(() => {
        const service = RoleService.load()
        getRoles()
        console.log(recordForEdit)
        if (recordForEdit != null) {
            service.getRoleByName(recordForEdit?.roles).then((res) => {
                console.log(res)
                setValues({
                    ...recordForEdit,
                    roleId: res?.data?.id
                })
            })
        }
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Container>
                        <Controls.Input
                            name="firstname"
                            label="Nom"
                            value={values.firstname}
                            onChange={handleInputChange}
                            error={errors.firstname}
                            isRequired={true}
                        />
                        <Controls.Input
                            label="Email"
                            name="email"
                            value={values.email}
                            onChange={handleInputChange}
                            error={errors.email}
                            isRequired={true}
                        />
                        <Controls.AutoCompleteSelect
                            label="Région assignée"
                            name="userRegion"
                            value={values?.userRegion || ''}
                            options={regionsList}
                            onChange={handleInputChange}
                            labelKey="name"
                            valueKey="id"
                        />
                    </Container>
                </Grid>
                <Grid item xs={6}>
                    <Container>
                        <Controls.Input
                            name="lastname"
                            label="Prénom"
                            value={values.lastname}
                            onChange={handleInputChange}
                            error={errors.lastname}
                            isRequired={true}
                        />
                        {!recordForEdit && (
                            <>
                                <Controls.Input
                                    label="Mot de passe"
                                    name="password"
                                    type="password"
                                    value={values.password}
                                    onChange={handleInputChange}
                                    error={errors.password}
                                    isRequired={true}
                                />
                            </>
                        )}
                        <Controls.AutoCompleteSelect
                            label="Rôle"
                            name="roleId"
                            value={values?.roleId || ''}
                            options={rolesOptions}
                            onChange={handleInputChange}
                            labelKey="title"
                            valueKey="id"
                            isRequired={true}
                        />
                    </Container>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center', marginTop: '8px' }}>
                    <Controls.Button type="submit" text="Enregistrer" haveSpin={true} disabled={userHaveToPost} />
                </Grid>
            </Grid>
        </Form>
    )
}
