import React, { createContext, useEffect, useState } from 'react'
import Loader from '../../../../components/Entry/Loading'

export const Context = createContext()

function InfoPlanterContext({ children, service, needLoad, setNeedLoad, dataId }) {
    const [value, setValue] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (needLoad) {
            setLoading(true)
            service
                .getById(dataId)
                .then((res) => {
                    setValue(res.data)
                    setNeedLoad(false)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [needLoad, setNeedLoad, service, dataId])

    return (
        <>
            <Loader isLoading={loading} />
            <Context.Provider value={value}>
                {children}
            </Context.Provider>
        </>
    )
}

export default InfoPlanterContext
