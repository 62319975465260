import React from 'react'
import { Button as MuiButton } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0.5
    },
    label: {
        textTransform: 'none'
    },
    background: {
        backgroundColor: '#0FCB8F'
    }
}))

export default function CustomizedButton(props) {
    const { text, size, variant, onClick, customStyles, other } = props
    const classes = useStyles()

    return (
        <MuiButton
            style={{
                backgroundColor: '#0FCB8F',
                borderRadius: 10,
                color: '#fff',
                textTransform: 'unset',
                fontWeight: 600,
                ...customStyles
            }}
            variant={variant || 'contained'}
            size={size || 'small'}
            color="primary"
            onClick={onClick}
            {...other}
            classes={{ root: classes.root, label: classes.label }}
        >
            {text}
        </MuiButton>
    )
}
