
import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class PlantationsMonitoring extends Service {
    static messages = {
        update_success: 'Modification Paysans avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création Paysans avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/plantations/monitoring')
        return this
    }

    static async getById(){
        return await Http.get([this.url].join('/'), {})
    }

    static async getMonitoringByPlantationId(id){
        this.url = "/api/plantations/monitoring"
        return await Http.get([this.url, 'byPlantationId', id].join('/'), {})
    }
}

export default PlantationsMonitoring
