// import { Http } from '../../../applications/Http.js'
import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class NurseryService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.',
        get_error: 'Erreur lors du chargement des données.'
    }
    static load() {
        this.setUrl('/api/nursery/agro')
        return this
    }

    static async getAllNursery(filter = null, com = null) {
        try {
            let params = null
            if (filter) {
                params = filter
                if (com) {
                    params = {
                        ...params,
                        com: com
                    }
                }
            }
            const response = await Http.get(this.url, params)
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async getById(id) {
        try {
            const response = await Http.get([this.url, id].join('/'), {})
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async getAllNurseryFilter(filter = null, com = null, ct = null) {
        try {
            let params = null
            if (filter) {
                params = filter
                if (com) {
                    params = {
                        ...params,
                        com: com
                    }
                }
                if (ct) {
                    params = {
                        ...params,
                        ct: ct
                    }
                }
            }
            const response = await Http.get([this.url, 'filter'].join('/'), params)
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async getByCode(code) {
        try {
            const response = await Http.get([this.url, 'byCode', code].join('/'), {})
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async getAllNurseryBySite(siteId) {
        try {
            const response = await Http.get(['/api/nursery/agro', 'bySite', siteId].join('/'))
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async addNursery(data) {
        try {
            this.url = '/api/nursery/agro'
            const response = await Http.put(this.url, data)
            return response
        } catch (error) {
            throw new Error("Erreur lors de l'ajout de la pépinière.")
        }
    }

    static async updateNursery(id, data) {
        try {
            const response = await Http.patch([this.url, id].join('/'), data)
            return response.data
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour de la pépinière.')
        }
    }

    static async getDataAdd() {
        try {
            const url = [this.url, `dataAdd`].join('/')
            console.log(url)
            const response = await Http.get(url, {})
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async getNuseryPlantMaterial(id) {
        try {
            const url = [this.url, `plantMaterial`, id].join('/')
            console.log(url)
            const response = await Http.get(url, {})
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async getCentralBySiteId(siteId) {
        try {
            const url = [this.url, `centralBySite`, siteId].join('/')
            const response = await Http.get(url, {})
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async deleteNursery(params){
        try {
            const url = [this.url, 'byNurseryId', params].join('/')
            return await Http.delete(url, {})
        } catch (error) {
            console.log(error)
        }
    }
}

export default NurseryService
