import React, { useState } from 'react'
import Controls from './Controls'
import { FaTrashAlt, FaFile } from 'react-icons/fa'

const useStyles = {
    labelInput: {
        fontSize: '12px'
    },
    labelContainer: {
        marginBottom: '20px',
        width: '100%',
        display: 'block'
    },
    fileContainer: {
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        height: '120px',
        border: '1px solid #ccc',
        padding: '10px',
        width: '100%',
        borderRadius: '5px',
        overflow: 'hidden',
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
        scrollbarColor: '#d0e0d7 #E7F2EE00',
        '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
            background: '#d0e0d7'
        }
    },
    fileItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    fileIcon: {
        fontSize: '40px',
        color: '#999'
    },
    removeButton: {
        cursor: 'pointer',
        color: 'red',
        marginTop: '5px'
    }
}

function MultipleFileInput({ label, onChange, name, setValues }) {
    const classes = useStyles
    const [fileValues, setFileValues] = useState([])

    const handleAddFile = (event) => {
        const files = Array.from(event.target.files)
        files.forEach((file) => {
            const reader = new FileReader()
            reader.onloadend = () => {
                const base64 = reader.result
                const newFileValue = {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    base64: base64
                }
                setFileValues((prevValues) => {
                    const updatedValues = [...prevValues, newFileValue]
                    onChange({
                        target: {
                            name,
                            value: updatedValues.map((file) => file.base64)
                        }
                    })
                    setValues(updatedValues.map((file) => file.base64))
                    return updatedValues
                })
            }
            reader.readAsDataURL(file)
        })
    }

    const handleRemoveFile = (index) => {
        const newFileValues = fileValues.filter((_, i) => i !== index)
        console.log(newFileValues)
        setValues(newFileValues.map((file) => file.base64))
        setFileValues(newFileValues)
        if (onChange) {
            onChange({
                target: {
                    name,
                    value: newFileValues
                }
            })
        }
    }

    return (
        <div style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <div style={classes.labelInput}>{label}</div>
                <input type="file" multiple style={{ display: 'none' }} id="file-upload" onChange={handleAddFile} />
                <label htmlFor="file-upload">
                    <Controls.Button text="Ajouter" size="small" component="span" />
                </label>
            </div>
            <div style={classes.fileContainer}>
                {fileValues.map((file, index) => (
                    <div key={index} style={classes.fileItem}>
                        <FaFile style={classes.fileIcon} />
                        <div>{file.name}</div>
                        <FaTrashAlt style={classes.removeButton} onClick={() => handleRemoveFile(index)} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MultipleFileInput
