import { useEffect, useState } from 'react'
import Popup from '../../../../../../layouts/Popup'
import { Container, Grid } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls'
import DataSetsService from '../../../../../datasets/services/DataSets'

function AddPedologicalDiag(props) {
    const { openPopup, setOpenPopup, onSave, values, errors, handleInputChange } = props
    const [substratColorOption, setSubstratColorOption] = useState([])
    const [soilTextureOption, setSoilTextureOption] = useState([])
    const [depthOption, setDepthOption] = useState([])
    const dataset = DataSetsService.load()

    const [data, setData] = useState(null)

    const handleSaveData = () => {
        onSave(data)
        console.log(data)
        setOpenPopup(false)
    }

    useEffect(() => {
        dataset
            .getDataCode('substrat_color')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setSubstratColorOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })

        dataset
            .getDataCode('soil_texture')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setSoilTextureOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })

        dataset
            .getDataCode('soil_depth')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setDepthOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [])

    return (
        <>
            <Popup
                title={'Diagnostic pédologique'}
                sx={{ width: '400px' }}
                setOpenPopup={setOpenPopup}
                openPopup={openPopup}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <Container>
                            <Controls.AutoCompleteSelect
                                label="Couleur substrat"
                                name="substrat_color"
                                value={values?.substrat_color || ''}
                                options={substratColorOption || []}
                                onChange={(e) => {
                                    setData({ ...data, substrat_color: e.target.value })
                                    handleInputChange(e)
                                }}
                                error={errors.substrat_color}
                                labelKey="label"
                                valueKey="label"
                            />
                            {/* <Controls.Input
                                label="Couleur substrat"
                                name="substrat_color"
                                value={values.substrat_color}
                                onChange={(e) => {
                                    setData({ ...data, substrat_color: e.target.value })
                                    handleInputChange(e)
                                }}
                                error={errors.substrat_color}
                            /> */}
                            <Controls.AutoCompleteSelect
                                label="Profondeur"
                                name="depth"
                                value={values?.depth || ''}
                                options={depthOption || []}
                                onChange={(e) => {
                                    setData({ ...data, depth: e.target.value })
                                    handleInputChange(e)
                                }}
                                error={errors.depth}
                                labelKey="label"
                                valueKey="label"
                            />
                            {/* <Controls.Input
                                label="Profondeur"
                                name="depth"
                                value={values.depth}
                                onChange={(e) => {
                                    setData({ ...data, depth: e.target.value })
                                    handleInputChange(e)
                                }}
                                error={errors.depth}
                            /> */}
                        </Container>
                    </Grid>
                    <Grid item xs={6}>
                        <Container>
                            <Controls.AutoCompleteSelect
                                label="Texture de sol"
                                name="soil_texture"
                                value={values?.soil_texture || ''}
                                options={soilTextureOption || []}
                                onChange={(e) => {
                                    setData({ ...data, soil_texture: e.target.value })
                                    handleInputChange(e)
                                }}
                                error={errors.soil_texture}
                                labelKey="label"
                                valueKey="label"
                            />
                            {/* <Controls.Input
                                label="Texture de sol"
                                name="soil_texture"
                                value={values.soil_texture}
                                onChange={(e) => {
                                    setData({ ...data, soil_texture: e.target.value })
                                    handleInputChange(e)
                                }}
                                error={errors.soil_texture}
                            /> */}
                            <Controls.Input
                                label="Salinité (‰)"
                                type="number"
                                name="salinity"
                                value={values.salinity}
                                onChange={(e) => {
                                    setData({ ...data, salinity: e.target.value })
                                    console.log(e.target.value)
                                    handleInputChange(e)
                                }}
                                error={errors.salinity}
                            />
                        </Container>
                    </Grid>
                    <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Controls.Button onClick={handleSaveData} text="Ajouter" size={'small'} />
                    </Container>
                </Grid>
            </Popup>
        </>
    )
}

export default AddPedologicalDiag
