export const regionModel = {
    label: 'Région',
    tableName: 'regions',
    properties: [
        
        {
            label: 'Nom',
            id: 'name',
            type: 'text',
            required: true,
        }
    ]
}