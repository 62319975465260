/* eslint-disable react-hooks/exhaustive-deps */
import { Box, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import DataSetsService from '../services/DataSets'
import { IoSaveOutline } from 'react-icons/io5'
import { CiCirclePlus } from 'react-icons/ci'

export const CustomData = (props) => {
    const { dataId, toggleDrawer } = props

    const [onLoad, setOnLoad] = useState(true)
    const [data, setData] = useState([{ label: '' }])

    useEffect(() => {
        if (onLoad) {
            DataSetsService.getDataForm(dataId)
                .then((response) => {
                    return JSON.parse(response?.data?.database || '{}')
                })
                .then((data) => {
                    console.log(data)
                    if (data?.length >= 0) {
                        setData(data)
                    }
                    setOnLoad(false)
                })
        }
    }, [data])

    const handleAddItem = () => {
        const newItem = { label: '' }
        setData([...data, newItem])
    }

    const handleDeleteItem = (index) => {
        const newData = data.filter((_, i) => i !== index)
        setData(newData)
    }

    const handleChange = (index, value) => {
        const newData = [...data]
        newData[index].label = value
        setData(newData)
    }

    const handleSave = () => {
        const jsonData = JSON.stringify(data)
        DataSetsService.setDataForm(dataId, { database: jsonData })
        toggleDrawer(false)
    }

    return (
        <div>
            {data?.map((item, index) => (
                <div className="flex" key={index}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': {
                                m: 1,
                                width: '40ch',
                                height: '15px'
                            }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="standard-basic"
                            variant="standard"
                            value={item?.label}
                            size="small"
                            inputProps={{ style: { fontSize: '13px' } }}
                            InputLabelProps={{ style: { fontSize: '13px' } }}
                            name="data"
                            onChange={(e) => handleChange(index, e.target.value)}
                        />
                    </Box>
                    <div className="flex justify-end items-end mr-2 ml-3">
                        <FaTrashAlt
                            className="mb-[15px] mt-[12px] mr-[10px] w-5 h-5 transform hover:cursor-pointer text-gray-500  hover:scale-110"
                            onClick={() => handleDeleteItem(index)}
                        />
                    </div>
                </div>
            ))}

            <div className="hover:cursor-pointer  mt-[5px]">
                <CiCirclePlus className="w-8 h-8 " onClick={handleAddItem} />
            </div>
            <div className="flex gap-1 justify-end mt-[10px]">
                <IoSaveOutline
                    className="w-10 h-10 mt-[2px] transform hover:cursor-pointer text-gray-500  hover:scale-110"
                    title="Enregistrer"
                    onClick={handleSave}
                />
            </div>
        </div>
    )
}
