import React, { useEffect, useState } from 'react'
import { Typography, Grid, Paper } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls'
import AddNaturalRegeneration from '../card/AddNaturalRegeneration'

function Topography(props) {
    const { styles, disabledField, handleInputChange, setValues, values } = props

    return (
        <>
            <Typography sx={{ ...styles.form_title, color: '#919ca9' }}>Topographie</Typography>
            <Grid container>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        {/* <Controls.CustomTimePicker
                            needMinutes={false}
                            label={'Temps de submersion'}
                            name="submerge_time"
                            isDisabled={disabledField}
                            onChange={(time) => {
                                console.log(time)
                                handleInputChange({ target: time })
                                setValues({ ...values, submerge_time: time })
                            }}
                        /> */}
                        <Controls.Input
                            label="Temps de submersion (h)"
                            name="submerge_time"
                            type="number"
                            isDisabled={disabledField}
                            onChange={handleInputChange}
                            value={values.submerge_time}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        <Controls.Input
                            label="Nombre de jours de submersion par mois"
                            name="mare_frequency"
                            type="number"
                            isDisabled={disabledField}
                            onChange={(e) => {
                                if (parseInt(e.target.value) < 0) {
                                    e.target.value = 0
                                } else if (parseInt(e.target.value) > 31) {
                                    e.target.value = 31
                                }
                                handleInputChange(e)
                            }}
                            value={values.mare_frequency}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <AddNaturalRegeneration
                disabledField={disabledField}
                values={values}
                handleInputChange={handleInputChange}
                setValues={setValues}
            />
        </>
    )
}

export default Topography
