import React, { useState } from 'react'
import Table from '../../../../components/DataTable/Table'
import { ReactComponent as IconQuadrillage } from '../../../../assets/icons/quadrillage.svg'
import Controls from '../../../../components/Entry/Controls'
import GridView from '../../../../components/popupView/GridView'

export default function PlotList(props) {
    const {
        haveAccessTo,
        currentPage,
        Form,
        drawer,
        service,
        needLoad,
        setNeedLoad,
        filterFromList,
        isPlotMonitoring = false
    } = props
    const [openPopupGrid, setOpenPopupGrid] = useState(false)
    const [gridData, setGridData] = useState(null)

    const headCells = [
        { id: 'code', label: 'Code' },
        {
            id: 'peasant_name',
            label: 'Nom paysan',
            link: 'true',
            showView: { link: '/app/agroforestry/peasants/view', param: 'peasant' }
        },
        { id: 'fkt', label: 'Fokontany' },
        { id: 'village', label: 'Village' },
        { id: 'surface', label: 'Surface (m²)', type: 'Integer' },
        { id: 'plot_monitoring_sate', label: 'Etat' },
        {
            id: 'actions',
            type: 'actions',
            label: 'Actions',
            disableSorting: true,
            showEdit: false,
            showRemove: true,
            showView: '/app/agroforestry/plots/view'
        }
    ]

    if (!isPlotMonitoring) {
        // delete headCells where id = 'plot_monitoring_sate'
        headCells.splice(5, 1)
    }

    return (
        <>
            <Table
                title="Information parcelle"
                headCells={headCells}
                service={service}
                Form={Form}
                drawer={drawer}
                keyToSearch="code"
                haveAccessTo={haveAccessTo}
                currentPage={currentPage}
                setNeedLoad={setNeedLoad}
                filterFromList={filterFromList}
                needLoad={needLoad}
                hideToolbar={false}
                hideFilterButton={true}
                showSiteButton={false}
            />

            <GridView
                openPopup={openPopupGrid}
                setOpenPopup={setOpenPopupGrid}
                title="Polygone de quadrillage"
                gridData={gridData}
            />
        </>
    )
}
