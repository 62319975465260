import React, { useContext, useEffect, useState } from 'react'
import { Grid, Container, Paper, Chip, Typography, Box } from '@mui/material'
import AdministrativeDelimitation from './form/AdministrativeDelimitation'
import LandInformations from './form/LandInformations'
import SavedParameters from './card/SavedParameters'
import Topography from './form/Topography'
import Controls from '../../../../../components/Entry/Controls'
import { Context } from '../../utils/hooks/LandDataContext'
import { isEmptyObject } from '../../../../../applications/UtilFunctions'
import { LoadScript } from '@react-google-maps/api'
import Map from '../../../../../components/Entry/Map'
import QrViewer from '../../../../../components/Entry/QrViewer'
import Popup from '../../../../../layouts/Popup'
import ImportationContent from '../../../../../components/PageView/Form/ImportationContent'
import GridImport from '../../../../data_import/components/gridImport/GridImport'

const styles = {
    second_section_container: {
        padding: 1,
        backgroundColor: 'none',
        boxShadow: 'none',
        borderLeft: '1px solid #E5E5E5'
    },
    paper: {
        padding: '20px 0px 0px 0px',
        backgroundColor: 'none',
        boxShadow: 'none',
        '& .MuiPaper-root': {
            backgroundColor: 'none',
            boxShadow: 'none'
        }
    },
    list_semi_container: {
        padding: '20px 0px 20px 0px',
        backgroundColor: '#f8fcff',
        boxShadow: 'none'
    },
    form_title: {
        padding: `10px 0px 20px 0px `,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    },
    form_input_container: {
        padding: '0px 10px 0px 10px'
    },

    paperInput: {
        padding: '0px 20px 0px 0px',
        backgroundColor: 'none',
        boxShadow: 'none',
        '& .MuiPaper-root': {
            backgroundColor: 'none',
            boxShadow: 'none'
        }
    }
}
function LandDetailsContent(props) {
    const { setNeedLoad, action } = props
    const landData = useContext(Context)
    const entity_name = 'land'
    const [technicianData, setTechnicianData] = useState(null)
    const [administrativeDelimitationData, setAdministrativeDelimitationData] = useState(null)
    const [landInformationsData, setLandInformationsData] = useState(null)
    const [savedParametersData, setSavedParametersData] = useState(null)
    const [topographyData, setTopographyData] = useState(null)
    const [naturalRegenerationData, setNaturalRegenerationData] = useState(null)
    const [userHaveToUploadGrid, setUserHaveToUploadGrid] = useState(false)
    const [landDegradationData, setLandDegradationData] = useState(null)
    const [landLocationData, setLandLocationData] = useState(null)

    const [deseaseOption, setDeseaseOption] = useState([
        { id: true, title: 'Oui' },
        { id: false, title: 'Non' }
    ])
    const [landChangementOption, setLandChangementOption] = useState([
        { id: true, title: 'Oui' },
        { id: false, title: 'Non' }
    ])

    const [desease, setDesease] = useState(null)
    const [landChangement, setLandChangement] = useState(null)
    const [grid, setGrid] = useState(null)

    useEffect(() => {
        if (isEmptyObject(landData)) {
            setNeedLoad(true)
        }
        if (!isEmptyObject(landData)) {
            console.log('data land dans data content : ', landData)
            setTechnicianData(landData.dataTechnician)
            setAdministrativeDelimitationData(landData.administrativeDelimitationData)
            setLandInformationsData(landData.landInformationsData)
            setSavedParametersData(landData.savedParametersData)
            setTopographyData(landData.topographyData)
            setNaturalRegenerationData(landData.naturalRegenerationData)
            setLandDegradationData(landData.landDegradationData)
            setLandLocationData(landData.landLocationData)
            setNeedLoad(false)
        }
    }, [landData, setNeedLoad])

    useEffect(() => {
        if (landDegradationData) {
            setDesease(landDegradationData.have_desease)
            setLandChangement(landDegradationData.use_land_changement)
        }
    }, [landDegradationData, desease, landChangement])

    useEffect(() => {
        if (landLocationData?.grid) {
            console.log(landLocationData.grid)
            setGrid([
                {
                    id: 1,
                    coordinates: landLocationData?.grid?.coordinates
                }
            ])
        }
    }, [landLocationData])
    return (
        <>
            <Container
                sx={{
                    paddingTop: '1rem',
                    paddingBottom: '1rem'
                }}
            >
                <Grid container>
                    <Grid item xs={7.5}>
                        <p style={{ color: '#677788', paddingBottom: '0.5rem', fontSize: '0.7rem' }}>Technicien</p>
                        <Paper sx={{ padding: '0.4rem', borderRadius: '0.5rem', display: 'inline-flex' }}>
                            {technicianData?.map((tech, index) => (
                                <Chip
                                    key={index}
                                    style={{ color: '#677788', fontSize: '0.7rem', margin: '0.2rem', height: '1.3rem' }}
                                    label={[tech.firstname, ' ', tech.lastname[0], '.'].join('')}
                                />
                            ))}
                        </Paper>
                        <Paper sx={styles.paper}>
                            <Paper>
                                <AdministrativeDelimitation
                                    setNeedLoad={setNeedLoad}
                                    styles={styles}
                                    administrativeDelimitationData={administrativeDelimitationData}
                                />
                            </Paper>
                            <Paper>
                                <LandInformations
                                    setNeedLoad={setNeedLoad}
                                    styles={styles}
                                    landInformationsData={landInformationsData}
                                />
                            </Paper>
                            <Paper>
                                <SavedParameters
                                    setNeedLoad={setNeedLoad}
                                    styles={styles}
                                    savedParametersData={savedParametersData}
                                />
                            </Paper>
                            <Paper sx={{ paddingBottom: '1rem' }}>
                                <Topography
                                    styles={styles}
                                    topographyData={topographyData}
                                    naturalRegenerationData={naturalRegenerationData}
                                />
                            </Paper>
                            {landDegradationData && (
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Paper sx={styles.paperInput}>
                                            <Controls.CustomSelect
                                                label="Cause de dégradation"
                                                name="sub_time"
                                                options={[{ id: 1, title: `${landDegradationData.cause || 'Autre'}` }]}
                                                value={1}
                                                labelKey="title"
                                                valueKey="id"
                                                isDisabled={true}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Paper sx={{ ...styles.paperInput }}>
                                            <Controls.DynamicTextInput
                                                placeholder={'Texte explicatif'}
                                                customStyle={{
                                                    height: landDegradationData.explanation_cause ? '100%' : '70px',
                                                    marginBottom: '10px'
                                                }}
                                                value={landDegradationData.explanation_cause || ''}
                                                isDisabled={true}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Paper sx={{ ...styles.paperInput }}>
                                            <Controls.Input
                                                label="Date de dégradation"
                                                name="first_germination_date"
                                                isDisabled={true}
                                                value={landDegradationData.degradation_date || ''}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper sx={{ ...styles.paperInput, paddingBottom: '1rem' }}>
                                            <Controls.RadioGroup
                                                label="Présence des maladies"
                                                name="recipient_polygon"
                                                items={deseaseOption}
                                                value={desease}
                                                isDisabled={true}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper sx={{ ...styles.paperInput }}>
                                            <Controls.RadioGroup
                                                label="Changement d'utilisation de terre"
                                                name="recipient_polygon"
                                                items={landChangementOption}
                                                value={landChangement}
                                                isDisabled={true}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Paper sx={{ ...styles.paperInput }}>
                                            <Controls.DynamicTextInput
                                                placeholder={'Texte explicatif'}
                                                customStyle={{
                                                    height: landDegradationData.explanation_use ? '100%' : '70px',
                                                    marginBottom: '10px'
                                                }}
                                                value={landDegradationData.explanation_use}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {landInformationsData?.photos && (
                                            <Paper sx={{ ...styles.paperInput, paddingBottom: '1rem' }}>
                                                <Typography sx={{ ...styles.form_title, color: '#687889' }}>
                                                    Photo
                                                </Typography>
                                                <Paper
                                                    //display photos on line and add margin left and right 5px for each photo
                                                    style={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        justifyContent: 'left',
                                                        margin: '-5px'
                                                    }}
                                                >
                                                    {landInformationsData.photos.map((item, index) => (
                                                        <Controls.CustomImageAvatar
                                                            place="left"
                                                            size={80}
                                                            key={index}
                                                            src={item}
                                                            customStyle={{ marginRight: '10px' }}
                                                        />
                                                    ))}
                                                </Paper>
                                            </Paper>
                                        )}
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Paper sx={{ ...styles.paperInput }}>
                                            <Controls.Input
                                                label="Note"
                                                value={landDegradationData.note}
                                                isDisabled={true}
                                            />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={4.5} sx={{ borderLeft: '1px solid #E5E5E5' }}>
                        <Container>
                            <Paper sx={{ ...styles.paperInput }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Controls.Input
                                            label="Coordonnées GPS"
                                            value={landLocationData && landLocationData.gps_coordinate}
                                            isDisabled={true}
                                        />
                                    </Grid>
                                    {/* <Grid
                                        item
                                        xs={2}
                                        sx={{
                                            paddingTop: '1.5rem',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        <Controls.CustomizedButton
                                            disabled={false}
                                            text="OK"
                                            variant="contained"
                                            size={'large'}
                                            customStyles={{
                                                marginRight: '1rem',
                                                backgroundColor: '#ffffff',
                                                color: '#0FCB8F',
                                                border: '3px solid #0FCB8F',
                                                boxShadow: 'none'
                                            }}
                                        />
                                    </Grid> */}
                                </Grid>
                                {grid ? (
                                    <LoadScript>
                                        <Map data={grid} zoom={10} />
                                    </LoadScript>
                                ) : (
                                    <>
                                        <h5>(Aucun quadrillage n'a été enregistré.)</h5>
                                    </>
                                )}
                                {/**
                                 * bouton pour importation grid
                                 */}
                                <Controls.GridImportButton setUserHaveToUploadGrid={setUserHaveToUploadGrid} />
                                <Box sx={{ paddingTop: '40px' }}>
                                    <Container>
                                        <QrViewer value={landData?.code_qr} size={'60%'} />
                                    </Container>
                                </Box>
                                {/* <Controls.Input
                                    label="Lieux ou adresse"
                                    value={landLocationData && landLocationData.adress}
                                    isDisabled={true}
                                />
                                <Controls.Input
                                    label="Latitude (x.y °)"
                                    value={landLocationData && landLocationData.latitude}
                                    isDisabled={true}
                                />
                                <Controls.Input
                                    label="Longitude (x.y °)"
                                    value={landLocationData && landLocationData.longitude}
                                    isDisabled={true}
                                />
                                <Controls.Input
                                    label="Altitude (m)"
                                    value={landLocationData && landLocationData.altitude}
                                    isDisabled={true}
                                />
                                <Controls.Input
                                    label="Précision (m)"
                                    value={landLocationData && landLocationData.precision}
                                    isDisabled={true}
                                /> */}
                            </Paper>
                        </Container>
                    </Grid>
                    {/* {!landInformationsData?.state_restore_land && (
                        <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Controls.CustomizedButton
                                disabled={false}
                                text="Refuser"
                                variant="contained"
                                size={'large'}
                                customStyles={{
                                    marginRight: '1rem',
                                    backgroundColor: '#ffffff',
                                    color: '#0FCB8F',
                                    border: '3px solid #0FCB8F',
                                    boxShadow: 'none',
                                    '& .MuiButtonBase-root': {
                                        hover: {
                                            backgroundColor: '#0FCB8F',
                                            color: '#ffffff'
                                        }
                                    }
                                }}
                            />
                            <Controls.Button disabled={false} text="Valider" variant="contained" size={'large'} />
                        </Container>
                    )} */}
                </Grid>
            </Container>

            <Popup
                title="Importation quadrillage"
                openPopup={userHaveToUploadGrid}
                setOpenPopup={setUserHaveToUploadGrid}
            >
                <ImportationContent title={`Quadrillage pour le terrain : ${landData?.code}`}>
                    <GridImport
                        entity={{
                            entity: entity_name,
                            entity_id: landData?.id,
                            column_name: 'land_grid'
                        }}
                        setNeedLoad={setNeedLoad}
                        setUserHaveToUpload={setUserHaveToUploadGrid}
                    />
                </ImportationContent>
            </Popup>
        </>
    )
}

export default LandDetailsContent
