export const etalonnageModel = {
    label: 'Etalonnage',
    tableName: 'calibration',
    properties: [
        {
            label: 'Espèce',
            id: 'species',
            isForeignKey: true,
            column_name: 'name',
            table_column: 'specie_id',
            type: 'text',
            required: true,
        },
        {
            label: 'Unité',
            id: 'unity',
            type: 'text',
            required: true,
        },
        {
            label: 'Quantité',
            id: 'value',
            type: 'text',
            required: true,
        }
    ]
}