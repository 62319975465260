import React, { useEffect, useState } from 'react'
import { Typography, Grid, Paper } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls'
import LandedStateFiles from '../popup/LandedStateFiles'
import { convertM2ToHa } from '../../../../../../applications/UtilFunctions'

function LandInformations(props) {
    const { styles, landInformationsData, setNeedLoad } = props
    const recipient_polygon = [
        { id: true, title: 'Oui' },
        { id: false, title: 'Non' }
    ]
    const [recipientPolygonValue, setRecipientPolygonValue] = useState(null)
    const [openLandedState, setOpenLandedState] = useState(false)

    useEffect(() => {
        setNeedLoad(true)
        if (landInformationsData) {
            console.log(landInformationsData)
            setRecipientPolygonValue(landInformationsData.is_recipient_polygon)
            setNeedLoad(false)
        }
    }, [landInformationsData, setNeedLoad])
    return (
        <>
            <Typography sx={styles.form_title}>Information terrains</Typography>
            <Grid container>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        <Controls.Input
                            label="ID polygone de quadrillage"
                            name="land_grid"
                            value={landInformationsData?.land_grid}
                            isDisabled={true}
                        />
                        <Controls.Input
                            label="Classe de mangroves"
                            name="mangrove_class"
                            value={landInformationsData?.mangrove_class}
                            isDisabled={true}
                        />
                        {/* <Controls.DynamicTextInput
                            label="Statut foncier"
                            placeholder={''}
                            customStyle={{
                                height: landInformationsData?.landed_state ? '100%' : '70px',
                                marginBottom: '10px',
                                '& textarea': {
                                    width: '80%'
                                }
                            }}
                            value={landInformationsData?.landed_state.map((item, index) => `fichier ${index + 1}`).join(', ') || ''}
                            isDisabled={true}
                            haveIcon={true}
                            onClick={() => {
                                setOpenLandedState(true)
                            }}
                        /> */}
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        <Controls.Input
                            label="Surface (ha)"
                            name="surface"
                            value={landInformationsData?.surface}
                            isDisabled={true}
                        />
                        <Controls.RadioGroup
                            name="is_recipient_polygon"
                            label="polygone d'étude d'état initial"
                            items={recipient_polygon}
                            value={recipientPolygonValue}
                            isDisabled={true}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {landInformationsData?.landed_state && (
                <LandedStateFiles
                    openPopup={openLandedState}
                    setOpenPopup={setOpenLandedState}
                    fileData={landInformationsData?.landed_state}
                />
            )}
        </>
    )
}

export default LandInformations
