import React, { useState, useEffect, useContext } from 'react'

import { Form, useForm } from '../../../../applications/hooks/UseForm'
import Controls from '../../../../components/Entry/Controls'
import { useNavigate } from 'react-router-dom'
import Notification from '../../../../layouts/Notification'

import ProvinceService from '../../../provinces/services/Provinces'
import PlatDrainService from '../services/plat_drain'
import moment from 'moment'
import SpeciesService from '../../../species/services/Species'
import { LoadScript } from '@react-google-maps/api'
import Map from '../../../../components/Entry/Map'
import { Box, Container, Grid, Typography } from '@mui/material'
import { context } from '../utils/hooks/PlatDrainContext'
import QrViewer from '../../../../components/Entry/QrViewer'
import TableDetail from '../../../../components/DataTable/TableDetail'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import VillageAutoCompleteSection from '../../../provinces/components/VillageAutoCompleteSection'
import ImportationContent from '../../../../components/PageView/Form/ImportationContent'
import GridImport from '../../../data_import/components/gridImport/GridImport'
import Popup from '../../../../layouts/Popup'
import SectionTitle from '../../../../components/Typography/SectionTitle'

const { Input: TextField, Button } = Controls

const initialFValues = {
    sowing_date: new Date().toISOString(),
    species: '',
    responsable: '',
    quantity: '',
    quantity_repique: '',
    quantity_rebouche: '',
    site: ''
}

const headCellsTraining = [
    { id: 'date', label: 'Date de suivi', type: 'date' },
    { id: 'nbr_death', label: 'Nombre de morts' },
    { id: 'nbr_refilled', label: 'Nombre regarnies' },
    { id: 'average_number', label: 'Nombre de feuille (Moy.) ' },
    { id: 'average_height', label: 'Hauteur moyenne' },
    { id: 'responsable', label: 'Responsable' },
    {
        id: 'actions',
        type: 'actions',
        label: 'Actions',
        disableSorting: true,
        showEdit: false,
        showRemove: false,
        showView: '/app/mangrove/nursery/tracking/edit',
        forcePassId: true
    }
]

const headCellsOuting = [
    { id: 'release_date', label: 'Date de sortie', type: 'date' },
    { id: 'planting', label: 'Plantation attribuée' },
    { id: 'land', label: "Parcelle d'affectation" },
    { id: 'nbr_output', label: 'Nombre sortie' },
    { id: 'nbr_remaining', label: 'Nombre restant' },
    {
        id: 'actions',
        type: 'actions',
        label: 'Actions',
        disableSorting: true,
        showEdit: false,
        showRemove: false,
        showView: '/app/mangrove/nursery/outing/edit',
        forcePassId: true
    }
]

export const PlatDrainForm = (props) => {
    const { records, setNeedLoad } = props
    const entity_name = 'plat_drain'
    const platDrainContext = useContext(context)
    const [userHaveToAdd, setUserHaveToAdd] = useState(false)
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [platDrainGrid, setPlatDrainGrid] = useState(null)
    const [userHaveToUploadGrid, setUserHaveToUploadGrid] = useState(false)
    const { user } = useAuth()

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('species' in fieldValues) temp.species = fieldValues.species ? '' : 'Ce champ est requis.'
        if ('site' in fieldValues) temp.site = fieldValues.site ? '' : 'Ce champ est requis.'
        if ('sowing_date' in fieldValues) temp.sowing_date = fieldValues.sowing_date ? '' : 'Ce champ est requis.'
        if ('responsable' in fieldValues) temp.responsable = fieldValues.responsable ? '' : 'Ce champ est requis.'
        if ('quantity_repique' in fieldValues)
            temp.quantity_repique = fieldValues.quantity_repique ? '' : 'Ce champ est requis.'
        if ('quantity_rebouche' in fieldValues)
            temp.quantity_rebouche = fieldValues.quantity_rebouche ? '' : 'Ce champ est requis.'

        console.log(temp)
        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate)

    useEffect(() => {
        if (platDrainContext) {
            console.log('plat drain context : ', platDrainContext)
            setUserHaveToAdd(platDrainContext?.userHaveToAdd)
        }
    }, [platDrainContext])

    const navigate = useNavigate()

    const [site, setSite] = useState([])
    const [specie, setSpecie] = useState([])

    useEffect(() => {
        if (records) {
            console.log('records dans plat drain form : ', records)
            setValues((prevFormData) => ({
                ...prevFormData,
                site: records?.sites?.id || '',
                species: records?.species?.id || '',
                sowing_date: records?.sowing_date ? moment(records.sowing_date).format('YYYY-MM-DD') : '',
                quantity: records?.quantity || 0,
                quantity_repique: records?.quantity_repique || 0,
                quantity_rebouche: records?.quantity_rebouche || 0,
                responsable: records?.responsable || ''
            }))
            if (records?.grid) {
                setPlatDrainGrid(records?.grid)
            }
        }
    }, [records, setValues])

    useEffect(() => {
        ProvinceService.getVillages().then((response) => {
            if (response?.data) {
                console.log('response', response?.data)
                setSite(response?.data)
            }
        })
    }, [])

    useEffect(() => {
        SpeciesService.getAllSpecies()
            .then((users) => {
                if (users) {
                    setSpecie(users)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [])

    useEffect(() => {
        if (userHaveToAdd) {
            setValues(initialFValues)
        }
    }, [userHaveToAdd, setValues])

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(values, errors)
        // if (!values.site) {
        //     new Notification('Remplir la délimitation administrative !', 0)
        // }
        if (validate()) {
            setUserHaveToPost(true)
            try {
                const dataToSend = { ...values, user: [user.firstname, user.lastname].join(' ') }
                const siteId = parseInt(values.site)
                const specieId = parseInt(values.species)
                delete dataToSend.site
                delete dataToSend.species

                // const quantity = parseInt(dataToSend.quantity)
                const quantity_repiqueValue = parseInt(dataToSend.quantity_repique)
                const quantity_reboucheValue = parseInt(dataToSend.quantity_rebouche)

                // dataToSend.quantity = quantitys
                dataToSend.quantity_repique = quantity_repiqueValue
                dataToSend.quantity_rebouche = quantity_reboucheValue
                dataToSend.siteId = siteId
                dataToSend.specieId = specieId

                console.log('données envoyées : ', dataToSend)
                if (records?.id) {
                    await PlatDrainService.updatePlatDrain(records.id, dataToSend)
                    new Notification(PlatDrainService.messages.update_success, 1)
                    setUserHaveToPost(false)
                } else {
                    await PlatDrainService.addPlatDrain(dataToSend)
                    new Notification(PlatDrainService.messages.create_success, 1)
                    setUserHaveToPost(false)
                }
                navigate(-1)
            } catch (error) {
                console.error('Erreur lors de la soumission du formulaire : ', error)
                new Notification(PlatDrainService.messages.create_error, 0)
                setUserHaveToPost(false)
            }
        }
    }

    const handleChange = (e) => {
        handleInputChange(e)

        setValues((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <>
            <Form>
                <Grid container style={{ paddingTop: '20px' }}>
                    <Grid
                        item
                        xs={userHaveToAdd ? 12 : 8}
                        sx={!userHaveToAdd && { borderRight: '1px solid #C0C0C0', pr: 1 }}
                    >
                        <div
                            style={{
                                padding: '0px 15px 0px 15px'
                            }}
                        >
                            <SectionTitle>Délimitation administrative</SectionTitle>
                            {userHaveToAdd && (
                                <VillageAutoCompleteSection
                                    values={values}
                                    setValues={setValues}
                                    errors={errors}
                                    onChange={handleInputChange}
                                    isRequired={true}
                                />
                            )}
                        </div>
                        {!userHaveToAdd && (
                            <Grid container>
                                <Grid item xs={6}>
                                    <Container>
                                        <TextField
                                            id="outlined-disabled"
                                            label="Région"
                                            name="designation"
                                            value={records?.site?.fkt?.commune?.district?.regions?.name}
                                            size="small"
                                            onChange={handleChange}
                                            isDisabled={userHaveToAdd ? false : true}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="assignment"
                                            value={records?.site?.fkt?.commune?.name}
                                            size="small"
                                            label="Commune"
                                            isDisabled={userHaveToAdd ? false : true}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="quantity"
                                            value={records?.site?.name}
                                            size="small"
                                            label="Village"
                                            onChange={handleChange}
                                            isDisabled={userHaveToAdd ? false : true}
                                        />
                                    </Container>
                                </Grid>
                                <Grid item xs={6}>
                                    <Container>
                                        <TextField
                                            id="outlined-disabled"
                                            label="District"
                                            name="designation"
                                            value={records?.site?.fkt?.commune?.district?.name}
                                            size="small"
                                            onChange={handleChange}
                                            isDisabled={userHaveToAdd ? false : true}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="assignment"
                                            value={records?.site?.fkt?.name}
                                            size="small"
                                            label="Fokontany"
                                            isDisabled={userHaveToAdd ? false : true}
                                        />
                                    </Container>
                                </Grid>
                            </Grid>
                        )}
                        <div
                            className="font-semibold text-sm mb-[20px]  mt-[20px]"
                            style={{
                                padding: '0px 15px 0px 15px'
                            }}
                        >
                            Information pépinière
                        </div>
                        <Grid container>
                            <Grid item xs={6}>
                                <Container>
                                    {!userHaveToAdd && (
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="code"
                                            value={records?.code}
                                            size="small"
                                            label="ID plate bande"
                                            isDisabled={userHaveToAdd ? false : true}
                                        />
                                    )}
                                    <Controls.AutoCompleteSelect
                                        label="Espèces"
                                        name="species"
                                        value={values?.species || ''}
                                        onChange={handleChange}
                                        options={specie}
                                        labelKey="name"
                                        valueKey="id"
                                        error={errors?.species}
                                        isDisabled={userHaveToAdd ? false : true}
                                        isRequired={true}
                                    />
                                    <Controls.DatePicker
                                        label="Date de semis"
                                        name="sowing_date"
                                        onChange={handleChange}
                                        type="date"
                                        isDisabled={userHaveToAdd ? false : true}
                                        value={values?.sowing_date}
                                        error={values?.sowing_date}
                                        isRequired={true}
                                    />
                                    {/* {!userHaveToAdd && ( */}
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        value={values?.quantity}
                                        name="quantity"
                                        size="small"
                                        label="Quantité"
                                        onChange={handleChange}
                                        type="number"
                                        error={errors?.quantity}
                                        isDisabled={userHaveToAdd ? false : true}
                                        // isRequired={true}
                                    />
                                    {/* )} */}
                                </Container>
                            </Grid>
                            <Grid item xs={6}>
                                <Container>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="responsable"
                                        value={values?.responsable}
                                        size="small"
                                        label="Pépiniériste responsable"
                                        onChange={handleChange}
                                        error={errors?.responsable}
                                        isDisabled={userHaveToAdd ? false : true}
                                        isRequired={true}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        value={values?.quantity_rebouche}
                                        name="quantity_rebouche"
                                        size="small"
                                        label="Nombre de pots rebouchés "
                                        onChange={handleChange}
                                        type="number"
                                        error={errors?.quantity_rebouche}
                                        isDisabled={userHaveToAdd ? false : true}
                                        isRequired={true}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        value={values?.quantity_repique}
                                        name="quantity_repique"
                                        size="small"
                                        label="Nombre de pots repiqués "
                                        onChange={handleChange}
                                        type="number"
                                        error={errors?.quantity_repique}
                                        isDisabled={userHaveToAdd ? false : true}
                                        isRequired={true}
                                    />
                                </Container>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!userHaveToAdd && (
                        <>
                            <Grid item xs={4}>
                                <Container style={{ paddingTop: '20px' }}>
                                    <div>
                                        <div className="text-xs"> Localisation </div>
                                        <LoadScript>
                                            {platDrainGrid ? (
                                                <Map data={platDrainGrid || null} zoom={10} />
                                            ) : (
                                                <Typography style={{ textAlign: 'center' }}>
                                                    (Aucun quadrillage.)
                                                </Typography>
                                            )}
                                        </LoadScript>
                                        {/**
                                         * bouton pour importation grid
                                         */}
                                        <Controls.GridImportButton setUserHaveToUploadGrid={setUserHaveToUploadGrid} />
                                    </div>
                                    <div className="mt-[20px]">
                                        <div className="text-xs "> Photos </div>
                                        <div className="  flex items-center mt-[10px] w-[100px] h-[100px]">
                                            <Controls.CustomImageAvatar
                                                title="Photo"
                                                src={'vide'}
                                                size={100}
                                                place="left"
                                            />
                                        </div>
                                    </div>
                                    <Box sx={{ paddingTop: '40px' }}>
                                        <QrViewer value={records?.code_qr} size={'50%'} place="left" />
                                    </Box>
                                </Container>
                            </Grid>
                        </>
                    )}
                </Grid>
                {userHaveToAdd && (
                    <div className="flex justify-end items-end m-5 p-5">
                        <Button text="Enregistrer" onClick={handleSubmit} haveSpin={true} disabled={userHaveToPost} />
                    </div>
                )}
            </Form>
            {!userHaveToAdd && (
                <>
                    <TableDetail
                        headCells={headCellsTraining}
                        // service={platDrainTrackingService}
                        hideFilterButton={true}
                        needData={true}
                        data={records?.Plat_drain_tracking}
                        title={'Suivis'}
                    />
                    <TableDetail
                        headCells={headCellsOuting}
                        // service={platDrainTrackingService}
                        hideFilterButton={true}
                        needData={true}
                        data={records?.OutingNursery}
                        title={'Sorties'}
                    />
                </>
            )}
            <Popup
                title="Importation quadrillage"
                openPopup={userHaveToUploadGrid}
                setOpenPopup={setUserHaveToUploadGrid}
            >
                <ImportationContent title={`Quadrillage pour la plate-bande : ${records?.code}`}>
                    <GridImport
                        entity={{
                            entity: entity_name,
                            entity_id: records?.id,
                            column_name: 'grid'
                        }}
                        setNeedLoad={setNeedLoad}
                        setUserHaveToUpload={setUserHaveToUploadGrid}
                    />
                </ImportationContent>
            </Popup>
        </>
    )
}
