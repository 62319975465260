import { Container, Grid, Paper } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls.js'
import DatePickerInput from '../../../../../../components/Entry/DatePicker'

import React, { useEffect, useState } from 'react'
import { Form, useForm } from '../../../../../../applications/hooks/UseForm'
import { makeStyles } from '@mui/styles'
import OrderTrackingService from '../../../../../order_tracking/services/OrderTracking.js'
import PlantationService from '../../../../services/Plantation.js'
import Notification from '../../../../../../layouts/Notification.js'

const { Input: TextField } = Controls

const useStyles = makeStyles((theme) => ({
    columnForm: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        border: 'none',
        boxShadow: 'none',
        '& .MuiTextField-root': {
            marginBottom: '20px',
            marginLeft: '0px'
        }
    }
}))

function ReforestationInfo(props) {
    const { records, setNeedLoad } = props
    const classes = useStyles()
    const plantationApi = PlantationService.load()
    const orderApi = OrderTrackingService.load()
    const [userHaveToSetOrder, setUserHaveToSetOrder] = useState(false)
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [orderList, setOrderList] = useState([])

    const handleChange = () => {}

    const initialValues = {
        order: ''
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('order' in fieldValues) temp.order = fieldValues.order ? '' : 'Ce champ est requis.'

        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialValues, true, validate)

    useEffect(() => {
        console.log(records)
        setValues({
            ...records,
            order: records?.order || ''
        })
        setOrderList(records?.order_list)
    }, [records])

    useEffect(() => {
        if (values.order) {
            console.log('order selected ', values.order)
            setValues({
                ...values,
                customer:
                    orderList.length > 0
                        ? orderList?.find((order) => order?.id === values.order).customers?.firstname
                        : ''
            })
        }
    }, [values.order])

    const handleOrderAssignment = () => {
        setUserHaveToSetOrder(true)
        console.log(values)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validate()) {
            setNeedLoad(true)
            setUserHaveToPost(true)
            console.log('values order planting ', values)
            const dataToPost = {
                order_id: values.order
            }
            plantationApi
                .updatePlantation(values?.id, dataToPost)
                .then((response) => {
                    if (response) {
                        setUserHaveToSetOrder(false)
                        const dataOrderState = {
                            state: 'Plantation terminée'
                        }

                        orderApi
                            .updateOrderTracking(values?.order, dataOrderState)
                            .then((response) => {
                                if (response) {
                                    new Notification('Etat opérationnel de la commande à jour.', 1)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                new Notification(
                                    "Erreur lors de la mise à jour de l'etat opérationnel de la commande.",
                                    0
                                )
                            })

                        new Notification('Attribution avec succès.', 1)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    new Notification("Erreur lors de l'attribution de la commande.", 0)
                })
                .finally(() => {
                    setNeedLoad(false)
                    setUserHaveToPost(false)
                })
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Container className={classes.columnForm}>
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="plot"
                            label="Parcelle"
                            value={values?.plot_code || ''}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            label="Fokontany"
                            name="fokontany"
                            value={values?.fkt || ''}
                        />

                        <TextField
                            isDisabled={true}
                            size="small"
                            label="Nom paysan"
                            name="peasant"
                            value={values?.peasant_firstname + ' ' + values?.peasant_lastname || ''}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="number_tree_planted"
                            label="Nombre d'arbres plantés"
                            value={values?.nb_tree_planted || ''}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            label="Surface plantée (m2)"
                            name="surface"
                            value={values?.surface || ''}
                        />
                    </Container>
                </Grid>
                <Grid item xs={6}>
                    {/* <TextField name="peasant" label="Commune" value={records?.peasant || ''} /> */}
                    <Container className={classes.columnForm}>
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="commune"
                            label="Commune"
                            value={values?.commune || ''}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            name="village"
                            label="Village"
                            value={values?.village || ''}
                        />

                        <Controls.AutoCompleteSelect
                            isDisabled={!userHaveToSetOrder}
                            name="order"
                            label="Commande"
                            options={orderList || []}
                            labelKey={'label'}
                            valueKey={'id'}
                            value={values?.order || records?.order}
                            error={errors?.order}
                            onChange={handleInputChange}
                        />
                        <TextField
                            isDisabled={true}
                            size="small"
                            label="Client"
                            name="customer"
                            value={values?.customer || ''}
                        />
                        <Controls.DatePicker
                            name="planting_date"
                            label="Date de plantation"
                            isDisabled={true}
                            value={values?.planting_date || new Date()}
                        />
                    </Container>
                </Grid>
            </Grid>
            <Container>
                <div className="flex gap-2">
                    {!records?.order && (
                        <Controls.Button text="Attribuer à une commande" onClick={handleOrderAssignment} />
                    )}
                    {userHaveToSetOrder && (
                        <Controls.Button
                            type="submit"
                            text="Enregistrer"
                            haveSpin={true}
                            disabled={userHaveToPost}
                            onClick={handleOrderAssignment}
                            icon={'save'}
                        />
                    )}
                </div>
            </Container>
        </Form>
    )
}

export default ReforestationInfo
