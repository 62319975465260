import Notification from '../../../../../layouts/Notification'
import NurseryService from '../../../services/Nursery'

class Nursery {
    constructor(name, target, siteId) {
        this.id = null
        this.name = name
        this.target = target
        this.site_id = siteId
    }

    setId(id) {
        this.id = id
    }

    getId() {
        return this.id
    }

    setTarget(target) {
        try {
            if (!isNaN(target)) this.target = parseInt(target)
        } catch (error) {
            console.log(error)
            new Notification('Veuillez entrer un nombre', 3)
        }
    }

    getTarget() {
        return this.target
    }

    setType(type) {
        this.type = type
    }

    getType() {
        return this.type
    }

    setName(name) {
        this.name = name
    }

    getName() {
        return this.name
    }

    setSiteId(siteId) {
        this.site_id = siteId
    }

    getSiteId() {
        return this.site_id
    }
}

class AppNursery {
    constructor(nursery) {
        this.nursery = nursery || null
        this.service = NurseryService.load()
        this._processResult = null
    }

    async fetchDataNursery(id, siteId) {
        try {
            if (id !== 0) {
                const result = await this.service.get(id)
                if (result?.status.code !== 200) throw new Error(result.status.message)
                return result?.data
            } else if (id === 0) {
                const result = await this.service.getCentralBySiteId(siteId)
                console.log(result)
                return result
            }
        } catch (error) {
            console.log(error)
        }
    }

    async saveNursery(user) {
        try {
            if (!this.nursery.id) {
                const result = await this.service.create({...this.nursery, user: [user?.firstname, user?.lastname].join(' ')})
                console.log(result)
                if (result.status.code !== 200) throw new Error(result.status.message)
                return result
            } else {
                const result = await this.service.update(this.nursery.id, {...this.nursery, user: [user?.firstname, user?.lastname].join(' ')})
                console.log(result)
                if (result.status.code !== 200) throw new Error(result.status.message)
                return result
            }
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}

export { AppNursery, Nursery }
