import React, { useState } from 'react'
import { Form } from '../../../../../../../applications/hooks/UseForm'
import { Container, Grid, Paper } from '@mui/material'
import Controls from '../../../../../../../components/Entry/Controls'
import { useEffect } from 'react'
import Loader from '../../../../../../../components/Entry/Loading'
import { useNavigate } from 'react-router-dom'
import NurseryService from '../../../../../services/Nursery'
import NurserySemisService from '../../../../../services/NurserySemis'
import Notification from '../../../../../../../layouts/Notification'
import ProvinceCard from '../../../../../../provinces/components/ProvinceCardGrid'
import ConfirmDialog from '../../../../../../../layouts/ConfirmDialog'
import DataSetsService from '../../../../../../datasets/services/DataSets'
import { AppNursery, Nursery } from '../../../application/AppNursery'
import { useAuth } from '../../../../../../../applications/hooks/UseAuth'

const defaultOptions = [{ label: 'Option 1', value: 'option1' }]

const initialValues = {
    // site_id: '',
    target: '',
    // SemisType: '',
    // specie_id: '',
    // pot_size: '',
    // SemisDate: new Date().toISOString(),
    // quantity: '',
    // pot_number: '',
    name: ''
    // type: 0
}

function AddNursery(props) {
    const { needLoad } = props
    //const [siteValues, setSiteValues] = useState(null)
    const [semisSpeciesData, setSemisSpeciesData] = useState([])
    const [dataSemisType, setDataSemisType] = useState([])
    const [dataPotSizes, setDataPotSizes] = useState([])

    const [values, setValues] = useState(null)
    const { user } = useAuth()
    const nursery = new Nursery(values?.target, 1, values?.name)
    const [errors, setErrors] = useState({})
    const [centers, setCenters] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedSite, setSelectedSite] = useState(null)
    const [userHaveToAdd, setUserHaveToAdd] = useState(null)
    const [userHaveToModify, setUserHaveToModify] = useState(null)
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [launchSpin, setLaunchSpin] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })

    const nurseryService = NurseryService.load()

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('name' in fieldValues) temp.name = fieldValues.name ? '' : 'Ce champ est requis.'
        if ('target' in fieldValues) temp.target = fieldValues.target ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })
        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '')
        }
    }

    useEffect(() => {
        if (selectedSite || userHaveToPost) {
            setLoading(true)
            nurseryService
                .getAllNurseryBySite(selectedSite)
                .then((response) => {
                    if (response) {
                        console.log('response : ', response)
                        const centersList = response.map((item) => ({
                            id: item.id,
                            label: item.name
                        }))
                        centersList.push({ id: 'n', label: 'Nouvelle pépinière' })

                        setCenters(centersList)
                        setLoading(false)
                    } else {
                        setCenters([{ id: 'n', label: 'Nouvelle pépinière' }])
                        setLoading(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setLoading(false)
                })
        }
    }, [selectedSite, nurseryService, userHaveToPost])

    const handleInputChange = async (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (name === 'site_id') {
            setSelectedSite(value)
        }
        if (name === 'nursery') {
            console.log({ name, value })
            const nursery = new AppNursery()
            if (value !== 'n') {
                setUserHaveToAdd(false)
                setLoading(true)
                nursery.fetchDataNursery(value, values?.site_id).then((response) => {
                    if (response) {
                        setValues({
                            ...values,
                            ...response,
                            nursery: response?.id
                        })
                        setLoading(false)
                    }
                })
            } else if (value === 0) {
            } else {
                setUserHaveToAdd(true)
                setValues({ ...initialValues, site_id: values?.site_id, nursery: 'n' })
            }
        }
        if (name === 'target') {
            setUserHaveToModify(true)
        }
    }

    useEffect(() => {
        if (values) {
            console.log(values)
            nursery.setId(values?.id)
            nursery.setName(values?.name)
            nursery.setTarget(values?.target)
            nursery.setSiteId(values?.site_id)
        }
    }, [values, nursery])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setUserHaveToPost(true)
        console.log(user)
        console.log(values)
        if (validate()) {
            try {
                console.log(nursery)
                if (values.nursery === 'n') {
                    setLaunchSpin(true)
                    nursery.setName(values?.name)
                    nursery.setTarget(values?.target)
                    nursery.setSiteId(values?.site_id)
                    nursery.setType(1)
                    const appNursery = new AppNursery(nursery)
                    appNursery
                        .saveNursery(user)
                        .then((response) => {
                            if (response) {
                                new Notification(nurseryService.messages.create_success, 1)
                                setUserHaveToPost(false)
                                setUserHaveToModify(false)
                                // setUserHaveToAdd(false)
                                setValues({ ...initialValues, site_id: values?.site_id, nursery: 'n' })
                                setLaunchSpin(false)
                            }
                            nursery.setType(null)
                        })
                        .catch((error) => {
                            console.log(error)
                            nursery.setType(null)
                            setUserHaveToPost(false)
                            setUserHaveToModify(false)
                            // setUserHaveToAdd(false)
                            setValues({ ...initialValues, site_id: values?.site_id, nursery: null })
                        })
                } else if (nursery && nursery?.name) {
                    console.log(nursery)
                    const appNursery = new AppNursery(nursery)
                    appNursery.saveNursery(user).then((response) => {
                        if (response) {
                            new Notification(nurseryService.messages.update_success, 1)
                            setUserHaveToPost(false)
                            setUserHaveToAdd(false)
                            setUserHaveToModify(false)
                        }
                    })
                } else {
                    setUserHaveToAdd(false)
                    setUserHaveToPost(false)
                    setUserHaveToModify(false)
                }
            } catch (error) {
                new Notification(nurseryService.messages.update_error, 0)
                setUserHaveToAdd(false)
                setUserHaveToPost(false)
                setUserHaveToModify(false)
                console.log(error)
            }
        }
    }

    const handleDelete = async () => {
        try {
            setUserHaveToAdd(false)
            setUserHaveToPost(true)
            setUserHaveToModify(false)
            let params = new URLSearchParams({
                id: values?.id,
                userStory: [user.firstname, user.lastname].join(' ')
            }).toString()
            const response = await nurseryService.deleteNursery(params)
            if (response) {
                setValues(initialValues)
                new Notification(nurseryService.messages.delete_success, 1)
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false
                })
                setUserHaveToAdd(false)
                setUserHaveToPost(false)
                setUserHaveToModify(false)
            }
        } catch (error) {
            console.error('Erreur lors de la suppression de la Pepinière:', error)
        }
    }

    return (
        <>
            <Loader isLoading={loading}></Loader>
            <Paper sx={{ p: 2 }}>
                <Form>
                    <h1 className="font-semibold mb-[20px] ml-[30px] mt-[20px]">Délimitation administrative</h1>
                    <Grid container spacing={2}>
                        <ProvinceCard site_id={values?.site_id} onChange={handleInputChange} />
                    </Grid>
                    <div className="flex items-center gap-2  mb-[20px] ml-[30px] mt-[20px] ">
                        <h1 className="font-semibold">Pépinière</h1>
                        {nursery?.name && values?.nursery !== 'n' && values?.name !== 'Centrale' && (
                            <Controls.Button
                                text="Supprimer"
                                size="small"
                                color="error"
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        successMessage: 'Suppression en cours ...',
                                        title: 'Vous voulez vraiment le supprimer?',
                                        subTitle: 'Cette opération est irréversible.',
                                        onConfirm: () => {
                                            handleDelete(values?.id)
                                            setUserHaveToAdd(false)
                                            setUserHaveToModify(false)
                                        }
                                    })
                                }
                            />
                        )}
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Container>
                                <Controls.CustomSelect
                                    name="nursery"
                                    label="Pépinière"
                                    options={centers || defaultOptions}
                                    labelKey={'label'}
                                    valueKey={'id'}
                                    onChange={handleInputChange}
                                    value={values?.nursery || ''}
                                />
                                {/* <LoadScript>
                                    <Controls.GpsCoordinatesInput data={data} zoom={10} inputUse={true} />
                                </LoadScript> */}
                            </Container>
                        </Grid>
                        {userHaveToAdd && (
                            <Grid item xs={4}>
                                <Container>
                                    <Controls.Input
                                        name="name"
                                        label="Nom pépinière"
                                        onChange={handleInputChange}
                                        value={values?.name}
                                        isRequired={true}
                                    />
                                </Container>
                            </Grid>
                        )}
                        <Grid item xs={4}>
                            <Container>
                                <Controls.Input
                                    name="target"
                                    label="Objectif de production"
                                    onChange={handleInputChange}
                                    value={values?.target}
                                    type="number"
                                    isRequired={true}
                                />
                            </Container>
                        </Grid>
                    </Grid>
                    {userHaveToAdd || userHaveToModify ? (
                        <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Controls.Button
                                onClick={handleSubmit}
                                text="Enregistrer"
                                haveSpin={launchSpin}
                                disabled={
                                    userHaveToPost
                                        ? true
                                        : values?.name === ''
                                        ? true
                                        : values?.target === ''
                                        ? true
                                        : false
                                }
                            />
                        </Container>
                    ) : (
                        <></>
                    )}
                </Form>
                <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
            </Paper>
        </>
    )
}

export default AddNursery
