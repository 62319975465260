import React from 'react'
import Popup from '../../../../../../layouts/Popup'
import CustomFileView from '../../../../../../components/Entry/CustomFileView'
import { Container, Paper } from '@mui/material'

function AddLandedStateFiles(props) {
    const { openPopup, setOpenPopup, fileData } = props
    return (
        <>
            <Popup title={'Emplacement administratif'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <Container
                    sx={{
                        padding: 0,
                        margin: 0,
                        display: 'flex',
                        '& .MuiPaper-root': {
                            boxShadow: 'none'
                        }
                    }}
                >
                    {fileData &&
                        fileData.map((file, index) => {
                            return (
                                <>
                                    <Paper>
                                        <CustomFileView
                                            fileName={'fichier ' + (index + 1)}
                                            file={file}
                                            type={'image'}
                                        />
                                    </Paper>
                                </>
                            )
                        })}
                </Container>
            </Popup>
        </>
    )
}

export default AddLandedStateFiles
