import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import { Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Subtitle from '../../../../../../components/Typography/Subtitle'
import Loader from '../../../../../../components/Entry/Loading'
import PlantationSpeciesService from '../../../../services/PlantationSpecies'
import Controls from '../../../../../../components/Entry/Controls'

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        backgroundColor: '#f8f8f8',
        margin: '30px 30px 30px 30px',
        border: 'none',
        boxShadow: 'none',
        width: 'auto'
    },
    card: {
        width: '30%',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '& .MuiGrid-root': {
            padding: '20px 5px 20px 5px'
        }
    },
    title: {
        padding: '20px 20px 20px 20px',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none'
    },
    subtitle: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none'
    },
    voirPhoto: {
        fontSize: '13px',
        textAlign: 'end',
        padding: '0 20px 0 20px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    cardContent: {
        display: 'flex',
        flexWrap: 'wrap'
    }
}))

function SpeciesCard(props) {
    const { records, needLoad } = props
    const classes = useStyles()
    const [plantedSpecies, setPlantedSpecies] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            if (needLoad && records?.id) {
                const speciesData = await PlantationSpeciesService.getByPlantationId(records?.id)
                if (speciesData) {
                    setPlantedSpecies(speciesData?.data)
                }
                setLoading(false)
            }
        }

        fetchData()
    }, [needLoad, records])

    return (
        <>
            <Loader isLoading={loading} />

            <div className={classes.cardContainer}>
                <div className={classes.title}>
                    <Subtitle
                        style={{
                            fontSize: '0.8rem',
                            color: '#677788'
                        }}
                    >
                        Espèces plantés ({plantedSpecies.length})
                    </Subtitle>
                </div>
                <Grid className={classes.cardContent}>
                    {plantedSpecies.map((specie) => {
                        return (
                            <div className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                        <Controls.CustomImageAvatar
                                            place={'right'}
                                            title={specie.specie_name}
                                            src={specie.specie_photo}
                                            rounded={50}
                                            size={70}
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <div className={classes.subtitle}>
                                            <Subtitle
                                                style={{
                                                    fontSize: '0.8rem',
                                                    color: '#677788'
                                                }}
                                            >
                                                {specie.specie_name}
                                            </Subtitle>
                                        </div>
                                        <Typography variant="body2" color="text.secondary">
                                            Nombre plantés : {specie.specie_number}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    })}
                </Grid>
            </div>
        </>
    )
}

export default SpeciesCard
