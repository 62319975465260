import React from 'react'
import { CircularProgress, Typography, Container, Paper } from '@mui/material'

const styles = {
    container_info: {
        padding: '3px 3px 3px 3px'
    },
    list_param: { color: '#748291', fontSize: '0.7rem', paddingBottom: '0.5rem' },
    container_chart: {
        padding: '3px 3px 3px 3px',
        backgroundColor: '#f8f8f8',
        borderRadius: '0.5rem'
    }
}
function CustomCircularPercentage(props) {
    const { value = 0, label, size = 90, unit = '%' } = props
    const grey = 'rgba(0, 0, 0, 0.1)' // Couleur de fond grise
    const colorValue = '#13d8f3'

    value && console.log(value)

    return (
        <>
            <Paper sx={{ ...styles.container_chart, margin: 'auto', display: 'table' }}>
                <div style={{ position: 'relative', display: 'inline-flex' }}>
                    {/* Progress actuel */}
                    <CircularProgress
                        variant="determinate"
                        value={value}
                        sx={{ position: 'absolute' }}
                        size={size}
                        style={{ color: colorValue }}
                    />

                    {/* Arrière-plan gris */}
                    <CircularProgress variant="determinate" value={100} size={size} style={{ color: grey }} />

                    {/* Label de pourcentage */}
                    <div style={{ position: 'absolute', width: '100%', textAlign: 'center' ,top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: '0.9rem'
                            }}
                            component="div"
                            color="textSecondary"
                        >{`${value} ${unit}`}</Typography>
                    </div>
                </div>
            </Paper>
            <Container
                sx={{
                    padding: 1,
                    textAlign: 'center',
                    margin: 'auto',
                    color: '#748291',
                    fontSize: '0.68rem'
                }}
            >
                {label}
            </Container>
        </>
    )
}

export default CustomCircularPercentage
