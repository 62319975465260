import React, { useState } from 'react'
import Table from '../../../components/DataTable/Table'
import { formatDate } from '../../../applications/UtilFunctions'
import ContentListViewer from '../../../components/PageView/viewer/ContentListViewer'
// import Controls from '../../../components/Entry/Controls'
// import Drawer from '../../../layouts/Drawer'

// import { FaWpforms } from 'react-icons/fa'

export default function PeasantPlantingList(props) {
    const { haveAccessTo, currentPage, Form, drawer, service, needLoad, setNeedLoad, filterFromList } = props

    const headCells = [
        { id: 'group', label: 'Groupe', condition: true },
        { id: 'code', label: 'Code', condition: false },
        { id: 'name', label: 'Nom & Prénoms', condition: true },
        { id: 'age', label: 'Âge', condition: true },
        { id: 'phone', label: 'Tél.', condition: true },
        { id: 'status', label: 'Statut', condition: true },
        { id: 'activity', label: 'Activité', condition: true },
        {
            id: 'nursery_species_info',
            label: "Info espèce",
            type: 'hover',
            hover: (values) => {
                console.log(values.nursery_species_info)
                const getOutInfoList = values?.nursery_species_info?.map((value) => {
                    return {
                        title: value?.nursery_specie?.specie?.name,
                        contents: [
                            { label: 'Pépinière', value: value?.nursery_specie?.nursery?.code },
                            { label: 'Quantité', value: value?.quantity },
                        ]
                    }
                })
                return (
                    <>
                        <ContentListViewer values={getOutInfoList} />
                    </>
                )
            }
        }
        // { id: 'member_since', type: 'date', label: 'Membre depuis', condition: true }
        // {
        //     id: 'actions',
        //     type: 'actions',
        //     label: 'Actions',
        //     disableSorting: true,
        //     showEdit: false,
        //     showRemove: true,
        //     showView: '/app/agroforestry/peasants/view'
        // }
        // { id: 'actions2', type: 'actions', label: 'Actions', disableSorting: true, showEdit: true, showRemove: true }
    ]

    return (
        <>
            <Table
                title={'Détails du paysan'}
                headCells={headCells}
                service={service}
                Form={Form}
                drawer={drawer}
                keyToSearch={null}
                haveAccessTo={haveAccessTo}
                currentPage={currentPage}
                setNeedLoad={setNeedLoad}
                filterFromList={filterFromList}
                needLoad={needLoad}
                hideToolbar={false}
                hideFilterButton={true}
                showSiteButton={false}
            />
        </>
    )
}
