import React, { useState } from 'react'
import PageHeader from '../../components/Typography/PageHeader'
import ToolbarButton from '../../components/DataTable/ToolbarButton'
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined'
import { useAuth } from '../../applications/hooks/UseAuth'
import { AddForm } from './components/AddForm'
import FormService from './services/Form'
// import RolesList from '../users/components/roles/RoleList'
import FormList from '../form/components/FormList'

export const Form = () => {
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const useDrawer = false
    const service = FormService.load()

    const CURRENT_PAGE = 'administration_roles'

    return (
        <div className={'card w-full bg-base-100'} style={{ paddingBottom: 20 }}>
            <PageHeader
                title={'Gestion des formulaires'}
                subTitle={'Gestion des formulaires'}
                icon={<LockPersonOutlinedIcon color="primary" fontSize="large" />}
            >
                {' '}
                <ToolbarButton
                    moduleTitle="formulaire"
                    service={service}
                    drawer={useDrawer}
                    haveAccessTo={haveAccessTo}
                    currentPage={CURRENT_PAGE}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={AddForm}
                />
            </PageHeader>
            <FormList
                service={service}
                drawer={useDrawer}
                Form={AddForm}
                haveAccessTo={haveAccessTo}
                currentPage={CURRENT_PAGE}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
            />
        </div>
    )
}
