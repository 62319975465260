export const mangroGroupModel = {
    label: 'Groupe mangrove',
    tableName: 'mangroGroup',
    properties: [
        {
            label: 'Site',
            id: 'village',
            isForeignKey: true,
            column_name: 'name',
            table_column: 'parent',
            type: 'text',
            required: true,
        },
        {
            label: 'Nom',
            id: 'name',
            type: 'text',
            required: true,
        },
    ]
}
