import React, { useEffect, useState } from 'react'
import ContentView from '../../../../../../components/PageView/Content'
import { useAuth } from '../../../../../../applications/hooks/UseAuth'
import { useLocation } from 'react-router-dom'
import NurseryService from '../../../../services/Nursery'
import NurseryDetailsContent from './nurseryManagement/NurseryDetailsContent'
import NurseryDataContext from '../../utils/hooks/NurseryDataContext'

function NurseryManagement(props) {
    const { addReturnButton, recordForEdit } = props

    const haveAccessTo = useAuth()
    const location = useLocation()
    //get data state from useNavigate
    const service = NurseryService.load()
    const dataCode = location.pathname.split('/').pop()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(false)
    const [supplementData, setSupplementData] = useState(null)
    const [selectedSemisOnAdd, setSelectedSemisOnAdd] = useState(null)

    //display state on console
    useEffect(() => {
        setSupplementData(location?.state?.supplementData)
    }, [location])

    //set selected semis on add if setSelectedSemisOnAdd is called
    useEffect(() => {
        if (selectedSemisOnAdd) {
            setSupplementData({ key: 'specie_id', value: selectedSemisOnAdd })
        }
    }, [selectedSemisOnAdd])

    return (
        <>
            <NurseryDataContext
                dataCode={recordForEdit ? recordForEdit.id : dataCode}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                recordForEdit={recordForEdit}
            >
                <ContentView
                    // title={`Gestion pépinière`}
                    dataId={recordForEdit ? recordForEdit.id : dataCode}
                    supplementData={supplementData}
                    setSupplementData={setSupplementData}
                    addReturnButton={addReturnButton}
                    service={service}
                    needLoad={needLoad}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoadCode={needLoadCode}
                    setNeedLoadCode={setNeedLoadCode}
                    Form={NurseryDetailsContent}
                    otherFunction={{ setSelectedSemisOnAdd }}
                />
            </NurseryDataContext>
        </>
    )
}

export default NurseryManagement
