import React, { useState, useEffect, useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import { Form, useForm } from '../../../applications/hooks/UseForm'
import Controls from '../../../components/Entry/Controls'
import Notification from '../../../layouts/Notification'
import PlantingService from '../services/Planting'
import moment from 'moment'
import PlantingTrackingService from '../services/PlantingTracking'
import { Container, Grid, Typography } from '@mui/material'
import { useAuth } from '../../../applications/hooks/UseAuth'
import { Context } from '../utils/hooks/PlantingTrackingContext'
import Popup from '../../../layouts/Popup'
import TrackYoungPlant from './popupView/TrackYoungPlant'
import DataSetsService from '../../datasets/services/DataSets'
import Loader from '../../../components/Entry/Loading'
import Subtitle from '../../../components/Typography/Subtitle'

const { Input: TextField, Button } = Controls

export const PlantingTrackingForm = (props) => {
    const { records } = props

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const dataContext = useContext(Context)
    const [planting, setPlanting] = useState([])
    const [userHaveToTrackYoungPlant, setUserHaveToTrackYoungPlant] = useState(false)
    const [trackingPointList, setTrackingPointList] = useState([])
    const [popupValues, setPopupValues] = useState(null)
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    const [formData, setFormData] = useState({
        // polygon_grid: '',
        technician: '',
        tracking_date: new Date().toISOString(),
        planting_id: ''
    })
    const { user } = useAuth()

    useEffect(() => {
        if (dataContext) {
            console.log('context >>>>>>>>>>>', dataContext)
            setValues((prevFormData) => ({
                ...prevFormData,
                planting_id: dataContext?.id
            }))

            PlantingService.getPlantingTrackingPoints(dataContext?.land?.site?.parent)
                .then((result) => {
                    if (result) {
                        setTrackingPointList(result)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [dataContext])

    useEffect(() => {
        if (records) {
            setValues((prevFormData) => ({
                ...prevFormData,
                technician: records?.technician || [user?.firstname, user?.lastname].join(' '),
                // tracking_point: records?.tracking_point || '',
                tracking_date: records?.tracking_date
                    ? moment(records.tracking_date).format('YYYY-MM-DD')
                    : new Date().toISOString(),
                // number_refilled: records?.number_refilled || '',
                // average_number: records?.average_number || '',
                // average_height: records?.average_height || '',
                // polygon_grid: records?.polygon_grid || '',
                planting_id: records?.planting_id || ''
            }))
        }
    }, [records])

    useEffect(() => {
        if (popupValues) {
            console.log(popupValues)
            setValues((prevFormData) => ({
                ...prevFormData,
                tracking_point: popupValues
            }))
        }
    }, [popupValues])

    useEffect(() => {
        setLoading(true)
        PlantingService.getAllPlanting()
            .then((users) => {
                if (users) {
                    setLoading(false)
                    setPlanting(users)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [])

    const validate = (fieldValues = values) => {
        console.log(fieldValues)
        let temp = { ...errors }
        if ('planting_id' in fieldValues) temp.planting_id = fieldValues.planting_id ? '' : 'Ce champ est requis.'

        if ('technician' in fieldValues) temp.technician = fieldValues.technician ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })
        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(formData, true, validate)

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(values)
        console.log(errors)
        console.log(validate())
        if (validate()) {
            try {
                setUserHaveToPost(true)
                const dataToSend = { ...values, user: [user.firstname, user.lastname].join(' ') }
                console.log('data to send >>>>>>', dataToSend)
                await PlantingTrackingService.addPlantingTracking(dataToSend)
                new Notification(PlantingTrackingService.messages.create_success, 1)
                navigate(-1)
            } catch (error) {
                console.error('Erreur lors de la soumission du formulaire : ', error)
                setUserHaveToPost(false)
                new Notification(PlantingTrackingService.messages.create_error, 0)
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }))
    }

    const trackEachPlant = () => {
        setUserHaveToTrackYoungPlant(true)
    }

    return (
        <>
            <Loader isLoading={loading} />

            <Form>
                <Container sx={{ paddingTop: '20px' }}>
                    <Typography variant="p" component="div" style={{ paddingBottom: '10px' }}>
                        <b> Information sur la plantation :</b>
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Container>
                                <Controls.AutoCompleteSelect
                                    label="Plantation"
                                    name="planting_id"
                                    value={values?.planting_id}
                                    error={errors?.planting_id}
                                    onChange={handleInputChange}
                                    options={planting}
                                    labelKey="code"
                                    valueKey="id"
                                    isDisabled={true}
                                    isRequired={true}
                                />
                                <Controls.DatePicker
                                    label="Date de suivi"
                                    name="tracking_date"
                                    onChange={handleInputChange}
                                    type="date"
                                    value={values?.tracking_date}
                                    error={errors?.tracking_date}
                                />
                            </Container>
                        </Grid>
                        <Grid item xs={6}>
                            <Container>
                                <Controls.Input
                                    id="outlined-disabled"
                                    label="Technicien"
                                    // variant="outlined"
                                    name="technician"
                                    value={values?.technician}
                                    error={errors?.technician}
                                    size="small"
                                    onChange={handleInputChange}
                                    isDisabled={true}
                                    isRequired={true}
                                />
                                {/* <Controls.Input
                                    id="outlined-disabled"
                                    label="Polygone de quadrillage"
                                    name="polygon_grid"
                                    value={values?.polygon_grid}
                                    error={errors?.polygon_grid}
                                    size="small"
                                    onChange={handleInputChange}
                                /> */}
                                {/* <Controls.AutoCompleteSelect
                                    label="Espèce"
                                    name="specie_id"
                                    value={values?.specie_id}
                                    error={errors?.specie_id}
                                    onChange={handleInputChange}
                                    options={dataContext?.plantationSpecies || []}
                                    labelKey="label"
                                    valueKey="id"
                                    isRequired={true}
                                /> */}
                            </Container>
                        </Grid>
                    </Grid>
                    <div>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', paddingBottom: '20px' }}>
                            <Typography variant="p" component="div">
                                <b> Suivi de chaque plant :</b>
                            </Typography>
                            <Button text="Suivi de jeunes plants" onClick={trackEachPlant} />
                        </div>
                        <div
                            style={{
                                marginBottom: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                backgroundColor: '#f1efef',
                                overflowY: 'scroll',
                                height: '250px',
                                border: '2px solid #ccc',
                                padding: '10px',
                                borderRadius: '5px',
                                scrollbarWidth: 'thin',
                                scrollbarColor: '#d0e0d7 #E7F2EE',
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                    height: '8px',
                                    background: '#d0e0d7'
                                }
                            }}
                        >
                            {popupValues &&
                                popupValues
                                    .map((point) => {
                                        return (
                                            <div
                                                key={point.id}
                                                style={{
                                                    border: '1px solid #ccc',
                                                    borderRadius: '5px',
                                                    padding: '7px',
                                                    backgroundColor: '#fff',
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Typography
                                                    variant="p"
                                                    component="div"
                                                    style={{
                                                        marginBottom: '10px',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                    fontSize="0.6rem"
                                                >
                                                    <Subtitle
                                                        style={{
                                                            fontSize: '0.8rem',
                                                            color: '#677788'
                                                        }}
                                                    >
                                                        {point.id}
                                                    </Subtitle>
                                                    <div>
                                                        Nombre moyenne de feuilles :
                                                        <b>
                                                            {point.species.length > 0
                                                                ? (
                                                                      point.species.reduce(
                                                                          (a, b) => a + b.leafNumber,
                                                                          0
                                                                      ) / point.species.length
                                                                  ).toFixed(2)
                                                                : 0}
                                                        </b>
                                                    </div>
                                                    <div>
                                                        Hauteur moyenne :
                                                        <b>
                                                            {point.species.length > 0
                                                                ? (
                                                                      point.species.reduce((a, b) => a + b.height, 0) /
                                                                      point.species.length
                                                                  ).toFixed(2)
                                                                : 0}{' '}
                                                            cm
                                                        </b>
                                                    </div>
                                                    <div>
                                                        Nombre de plants vivants : <b>{point.plants_alived}</b>
                                                    </div>
                                                    <div>
                                                        Nombre de plants morts : <b>{point.plants_dead}</b>
                                                    </div>
                                                </Typography>
                                            </div>
                                        )
                                    })
                                    .reverse()}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            paddingBottom: '20px'
                        }}
                    >
                        <Button
                            text="Enregistrer"
                            size="large"
                            onClick={handleSubmit}
                            haveSpin={true}
                            disabled={userHaveToPost}
                        />
                    </div>
                </Container>
            </Form>
            <Popup
                title="Suivi de chaque point"
                openPopup={userHaveToTrackYoungPlant}
                setOpenPopup={setUserHaveToTrackYoungPlant}
            >
                <TrackYoungPlant
                    species={dataContext?.plantationSpecies}
                    trackingPointList={trackingPointList}
                    setPopupValues={setPopupValues}
                    setUserHaveToTrackYoungPlant={setUserHaveToTrackYoungPlant}
                />
            </Popup>
        </>
    )
}
