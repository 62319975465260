import axios from 'axios'
import ErrorHandler from './Error'

/** */
export class Http {
    static async get(url, params) {
        try {
            const { data } = await axios.get(url, {
                params: { ...params, timestamp: new Date().getTime() }
            })

            if (data?.status?.success) {
                return { status: data?.status, data: data?.data }
            } else {
                throw data?.status
            }
        } catch (e) {
            ErrorHandler.handle(e)
        }
    }

    static async post(url, params) {
        try {
            const { data } = await axios.post(url, params)
            if (data?.status?.success) {
                return { status: data?.status, data: data?.data }
            } else {
                throw data?.status
            }
        } catch (e) {
            ErrorHandler.handle(e)
        }
    }

    static async patch(url, params) {
        try {
            const { data } = await axios.patch(url, params)

            if (data?.status?.success) {
                return { status: data?.status, data: data?.data }
            } else {
                throw data?.status
            }
        } catch (e) {
            ErrorHandler.handle(e)
        }
    }

    static async put(url, params) {
        try {
            const { data } = await axios.put(url, params)
            if (data?.status?.success) {
                return { status: data?.status, data: data?.data }
            } else {
                throw data?.status
            }
        } catch (e) {
            ErrorHandler.handle(e)
        }
    }

    static async delete(url) {
        try {
            const { data, status } = await axios.delete(url)
            if (data?.status?.success) {
                return { status: data?.status, data: data?.data }
            } else {
                throw data?.status
            }
        } catch (e) {
            ErrorHandler.handle(e)
        }
    }
}
