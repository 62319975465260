import React, { useEffect, useState } from 'react'
import { Container, Grid, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyle = makeStyles((theme) => ({
    labelInput: {
        fontSize: '12px',
        padding: `0 0 ${theme.spacing(1)} 0`
    },
    labelContainer: {
        marginBottom: '20px',
        width: '100%',
        display: 'block',
        '& .MuiFormControl-root': {
            margin: '0px',
            width: '100%'
        }
    }
}))

export default function CustomTimePicker(props) {
    const {
        name,
        label,
        value,
        error = null,
        isDisabled = false,
        multiline = false,
        onChange,
        style,
        needMinutes = true,
        ...other
    } = props
    const classes = useStyle()

    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [time, setTime] = useState(`${hours}:${minutes}`)

    const onChangeTime = (event) => {
        const { name, value } = event.target
        if (name === 'hours') {
            setHours(value)
            setTime(`${value}:${minutes}`)
        } else if (name === 'minutes') {
            setMinutes(value)
            setTime(`${hours}:${value}`)
        }
    }

    useEffect(() => {
        if (time) {
            onChange(time)
        }
    }, [time])

    return (
        <div className={classes.labelContainer}>
            <Container className={classes.labelInput}>{label}</Container>
            <Grid container>
                <Grid item xs={4}>
                    <TextField
                        disabled={isDisabled}
                        label="Heures"
                        sx={{ width: '100%' }}
                        size="small"
                        name={'hours'}
                        value={hours}
                        //specify the value type to Int
                        type="number"
                        onChange={onChangeTime}
                        style={style}
                        multiline={multiline}
                        {...other}
                        {...(error && { error: true, helperText: error })}
                    />
                </Grid>
                {needMinutes && (
                    <Grid item xs={4}>
                        <TextField
                            disabled={isDisabled}
                            label="Minutes"
                            sx={{ width: '100%' }}
                            size="small"
                            name={'minutes'}
                            type="number"
                            value={minutes}
                            onChange={onChangeTime}
                            style={style}
                            multiline={multiline}
                            {...other}
                            {...(error && { error: true, helperText: error })}
                        />
                    </Grid>
                )}
            </Grid>
        </div>
    )
}
