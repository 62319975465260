import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import PlantationService from '../../services/Plantation'
import PlantationDetailsContent from './content/PlantationDetailsContent'
import ContentView from '../../../../components/PageView/Content'

function PlantationDetailsPage(props) {
    const {addReturnButton, recordForEdit} = props
    const haveAccessTo = useAuth()
    const location = useLocation()
    const dataCode = location.pathname.split('/').pop()
    const plantationService = PlantationService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(true)
    return (
        <>
            <ContentView
                title={recordForEdit ? '' : `Détails de plantation`}
                dataId={recordForEdit ? recordForEdit.code : dataCode}
                service={plantationService}
                needLoad={needLoad}
                haveAccessTo={haveAccessTo}
                setNeedLoad={setNeedLoad}
                needLoadCode={needLoadCode}
                addReturnButton={addReturnButton}
                setNeedLoadCode={setNeedLoadCode}
                Form={PlantationDetailsContent}
            />
        </>
    )
}

export default PlantationDetailsPage
