import { Button } from '@mui/material'
import React from 'react'



function ExportButton(props) {
    const { title, action, style } = props

    const hanldeClick = () => {
        action()
    }
    return (
        <>
            <Button
                variant="outlined"
                className={style}
                
                onClick={hanldeClick}
            >
                {title}
            </Button>
        </>
    )
}

export default ExportButton
