import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import PlantingService from '../services/Planting'
import { PlantingForm } from './PlantingForm'
import { useAuth } from '../../../applications/hooks/UseAuth'
import ContentView from '../../../components/PageView/Content'
import { PlantingTrackingList } from './PlantingTrackingList'
import { PlantingGroupList } from './PlantingGroupList'

function PlantingView(props) {
    const { addReturnButton, recordForEdit } = props

    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const plantingService = PlantingService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const needLoadCode = true
    return (
        <>
            <ContentView
                dataId={recordForEdit ? recordForEdit.id : dataId}
                title={recordForEdit ? `` : `Ajouter une plantation`}
                service={plantingService}
                haveAccessTo={haveAccessTo}
                addReturnButton={addReturnButton}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
                Form={PlantingForm}
                needLoadCode={needLoadCode}
            />
            {dataId !== 'new' && (
                <ContentView
                    dataId={recordForEdit ? recordForEdit.id : dataId}
                    service={plantingService}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={PlantingGroupList}
                    needLoadCode={needLoadCode}
                />
            )}
            {dataId !== 'new' && (
                <ContentView
                    dataId={recordForEdit ? recordForEdit.id : dataId}
                    service={plantingService}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={PlantingTrackingList}
                    needLoadCode={needLoadCode}
                />
            )}
        </>
    )
}

export default PlantingView
