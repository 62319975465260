// import { Http } from '../../../applications/Http.js'

import moment from 'moment'
import { Http } from '../../../../applications/Http'
import Service from '../../../../applications/Service'

class OutingService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/outing_nursery')
        return this
    }

    static async getByCode(id) {
        return await Http.get([this.url, id].join('/'), {})
    }

    static async addOuting(data) {
        try {
            data.release_date = moment(data.release_date).format()

            const response = await Http.put('/api/outing_nursery', data)
            return response.data
        } catch (error) {
            throw new Error("Erreur lors de l'ajout .")
        }
    }

    static async updateOuting(id, data) {
        try {
            data.release_date = moment(data.release_date).format()

            const response = await Http.patch([this.url, id].join('/'), data)
            return response.data
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour .')
        }
    }

    // static async getAllPlatDRain() {
    //     try {
    //         const response = await Http.get('/api/plat_drain')
    //         console.log('response user', response.data)
    //         return response.data
    //     } catch (error) {
    //         console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
    //         throw error
    //     }
    // }
}

export default OutingService
