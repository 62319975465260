import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class PlantationSpeciesService extends Service {
    static messages = {
        update_success: 'Modification Paysans avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création Paysans avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/plantations/species')
        return this
    }

    static async getByPlantationId(id) {
        this.setUrl('/api/plantations/species')
        return await Http.get(`${this.url}/byPlantationId/${id}`, {})
    }
    static async getBySpecieId(id) {
        this.setUrl('/api/plantations/species')
        return await Http.get(`${this.url}/bySpecieId/${id}`, {})
    }

    static async getAllPlantationSpecies() {
        try {
            const response = await Http.get('/api/plantations/species/all')
            console.log('response user', response.data)
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            throw error
        }
    }
}

export default PlantationSpeciesService
