import * as XLSX from 'xlsx'
import Notification from '../../../../layouts/Notification'
import ImportService from '../../services/ImportService'

class ApplicationImport {
    constructor() {
        this.needLoad = true
        this.data = null
        this.file = null
        this.errors = {}
        this.checkedData = 0
    }

    setNeedLoad(needLoad) {
        this.needLoad = needLoad
    }

    getNeedLoad() {
        return this.needLoad
    }

    setData(data) {
        this.data = data
    }

    getData() {
        return this.data
    }

    generateData() {
        const file = this.getFile()
        console.log('fichier défini : ', file)
        if (!file) {
            console.error("Aucun fichier n'a été défini.")
            return
        }
        // récupérer les valeurs dans le file xlsx en objet
        const reader = new FileReader()
        reader.onload = function (e) {
            const data = new Uint8Array(e.target.result)
            const workbook = XLSX.read(data, { type: 'array' })
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }).filter((d) => d.length > 0)
            console.log('json data  : ', jsonData)
            this.setData(jsonData)
            this.setNeedLoad(false)
        }.bind(this)
        reader.onerror = function (e) {
            console.error('Erreur lors de la lecture du fichier:', e)
        }
        reader.readAsArrayBuffer(file)
    }

    setFile(file) {
        this.file = file
    }

    getFile() {
        return this.file
    }

    setErrors(errors) {
        this.errors = errors
    }

    getErrors() {
        return this.errors
    }

    verifyFileHeader(headers) {
        console.log(this.data)
        if (this.data) {
            const dataHeader = this.getData()[0]
            let dataResult = []
            console.log('incoming headers : ', dataHeader)
            console.log('headers : ', headers)
            dataHeader.every((value, index) => (value === headers[index] ? true : dataResult.push(dataHeader[index])))

            console.log('Résultat : ', dataResult)

            if (dataResult.length !== 0) {
                this.setErrors({
                    ...this.errors,
                    headersNotEquals: [...dataResult]
                })
                return false
            } else {
                return true
            }
        } else {
            this.setErrors({
                ...this.errors,
                noFile: true
            })
            new Notification('Veuiller choisir le fichier.', 0)
            return this.getErrors()
        }
    }

    async verifyData(record) {
        console.log(record)
        const importService = ImportService.load()
        const response = await importService.verifyData(record)
        console.log(response)
        return response
    }

    async importData(record) {
        const importService = ImportService.load()
        const response = await importService.importData(record)
        return response
    }

    exportHeadersToExcel(model) {
        // Récupérer les labels des propriétés comme entêtes
        const headers = model.properties.map((property) => property.label)

        // Créer une feuille de calcul avec les entêtes
        const worksheet = XLSX.utils.aoa_to_sheet([headers])

        // Créer un classeur et ajouter la feuille de calcul
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, model.tableName)

        // Exporter le fichier Excel
        XLSX.writeFile(workbook, `${model.tableName}_export.xlsx`)
    }
}

export default ApplicationImport
