import React, { useState } from 'react'
import PageHeader from '../../components/Typography/PageHeader'
import PlotList from './components/plot/PlotList'
import PlotService from './services/Plots'
import { useAuth } from '../../applications/hooks/UseAuth'
import { Form, useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

import { Toolbar as MuiToolbar } from '@mui/material'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import PlotView from './components/plot/PlotView'
import ProvinceFilter from '../provinces/components/ProvinceFilter'
import ToolbarButton from '../../components/DataTable/ToolbarButton'
import PeasantForm from '../peasants/components/PeasantForm'
import AddPlotForm from './components/plot/form/AddPlotForm'

const CURRENT_PAGE = 'agroforestry_plantation'

const useStyles = makeStyles((theme) => ({
    siteChange: {
        '& .MuiBottomNavigation-root': {
            height: '50px',
            borderRadius: 10,
            borderColor: '#0FCB8F',
            borderWidth: 2,
            background: '#43D5A7'
        },
        '& .MuiBottomNavigationAction-root': {
            background: '#0FCB8F',
            minWidth: '18vw'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            borderRadius: 10,
            background: 'none'
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        }
    }
}))

function Plot() {
    const classes = useStyles()
    const navigate = useNavigate()

    const { haveAccessTo } = useAuth()
    const service = PlotService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [filter, setFilter] = useState({})
    const useDrawer = true

    const [value, setValue] = useState('plots')

    const handleChange = (event, newValue) => {
        setValue(newValue)
        navigate('/app/agroforestry/' + newValue)
    }

    return (
        <div className={'card w-full bg-base-100 font-sans font-bold'} style={{ paddingBottom: 20 }}>
            <PageHeader
                title={'Suivi des parcelles'}
                subTitle={'Gestions parcelles'}
                // icon={<LockPersonOutlinedIcon color="primary" fontSize="large" />}
            >
                <ToolbarButton
                    label="Nouvelle parcelle administrative"
                    moduleTitle="parcelle administrative"
                    service={service}
                    drawer={!useDrawer}
                    haveAccessTo={haveAccessTo}
                    currentPage={CURRENT_PAGE}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={AddPlotForm}
                />
            </PageHeader>
            {/* <PageHeader title={'Suivi des parcelles'} subTitle={'Gestions parcelles'} /> */}
            <div style={{ display: 'flex', gap: 10, marginTop: 10 }}>
                <ProvinceFilter setNeedLoad={setNeedLoad} filter={filter} setFilter={setFilter} />
                <MuiToolbar style={{ padding: 0, alignItems: 'start' }} className={classes.siteChange}>
                    <BottomNavigation showLabels value={value} onChange={handleChange}>
                        <BottomNavigationAction key="1" label="Pré-registre" value="preregistry" />
                        <BottomNavigationAction key="2" label="Paysans enregistrés" value="peasants" />
                        <BottomNavigationAction key="2" label="Liste des parcelles" value="plots" />
                    </BottomNavigation>
                </MuiToolbar>
            </div>
            <PlotList
                isPlotMonitoring={false}
                service={service}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                Form={PlotView}
                filterFromList={filter}
                drawer={useDrawer}
                currentPage={CURRENT_PAGE}
            />
        </div>
    )
}

export default Plot
