import React, { useState } from 'react'
import PageHeader from '../../components/Typography/PageHeader'
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined'
import { useAuth } from '../../applications/hooks/UseAuth'
// import { AddForm } from './components/AddForm'
import PeasantList from './components/PeasantList'
import { ShowPeasant } from './components/ShowPeasant'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'

import { Toolbar as MuiToolbar } from '@mui/material'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import PeasantView from './components/PeasantView'
import PeasantPlantingService from './services/Peasant_planting'
import ProvinceFilter from '../provinces/components/ProvinceFilter'
import PeasantPlantingList from './components/PeasantPlantingList'

const CURRENT_PAGE = 'administration_peasant'
const useStyles = makeStyles((theme) => ({
    siteChange: {
        '& .MuiBottomNavigation-root': {
            height: '50px',
            borderRadius: 10,
            borderColor: '#0FCB8F',
            borderWidth: 2,
            background: '#43D5A7'
        },
        '& .MuiBottomNavigationAction-root': {
            background: '#0FCB8F',
            minWidth: '18vw'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            borderRadius: 10,
            background: 'none'
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        }
    }
}))

export const PeasantsPLanting = () => {
    const classes = useStyles()

    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const [filter, setFilter] = useState({})
    const useDrawer = true
    const service = PeasantPlantingService.load()

    const [value, setValue] = useState('peasants')

    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        setValue(newValue)
        navigate('/app/agroforestry/plantations/' + newValue)
    }

    return (
        <div className={'card w-full bg-base-100 font-sans font-bold'} style={{ paddingBottom: 20 }}>
            <PageHeader
                title={'Paysans partenaires'}
                subTitle={'Paysans partenaires'}
                icon={<LockPersonOutlinedIcon color="primary" fontSize="large" />}
            >
                {' '}
                {/* <ToolbarButton
                    moduleTitle="paysan"
                    service={service}
                    drawer={!useDrawer}
                    haveAccessTo={haveAccessTo}
                    currentPage={CURRENT_PAGE}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={PeasantForm}
                /> */}
            </PageHeader>
            <div style={{display: 'flex', gap: 10, marginTop: 10}}>
            <ProvinceFilter setNeedLoad={setNeedLoad} filter={filter} setFilter={setFilter} />
            <MuiToolbar style={{ padding: 0, alignItems: 'start' }} className={classes.siteChange}>
                <BottomNavigation showLabels value={value} onChange={handleChange}>
                    <BottomNavigationAction key="1" label="Plantations" value="" />
                    <BottomNavigationAction key="2" label="Paysans partenaires" value="peasants" />
                    <BottomNavigationAction key="3" label="Suivi des parcelles" value="plots" />
                </BottomNavigation>
            </MuiToolbar>
            </div>
            <PeasantPlantingList
                service={service}
                drawer={useDrawer}
                Form={PeasantView}
                haveAccessTo={haveAccessTo}
                currentPage={CURRENT_PAGE}
                filterFromList={filter}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
            />
        </div>
    )
}
