import React, { useEffect, useState } from 'react'
import Controls from '../../../../components/Entry/Controls'
import { Container } from '@mui/material'
import { Form, useForm } from '../../../../applications/hooks/UseForm'
import Notification from '../../../../layouts/Notification'

const initialValues = {
    name: '',
    photos: ''
}

function AddSpecies(props) {
    const { addOrEdit, recordForEdit } = props

    const [valueImage, setValueImage] = useState()
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('name' in fieldValues) temp.name = fieldValues.name ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })
        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialValues,
        true,
        validate
    )

    const saveSpecies = async (e) => {
        e.preventDefault()
        console.log(validate())

        if (validate()) {
            const result = await addOrEdit(values, resetForm, setUserHaveToPost)
            if(result) setUserHaveToPost(false)
            else{
                new Notification('Erreur de création', 0)
                setUserHaveToPost(false)
            }
        }
    }

    useEffect(() => {
        console.log('recordForEdit', recordForEdit)
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
                photos: recordForEdit.photos,
                oldPhotos: recordForEdit.photos
            })
            setValueImage(recordForEdit.photos)
        }
    }, [recordForEdit, setValues])

    return (
        <>
            <Form onSubmit={saveSpecies}>
                <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Controls.Input
                        label="Nom"
                        name="name"
                        placeholder="Nom de l'espèce"
                        value={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                        isRequired={true}
                    />
                </Container>
                <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Controls.ImageInput
                        error={errors.photos}
                        value={values.photos}
                        name="photos"
                        onChange={handleInputChange}
                        label="Image de l'espèce"
                        valueImage={valueImage}
                        setValueImage={setValueImage}
                    />
                </Container>
                <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Controls.Button type="submit" text="Enregistrer" haveSpin={true} disabled={userHaveToPost} />
                </Container>
            </Form>
        </>
    )
}

export default AddSpecies
