import React, { createContext, useEffect, useState } from 'react'
import Loader from '../../../../../../components/Entry/Loading'
import PlantMaterialService from '../../../../services/plant_material/plant_material'
import Notification from '../../../../../../layouts/Notification'

export const context = createContext()

function PlantMaterialContext(props) {
    const { children, nurseryId, needLoad, setNeedLoad, specieId = null, unity } = props

    const plantMaterialService = PlantMaterialService.load()
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState({})

    useEffect(() => {
        if (nurseryId && !specieId) {
            const getNurseryData = async () => {
                setLoading(true)
                const nurseryData = {
                    userHaveToAdd: true,
                    nurseryId: parseInt(nurseryId),
                }
                if (nurseryData) {
                    setValue(nurseryData)
                }
            }
            if (needLoad) {
                getNurseryData()
                setLoading(false)
                setNeedLoad(false)
            }
        }

        if(specieId){
            const getNurserySpecieData = async () => {
                setLoading(true)
                const nurserySpecieData = await plantMaterialService.getNuseryPlantMaterialBySpecie(nurseryId, specieId, unity)
                if (nurserySpecieData) {
                    setValue(nurserySpecieData)
                    console.log("données Pepinières éspèces :", nurserySpecieData)
                }
            }

            if (needLoad) {
                getNurserySpecieData()
                setLoading(false)
                setNeedLoad(false)
            }
        }
    }, [nurseryId, plantMaterialService, needLoad, setNeedLoad, specieId])

    return (
        <>
            <Loader isLoading={loading} />
            <context.Provider value={value}>{children}</context.Provider>
        </>
    )
}

export default PlantMaterialContext
