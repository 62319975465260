export const platdrainModel = {
    label: 'Plate bande',
    tableName: 'plat_drain',
    properties: [
        {
            label: 'Site',
            id: 'village',
            isForeignKey: true,
            column_name: 'name',
            table_column: 'siteId',
            type: 'text',
            required: true,
        },
        {
            label: 'Espèce',
            id: 'species',
            isForeignKey: true,
            column_name: 'name',
            table_column: 'specieId',
            type: 'text',
            required: true,
        },
        {
            label: 'Date de semis',
            id: 'sowing_date',
            type: 'date',
            required: true,
        },
        {
            label: 'Nombre de pots rebouchés',
            id: 'quantity_rebouche',
            type: 'text',
            required: true,
        },
        {
            label: 'Nombre de pots repiqués',
            id: 'quantity_repique',
            type: 'text',
            required: true,
        },
        {
            label: 'Quantité',
            id: 'quantity',
            type: 'text',
            required: true,
        },
        {
            label: 'Pépiniériste responsable',
            id: 'responsable',
            type: 'text',
            required: true,
        }
    ]
}