import { Container, Paper } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Controls from '../../../../../components/Entry/Controls.js'
import AdministrativeDelimitation from './form/AdministrativeDelimitation.jsx'
import PersonalInformations from './form/PersonalInformations.jsx'
import SpeciesCard from './card/SpeciesCard.jsx'
import { LoadScript } from '@react-google-maps/api'
import Map from '../../../../../components/Entry/Map.js'
import { Context } from '../../../utils/hooks/InfoPlanterContext.jsx'

const styles = {
    second_section_container: {
        paddingRight: '0px',
        backgroundColor: 'none',
        boxShadow: 'none',
        borderLeft: '1px solid #E5E5E5'
    },
    paper: {
        padding: '20px 0px 0px 0px',
        backgroundColor: 'none',
        boxShadow: 'none',
        '& .MuiPaper-root': {
            backgroundColor: 'none',
            boxShadow: 'none'
        }
    },
    list_semi_container: {
        padding: '20px 0px 20px 0px',
        backgroundColor: '#f8fcff',
        boxShadow: 'none'
    },
    form_title: {
        padding: `10px 0px 20px 0px `,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    },
    form_input_container: {
        padding: '0px 10px 0px 10px'
    },

    paperInput: {
        padding: '0px 20px 0px 0px',
        backgroundColor: 'none',
        boxShadow: 'none',
        '& .MuiPaper-root': {
            backgroundColor: 'none',
            boxShadow: 'none'
        }
    }
}

function InfoPlanterDetailsContent(props) {
    const { setNeedLoad } = props
    const dataContext = useContext(Context)
    const [landsData, setLandsData] = useState(null)
    const [grids, setGrids] = useState(null)
    const [plantedArea, setPlantedArea] = useState(0)
    const [gpsCoordinate, setGpsCoordinate] = useState('')

    useEffect(() => {
        setNeedLoad(true)
        if (dataContext) {
            console.log('Data context >>>>>>>>>>>>>>>', dataContext)
            setPlantedArea(dataContext?.planted_area)
            setLandsData(dataContext?.lands)
            setNeedLoad(false)
        }
    }, [dataContext, setNeedLoad])

    useEffect(() => {
        if (landsData) {
            console.log('landsData >>>>>>>>>>>>>>>', landsData)
            const grids = []

            landsData.forEach((land) => {
                if (land?.land_grid !== null) {
                    grids.push(land?.land_grid)
                }
            })
            grids.map((grid) => {
                return {
                    coordinates: grid?.coordinates,
                    id: grid?.id
                }
            })
            if (grids.length > 0) {
                setGrids(grids)
            }
        }
    }, [landsData])

    useEffect(() => {
        if (grids) {
            console.log('grid >>>>>>>>>>>>>>>', grids)
            setGpsCoordinate(
                `Latitude : ${grids[0]?.coordinates[0]?.lat}, longitude : ${grids[0]?.coordinates[0]?.lng}`
            )
        }
    }, [grids])

    return (
        <>
            <CssBaseline />
            <Container
                sx={{
                    padding: '20px'
                }}
            >
                <Grid container>
                    {/* Première colonne (2/3 de la page) */}
                    <Grid item xs={8}>
                        <Paper sx={styles.paper}>
                            <Paper>
                                <AdministrativeDelimitation styles={styles} setNeedLoad={setNeedLoad} />
                                <PersonalInformations styles={styles} setNeedLoad={setNeedLoad} />
                                <SpeciesCard />
                            </Paper>
                        </Paper>
                    </Grid>
                    {/* Deuxième colonne (1/3 de la page) */}
                    <Grid item xs={4} sx={{ borderLeft: '1px solid #E5E5E5' }}>
                        <Container sx={styles.second_section_container}>
                            <Paper sx={{ ...styles.paperInput, padding: '0px 0px 0px 0px' }}>
                                <Grid>
                                    <Controls.Input label="Superficie planté" isDisabled={true} value={plantedArea} />
                                    <Controls.Input label="Coordonnées GPS" isDisabled={true} value={gpsCoordinate} />
                                    {grids ? (
                                        <LoadScript>
                                            <Map data={grids} zoom={10} />
                                        </LoadScript>
                                    ) : (
                                        <p>(Aucun quadrillage.)</p>
                                    )}

                                </Grid>
                            </Paper>
                        </Container>
                    </Grid>
                </Grid>
                <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* <Controls.Button disabled={true} text="Générer fichier" variant="contained" size={'large'} /> */}
                </Container>
            </Container>
        </>
    )
}

export default InfoPlanterDetailsContent
