import { Grid, Paper, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Controls from '../../../../../../components/Entry/Controls'
import { Context } from '../../../../utils/hooks/InfoPlanterContext'
import PlanterGroup from '../../../popupView/PlanterGroup'
import { useNavigate } from 'react-router-dom'

function PersonalInformations(props) {
    const { styles, setNeedLoad } = props
    const [defaultOptionGroup, setDefaultOptionGroup] = useState([{ id: 'aucun', code: 'code' }])
    const [defaultOptionLand, setDefaultOptionLand] = useState([{ id: 'aucun', code_land: 'aucun' }])
    const [planterNumber, setPlanterNumber] = useState(0)
    const [openPopup, setOpenPopup] = useState(false)
    const [menNumber, setMenNumber] = useState(0)
    const [womenNumber, setWomenNumber] = useState(0)
    const [groupNumber, setGroupNumber] = useState(0)
    const dataContext = useContext(Context)
    const [records, setRecords] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        setNeedLoad(true)
        if (dataContext) {
            console.log(dataContext)
            setDefaultOptionGroup(dataContext.group)
            dataContext.lands.length > 0 && setDefaultOptionLand(dataContext.lands)
            setPlanterNumber(dataContext.planter_number)
            setMenNumber(dataContext.men_number)
            setWomenNumber(dataContext.women_number)
            setGroupNumber(dataContext.group_number)
            setNeedLoad(false)
        }
    }, [dataContext, setNeedLoad])

    const planterChoosenAction = (code) => {
        navigate(`/app/mangrove/planter/information/${code}`)
        setOpenPopup(false)
    }

    return (
        <>
            <Typography sx={styles.form_title}>Information personelle</Typography>

            <Grid container>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        <Controls.Input
                            label="Réference groupe"
                            name="group_ref"
                            value={defaultOptionGroup.map((item) => item.name).join(', ')}
                            isDisabled={true}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>

                    <Controls.Input
                            label="Code parcelle"
                            name="land_ref"
                            value={defaultOptionLand.map((item) => item.code_land).join(', ')}
                            isDisabled={true}
                        />
                    </Paper>
                    <Grid container>
                        <Grid item xs={6}>
                            <Paper sx={styles.paperInput}>
                                <Controls.Input label="Homme" name="region" value={menNumber} isDisabled={true} />
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper sx={styles.paperInput}>
                                <Controls.Input label="Femme" name="region" value={womenNumber} isDisabled={true} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Paper sx={styles.paperInput}>
                                <Controls.Input
                                    label="Nombre planteurs"
                                    name="district"
                                    value={planterNumber}
                                    isDisabled={true}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={4} sx={{ paddingTop: '34px' }}>
                            <Typography
                                variant="caption"
                                sx={{ marginTop: '10px', ':hover': { cursor: 'pointer' } }}
                                component="div"
                                color="text.secondary"
                                onClick={() => setOpenPopup(true)}
                            >
                                <i>
                                    <u>Afficher la liste</u>
                                </i>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper sx={styles.paperInput}>
                        <Controls.Input label="Nombre groupes" name="region" value={groupNumber} isDisabled={true} />
                    </Paper>
                </Grid>
            </Grid>
            {dataContext && (
                <PlanterGroup
                    records={dataContext}
                    setRecords={setRecords}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    actions={(code) => planterChoosenAction(code)}
                />
            )}
        </>
    )
}

export default PersonalInformations
