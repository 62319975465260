import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText, Container } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyle = makeStyles((theme) => ({
    labelInput: {
        fontSize: '12px',
        padding: `0 0 ${theme.spacing(1)} 0`
    },
    labelContainer: {
        marginBottom: '20px'
        // width: '100%',
        // display: 'block',
        // '& .MuiFormControl-root': {
        //     margin: '0px',
        //     width: '100%'
        // }
    }
}))

export default function SelectDataSet(props) {
    const { name, label, value, error = null, onChange, options, isDisabled } = props
    const classes = useStyle()

    return (
        <div className={classes.labelContainer}>
            <Container className={classes.labelInput}>{label}</Container>
            <FormControl variant="outlined" {...(error && { error: true })} className="mt-[20px]" disabled={isDisabled}>
                <MuiSelect name={name} value={value} onChange={onChange} size="small">
                    <MenuItem value="">Aucun</MenuItem>
                    {options.map((item, index) => (
                        <MenuItem key={index} value={item.label}>
                            {item.label}
                        </MenuItem>
                    ))}
                </MuiSelect>
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </div>
    )
}
