// import { Http } from '../../../applications/Http.js'
import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class ImportService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.',
        get_error: 'Erreur lors du chargement des données.'
    }
    static load() {
        this.setUrl('/api/importation')
        return this
    }

    static async getDataImport(){
        try {
            const response = await Http.get(this.url, {})
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async verifyData(data){
        try {
            const response = await Http.post(this.url + '/verify', data)
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async importData(data){
        try {
            const response = await Http.post(this.url + '/import', data)
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async importGrid(data){
        try {
            const response = await Http.post(this.url + '/grid', data)
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    
}

export default ImportService
