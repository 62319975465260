import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
// import AddIcon from '@mui/icons-material/Add'
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { useNavigate } from 'react-router-dom'
import Drawer from '../../layouts/Drawer'
import Notification from '../../layouts/Notification'
import Popup from '../../layouts/Popup'
import Controls from '../Entry/Controls'

const useStyles = makeStyles((theme) => ({
    newButton: {
        //position: 'absolute',
        //right: '10px'
    },
    exportButton: {}
}))

const ToolbarButton = (props) => {
    const classes = useStyles()
    const { moduleTitle, haveAccessTo, currentPage, drawer, Form, service, setNeedLoad, isContentView = false, link, size = 'large', objectRelated, label } = props
    const [openPopup, setOpenPopup] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [title, setTitle] = useState('')
    const navigate = useNavigate()

    const addAction = () => {
        setTitle(`Ajout ${moduleTitle}`)
        if (drawer) {
            toggleDrawer(true)
        } else {
            setOpenPopup(true)
        }
        if(isContentView && link) {
            navigate(link)
        }
    }

    // const exportAction = () => {
    //     alert('export')
    // }

    const toggleDrawer = (drawerOpen) => {
        setOpenDrawer(drawerOpen)
    }

    const addOrEdit = async (data, resetForm, setUserHaveToPost = null) => {
        try {
            setUserHaveToPost && setUserHaveToPost(true)
        const response = await service.create(data)
        console.log('status', response)
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification(service.messages.create_success, 1)
            resetForm()
        drawer && !isContentView ? setOpenDrawer(false) : setOpenPopup(false)
        setNeedLoad(true)
        setUserHaveToPost && setUserHaveToPost(false)
        }
        console.log('response', response)
        return response?.data || null
        } catch (error) {
            setUserHaveToPost && setUserHaveToPost(false)
            console.error(error)
        }
    }

    return (
        <>
            <div className="space-x-2 padding-x-2 ml-[10px]">
                <Controls.Button
                    disabled={!haveAccessTo(currentPage, 'add')}
                    text= { label || "Nouveau" }
                    variant="contained"
                    size={size}
                    // startIcon={<AddIcon />}
                    className={classes.newButton}
                    onClick={addAction}
                />
                {/* <Controls.Button
                    disabled={!haveAccessTo(currentPage, 'export')}
                    text="Exporter"
                    variant="outlined"
                    size="large"
                    startIcon={<FileDownloadOutlinedIcon />}
                    className={classes.exportButton}
                    onClick={exportAction}
                /> */}
            </div>
            {!drawer && !isContentView && (
                <Popup title={title} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    <Form addOrEdit={addOrEdit} objectRelated={objectRelated} setNeedLoad={setNeedLoad} setOpenPopup={setOpenPopup} />
                </Popup>
            )}
            {drawer && (
                <Drawer title={title} openDrawer={openDrawer} toggleDrawer={toggleDrawer}>
                    <Form addOrEdit={addOrEdit} />
                </Drawer>
            )}
        </>
    )
}

export default ToolbarButton
