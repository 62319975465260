import React, { useEffect, useState } from 'react'
import { Typography, Paper, Grid, Container } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls'

const styles = {
    container_info: {
        padding: '3px 3px 3px 3px'
    },
    list_param: { color: '#748291', fontSize: '0.7rem', paddingBottom: '0.5rem' },
    container_chart: {
        padding: '3px 3px 3px 3px',
        backgroundColor: '#f8f8f8',
        borderRadius: '0.5rem'
    }
}

function NaturalRegeneration(props) {
    const { disabledField, handleInputChange, setValues, values } = props
    const [regenerationData, setRegenerationData] = useState(null)
    const [abundanceList, setAbundanceList] = useState([
        { id: 1, title: 'Moins abondantes' },
        { id: 2, title: 'Abondantes' },
        { id: 3, title: 'Très abondantes' }
    ])
    const [repartitionList, setRepartitionList] = useState([
        { id: 1, title: 'Dispersée' },
        { id: 2, title: 'Concentrée' }
    ])
    const [abundanceSelected, setAbundanceSelected] = useState(null)
    const [repartitionSelected, setRepartitionSelected] = useState(null)

    useEffect(() => {
        if (values.natural_regeneration) {
            setAbundanceSelected(values.natural_regeneration.abundance)
            setRepartitionSelected(values.natural_regeneration.repartition)
        }
    }, [values.natural_regeneration])

    return (
        <>
            <Paper
                sx={{
                    padding: '0.4rem',
                    borderRadius: '0.5rem',
                    display: 'inline-flex',
                    width: '95%',
                    backgroundColor: '#f1f1f1'
                }}
            >
                <Container style={styles.container_info}>
                    <Container style={{ ...styles.container_info, paddingBottom: '0.5rem' }}>
                        <Typography variant="p" sx={{ color: '#6f7e8e', fontSize: '0.7rem' }}>
                            Régénération naturelle
                        </Typography>
                    </Container>
                    <Container style={styles.container_info}>
                        <Grid container>
                            <Grid item xs={2} sx={{ padding: '10px 0px 10px 0px' }}>
                                <Typography variant="p" sx={{ color: '#6f7e8e', fontSize: '0.7rem' }}>
                                    <b>Nombre :</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="p" sx={{ color: '#6f7e8e', fontSize: '0.7rem' }}>
                                    <Controls.CustomRadioGroup
                                        name="recipient_polygon"
                                        items={abundanceList}
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                natural_regeneration: {
                                                    ...values.natural_regeneration,
                                                    abundance: e.target.value
                                                }
                                            })
                                        }}
                                        valueKey={'title'}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container style={styles.container_info}>
                        <Grid container>
                            <Grid item xs={2} sx={{ padding: '10px 0px 10px 0px' }}>
                                <Typography variant="p" sx={{ color: '#6f7e8e', fontSize: '0.7rem' }}>
                                    <b>Répartition :</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="p" sx={{ color: '#6f7e8e', fontSize: '0.7rem' }}>
                                    <Controls.CustomRadioGroup
                                        name="recipient_polygon"
                                        items={repartitionList}
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                natural_regeneration: {
                                                    ...values.natural_regeneration,
                                                    repartition: e.target.value
                                                }
                                            })
                                        }}
                                        valueKey={'title'}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container style={styles.container_info}>
                        <Grid container>
                            <Grid item xs={2}>
                                <Typography variant="p" sx={{ color: '#6f7e8e', fontSize: '0.7rem' }}>
                                    <b>Age : (max 5 ans)</b>
                                </Typography>
                                <Controls.Input
                                    style={{}}
                                    name="age"
                                    type="number"
                                    isDisabled={false}
                                    onChange={(e) => {
                                        if (parseInt(e.target.value) < 0) {
                                            e.target.value = 1
                                        } else if (parseInt(e.target.value) > 5) {
                                            e.target.value = 5
                                        }
                                        setValues({
                                            ...values,
                                            natural_regeneration: {
                                                ...values.natural_regeneration,
                                                age: e.target.value
                                            }
                                        })
                                    }}
                                    value={values.age}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Paper>
        </>
    )
}

export default NaturalRegeneration
