import React, { useEffect } from 'react'
import { Box, Container, Grid, Paper, Typography } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls'
import { IoMdMale } from 'react-icons/io'
import { FaPhoneVolume } from 'react-icons/fa'
import { CiCalendar } from 'react-icons/ci'
import { IoMaleFemaleOutline } from 'react-icons/io5'
import Format from '../../../../../../applications/Format'
import QrViewer from '../../../../../../components/Entry/QrViewer'

function ShowPlanter(props) {
    const { records } = props
    useEffect(() => {
        if (records) {
            console.log(records)
        }
    }, [records])
    return (
        <>
            <Container sx={{ padding: '20px 0px 20px 0px' }}>
                <Grid container>
                    <Grid item xs={3} sx={{ borderRight: '1px solid #ccc' }}>
                        <Container sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                            <Controls.CustomImageAvatar size={150} rounded={50} src={records?.photo || ''} />
                        </Container>
                        <Container>
                            <Typography
                                sx={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '5px' }}
                                variant="h5"
                                component="div"
                            >
                                {records && [records.firstname, ' ', records.lastname].join('')}
                            </Typography>
                        </Container>
                        <Container>
                            <Paper
                                style={{ boxShadow: 'unset', border: '1px solid #ccc', borderRadius: '5px' }}
                                sx={{ backgroundColor: '#fcfcfc' }}
                            >
                                <Paper
                                    style={{ boxShadow: 'unset' }}
                                    sx={{ padding: '5px', display: 'flex', alignItems: 'center' }}
                                >
                                    {records && records.genre === 'Homme' ? (
                                        <IoMdMale className="mt-[2px]" />
                                    ) : (
                                        <IoMaleFemaleOutline className="mt-[2px]" />
                                    )}
                                    <Typography
                                        sx={{ fontSize: '13px', marginLeft: '10px' }}
                                        variant="p"
                                        component="div"
                                    >
                                        {records && records.genre}
                                    </Typography>
                                </Paper>
                                <Paper
                                    style={{ boxShadow: 'unset' }}
                                    sx={{ padding: '5px', display: 'flex', alignItems: 'center' }}
                                >
                                    <FaPhoneVolume className="mt-[2px]" />
                                    <Typography
                                        sx={{ fontSize: '13px', marginLeft: '10px' }}
                                        variant="p"
                                        component="div"
                                    >
                                        {records && records.phone}
                                    </Typography>
                                </Paper>
                                <Paper
                                    style={{ boxShadow: 'unset' }}
                                    sx={{ padding: '5px', display: 'flex', alignItems: 'center' }}
                                >
                                    <CiCalendar className="mt-[2px]" />
                                    <Typography
                                        sx={{ fontSize: '13px', marginLeft: '10px' }}
                                        variant="p"
                                        component="div"
                                    >
                                        {records && 'membre depuis ' + Format.formatYear(records.member_since)}
                                    </Typography>
                                </Paper>
                            </Paper>
                        </Container>
                        {/* <Container>
                            {records?.code_qr && (
                                <Box sx={{ paddingTop: '40px' }}>
                                    <QrViewer value={records?.code_qr} size='70%' />
                                </Box>
                            )}
                        </Container> */}
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Container>
                                    <Controls.Input
                                        label={'Age'}
                                        value={Format.calculAgeWithDob(records.dob)}
                                        isDisabled={true}
                                    />
                                    <Controls.Input
                                        label={'Numero CIN'}
                                        isDisabled={true}
                                        value={records.cin_number || ''}
                                    />
                                    <Controls.DatePicker
                                        label={'Début de collaboration'}
                                        isDisabled={true}
                                        value={records.collaboration_beginning}
                                    />
                                </Container>
                            </Grid>
                            <Grid item xs={6}>
                                <Container>
                                    <Controls.Input
                                        label={'Activité principale'}
                                        isDisabled={true}
                                        value={records.activity}
                                    />
                                </Container>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Controls.CustomizedButton
                        disabled={false}
                        text="Refuser"
                        variant="contained"
                        size={'large'}
                        customStyles={{
                            marginRight: '1rem',
                            backgroundColor: '#ffffff',
                            color: '#0FCB8F',
                            border: '3px solid #0FCB8F',
                            boxShadow: 'none',
                            '& .MuiButtonBase-root': {
                                hover: {
                                    backgroundColor: '#0FCB8F',
                                    color: '#ffffff'
                                }
                            }
                        }}
                    />
                    <Controls.Button disabled={false} text="Valider" variant="contained" size={'large'} />
                </Container> */}
            </Container>
        </>
    )
}

export default ShowPlanter
