import { Paper } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import CustomSelect from '../../../components/Entry/CustomSelect'
import { DashboardPercent } from '../utils/DashboardPercent'
import { DashboardInput } from '../utils/DashboardInput'
import logo1 from '../../../assets/image/dashboard1.png'
import logo2 from '../../../assets/image/dashboard4.png'
import ProvinceService from '../../provinces/services/Provinces.js'
import NurseryService from '../../nursery/services/Nursery.js'
import Controls from '../../../components/Entry/Controls.js'
import { Context } from '../utils/hooks/AgroDashboardContext.jsx'

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: 10,
        backgroundColor: '#F2F8F6',
        '& .MuiPaper-root': {
            border: 'none',
            boxShadow: 'none'
        }
    }
}))

const TablePaper = styled(Paper)(({ theme }) => ({
    boxShadow: '0px 5px 5px -3px #d2d2d2,0px 8px 10px 1px #d2d2d2,0px 3px 14px 2px #d2d2d2'
}))

export const NurseryDashboard = (props) => {
    const { needLoad, setNeedLoad } = props
    const classes = useStyles()

    const dataContext = useContext(Context)
    const [data, setData] = useState({})

    const [site, setSite] = useState([])
    const [nursery, setNursery] = useState([])
    const [specie, setSpecie] = useState([])
    const [selectedNursery, setSelectedNursery] = useState({})
    const [values, setValues] = useState({
        site: '',
        nursery: '',
        species: '',
        year: new Date().getFullYear()
    })
    useEffect(() => {
        setNeedLoad(true)
        ProvinceService.getAgroSites().then((response) => {
            if (response?.data) {
                setSite(response?.data)
                if (!values.site && response.data.length > 0) {
                    setValues((prevValues) => ({
                        ...prevValues,
                        site: response.data[0].id
                    }))
                }
                setNeedLoad(false)
            }
        })
    }, [])

    useEffect(() => {
        if (values.site) {
            setNeedLoad(true)
            NurseryService.getAllNurseryBySite(values.site)
                .then((res) => {
                    if (res) {
                        const filteredNursery = res.filter((nursery) => {
                            return nursery.AgroNurserySemis.some((semis) => {
                                return new Date(semis.SemisDate).getFullYear() === parseInt(values.year)
                            })
                        })
                        if (filteredNursery.length > 0) {
                            setNursery(filteredNursery)
                            setValues((prevValues) => ({
                                ...prevValues,
                                nursery: filteredNursery[0].id
                            }))
                        } else {
                            setNursery([])
                            setSpecie([])
                            setValues((prevValues) => ({
                                ...prevValues,
                                nursery: '',
                                species: ''
                            }))
                            setData({})
                        }
                    } else {
                        setNursery([])
                        setValues((prevValues) => ({
                            ...prevValues,
                            nursery: ''
                        }))
                    }
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération des pépinières :', error)
                })
                .finally(() => {
                    setNeedLoad(false)
                })
        } else {
            setNursery([])
            setValues((prevValues) => ({
                ...prevValues,
                nursery: ''
            }))
        }
    }, [values.site, values.year])

    useEffect(() => {
        if (values.nursery) {
            const nurs = nursery?.find((item) => item?.id === values.nursery)
            if (nurs) {
                setSelectedNursery(nurs)
                if (nurs?.AgroNurserySemis?.length > 0) {
                    setValues((prevValues) => ({
                        ...prevValues,
                        species: nurs.AgroNurserySemis[0].specie_id
                    }))
                }
            }
        } else {
            setSpecie([])
            setValues((prevValues) => ({
                ...prevValues,
                species: ''
            }))
        }
    }, [values.nursery])

    useEffect(() => {
        if (selectedNursery && selectedNursery.AgroNurserySemis) {
            setSpecie(selectedNursery.AgroNurserySemis)
            const semisDate = new Date(selectedNursery.AgroNurserySemis[0].SemisDate)
            const yearNursery = semisDate.getFullYear()

            if (
                values.species !== '' &&
                values.site !== '' &&
                values.nursery !== '' &&
                selectedNursery.AgroNurserySemis[0]?.specie_id === values.species &&
                values.year === yearNursery
            ) {
                setData(selectedNursery)
            } else {
                setData({})
            }
        }
    }, [values, selectedNursery])

    const handleChange = (e) => {
        const { name, value } = e.target
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }))
    }

    const formatNumber = (number) => {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }

    const getYears = () => {
        const currentYear = new Date().getFullYear()
        const options = []
        for (let i = currentYear - 4; i <= currentYear + 4; i++) {
            options.push(i)
        }
        return options
    }

    useEffect(() => {
        if (values && dataContext) {
            console.log(values)
            console.log(dataContext)
        }
    }, [values, dataContext])

    return (
        <Paper className={classes.pageContent} elevation={0} style={{ backgroundColor: '#F2F8F6' }}>
            <TablePaper style={{ padding: 10, marginTop: 20, borderRadius: 10 }} elevation={8}>
                <div>
                    <div className="flex justify-between">
                        <div>
                            <p className="font-semibold text-lg">Pépinière</p>
                        </div>
                        <div>
                            <select
                                className="bg-[#0FCB8F] w-24 h-8 rounded-md border-none text-white"
                                name="year"
                                id="year"
                                value={values.year}
                                onChange={handleChange}
                            >
                                {getYears().map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="flex gap-[40px] mb-[15px] w-[60%] mt-[16px]">
                        <div className="w-full">
                            <Controls.AutoCompleteSelect
                                label="Site"
                                name="site"
                                value={values.site || ''}
                                onChange={handleChange}
                                options={site}
                                labelKey="name"
                                valueKey="id"
                            />
                        </div>
                        <div className="w-full">
                            <Controls.AutoCompleteSelect
                                label="Pépinière"
                                name="nursery"
                                value={values.nursery || ''}
                                onChange={handleChange}
                                options={nursery}
                                labelKey="name"
                                valueKey="id"
                            />
                        </div>
                        <div className="w-full">
                            <Controls.AutoCompleteSelect
                                label="Espèces"
                                name="species"
                                value={values.species || ''}
                                onChange={handleChange}
                                options={specie}
                                labelKey="specie_name"
                                valueKey="specie_id"
                            />
                        </div>
                    </div>

                    <div className="flex gap-[20px] w-[75%]">
                        <DashboardInput
                            title="Objectif"
                            content={`${
                                dataContext
                                    ?.find((item) => item.id === values.site)
                                    ?.AgroNursery?.find((nursery) => nursery.id === values.nursery)
                                    ?.AgroNurserySpecie?.find(
                                        (nurserySpecie) => nurserySpecie?.specie_id === values.species
                                    )?.target || 0
                            } de jeunes plants.`}
                        />
                        <DashboardInput
                            title="Nombre de plants semés"
                            showImg={true}
                            textColor="text-green-500"
                            src={logo1}
                            value={
                                dataContext
                                    ?.find((item) => item.id === values.site)
                                    ?.AgroNursery?.find((nursery) => nursery.id === values.nursery)
                                    ?.AgroNurserySpecie?.find(
                                        (nurserySpecie) => nurserySpecie?.specie_id === values.species
                                    )?.sown_number || 0
                            }
                        />
                        <DashboardInput
                            title="Nombre de plants disponibles"
                            showImg={true}
                            textColor="text-green-500"
                            src={logo2}
                            value={
                                dataContext
                                    ?.find((item) => item.id === values.site)
                                    ?.AgroNursery?.find((nursery) => nursery.id === values.nursery)
                                    ?.AgroNurserySpecie?.find(
                                        (nurserySpecie) => nurserySpecie?.specie_id === values.species
                                    )?.plant_ready || 0
                            }
                        />
                    </div>

                    <div className="flex justify-between gap-[20px] mt-[40px] mb-[20px]">
                        <DashboardPercent
                            text="Taux de germination"
                            value={
                                dataContext
                                    ?.find((item) => item.id === values.site)
                                    ?.AgroNursery?.find((nursery) => nursery.id === values.nursery)
                                    ?.AgroNurserySpecie?.find(
                                        (nurserySpecie) => nurserySpecie?.specie_id === values.species
                                    )?.germination_rate || 0
                            }
                        />
                        <DashboardPercent text="Taux de réussite" value={20} />
                        <DashboardPercent text="Taux de survie" value={20} />
                    </div>
                </div>
            </TablePaper>
        </Paper>
    )
}
