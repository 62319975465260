import React from 'react'
import { Container, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyle = makeStyles((theme) => ({
    labelInput: {
        fontSize: '12px',
        padding: `0 0 ${theme.spacing(1)} 0`
    },
    labelContainer: {
        marginBottom: '20px',
        width: '100%',
        display: 'block',
        '& .MuiFormControl-root': {
            margin: '0px',
            width: '100%'
        }
    }
}))

export default function InputNumber(props) {
    const { name, label, value, error = null, isDisabled = false, multiline = false, onChange, style, ...other } = props
    const classes = useStyle()

    return (
        <div className={classes.labelContainer}>
            <Container className={classes.labelInput}>{label}</Container>
            <TextField
                disabled={isDisabled}
                sx={{ width: '100%' }}
                size="small"
                name={name}
                type="number"
                value={value}
                onChange={onChange}
                style={style}
                multiline={multiline}
                {...other}
                {...(error && { error: true, helperText: error })}
            />
        </div>
    )
}
