import { TextField } from '@mui/material'
import React from 'react'

function MultilineInput(props) {
  const { label, name, value, placeholder, isAutoFocused, multiline, fullWidth } = props
  return (
    <TextField
      size='small'
      label={label}
      variant="outlined"
      name={name}
      value={value}
      placeholder={placeholder}
      multiline={multiline}
      fullWidth={fullWidth}
      autoFocus={isAutoFocused}
    />
  )
}

export default MultilineInput
