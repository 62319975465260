import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class PlanterService extends Service {
    static messages = {
        update_success: 'Modification Planteur avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création Planter avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/planter')
        return this
    }

    static async getById(id){
        return await Http.get(`${this.url}/${id}`, {})
    }

    static async getByCode(code){
        return await Http.get(`${this.url}/byCode/${code}`, {})
    }

    static async deletePlanter(data) {
        return await Http.delete(`${this.url}/byCode/${data}`, {})
    }
}

export default PlanterService
