import { Avatar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Popup from '../../layouts/Popup';

function AvatarContainer(props) {
    const { title, src, rounded, place, other, size, padding: margin , customStyle={} } = props;
    const [openPopup, setOpenPopup] = useState(false);

    const handleClick = () => {
        setOpenPopup(true);
    }

    return (
        <>
            <Avatar
                sx={{
                    width: size,
                    height: size,
                    borderRadius: rounded ? `${rounded}%` : '10%',
                    marginLeft: place === 'left' ? 0 : !place ? 'unset' : 'auto',
                    margin: margin,
                    ...customStyle
                }}
                src={src}
                {...other}
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
            />
            {openPopup && (
                <Popup title={title} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    <AvatarContainer src={src} rounded={3} size={500} />
                </Popup>
            )
            }
        </>
    );
}

export default AvatarContainer;
