import Notification from "../layouts/Notification.js";
import { Http } from "./Http.js";

class Service {

  static setUrl(url)
  {
    this.url = url;
    return this;
  }
  
  static async getAll(params = null) {
    console.log(params)
    return await Http.get(this.url, params);
  }

  static async get(id) {
    return await Http.get([this.url, id].join("/"), {});
  }

  static async create(params) {
    return await Http.put(this.url, params);
  }

  static async update(id, params) {
    return await Http.patch([this.url, id].join("/"), params);
  }

  static async delete(id) {
    return await Http.delete([this.url, id].join("/"), {});
  }
}

export default Service;
