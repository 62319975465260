export const agroPlotModel = {
    label: 'Parcelle (Agroforesterie)',
    tableName: 'plots',
    properties: [
        {
            label: 'Nom paysan',
            id: 'peasant_firstname',
            type: 'text',
            required: true
        },
        {
            label: 'Prénom paysan',
            id: 'peasant_lastname',
            type: 'text',
            required: true
        },
        {
            label: 'Groupe du paysan',
            id: 'agroGroups',
            isForeignKey: true,
            column_name: 'name',
            table_column: 'group_id',
            type: 'text',
            required: true
        },
        {
            label: 'Surface',
            id: 'surface',
            type: 'text',
            required: true
        },
        {
            label: 'Statut foncier',
            id: 'statut_foncier',
            type: 'text',
            required: true
        },
        {
            label: 'Waypoint',
            id: 'waypoint',
            type: 'text',
            required: true
        },
        {
            label: 'Toposéquence',
            id: 'topo',
            type: 'text',
            required: true
        },
        {
            label: 'Texture',
            id: 'texture',
            type: 'text',
            required: true
        },
        {
            label: 'Utilisation actuelle de la parcelle',
            id: 'use_actually',
            type: 'text',
            required: true
        },
        {
            label: "Estimation nombres d'arbres",
            id: 'estimated_number_plant',
            type: 'text',
            required: true
        }
    ]
}
