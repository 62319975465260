import React, { useEffect, useState } from 'react'
import Controls from '../../../components/Entry/Controls'
import ProvinceService from '../services/Provinces'
import { Container, Grid } from '@mui/material'

function ProvinceCard(props) {
    const { site_id, onChange, errors, filter, setFilter = null } = props
    const province = ProvinceService.load()

    const [provinceData, setProvinceData] = useState(null)
    const [regionsList, setRegionsList] = useState(null)
    const [districtList, setDistrictList] = useState(null)
    const [communeList, setCommuneList] = useState(null)
    const [fktList, setFktList] = useState(null)
    const [villageList, setVillageList] = useState(null)

    const [selectedRegion, setSelectedRegion] = useState(null)
    const [selectedDistrict, setSelectedDistrict] = useState(null)
    const [selectedCommune, setSelectedCommune] = useState(null)
    const [selectedFkt, setSelectedFkt] = useState(null)
    const [selectedVillage, setSelectedVillage] = useState(null)

    useEffect(() => {
        // Récupérer les sites
        province.getProvinces().then((response) => {
            if (response?.data) {
                setProvinceData(response.data)
                setRegionsList(response.data.regions)
                setDistrictList(null)
                setCommuneList(null)
                setFktList(null)
                setVillageList(null)
                setSelectedRegion(null)
                setSelectedDistrict(null)
                setSelectedCommune(null)
                setSelectedFkt(null)
            }
        })
    }, [])

    useEffect(() => {
        if (selectedRegion && setFilter) {
            setFilter({ ...filter, region: selectedRegion })
        }
    }, [selectedRegion, setFilter, filter])

    useEffect(() => {
        if (selectedDistrict && setFilter) {
            setFilter({ ...filter, district: selectedDistrict })
        }
    }, [selectedDistrict, setFilter, filter])

    useEffect(() => {
        if (selectedCommune && setFilter) {
            setFilter({ ...filter, commune: selectedCommune })
        }
    }, [selectedCommune, setFilter, filter])

    useEffect(() => {
        if (selectedFkt && setFilter) {
            setFilter({ ...filter, fkt: selectedFkt })
        }
    }, [selectedFkt, setFilter, filter])

    useEffect(() => {
        if (selectedVillage && setFilter) {
            setFilter({ ...filter, village: selectedVillage })
        }
    }, [selectedVillage, setFilter, filter])

    const handleSelectedRegion = (event) => {
        setDistrictList(provinceData.regions.find((row) => row.id === event.target.value)?.District || null)
        setSelectedRegion(event.target.value || null)
    }

    const handleSelectedDistrict = (event) => {
        setCommuneList(districtList.find((row) => row.id === event.target.value)?.Commune || null)
        setSelectedDistrict(event.target.value)
    }

    const handleSelectedCommune = (event) => {
        setFktList(communeList.find((row) => row.id === event.target.value)?.Fokontany || null)
        setSelectedCommune(event.target.value)
    }

    const handleSelectedFkt = (event) => {
        setVillageList(fktList.find((row) => row.id === event.target.value)?.Village || null)
        setSelectedFkt(event.target.value)
    }

    return (
        <>
            <Grid item xs={3}>
                <Container>
                    <Controls.AutoCompleteSelect
                        name="region"
                        label={'Région'}
                        options={regionsList || []}
                        labelKey={'name'}
                        valueKey={'id'}
                        value={selectedRegion}
                        onChange={(e) => handleSelectedRegion(e)}
                    />
                </Container>
            </Grid>
            <Grid item xs={3}>
                <Container>
                    <Controls.AutoCompleteSelect
                        name="district"
                        label={'District'}
                        options={districtList || []}
                        labelKey={'name'}
                        valueKey={'id'}
                        value={selectedDistrict}
                        onChange={(e) => handleSelectedDistrict(e)}
                        isDisabled={districtList ? false : true}
                    />
                </Container>
            </Grid>
            <Grid item xs={3}>
                <Container>
                    <Controls.AutoCompleteSelect
                        name="commune"
                        label={'Commune'}
                        options={communeList || []}
                        labelKey={'name'}
                        valueKey={'id'}
                        value={selectedCommune}
                        onChange={(e) => handleSelectedCommune(e)}
                        isDisabled={communeList ? false : true}
                    />
                </Container>
            </Grid>
            <Grid item xs={3}>
                <Container>
                    <Controls.AutoCompleteSelect
                        name="fkt"
                        label={'Fokontany'}
                        options={fktList || []}
                        labelKey={'name'}
                        valueKey={'id'}
                        value={selectedFkt}
                        onChange={(e) => handleSelectedFkt(e)}
                        isDisabled={fktList ? false : true}
                    />
                </Container>
            </Grid>
            <Grid item xs={3}>
                <Container>
                    <Controls.AutoCompleteSelect
                        name="site_id"
                        label={'Village'}
                        options={villageList || []}
                        labelKey={'name'}
                        valueKey={'id'}
                        value={site_id}
                        onChange={(e) => onChange(e)}
                        isDisabled={villageList ? false : true}
                        error={errors?.site_id}
                    />
                </Container>
            </Grid>
        </>
    )
}

export default ProvinceCard
