import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import routes from '../routes'
import { useSelector } from 'react-redux'

import Page404 from '../pages/404'
import { useAuth } from '../applications/hooks/UseAuth'
import { useContext, useEffect } from 'react'
import { GlobalContext } from '../applications/hooks/UseContext'

function PageContent() {
    const { pageTitle } = useSelector((state) => state.header)
    const globalContext = useContext(GlobalContext)

    return (
        <Routes>
            {routes.map((route, key) => {
                return <Route key={key} exact={true} path={`${route.path}`} element={<route.component globalContext={globalContext} />} />
            })}

            {/* Redirecting unknown url to 404 page */}
            <Route path="*" element={<Page404 />} />
        </Routes>
    )
}

export default PageContent
