import React from 'react'
import { UseStyles } from '../../../utils/Styles'
import { Container, Paper } from '@mui/material'
import Subtitle from '../../../../../components/Typography/Subtitle'

function VegetationPlanted(props) {
    const { plantedSpecies } = props
    console.log(plantedSpecies)
    const classes = UseStyles()
    return (
        <div>
            <Container className={classes.title}>
                <Subtitle
                    style={{
                        fontSize: '0.8rem',
                        color: '#677788'
                    }}
                >
                    Végétation présentes sur la parcelle (
                    {plantedSpecies
                        ? plantedSpecies.length < 10
                            ? `${plantedSpecies.length}`
                            : plantedSpecies.length
                        : '0'}
                    )
                    {plantedSpecies
                        ? plantedSpecies.map((data) => (
                              <Container className={classes.vegetationContent}>{data.name}</Container>
                          ))
                        : ''}
                </Subtitle>
            </Container>
        </div>
    )
}

export default VegetationPlanted
