import React, { createContext, useEffect, useState } from 'react'

export const context = createContext({})

function MangroOutingContext(props) {
    const { children, userHaveToAdd } = props
    const [value, setValue] = useState(null)

    useEffect(() => {
        if (userHaveToAdd) {
            setValue({ userHaveToAdd: userHaveToAdd })
        }
    }, [userHaveToAdd])
    return <context.Provider value={value}>{children}</context.Provider>
}

export default MangroOutingContext
