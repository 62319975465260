import React, { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Controls from './Controls'
import Notification from '../../layouts/Notification'
import { Container, Paper } from '@mui/material'

function FileInput(props) {
    const { label, onClick, type, onChange, processCompleted, onChooseFile } = props
    const [valueFile, setValueFile] = useState(null)
    const handleFileChange = (event) => {
        const files = event.target.files
        console.log(files)
        // Handle the selected files here
        if (files && verifyFile(files[0], type)) {
            onChange(files[0])
        } else if (!valueFile) {
            console.log(valueFile)
            new Notification('Veuillez choisir le fichier.', 0)
        } else {
            new Notification('Le fichier doit etre de type ' + type, 0)
        }
        setValueFile(files)
    }

    const handleClick = () => {
        console.log(valueFile)
        if (valueFile && verifyFile(valueFile[0], type)) {
            //recupérer les donnes du fichier
            onClick(valueFile[0])
            console.log(valueFile[0])
        } else if (!valueFile) {
            console.log(valueFile)
            new Notification('Veuillez choisir le fichier.', 0)
        } else {
            new Notification('Le fichier doit etre de type ' + type, 0)
        }
    }

    return (
        <div
            style={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <TextField
                type="file"
                variant="outlined"
                onChange={handleFileChange}
                onClick={() => {
                    console.log(valueFile)
                    onChooseFile()
                }}
            />
            <Container>
                <Controls.Button onClick={handleClick} text={label} size="large" disabled={!processCompleted} />
            </Container>
        </div>
    )
}

export default FileInput

function verifyFile(file, type) {
    if (file.name.split('.')[file.name.split('.').length - 1] === type) {
        return true
    } else {
        return false
    }
}
