import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class RoleService extends Service {
    static messages = {
        update_success: 'Modification rôle avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création rôle avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/users/roles')
        return this
    }

    static async getRoleByName(name) {
        return await Http.get([this.url, 'byName'].join('/'), { name })
    }

    static async setPermission(id, params) {
        return await Http.post([this.url, 'permission', id].join('/'), params)
    }

    static async getPermission(id) {
        return await Http.get([this.url, 'permission', id].join('/'), {})
    }
}

export default RoleService
