import React, { useEffect, useState } from 'react'
import Popup from '../../../../layouts/Popup'
import { Typography } from '@mui/material'
import Controls from '../../../../components/Entry/Controls'
import ProvinceService from '../../services/Provinces'
import Notification from '../../../../layouts/Notification'
import { useForm } from '../../../../applications/hooks/UseForm'

const initialValues = {
    sigle: ''
}
function SigleForm(props) {
    const { openPopup, setOpenPopup, item, setGroupSaved } = props
    const service = ProvinceService.load()
    const [sigle, setSigle] = useState('')
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        // if ('genre' in fieldValues) temp.genre = fieldValues.genre.length !== 0 ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialValues,
        true,
        validate
    )

    useEffect(() => {
        if (item) {
            setValues({
                sigle: item?.sigle
            })
        }
    }, [item, setValues])

    const handleAddSigle = async () => {
        console.log(values)
        if (!item?.sigle) {
            if (values.sigle !== '') {
                setUserHaveToPost(true)
                const data = {
                    site_id: item?.id,
                    sigle: values?.sigle
                }
                console.log(data)
                service
                    .addVillageSigle(data)
                    .then((res) => {
                        if (!res.data.response.id) {
                            new Notification(res.data.response, 0)
                        } else {
                            new Notification('Ajout effectué.', 1)
                        }
                        console.log(res)
                        setUserHaveToPost(false)
                        setOpenPopup(false)
                        setGroupSaved(true)
                        setSigle('')
                        resetForm()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                new Notification('Veuillez renseigner le sigle', 0)
            }
        } else {
            console.log(item, values)
            if (values.sigle !== '') {
                setUserHaveToPost(true)
                const data = {
                    site_id: item?.site_id,
                    sigle: values?.sigle
                }
                service
                    .updateVillageSigle(data)
                    .then((res) => {
                        console.log(res)
                        if (!res.data.id) {
                            new Notification(res.data, 0)
                        } else {
                            new Notification('Modification effectuée.', 1)
                        }
                        console.log(res)
                        setUserHaveToPost(false)
                        setOpenPopup(false)
                        setGroupSaved(true)
                        setSigle('')
                        resetForm()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                new Notification('Veuillez renseigner le sigle', 0)
            }
        }
    }
    return (
        <>
            <Popup title={'Ajout sigle'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <div style={{ width: '400px' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }} sx={{ textAlign: 'center' }}>
                        {item?.name}
                    </Typography>
                    <Controls.Input label="Sigle" onChange={handleInputChange} value={values.sigle} name="sigle" />
                    <Controls.Button
                        text="Enregistrer"
                        onClick={handleAddSigle}
                        disabled={userHaveToPost}
                        haveSpin={true}
                    />
                </div>
            </Popup>
        </>
    )
}

export default SigleForm
