import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class PeasantTrainingService extends Service {
    static messages = {
        update_success: 'Modification Paysans avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création Paysans avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/trainings/peasants')
        return this
    }

    static async getPeasantTrainingsByPeasantId(peasantId) {
        return await Http.get(`${this.url}/peasant/${peasantId}`)
    }
}

export default PeasantTrainingService
