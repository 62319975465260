import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import { Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Controls from '../../../components/Entry/Controls'
import Subtitle from '../../../components/Typography/Subtitle'

const useStyles = {
    cardContainer: {
        backgroundColor: '#f8f8f8',
        border: 'none',
        boxShadow: 'none',
        width: '90%',
        marginTop: '20px',
        borderRadius: '10px'
    },
    //    card: {
    //         backgroundColor: 'transparent',
    //         border: 'none',
    //         boxShadow: 'none',
    //         '& .MuiGrid-root': {
    //             padding: '20px 5px 20px 5px'
    //         }
    //     },
    title: {
        padding: '20px 20px 20px 20px',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none'
    },
    subtitle: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none'
    },
    voirPhoto: {
        fontSize: '13px',
        textAlign: 'end',
        padding: '0 20px 0 20px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    cardContent: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        padding: '0px 20px 20px 20px'
    }
}

function PlantingSpeciesCard({ plantedSpecies }) {
    const classes = useStyles

    // Regrouper les espèces par specie_id
    const groupedSpecies = plantedSpecies?.reduce((acc, specie) => {
        const { specie_id, ...rest } = specie
        if (!acc[specie_id]) {
            acc[specie_id] = { ...rest, seed: 0, propagule: 0, young_plant: 0 }
        }
        acc[specie_id].seed += specie.seed || 0
        acc[specie_id].propagule += specie.propagule || 0
        acc[specie_id].young_plant += specie.young_plant || 0
        return acc
    }, {})

    const groupedSpeciesArray = groupedSpecies ? Object.values(groupedSpecies) : []

    return (
        <>
            <div style={classes.cardContainer}>
                <div style={classes.title}>
                    <Subtitle
                        style={{
                            fontSize: '0.8rem',
                            color: '#677788'
                        }}
                    >
                        Espèces plantées ({groupedSpeciesArray?.length})
                    </Subtitle>
                </div>
                <div style={classes.cardContent}>
                    {groupedSpeciesArray.map((specie) => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div>
                                    <Controls.CustomImageAvatar
                                        title={specie?.specie_name}
                                        src={specie?.specie_img}
                                        rounded={50}
                                        size={70}
                                        place="left"
                                    />
                                </div>
                                <div style={classes.subtitle}>
                                    <Subtitle
                                        style={{
                                            fontSize: '0.8rem',
                                            color: '#677788'
                                        }}
                                    >
                                        {specie.specie_name}
                                    </Subtitle>
                                    <Typography variant="body2" color="text.secondary" style={{ fontSize: '0.7rem' }}>
                                        <div>Graine : {specie?.seed}</div>
                                        <div>Propagule : {specie?.propagule}</div>
                                        <div>Jeune Plant : {specie?.young_plant}</div>
                                    </Typography>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default PlantingSpeciesCard
