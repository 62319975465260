export const communeModel = {
    label: 'Commune',
    tableName: 'commune',
    properties: [
        {
            label: 'District',
            id: 'district',
            isForeignKey: true,
            column_name: 'name',
            table_column: 'parent',
            type: 'text',
            required: true,
        },
        {
            label: 'Nom',
            id: 'name',
            type: 'text',
            required: true,
        },
    ]
}