import React, { useState } from 'react'
import { useAuth } from '../../../applications/hooks/UseAuth'
import ContentView from '../../../components/PageView/Content'
import { PlantingTrackingForm } from './PlantingTrackingForm'
import PlantingService from '../services/Planting'
import { useLocation } from 'react-router-dom'
import PlantingTrackingContext from '../utils/hooks/PlantingTrackingContext'

function PlantingTrackingView(props) {
    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const plantingService = PlantingService.load()
    const [needLoad, setNeedLoad] = useState(true)
    return (
        <>
            <PlantingTrackingContext records={location?.state}>
                <ContentView
                    title={` Ajout Suivi `}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={PlantingTrackingForm}
                    dataId={dataId}
                    service={plantingService}
                />
            </PlantingTrackingContext>
        </>
    )
}

export default PlantingTrackingView
