import React, { useState } from 'react'
import Table from '../../../../components/DataTable/Table'
import GridView from '../../../../components/popupView/GridView'
import { ReactComponent as IconQuadrillage } from '../../../../assets/icons/quadrillage.svg'

function LandList(props) {
    const { haveAccessTo, drawer, service, needLoad, currentPage, Form, setNeedLoad } = props
    const [openPopup, setOpenPopup] = useState(false)
    const [gridData, setGridData] = useState(null)

    const headCells = [
        {
            id: 'code',
            label: 'ID parcelle'
        },
        {
            id: 'land_grid',
            label: 'Polygone de quadrillage',
            type: 'actions',
            content: <IconQuadrillage fontSize="small" />,
            actionButton: true,
            actions: (item) => {
                setOpenPopup(true)
                setGridData(item.land_grid)
            }
        },
        { id: 'technicien', label: 'Technicien' },
        { id: 'surface', label: 'Surface(ha)', type: 'Integer_ha' },
        {
            id: 'region',
            label: 'Région'
        },
        { id: 'district', label: 'District' },
        { id: 'commune', label: 'Commune' },
        { id: 'fkt', label: 'Fokontany' },
        { id: 'village', label: 'Village' },
        {
            id: 'Actions',
            label: 'Actions',
            type: 'actions',
            showEdit: false,
            showRemove: true,
            showView: '/app/mangrove/lands/view'
        }
    ]

    return (
        <>
            <Table
                title="Détail terrain à restaurer"
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                Form={Form}
                drawer={drawer}
                hideFilterButton={true}
                showSiteButton={true}
            />

            <GridView
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title="Polygone de quadrillage"
                gridData={gridData}
            />
        </>
    )
}

export default LandList
