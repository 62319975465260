import React, { useState } from 'react'
import ContentView from '../../../../../../components/PageView/Content'
import { useAuth } from '../../../../../../applications/hooks/UseAuth'
import AddNursery from './form/AddNursery'
import NurseryService from '../../../../services/Nursery'

function Nursery() {
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
  return (
    <div>
      <ContentView
                title={`Nouvelle pépinière et semis`}
                haveAccessTo={haveAccessTo}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
                Form={AddNursery}
                service = {NurseryService}
                needCode={false}
            />
    </div>
  )
}

export default Nursery
