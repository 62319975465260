import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { InventoryForm } from './InventoryForm'
import InventoryService from '../../services/inventory/inventory'
import ContentView from '../../../../components/PageView/Content'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import InventoryContext from './utils/hooks/InventoryContext'

function InventoryView(props) {
    const { addReturnButton, recordForEdit } = props

    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const inventoryService = InventoryService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(true)

    console.log('location', recordForEdit)

    return (
        <>
            <CssBaseline />
            <InventoryContext needLoad={needLoad} setNeedLoad={setNeedLoad} data={recordForEdit}>
                <ContentView
                    dataId={recordForEdit ? recordForEdit.id : dataId}
                    title={recordForEdit ? null : `Inventaires - Fiche`}
                    addReturnButton={addReturnButton}
                    service={inventoryService}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={InventoryForm}
                    needLoadCode={needLoadCode}
                />
            </InventoryContext>
        </>
    )
}

export default InventoryView
