import * as XLSX from 'xlsx'
import Notification from '../../../layouts/Notification'
import PlantingService from '../services/Planting'

class AppPlantation {
    constructor() {
        this.data = null
        this.file = null
        this.error = false
    }

    setData(data) {
        this.data = data
    }

    getData() {
        return this.data
    }

    setFile(file) {
        this.file = file
    }

    getFile() {
        return this.file
    }

    async generateData() {
        const file = this.getFile()
        if (!file) {
            console.error("Aucun fichier n'a été défini.")
            return
        }

        if (this.verifyFile(file, 'xlsx')) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = (e) => {
                    try {
                        const data = new Uint8Array(e.target.result)
                        const workbook = XLSX.read(data, { type: 'array' })
                        const sheetName = workbook.SheetNames[0]
                        const worksheet = workbook.Sheets[sheetName]
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

                        const setXlsxDataToObjectArray = (data) => {
                            const headers = data[0]
                            const list = []
                            for (let i = 1; i < data.length; i++) {
                                const obj = {}
                                for (let j = 0; j < headers.length; j++) {
                                    obj[headers[j]] = data[i][j]
                                }
                                list.push(obj)
                            }
                            return list
                        }
                        const dataHeader = ['Fokontany', 'iD_points', 'ObjectID', 'X', 'Y']
                        if (jsonData[0].includes(...dataHeader)) {
                            console.log(jsonData)
                            const dataToObjectArray = setXlsxDataToObjectArray(jsonData)
                            this.setData(dataToObjectArray)
                            resolve(dataToObjectArray)
                        } else {
                            new Notification('Le fichier ne contient pas les bonnes colonnes.', 0)
                        }
                    } catch (error) {
                        reject(error)
                    }
                }
                reader.onerror = (e) => {
                    console.error('Erreur lors de la lecture du fichier:', e)
                    reject(e)
                }

                reader.readAsArrayBuffer(file)
            })
        } else {
            new Notification("Le fichier n'est pas au format XLSX.", 0)
        }
    }

    verifyFile(file, type) {
        if (file.name.split('.')[file.name.split('.').length - 1] === type) {
            return true
        } else {
            return false
        }
    }

    async saveData(values) {
        console.log(values)
        const service = PlantingService.load()
        const result = await service.importTrackingPoints({data: values})
        return result
    }
}

export default AppPlantation
