import Service from "../../../applications/Service";

class ActionsService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création avec succès.',
        create_error: 'Erreur de création.'
    }

    static load(){
        this.setUrl('/api/historics/actions')
    }

}

export default ActionsService