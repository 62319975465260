import { Http } from '../../../applications/Http'
import Service from '../../../applications/Service'

class HistoryService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création avec succès.',
        create_error: 'Erreur de création.',
        restore_success: 'Restauration effectuée.',
        restore_error: 'Erreur de restauration.'
    }

    static load() {
        this.setUrl('/api/historics')
    }

    static async restore(data) {
        try {
            console.log(data)
            const result = await Http.patch([this.url, 'restore'].join('/'), data)
            return result?.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}

export default HistoryService
