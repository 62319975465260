import React from 'react'
import { IconButton as MuiIconButton } from '@mui/material'

export default function IconButton(props) {
    const { text, size, color, variant, onClick, children, ...other } = props

    return (
        <MuiIconButton
            style={{
                borderRadius: 10,
                padding: 0,
                margin: '28px 0 0 10px',
            }}
            variant={variant || 'contained'}
            size={size || 'small'}
            color="primary"
            onClick={onClick}
            {...other}
        >
            {children}
        </MuiIconButton>
    )
}
