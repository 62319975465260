import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import QRCode from 'qrcode.react'
import logo_sidebar from '../../assets/image/acceuil.png'

function QrViewer(props) {
    const { value, size = '100%', place = 'center' } = props

    return (
        <Box display="flex" flexDirection="column" alignItems={place} justifyContent={place}>
            <Box sx={{ width: size, height: size }}>
                {value ? (
                    <Box position="relative" display="inline-block">
                        <QRCode value={value} renderAs="svg" size={'100%'} />
                        <Box
                            style={{
                                display: 'flex',
                                position: 'absolute',
                                justifyContent: place,
                                backgroundColor: '#ffffffff',
                                height: '22%',
                                width: '20%',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                            padding={0.4}
                        >
                            <img
                                src={logo_sidebar}
                                alt="logo"
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Typography variant="body1">(Aucun QR Code...)</Typography>
                )}
            </Box>
        </Box>
    )
}

export default QrViewer
