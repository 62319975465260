export const agroPeasantModel = {
    label: 'Paysan (Agroforesterie)',
    tableName: 'peasants',
    properties: [
        {
            label: 'Nom',
            id: 'firstname',
            type: 'text',
            required: true,
        },
        {
            label: 'Prénom',
            id: 'lastname',
            type: 'text',
            required: true,
        },
        {
            label: 'Gengre',
            id: 'genre',
            type: 'text',
            required: true,
        },
        {
            label: 'Téléphone',
            id: 'phone',
            type: 'text',
            required: true,
        },
        {
            label: 'Date de naissance',
            id: 'dob',
            type: 'date',
            required: true,
        },
        {
            label: 'Début de collaboration',
            id: 'member_since',
            type: 'date',
            required: true,
        },
        {
            label: 'Statut matrimonial',
            id: 'martial_status',
            type: 'text',
            required: true,
        },
        {
            label: 'Statut paysan',
            id: 'status',
            type: 'text',
            required: true,
        },
        {
            label: 'Lien avec le propriétaire',
            id: 'proprietary_link',
            type: 'text',
            required: true,
        },
        {
            label: 'Activité',
            id: 'activity',
            type: 'text',
            required: true,
        },
        {
            label: 'Raison de reboisement',
            id: 'reforestation_reason',
            type: 'text',
            required: true,
        },
        {
            label: 'Adresse',
            id: 'address',
            type: 'text',
            required: true,
        },
        {
            label: 'Groupe',
            id: 'agroGroups',
            isForeignKey: true,
            column_name: 'name',
            table_column: 'group_id',
            type: 'text',
            required: true,
        },
    ]
}
