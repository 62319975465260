import React, { useContext, useEffect, useState } from 'react'
import { Typography, Grid, Paper } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls'
import { Context } from '../../../../utils/hooks/InfoPlanterContext'

function AdministrativeDelimitation(props) {
    const { styles, setNeedLoad } = props
    const dataContext = useContext(Context)
    const initialOption = [{id: 'aucun', name: 'aucun'}]
    const [regions, setRegions] = useState(initialOption)
    const [communes, setCommunes] = useState(initialOption)
    const [villages, setVillages] = useState(initialOption)
    const [districts, setDistricts] = useState(initialOption)
    const [fkts, setFkts] = useState(initialOption)
    const [sites, setSites] = useState(initialOption)

    useEffect(() => {
        setNeedLoad(true)
        if(dataContext){
            setRegions([{id: dataContext.region, name: dataContext.region}])
            setCommunes([{id: dataContext.commune, name: dataContext.commune}])
            setVillages([{id: dataContext.village, name: dataContext.village}])
            setDistricts([{id: dataContext.district, name: dataContext.district}])
            setFkts([{id: dataContext.fkt, name: dataContext.fkt}])
            setSites([{id: dataContext.site, name: dataContext.site}])
            setNeedLoad(false)
        }
        
    }, [dataContext, setNeedLoad])

  
    return (
        <>
            <Typography sx={styles.form_title}>Délimitation administrative</Typography>

            <Grid container>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        <Controls.CustomSelect
                            label="Région"
                            name="region"
                            value={dataContext?.region || ''}
                            options={regions}
                            labelKey={'name'}
                            valueKey={'id'}
                            isDisabled={true}
                        />
                        <Controls.CustomSelect
                            label="Commune"
                            name="commune"
                            value={dataContext?.commune || ''}
                            options={communes}
                            labelKey={'name'}
                            valueKey={'id'}
                            isDisabled={true}
                        />
                        <Controls.CustomSelect
                            label="Village"
                            name="village"
                            value={dataContext?.village || ''}
                            options={villages}
                            labelKey={'name'}
                            valueKey={'id'}
                            isDisabled={true}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        <Controls.CustomSelect
                            label="District"
                            name="district"
                            value={dataContext?.district || ''}
                            options={districts}
                            labelKey={'name'}
                            valueKey={'id'}
                            isDisabled={true}
                        />
                        <Controls.CustomSelect
                            label="Fokontany"
                            name="fkt"
                            value={dataContext?.fkt || ''}
                            options={fkts}
                            labelKey={'name'}
                            valueKey={'id'}
                            isDisabled={true}
                        />
                        <Controls.CustomSelect
                            label="Site"
                            name="site"
                            value={dataContext?.site || ''}
                            options={sites}
                            labelKey={'name'}
                            valueKey={'id'}
                            isDisabled={true}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default AdministrativeDelimitation
