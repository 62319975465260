import React, { useEffect, useState } from 'react'
import { Form, useForm } from '../../../applications/hooks/UseForm'
import Controls from '../../../components/Entry/Controls'
import { Container } from '@mui/material'
import DataSetsService from '../../datasets/services/DataSets'
import CustomerOrderService from '../../customer_order/services/CustomerOrder'
import ProvinceService from '../../provinces/services/Provinces'
import Notification from '../../../layouts/Notification'
import OrderTrackingService from '../services/OrderTracking'

const initialFValues = {
    customer_id: '',
    project_type: '',
    plantation_type: '',
    tree_ordered: '',
    location: '',
    state: 'Commande à traiter'
}

function AddOrder(props) {
    const { setOpenPopup, setNeedLoad } = props

    const dataset = DataSetsService.load()
    const customer_api = CustomerOrderService.load()
    const province_api = ProvinceService.load()
    const plantationTypes = [{ label: 'Agroforesterie' }, { label: 'Mangroves' }]
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [customersData, setCustomersData] = useState([])
    const [regionData, setRegionData] = useState([])
    const [projectTypeData, setProjectTypeData] = useState([])
    const [agroTypeData, setAgroTypeData] = useState([])

    useEffect(() => {
        setNeedLoad(true)
        dataset
            .getDataCode('project_type')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setProjectTypeData(dataArray)
                    customer_api
                        .getCustomerOrder()
                        .then((res) => {
                            setCustomersData(res)
                            province_api
                                .getAllVillages()
                                .then((res) => {
                                    if (res) {
                                        setRegionData(res.data)
                                    }
                                    dataset
                                        .getDataCode('agro_type')
                                        .then((response) => {
                                            if (response?.data) {
                                                const dataArray = JSON.parse(response.data[0].database)
                                                setAgroTypeData(dataArray || [])
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err)
                                        })
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
            .finally(() => {
                setNeedLoad(false)
            })
    }, [customer_api, dataset, province_api, setNeedLoad])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('customer_id' in fieldValues) temp.customer_id = fieldValues.customer_id ? '' : 'Ce champ est requis.'
        if ('project_type' in fieldValues) temp.project_type = fieldValues.project_type ? '' : 'Ce champ est requis.'
        if ('plantation_type' in fieldValues)
            temp.plantation_type = fieldValues.plantation_type ? '' : 'Ce champ est requis.'
        if ('tree_ordered' in fieldValues) temp.tree_ordered = fieldValues.tree_ordered ? '' : 'Ce champ est requis.'
        // if ('location' in fieldValues) temp.location = fieldValues.location ? '' : 'Ce champ est requis.'

        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate)

    const handleSubmit = async (e) => {
        if (values?.customer_id === 1 || values?.customer_id === 2) {
            delete values.agro_type
        }
        e.preventDefault()
        if (validate()) {
            console.log(values)
            setUserHaveToPost(true)
            try {
                const order = await OrderTrackingService.addOrderTracking(values)
                if (order) {
                    setOpenPopup(false)
                    new Notification('Commande ajoutée avec success', 1)
                    setNeedLoad(true)
                }
            } catch (error) {
                new Notification('Erreur lors de la création de la commande.', 0)
                console.log(error)
            } finally {
                setUserHaveToPost(false)
            }
        }
    }

    const handleChange = (e) => {
        handleInputChange(e)
        setValues((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value
        }))
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Container style={{ width: '550px' }}>
                <div className="flex gap-4">
                    <Controls.AutoCompleteSelect
                        name="customer_id"
                        label="Client"
                        options={customersData}
                        labelKey={'firstname'}
                        valueKey={'id'}
                        value={values?.customer_id}
                        error={errors?.customer_id}
                        isRequired={true}
                        onChange={handleChange}
                    />
                    <Controls.AutoCompleteSelect
                        name="project_type"
                        label="Type de projet"
                        options={projectTypeData}
                        labelKey={'label'}
                        valueKey={'label'}
                        value={values?.project_type}
                        error={errors?.project_type}
                        isRequired={true}
                        onChange={handleChange}
                    />
                </div>
                <div className="flex gap-4">
                    <Controls.AutoCompleteSelect
                        name="plantation_type"
                        label="Type de plantation"
                        options={plantationTypes}
                        labelKey={'label'}
                        valueKey={'label'}
                        value={values?.plantation_type}
                        error={errors?.plantation_type}
                        isRequired={true}
                        onChange={handleChange}
                    />
                    <Controls.Input
                        id="outlined-disabled"
                        label="Nombre d'arbres commandés"
                        name="tree_ordered"
                        value={values?.tree_ordered || ''}
                        size="small"
                        type="number"
                        onChange={handleChange}
                        error={errors?.tree_ordered}
                        isRequired={true}
                    />
                </div>
                <div className="flex gap-4">
                    <div
                        style={{
                            display: values?.customer_id === 1 || values?.customer_id === 2 ? 'none' : 'contents'
                        }}
                    >
                        <Controls.AutoCompleteSelect
                            name="agro_type"
                            label="Type d'agro"
                            options={agroTypeData}
                            labelKey={'label'}
                            valueKey={'label'}
                            value={values?.agro_type}
                            error={errors?.agro_type}
                            onChange={handleChange}
                        />
                    </div>
                    <Controls.Input
                        id="outlined-disabled"
                        label="Localisation demandée"
                        name="location"
                        value={values?.location || ''}
                        size="small"
                        onChange={handleChange}
                        error={errors?.location}
                    />
                </div>
                <Controls.Button type="submit" text="Enregistrer" haveSpin={true} disabled={userHaveToPost} />
            </Container>
        </Form>
    )
}

export default AddOrder
