import { Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'

function ModifyButton({ onClick, userHaveToModify}) {
    const [textColor, setTextColor] = useState('black')
    const [label, setLabel] = useState('Modifier')

    useEffect(() => {
        if(userHaveToModify) {
            setLabel('Annuler')
            setTextColor('#14b57c')
        }
        else {
            setLabel('Modifier')
            setTextColor('black')
        }
    }, [userHaveToModify])

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '5px 5px 20px 5px' }}>
            <div
                style={{
                    display: 'contents',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px 5px 5px 5px',
                    cursor: 'pointer',
                }}
                onMouseOver={() => {
                    setTextColor('#14b57c')
                }}
                onMouseLeave={() => {
                    setTextColor('black')
                }}
                onClick={() => {
                    setLabel(label === 'Modifier' ? 'Annuler' : 'Modifier')
                    onClick()
                }}
            >
                <EditOutlinedIcon fontSize="small" onClick={() => {}} sx={{ color: textColor }} />
                <Typography
                    variant="body2"
                    color={textColor}
                    style={{ fontSize: '0.75rem', alignSelf: 'center', color: textColor }}
                >
                    <b>{label}</b>
                </Typography>
            </div>
        </div>
    )
}

export default ModifyButton
