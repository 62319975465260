/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { DASHBOARD_SIDEBAR_LINKS } from '../../../../routes/menu'
import { Grid } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Controls from '../../../../components/Entry/Controls'
import TransfertList from './TransfertList'
import RoleService from '../../services/Role'

const useStyles = makeStyles((theme) => ({
    pageContent: {
        textAlign: 'right',
        paddingBottom: '1rem'
    },
    pageContentButton: {
        margin: '0 0 0 1rem'
    }
}))

const Link = (props) => {
    const { linkKey, link, submenu, permLoaded, permissions, handlePermissions } = props
    const [expanded, setExpanded] = React.useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    let keyString = [link.key]
    linkKey && keyString.unshift(linkKey)

    return (
        <Accordion expanded={submenu || expanded === link.key} onChange={handleChange(link.key)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                <Typography sx={{ width: '33%', flexShrink: 0 }}>{link.label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid item>
                    {submenu
                        ? submenu.map((link) => (
                              <Grid item>
                                  <Link
                                      permissions={permissions}
                                      permLoaded={permLoaded}
                                      linkKey={keyString}
                                      handlePermissions={handlePermissions}
                                      link={link}
                                  />
                              </Grid>
                          ))
                        : link.permissions && (
                              <TransfertList
                                  permissions={permissions}
                                  permLoaded={permLoaded}
                                  linkKey={keyString.join('_')}
                                  handlePermissions={handlePermissions}
                                  allChoices={link.permissions}
                              />
                          )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

function SetPermissionPage(props) {
    const classes = useStyles()
    const { roleId, toggleDrawer } = props
    const [permissions, setPermissions] = useState({})
    const [onLoad, setOnLoad] = useState(true)
    const [permLoaded, setPermLoaded] = useState(false)

    const handlePermissions = (linkKey, permission) => {
        let changedPermission = permissions
        changedPermission[linkKey] = permission
        setPermissions(changedPermission)
    }

    const savePermissions = () => {
        RoleService.setPermission(roleId, { permissions: JSON.stringify(permissions) })
        toggleDrawer(false)
    }

    const resetPermissions = () => {
        alert('Reset')
    }

    useEffect(() => {
        if (onLoad) {
            RoleService.getPermission(roleId)
                .then((response) => {
                    return JSON.parse(response?.data?.permissions || '{}')
                })
                .then((data) => {
                    setPermissions(data)
                    setOnLoad(false)
                    setPermLoaded(true)
                })
        }
    }, [permissions])

    return (
        <>
            <Grid
                className={classes.pageContent}
                style={{
                    display: 'flex',
                    gap: '5px'
                }}
            >
                <Controls.Button
                    className={classes.pageContentButton}
                    size="small"
                    text="Réinitialiser"
                    onClick={resetPermissions}
                />
                <Controls.Button
                    className={classes.pageContentButton}
                    size="small"
                    text="Enregister"
                    onClick={savePermissions}
                />
            </Grid>

            {DASHBOARD_SIDEBAR_LINKS.map((link) => (
                <Grid item>
                    {link.submenu ? (
                        <Link
                            permissions={permissions}
                            permLoaded={permLoaded}
                            handlePermissions={handlePermissions}
                            link={link}
                            submenu={link.submenu}
                        />
                    ) : (
                        <Link
                            permissions={permissions}
                            permLoaded={permLoaded}
                            handlePermissions={handlePermissions}
                            link={link}
                        />
                    )}
                </Grid>
            ))}
        </>
    )
}

export default SetPermissionPage
