import React from 'react'
import { DialogTitle, DialogContent, Typography, Drawer as MuiDrawer } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Controls from '../components/Entry/Controls'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles((theme) => ({
    drawerWrapper: {
        marginTop: 165
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}))

export default function Drawer(props) {
    const { title, children, openDrawer, toggleDrawer, setNeedLoad = null } = props
    const classes = useStyles()

    return (
        <MuiDrawer
            sx={{
                '& .MuiDrawer-paper': { width: '81.8%', marginRight: '3px' }
            }}
            anchor="right"
            open={openDrawer}
            onClose={() => {
                toggleDrawer(false)
                setNeedLoad && setNeedLoad(true)
            }}
        >
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Controls.ActionButton
                        color="primary"
                        onClick={() => {
                            toggleDrawer(false)
                            setNeedLoad && setNeedLoad(true)
                        }}
                    >
                        <CloseIcon />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
        </MuiDrawer>
    )
}
