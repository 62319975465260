import React from 'react'
import Popup from '../../../../../../layouts/Popup'
import CustomFileView from '../../../../../../components/Entry/CustomFileView'
import { Container, Paper } from '@mui/material'

function LandedStateFiles(props) {
    const { openPopup, setOpenPopup, fileData } = props
    console.log(fileData)
    return (
        <>
            <Popup title={'Emplacement administratif'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <Container sx={{ padding: 0, margin: 0, display: 'flex', "& .MuiPaper-root": { boxShadow: "none" }}}>
                    {fileData &&
                        fileData.map((file, index) => {
                            return (
                                <>
                                    <Paper key={index}>
                                        <CustomFileView fileName={`fichier ${index + 1}`} file={file.file_path} />
                                    </Paper>
                                </>
                            )
                        })}
                </Container>
            </Popup>
        </>
    )
}

export default LandedStateFiles
