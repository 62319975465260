import React, { useState } from 'react'
import { Form, useNavigate } from 'react-router-dom'

import Controls from '../../components/Entry/Controls'
import PageHeader from '../../components/Typography/PageHeader'
import { useAuth } from '../../applications/hooks/UseAuth'
import OrderTrackingList from './components/OrderTrackingList'
import OrderTrackingService from './services/OrderTracking'
import OrderTrackingView from './components/OrderTrackingView'
import ToolbarButton from '../../components/DataTable/ToolbarButton'
import AddOrder from './components/AddOrder'

const CURRENT_PAGE = 'commercial_commande'

function OrderTracking() {
    const service = OrderTrackingService.load()
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const useDrawer = false
    const navigate = useNavigate()

    const addAction = () => {
        navigate('/app/commercial/order/new')
    }

    return (
        <>
            <PageHeader title={'Gestion des commandes'} subTitle={'Gestion des commandes'}>
                <div className="space-x-2 padding-x-2 ml-[10px]">
                    <ToolbarButton
                        moduleTitle="d'une commande"
                        service={service}
                        label="Ajouter"
                        drawer={useDrawer}
                        haveAccessTo={haveAccessTo}
                        currentPage={CURRENT_PAGE}
                        setNeedLoad={setNeedLoad}
                        needLoad={needLoad}
                        Form={AddOrder}
                    />
                </div>
            </PageHeader>

            <OrderTrackingList
                service={service}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                Form={OrderTrackingView}
                drawer={true}
                currentPage={CURRENT_PAGE}
            />
        </>
    )
}

export default OrderTracking
