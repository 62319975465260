import React, { useEffect, useState } from 'react'
import { Form, useForm } from '../../../applications/hooks/UseForm'
import { Box, Container, FormControlLabel, Link, Paper, Radio, RadioGroup } from '@mui/material'
import { IoMdMale } from 'react-icons/io'
import { FaPhoneVolume } from 'react-icons/fa6'
import { CiCalendar } from 'react-icons/ci'
import image_profil from '../../../assets/image/profil.jpg'
import { IoMaleFemaleOutline } from 'react-icons/io5'
import moment from 'moment'
import { makeStyles } from '@mui/styles'
import Controls from '../../../components/Entry/Controls'
import QrViewer from '../../../components/Entry/QrViewer'
import Popup from '../../../layouts/Popup'
import PeasantForm from './PeasantForm'
import PeasantService from '../services/Peasant'
import CustomFileView from '../../../components/Entry/CustomFileView'
import DataSetsService from '../../datasets/services/DataSets'
// import { useLocation } from 'react-router-dom'
// import PeasantService from '../services/Peasant'

const useStyles = makeStyles({
    noTypography: {
        fontFamily: 'inherit',
        fontWeight: 'inherit',
        fontSize: 'inherit'
    }
})

// const stateOptions = [
//     { id: 'Propriétaire', title: 'Propriétaire' },
//     { id: 'Locataire', title: 'Locataire' },
//     { id: 'Propriétaire/Locataire', title: 'Propriétaire/Locataire' }
// ]

const { Input: TextField } = Controls

export const ShowPeasant = (props) => {
    const { records, setNeedLoad, needLoad } = props

    const [userHaveToModify, setUserHaveToModify] = useState(false)
    const [peasantState, setPeasantState] = useState(null)
    const [stateOptions, setStateOptions] = useState([])
    const [openContract, setOpenContract] = useState(false)
    const classes = useStyles()
    const [values, setValues] = useState(null)
    const dataset = DataSetsService.load()

    useEffect(() => {
        dataset
            .getDataCode('statut_paysan')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setStateOptions(
                        dataArray.map((item) => {
                            return {
                                id: item.label,
                                title: item.label
                            }
                        })
                    )
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [])
    useEffect(() => {
        console.log('records : ', records)
        if (records) {
            setValues({
                ...records,
                age: `${moment().diff(records?.dob, 'years')} ans`
            })
            setPeasantState(records?.status)
        }
    }, [records])

    useEffect(() => {
        if (needLoad) {
            setNeedLoad(true)
            const service = PeasantService.load()
            service.getByCode(values?.code).then((data) => {
                if (data) {
                    setValues({
                        ...data,
                        age: `${moment().diff(data?.dob, 'years')} ans`
                    })
                }
                setNeedLoad(false)
            })
        }
    }, [setNeedLoad, needLoad, values])

    return (
        <div>
            <Form>
                <div>
                    <Form>
                        <div className="flex p-4">
                            <div className="w-[30%] max-w-[296px] p-4">
                                <div className="rounded-full overflow-hidden h-[150px] w-[150px] flex items-center justify-center bg-gray-200 items-center mx-auto">
                                    {/* <img src={`${window.location.origin}/uploads/${records?.photo}`} alt="image profil" className="h-full w-full object-cover" /> */}
                                    <Controls.CustomImageAvatar
                                        title="Photo du paysan"
                                        size={150}
                                        rounded={100}
                                        place="center"
                                        src={`${values?.photo}`}
                                    ></Controls.CustomImageAvatar>
                                </div>
                                <div className="mt-[25px]">
                                    <>
                                        <p className="font-semibold">
                                            {values?.firstname} {values?.lastname}
                                        </p>
                                        <div className="border border-gray-200 rounded-md p-1 mt-[15px] bg-gray-50">
                                            <div className="flex ml-[15px] gap-[15px] mb-[10px]">
                                                {values?.genre === 'Homme' ? (
                                                    <>
                                                        <IoMdMale className="mt-[2px]" />
                                                        <p>{values?.genre}</p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <IoMaleFemaleOutline className="mt-[2px]" />
                                                        <p>{values?.genre}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="flex ml-[15px] gap-[15px]  mb-[10px]">
                                                <FaPhoneVolume className="mt-[2px]" />
                                                <p>{values?.phone}</p>
                                            </div>
                                            <div className="flex ml-[15px] gap-[15px]  mb-[10px]">
                                                <CiCalendar className="mt-[2px]" />
                                                <p>membre depuis {moment(values?.member_since).format('DD/MM/YYYY')}</p>
                                            </div>
                                        </div>
                                        <Box sx={{ paddingTop: '40px' }}>
                                            <Container>
                                                <QrViewer value={values?.code_qr} />
                                            </Container>
                                        </Box>
                                    </>
                                </div>
                            </div>

                            <div className="w-[70%] border-l-2 p-2 pl-[76px]">
                                {values && (
                                    <Controls.ModifyButton
                                        onClick={() => setUserHaveToModify(!userHaveToModify)}
                                        userHaveToModify={userHaveToModify}
                                    />
                                )}
                                <div className="flex gap-[40px] mb-[40px]">
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-disabled"
                                            label="Code paysan"
                                            // variant="outlined"
                                            name="firstname"
                                            value={values?.code}
                                            disabled
                                            size="small"
                                        />
                                    </div>
                                    <div className="w-full font-sans font-light text-sm">
                                        {/* <div className="text-gray-500 ml-2">Statut </div> */}
                                        <Controls.RadioGroup
                                            name="status"
                                            label="Statut paysan"
                                            items={stateOptions}
                                            value={peasantState}
                                            isDisabled={true}
                                        />
                                        {/* <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            className="mt-[5px] "
                                            value={values?.status?.toString()}
                                            disabled
                                        >
                                            <FormControlLabel
                                                value="Propriétaire"
                                                control={<Radio />}
                                                label="Propriétaire"
                                                checked={values?.status === 'Propriétaire'}
                                                classes={{ label: classes.noTypography }}
                                            />
                                            <FormControlLabel
                                                value="Locataire"
                                                control={<Radio />}
                                                label="Locataire"
                                                checked={values?.status === 'Locataire'}
                                                classes={{ label: classes.noTypography }}
                                            />
                                            <FormControlLabel
                                                value="Propriétaire/locataire"
                                                control={<Radio />}
                                                label="Propriétaire/locataire"
                                                checked={values?.status === 'Propriétaire/locataire'}
                                                classes={{ label: classes.noTypography }}
                                            />
                                        </RadioGroup> */}
                                    </div>
                                </div>
                                <div className="flex gap-[40px] mb-[40px]">
                                    <div className="w-full">
                                        {values?.status !== 'Propriétaire' && (
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                name="proprietary_link"
                                                disabled
                                                value={values?.proprietary_link}
                                                size="small"
                                                label="Lien avec le propriétaire "
                                            />
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="martial_status"
                                            disabled
                                            value={values?.martial_status}
                                            size="small"
                                            label="Situation matrimoniale"
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-[40px] mb-[40px]">
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="address"
                                            disabled
                                            value={values?.address}
                                            size="small"
                                            label="Adresse"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            disabled
                                            name="activity"
                                            value={values?.activity}
                                            size="small"
                                            label="Activité principale"
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-[40px] mb-[40px]">
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="reforestation_reason"
                                            disabled
                                            value={values?.reforestation_reason}
                                            size="small"
                                            label="Raison du reboisement"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="species_requested"
                                            disabled
                                            value={values?.species_requested}
                                            size="small"
                                            label="Espèces demandées"
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-[40px] mb-[40px]">
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="education_level"
                                            disabled
                                            value={values?.group?.site?.name + ' / ' + values?.group?.name}
                                            size="small"
                                            label="Groupement"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="age"
                                            disabled
                                            value={values?.age || ''}
                                            size="small"
                                            label="Age"
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-[40px] mb-[40px]">
                                    <div className="w-full">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            disabled
                                            value={values?.responsible_technician}
                                            size="small"
                                            label="Technicien responsable"
                                        />
                                    </div>
                                    <div className="w-full" style={{ fontSize: '12px' }}>
                                        Contrat :{' '}
                                        <i
                                            style={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline'
                                            }}
                                            onClick={() => setOpenContract(!openContract)}
                                        >
                                            {records.contract_file ? JSON.parse(records.contract_file).length : 0}{' '}
                                            fichier(s)
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Form>
            <Popup title={'Contrat paysan'} openPopup={openContract} setOpenPopup={setOpenContract}>
                <ContractView contract_files={records.contract_file && JSON.parse(records.contract_file)} />
            </Popup>
            <Popup title={'Modification paysan'} openPopup={userHaveToModify} setOpenPopup={setUserHaveToModify}>
                <PeasantForm
                    userHaveToModify={userHaveToModify}
                    setNeedLoad={setNeedLoad}
                    userData={values}
                    setUserHaveToModify={setUserHaveToModify}
                />
            </Popup>
        </div>
    )
}

const ContractView = (props) => {
    const { contract_files } = props
    return (
        <div>
            {contract_files && contract_files.length > 0 ? (
                <div style={{ display: 'flex', gap: '10px', width: '550px', height: '300px', overflowY: 'auto' }}>
                    {contract_files.map((file, index) => (
                        <CustomFileView fileName={`fichier ${index + 1}`} file={file} />
                    ))}
                </div>
            ) : (
                <div>Aucun fichier</div>
            )}
        </div>
    )
}
