import { agroGroupModel } from './dataModels/AgroGroup'
import { agroPeasantModel } from './dataModels/AgroPeasant'
import { agroPlotModel } from './dataModels/AgroPlot'
import { communeModel } from './dataModels/Commune'
import { districtModel } from './dataModels/District'
import { etalonnageModel } from './dataModels/Etalonnage'
import { fokontanyModel } from './dataModels/Fokontany'
import { landModel } from './dataModels/Land'
import { mangroGroupModel } from './dataModels/MangroGroup'
import { planterModel } from './dataModels/Planter'
import { platdrainModel } from './dataModels/PlateBande'
import { regionModel } from './dataModels/Region'
import { villageModel } from './dataModels/Village'

export const dataModels = [
    planterModel,
    mangroGroupModel,
    etalonnageModel,
    regionModel,
    districtModel,
    communeModel,
    fokontanyModel,
    villageModel,
    platdrainModel,
    landModel,
    agroGroupModel,
    agroPeasantModel,
    agroPlotModel
]
