import React, { useEffect } from 'react'
import TableDetail from '../../../../../../components/DataTable/TableDetail'
import { useNavigate } from 'react-router-dom'

const headCells = [
    { id: 'visit_date', label: 'Date de suivi', type: 'date', disableSorting: true },
    { id: 'plot_code', label: 'Code parcelle' },
    { id: 'peasant_name', label: 'Nom paysan', disableSorting: true },
    { id: 'fkt', label: 'Fokontany', disableSorting: true }
]

function FollowUpVisit(props) {
    const { data, records } = props
    const title = 'Visite de suivi'
    const navigate = useNavigate()
    const handleOnDataDelete = () => {}

    const onClickButton = () => {
        navigate('/app/agroforestry/plantations/visits/new', { state: records })
    }

    return (
        <>
            <TableDetail
                showButton={true}   
                headCells={headCells}
                hideFilterButton={true}
                title={title}
                onClickButton={onClickButton}
                onDataDelete={handleOnDataDelete}
                data={data}
            />
        </>
    )
}

export default FollowUpVisit
