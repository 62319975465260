// import { Http } from '../../../applications/Http.js'

import Service from '../../../applications/Service.js'

class OrderTrackingMangroService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/order_tracking/type/Mangroves')
        return this
    }
}

export default OrderTrackingMangroService
