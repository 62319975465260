import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../../../applications/hooks/UseContext'
import { Form, useForm } from '../../../../applications/hooks/UseForm'
import { Container, Grid, Tab, Tabs, Typography } from '@mui/material'
import Loader from '../../../../components/Entry/Loading'
import NurseryService from '../../services/Nursery'
import Controls from '../../../../components/Entry/Controls'
import { formatDate } from '../../../../applications/UtilFunctions'
import DataSetsService from '../../../datasets/services/DataSets'
import { FaTrashAlt } from 'react-icons/fa'
import InventoryService from '../../services/inventory/inventory'
import Notification from '../../../../layouts/Notification'
import { useAuth } from '../../../../applications/hooks/UseAuth'

const initialValues = {
    designation: ''
}
function TakeInventoryForm({ setOpenPopup, setNeedLoad }) {
    const globalContext = useContext(GlobalContext)
    const nurseryService = NurseryService.load()
    const dataset = DataSetsService.load()
    const inventoryService = InventoryService.load()
    const { user } = useAuth()
    const [loading, setLoading] = useState(false)
    const [nursery, setNursery] = useState(null)
    const [tabValue, setTabValue] = useState(null)
    const [designationOptions, setDesignationOptions] = useState(null)
    const [stateOptions, setStateOptions] = useState([])
    const [inventoryItem, setInventoryItem] = useState(null)
    const [dataToSend, setDataToSend] = useState([])
    const [haveSpin, setHaveSpin] = useState(false)
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [restToTake, setRestToTake] = useState(0)
    const [disableAddButton, setDisableAddButton] = useState(false)
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialValues, false)

    useEffect(() => {
        setLoading(true)
        dataset
            .getDataCode('statut_inventaire')
            .then((response) => {
                console.log('response', response.data)
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setStateOptions([...dataArray])
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        // console.log(globalContext)
        if (globalContext?.value?.activeCenterCom) {
            setLoading(true)
            nurseryService
                .getById(globalContext?.value?.activeCenterCom)
                .then((response) => {
                    if (response) {
                        console.log(response)
                        setNursery(response)
                        const filteredResponse = response.Inventory.filter((inventory) => {
                            return inventory.category !== 'Intrant pépinière'
                        })
                        const inventoryItems = filteredResponse[0].InventoryItem.map((item) => {
                            return {
                                id: item.id,
                                label: `${item.designation} - ${item.state}`
                            }
                        })
                        console.log(inventoryItems)
                        setDesignationOptions(inventoryItems)
                        setTabValue(0)

                        setLoading(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setLoading(false)
                })
        }
    }, [globalContext, nurseryService])

    useEffect(() => {
        if (values?.designation) {
            const filteredNursery = nursery?.Inventory?.filter((inventory) => {
                return inventory.category !== 'Intrant pépinière'
            })
            const inventoryItem = filteredNursery[tabValue].InventoryItem.find(
                (item) => item.id === values?.designation
            )
            console.log(inventoryItem)
            setInventoryItem(inventoryItem)
            setRestToTake(inventoryItem?.quantity)
        }
    }, [values?.designation, nursery, tabValue])

    const handleChangeTab = (event, newValue) => {
        console.log(newValue)
        setTabValue(newValue)
        resetForm()
        setInventoryItem(null)
        setDataToSend([])
        const filteredNursery = nursery?.Inventory?.filter((inventory) => {
            return inventory.category !== 'Intrant pépinière'
        })
        const inventoryItems = filteredNursery[newValue].InventoryItem.map((item) => {
            return {
                id: item.id,
                label: `${item.designation} - ${item.state}`
            }
        })
        console.log(inventoryItems)
        setDesignationOptions(inventoryItems)
    }

    const handleAddInventory = () => {
        console.log(values)
        if (restToTake < values?.quantity) {
            new Notification('La valeure restante ne convient pas à votre entrée.', 0)
        } else if (values?.state && values?.state !== '' && values?.quantity && values?.quantity !== '') {
            setDataToSend([
                ...dataToSend,
                { id: dataToSend.length + 1, ...values, userStory: [user?.firstname, user?.lastname].join(' ') }
            ])
            setValues({ ...values, state: '', quantity: '' })
            setRestToTake(restToTake - values?.quantity)
        }
    }

    const handlePost = () => {
        if (restToTake === 0) {
            setHaveSpin(true)
            setUserHaveToPost(true)
            console.log(dataToSend)
            inventoryService
                .takeInventory(dataToSend)
                .then((response) => {
                    console.log(response)
                    if (response) {
                        new Notification(InventoryService.messages.update_success, 1)
                        setHaveSpin(false)
                        setUserHaveToPost(false)
                        setOpenPopup(false)
                        setNeedLoad(true)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    new Notification(InventoryService.messages.update_error, 0)
                    setHaveSpin(false)
                    setUserHaveToPost(false)
                    setNeedLoad(true)
                })
        } else {
            new Notification("Il faut qu'il n'y ai plus aucun restant.", 0)
        }
    }

    return (
        <div
            style={{
                width: '700px',
                height: '60vh'
            }}
        >
            <Form>
                <Container>
                    <div>
                        <Typography variant="p" component="div" sx={{ flexGrow: 1, fontSize: 11 }}>
                            <b>Pépinière : </b> {nursery?.name} <br />
                            <b>Code : </b> {nursery?.code}
                        </Typography>
                    </div>
                    <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable">
                        {nursery &&
                            nursery?.Inventory?.map((inventory, index) => {
                                if (inventory.category !== 'Intrant pépinière')
                                    return <Tab key={index} label={inventory.category} />
                                else return null
                            })}
                    </Tabs>
                    {nursery && designationOptions && (
                        <>
                            <Grid container sx={{ marginTop: '10px' }} gap={2}>
                                <Grid item xs={7}>
                                    <Controls.AutoCompleteSelect
                                        label="Désignation"
                                        name="designation"
                                        value={values?.designation || ''}
                                        options={designationOptions || []}
                                        onChange={handleInputChange}
                                        labelKey="label"
                                        valueKey="id"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={4.5}
                                    sx={{ borderLeft: '1px solid #ccc', alignItems: 'center', alignContent: 'center' }}
                                >
                                    <Container>
                                        <Typography variant="p" sx={{ fontSize: '12px' }} component="div">
                                            <b>Informations sur les matériels</b>
                                        </Typography>
                                        <Typography variant="p" sx={{ fontSize: '10px' }} component="div">
                                            <b>Quantité : </b> {inventoryItem?.quantity || ''} <br />
                                            <b>Date d'achat : </b>{' '}
                                            {inventoryItem?.date_purchased
                                                ? formatDate(inventoryItem?.date_purchased)
                                                : ''}
                                        </Typography>
                                    </Container>
                                </Grid>
                            </Grid>
                            <Typography variant="h1" component="div" sx={{ flexGrow: 1, fontSize: 15 }}>
                                <b>Inventaire : </b>
                                {values?.designation || values?.designation !== '' ? (
                                    <>( restant : {restToTake} )</>
                                ) : (
                                    ''
                                )}
                            </Typography>
                            <Grid container sx={{ marginTop: '10px' }} gap={2}>
                                <Grid item xs={7}>
                                    <Controls.AutoCompleteSelect
                                        label="Etat"
                                        name="state"
                                        value={values.state || ''}
                                        onChange={handleInputChange}
                                        options={stateOptions}
                                        labelKey="label"
                                        valueKey="label"
                                        error={errors?.state}
                                    />
                                    <Controls.Input
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="quantity"
                                        value={values?.quantity || ''}
                                        size="small"
                                        label="Nombre"
                                        onChange={handleInputChange}
                                        type="number"
                                        error={errors?.quantity}
                                    />
                                    <Controls.Button
                                        text="Ajouter"
                                        onClick={handleAddInventory}
                                        disabled={values?.designation || values?.designation !== '' ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={4.5}>
                                    <div
                                        style={{
                                            border: '1px solid #ccc',
                                            padding: '10px',
                                            borderRadius: '5px',
                                            height: '175px',
                                            overflow: 'hidden',
                                            overflowY: 'scroll',
                                            scrollbarWidth: 'thin',
                                            scrollbarColor: '#d0e0d7 #E7F2EE00',
                                            '&::-webkit-scrollbar': {
                                                width: '8px',
                                                height: '8px',
                                                background: '#d0e0d7'
                                            }
                                        }}
                                    >
                                        {dataToSend?.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <Typography
                                                        variant="p"
                                                        sx={{
                                                            fontSize: '11px',
                                                            display: 'flex',
                                                            alignContent: 'center',
                                                            alignItems: 'center',
                                                            margin: '8px 0px 5px 0px',
                                                            gap: '5px'
                                                        }}
                                                        component="div"
                                                    >
                                                        <b>Etat : </b> {item?.state}, {item?.quantity} pièce(s)
                                                        <FaTrashAlt
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: 'red',
                                                                alignSelf: 'center'
                                                            }}
                                                            onClick={() =>
                                                                setDataToSend(
                                                                    dataToSend.filter((v) => v.id !== item.id)
                                                                )
                                                            }
                                                        />
                                                    </Typography>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                        <Controls.Button
                                            text="Soumettre"
                                            onClick={handlePost}
                                            haveSpin={haveSpin}
                                            size="large"
                                            disabled={dataToSend.length > 0 ? (userHaveToPost ? true : false) : true}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Container>
            </Form>
            <Loader isLoading={loading} />
        </div>
    )
}

export default TakeInventoryForm
