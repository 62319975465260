// import { Http } from '../../../applications/Http.js'
import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class NurserySemisService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.',
        get_error: 'Erreur lors du chargement des données.'
    }
    static load() {
        this.setUrl('/api/nursery/agro/semis')
        return this
    }

    static async getByCode(code) {
        try {
            const response = await Http.get([this.url, 'byCode', code].join('/'), {})
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async create(data) {
        try {
            const response = await Http.put('/api/nursery/agro/semis', data)
            return response
        } catch (error) {
            throw new Error("Erreur lors de l'ajout de la pépinière.")
        }
    }

    static async update(id, data) {
        try {
            const response = await Http.patch([this.url, id].join('/'), data)
            return response
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour de la pépinière.')
        }
    }

    static async getDataAdd() {
        try {
            const url = [this.url, `dataAdd`].join('/')
            console.log(url)
            const response = await Http.get(url, {})
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }

    static async getBySite(siteId) {
        try {
            const response = await Http.get(`${this.url}/bySite/${siteId}`)
            return response?.data
        } catch (error) {
            throw new Error(this.messages.get_error)
        }
    }
}

export default NurserySemisService
