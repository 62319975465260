import React, { useState } from 'react'
import PageHeader from '../../components/Typography/PageHeader'
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined'
import RolesList from './components/roles/RoleList'
import { useAuth } from '../../applications/hooks/UseAuth'
import RoleForm from './components/roles/RoleForm'
import RoleService from '../../pages/users/services/Role'
import ToolbarButton from '../../components/DataTable/ToolbarButton'

const CURRENT_PAGE = 'administration_roles'

function Roles() {
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const useDrawer = false
    const service = RoleService.load()

    return (
        <div className={'card w-full bg-base-100 '} style={{ paddingBottom: 20 }}>
            <PageHeader
                title={'Rôles et Permissions'}
                subTitle={'Gestion des rôles et permissions'}
                icon={<LockPersonOutlinedIcon color="primary" fontSize="large" />}
            >
                {' '}
                <ToolbarButton
                    moduleTitle="d'un rôle"
                    service={service}
                    drawer={useDrawer}
                    haveAccessTo={haveAccessTo}
                    currentPage={CURRENT_PAGE}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={RoleForm}
                />
            </PageHeader>
            <RolesList
                service={service}
                drawer={useDrawer}
                Form={RoleForm}
                haveAccessTo={haveAccessTo}
                currentPage={CURRENT_PAGE}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
            />
        </div>
    )
}

export default Roles
