import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Badge, Paper } from '@mui/material'
import PageHeader from '../Typography/PageHeader'
import Loader from '../Entry/Loading'
import { useNavigate } from 'react-router-dom'
import Controls from '../Entry/Controls'
import { ReactComponent as ReturnButton } from '../../assets/icons/lets-icons_return.svg'

const useStyles = makeStyles((theme) => ({
    pageContent: {
        backgroundColor: '#FFFFFF',
        marginBottom: '4px',
        marginTop: '35px'
    }
}))

function ContentView(props) {
    const {
        user,
        action,
        needCode = true,
        title,
        needLoad,
        haveAccessTo,
        setNeedLoad,
        service,
        dataId,
        Form,
        addReturnButton = true,
        needLoadCode,
        Table,
        supplementData,
        setSupplementData,
        dataCategory,
        searchIsNotHidden,
        otherFunction,
        other
    } = props

    user && console.log(user)

    const navigate = useNavigate()

    const classes = useStyles()
    const [records, setRecords] = useState({})
    const [loading, setLoading] = useState(false)
    const [dataSupplement, setDataSupplement] = useState(null)

    useEffect(() => {
        const getData = async () => {
            if (service && needCode) {
                setLoading(true)
                const data = await service.getByCode(dataId)

                if (data) {
                    setRecords(data.data)
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            }
        }
        if (needLoad && service) {
            getData()
        }
    }, [needLoad, dataId, service, needCode])

    useEffect(() => {
        if (supplementData) {
            setDataSupplement(supplementData)
        }
    }, [supplementData])

    return (
        <div className={' w-full bg-base-100 font-sans font-light text-sm'} style={{ paddingBottom: 20 }}>
            <Loader isLoading={loading} />
            <div className="flex gap-6">
                {title && (
                    <>
                        {addReturnButton && (
                            <Controls.IconButton
                                sx={{
                                    margin: 0,
                                    boxShadow:
                                        '0px 0px 0px 0px #d2d2d2,0px 0px 0px 0px #d2d2d2,0px 0px 1px 0px #d2d2d2',
                                    backgroundColor: '#FFF',
                                    padding: 0
                                }}
                                onClick={() => navigate(-1)}
                                style={{ padding: '10px', borderRadius: '10px' }}
                            >
                                <ReturnButton />
                            </Controls.IconButton>
                        )}

                        <PageHeader title={title + `${needLoadCode && records?.code ? ` - ${records?.code}` : ''}`} />
                    </>
                )}
            </div>
            <Paper className={classes.pageContent} elevation={8}>
                {Form && (
                    <Form
                        user={user}
                        dataId={dataId}
                        records={records}
                        setNeedLoad={setNeedLoad}
                        needLoad={needLoad}
                        Form={Form}
                        service={service}
                        action={action}
                        supplementData={dataSupplement}
                        setSupplementData={setDataSupplement}
                        otherFunction={otherFunction}
                        other={{ ...other }}
                    />
                )}
                {Table && (
                    <Table
                        records={records}
                        service={service}
                        haveAccessTo={haveAccessTo}
                        setNeedLoad={setNeedLoad}
                        needLoad={needLoad}
                        hideFilterButton={true}
                    />
                )}
            </Paper>
        </div>
    )
}

export default ContentView
