import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form, useForm } from '../../../applications/hooks/UseForm'
import Controls from '../../../components/Entry/Controls'
import DataSetsService from '../../datasets/services/DataSets'
import CalibrationService from '../services/CalibrationService'
import SpeciesService from '../../species/services/Species'
import { FaTruckMedical } from 'react-icons/fa6'
const initialFValues = {
    unity: '',
    value: ''
}

const CalibrationForm = (props) => {
    const { addOrEdit, recordForEdit, setNeedLoad } = props
    const calibration = CalibrationService.load()
    const dataset = DataSetsService.load()
    const specie = SpeciesService.load()
    const [data, setData] = useState([])
    const [unity, setUnity] = useState([])
    const [species, setSpecies] = useState([])

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    useEffect(() => {
        setNeedLoad(true)
        calibration
            .getAll()
            .then((response) => {
                console.log('response', response.data)
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setData(dataArray)
                    setNeedLoad(false)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
                setNeedLoad(false)
            })
    }, [calibration, setNeedLoad])
    useEffect(() => {
        setNeedLoad(true)
        dataset
            .getDataCode('unity_code')
            .then((response) => {
                console.log('response', response.data)
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setUnity(dataArray)
                    setNeedLoad(false)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
                setNeedLoad(false)
            })
    }, [dataset, setNeedLoad])

    useEffect(() => {
        setNeedLoad(true)
        specie
            .getAllSpecies()
            .then((response) => {
                if (response) {
                    console.log(response)
                    setSpecies(response)
                    setNeedLoad(false)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
                setNeedLoad(false)
            })
    }, [specie, setNeedLoad])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('unity' in fieldValues) temp.unity = fieldValues.unity ? '' : 'Ce champ est requis.'
        if ('value' in fieldValues) temp.value = fieldValues.value ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })

        return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialFValues,
        true,
        validate
    )

    const handleSubmit = (e) => {
        e.preventDefault()
        const newValues = { ...values, value: parseInt(values.value) }
        if (validate()) {
            addOrEdit(newValues, resetForm)
        }
    }

    const handleChange = (e) => {
        handleInputChange(e)
        setValues((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value
        }))
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <Controls.AutoCompleteSelect
                        label="Espèce"
                        name="specie_id"
                        value={values?.specie_id || ''}
                        onChange={handleChange}
                        options={species}
                        labelKey="name"
                        valueKey="id"
                        error={errors?.specie_id}
                        isRequired={true}
                    />
                    {unity && (
                        <Controls.AutoCompleteSelect
                            label="Unité"
                            name="unity"
                            value={values?.unity || ''}
                            onChange={handleChange}
                            options={unity}
                            labelKey="label"
                            valueKey="label"
                            error={errors?.unity}
                            isRequired={true}
                        />
                    )}
                    <Controls.Input
                        name="value"
                        type="number"
                        label="Valeur"
                        value={values.value}
                        onChange={handleInputChange}
                        error={errors?.value}
                        isRequired={true}
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center', marginTop: '8px' }}>
                    <Controls.Button type="submit" text="Enregistrer" />
                </Grid>
            </Grid>
        </Form>
    )
}

export default CalibrationForm
