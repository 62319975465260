import React, { useState, useEffect } from 'react'
import TableDetail from '../../../components/DataTable/TableDetail'

const headCellsTraining = [
    { id: 'code', label: 'Groupe' },
    { id: 'nbr_planter', label: 'Nombre Planteur dans le groupe' },
    { id: 'specie_name', label: 'Espèces plantés' },
    { id: 'specie_number', label: 'Nombre par espèce' },
    { id: 'seed', label: 'Graine' },
    { id: 'propagule', label: 'Propagule' },
    { id: 'young_plant', label: 'Jeune Plants' }
    // {
    //     id: 'actions',
    //     type: 'actions',
    //     label: 'Actions',
    //     disableSorting: true,
    //     showEdit: false,
    //     showRemove: false,
    //     showView: '/app/mangrove/nursery/tracking/edit',
    //     forcePassId: true
    // }
]

export const PlantingGroupList = (props) => {
    const { records } = props

    return (
        <div>
            <TableDetail
                headCells={headCellsTraining}
                // service={platDrainTrackingService}
                hideFilterButton={true}
                data={records?.planting_group}
                title={' Groupe de planteurs'}
            />
        </div>
    )
}
