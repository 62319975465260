import React, { useContext, useEffect, useState } from 'react'
import Controls from './Controls'
import { MenuItem, TextField } from '@mui/material'
import { GlobalContext } from '../../applications/hooks/UseContext'

function YearSelector(props) {
    const { setNeedLoad } = props
    const globalContext = useContext(GlobalContext)
    const currentYear = new Date().getFullYear()
    const years = Array.from(new Array(50), (val, index) => currentYear - index)
    console.log(years)
    const [value, setValue] = useState('')

    useEffect(() => {
        if (globalContext.value?.year) {
            setValue(globalContext.value?.year)
        }
    }, [globalContext.value?.year])

    const onChangeYear = (event) => {
        setNeedLoad(true)
        setValue(event.target.value)
        globalContext.setValue((prev) => {
            return {
                ...prev,
                year: event.target.value
            }
        })
    }

    return (
        <div style={{ width: '150px' }}>
            <TextField
                select
                label="Année"
                value={value}
                onChange={onChangeYear}
                variant="outlined"
                fullWidth
                sx={{
                    '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                        padding: '10px 14px'
                    }
                }}
            >
                {years.map((year) => (
                    <MenuItem key={year} value={year}>
                        {year}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    )
}

export default YearSelector
