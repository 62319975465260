import NurseryService from "../services/Nursery"
import RecorgedPotService from "../services/recorged_pot/recorged_pot"

class InventoryActions {
    constructor() {
        this.inventory = null
    }

    setInventory(inventory) {
        this.inventory = inventory
    }

    getInventory() {
        return this.inventory
    }

    async getNurseryWithSiteId(siteId) {
        try {
            const response = await NurseryService.getAllNurseryBySite(siteId)
            return response
        } catch (error) {
            console.log(error)
        }
    }

    async verifyPotInNursery(nurseryId, potWidth) {
        try {
            const response = await RecorgedPotService.verifyPotWidth(nurseryId, potWidth)
            return response
        } catch (error) {
            console.log(error)
        }
    }

    async getInventoryByNurseryId(nurseryId) {
        try {
            const response = await RecorgedPotService.getInventoryByNurseryId(nurseryId)
            return response
        } catch (error) {
            console.log(error)
        }
    }
}

export default InventoryActions
