import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class FormService extends Service {
    static messages = {
        update_success: 'Modification formulaire avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création formulaire avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/form')
        return this
    }

    static async getFormByTitle(title) {
        return await Http.get([this.url, 'byTitle', title].join('/'), {})
    }

    static async getFormByCode(code) {
        return await Http.get([this.url, 'byCode', code].join('/'), {})
    }

    static async setCustomForm(id, params) {
        return await Http.post([this.url, 'custom', id].join('/'), params)
    }

    static async getCustomForm(id) {
        return await Http.get([this.url, 'custom', id].join('/'), {})
    }

    static async getAgroLocation() {
        return await Http.get([this.url, 'agroLocation'].join('/'), {})
    }

    static async getCollection(category) {
        return await Http.get([this.url, 'collection', category].join('/'), {})
    }

    static async sendData(data) {
        return await Http.post('/api/devices/send', data)
    }
}

export default FormService
