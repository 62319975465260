import React, { useEffect, useState } from 'react'
import ContentView from '../../../../components/PageView/Content'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import PlotService from '../../services/Plots'
import PlotViewForm from './plotViewForm'
import { CssBaseline } from '@mui/material'
import PlotContext from '../../utils/hooks/PlotContext'
import PlantationPlotTable from './table/PlantationPlotTable'

function PlotView(props) {
    const { addReturnButton, recordForEdit } = props
    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const plotService = PlotService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(true)

    return (
        <PlotContext dataId={recordForEdit ? recordForEdit.code : dataId} needLoad={needLoad}>
            <CssBaseline />

            <ContentView
                dataId={recordForEdit ? recordForEdit.code : dataId}
                title={recordForEdit ? '' : `Informations parcelle`}
                service={plotService}
                haveAccessTo={haveAccessTo}
                setNeedLoad={setNeedLoad}
                addReturnButton={addReturnButton}
                needLoad={needLoad}
                Form={PlotViewForm}
                needLoadCode={needLoadCode}
            />
            {/* <ContentView
                dataId={recordForEdit ? recordForEdit.code : dataId}
                haveAccessTo={haveAccessTo}
                setNeedLoad={setNeedLoad}
                Form={PlantationPlotTable}
                needLoadCode={needLoadCode}
            /> */}
        </PlotContext>
    )
}

export default PlotView
