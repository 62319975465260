import React from 'react'
import Popup from '../../layouts/Popup'
import Controls from './Controls'

function FileViewer(props) {
    const { openPopup, setOpenPopup, filePath } = props
  return (
    <>
        <Popup title={'Fichier'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
            <Controls.CustomImageAvatar src={filePath} />
        </Popup>
    </>
  )
}

export default FileViewer
