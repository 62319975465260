// All components mapping with path for internal routes
import Dashboard from '../pages/dashboard'
// import Page404 from '../pages/404'
import { DataSets } from '../pages/datasets/DataSets'
import { Form } from '../pages/form/Form'
import Species from '../pages/species/Species'

import Roles from '../pages/users/Roles'
import Users from '../pages/users/Users'
//////////////////////////////////AGROFORESTRY///////////////////
import Inventory from '../pages/nursery/Inventory'
import Supply from '../pages/nursery/Supply'
import InventoryView from '../pages/nursery/components/inventory/ContentForm'
import PlantMaterialView from '../pages/nursery/components/plant_material/PlantMaterialView'
import RecorgedPotView from '../pages/nursery/components/recorged_pot/RecorgedPotView'
import YoungPlant from '../pages/nursery/components/youngPlant/YoungPlant'
import NurseryAdd from '../pages/nursery/components/youngPlant/components/contentView/Nursery.jsx'
import NurseryManagement from '../pages/nursery/components/youngPlant/components/contentView/NurseryManagement.jsx'
import { Peasants } from '../pages/peasants/Peasants'
import PeasantView from '../pages/peasants/components/PeasantView'
import Plantations from '../pages/plantations/Plantations'
import PlantationView from '../pages/plantations/components/pageView/PlantationDetailsPage'
import Plot from '../pages/plot'
import PlotView from '../pages/plot/components/plot/PlotView'
import Preregistry from '../pages/preregistry/Preregistry'
import { Provinces } from '../pages/provinces/Provinces'

/////////////////////////////MANGROVE////////////////////////////////
import AgroCustomerOrder from '../pages/agro_customer_order/Agro_customer_order.jsx'
import Calibration from '../pages/calibration/Calibration.jsx'
import Configuration from '../pages/configuration/Configuration.jsx'
import CustomerOrder from '../pages/customer_order/CustomerOrder.jsx'
import CustomerOrderView from '../pages/customer_order/components/CustomerOrderView.jsx'
import DataImport from '../pages/data_import/components/DataImport.jsx'
import Fauna from '../pages/fauna/Fauna.jsx'
import MangroCustomerOrder from '../pages/mangro_customer_order/Mangro_customer_order.jsx'
import Land from '../pages/mangrove_land/Land.jsx'
import LandDetailsPage from '../pages/mangrove_land/components/contentView/LandDetailsPage.jsx'
import Planter from '../pages/mangrove_planter/Planter.jsx'
import PlanterAdd from '../pages/mangrove_planter/components/addView/PlanterAdd.jsx'
import InfoPlanterDetailsPage from '../pages/mangrove_planter/components/pageView/InfoPlanterDetailsPage.jsx'
import PlanterPage from '../pages/mangrove_planter/components/pageView/PlanterPage.jsx'
import Planting from '../pages/mangrove_planting/Planting.jsx'
import PlantingTrackingView from '../pages/mangrove_planting/components/PlantingTrackingView.jsx'
import PlantingView from '../pages/mangrove_planting/components/PlantingView.jsx'
import NurseryMangrove from '../pages/nursery_mangrove/NurseryMangrove'
import OutingMangrove from '../pages/nursery_mangrove/outing/OutingMangrove.jsx'
import OutingView from '../pages/nursery_mangrove/outing/components/OutingContent.jsx'
import PlatDrainView from '../pages/nursery_mangrove/plat_drain/components/PlatDrainContentForm'
import PlatDrainTracking from '../pages/nursery_mangrove/plat_drain_tracking/PlatDrainTracking.jsx'
import PlatDrainTrackingView from '../pages/nursery_mangrove/plat_drain_tracking/components/PlatDrainTrackingContent.jsx'
import OrderTracking from '../pages/order_tracking/OrderTracking.jsx'
import OrderTrackingView from '../pages/order_tracking/components/OrderTrackingView.jsx'
import { PeasantsPLanting } from '../pages/peasants/Peasants_planting.jsx'
import PlotPLanting from '../pages/plot/Plots_planting.jsx'
import PlantationMonitoringContent from '../pages/plantations/components/pageView/content/PlantationMonitoringContent.jsx'
import PlantationVisitContent from '../pages/plantations/components/pageView/content/PlantationVisitContent.jsx'
import Historics from '../pages/historics/Historics.jsx'

const routes = [
    {
        path: '/dashboard', // the url
        component: Dashboard // view rendered
    },
    {
        path: '/users', // the url
        component: Users // view rendered
    },

    {
        path: '/users/roles', // the url
        component: Roles // view rendered
    },

    {
        path: '/form', // the url
        component: Form // view rendered
    },
    {
        path: '/datasets',
        component: DataSets
    },
    {
        path: '/species/flora',
        component: Species
    },
    {
        path: '/species/fauna',
        component: Fauna
    },
    {
        path: '/sites',
        component: Provinces
    },
    {
        path: '/importation',
        component: DataImport
    },
    ////////////////////////////AGROFORESTRY////////////////////////////
    // PRE-REGISTRY
    {
        path: '/agroforestry/preregistry',
        component: Preregistry
    },
    // PAESANT
    {
        path: '/agroforestry/peasants',
        component: Peasants
    },
    {
        path: '/agroforestry/peasants/view/:code',
        component: PeasantView
    },
    // PLOT
    {
        path: '/agroforestry/plots',
        component: Plot
    },
    {
        path: '/agroforestry/plots/view/:code',
        component: PlotView
    },
    //PLANTATION
    {
        path: '/agroforestry/plantations',
        component: Plantations
    },
    {
        path: '/agroforestry/plantations/monitoring/new',
        component: PlantationMonitoringContent
    },
    {
        path: '/agroforestry/plantations/visits/new',
        component: PlantationVisitContent
    },
    {
        path: '/agroforestry/plantations/peasants',
        component: PeasantsPLanting
    },
    {
        path: '/agroforestry/plantations/plots',
        component: PlotPLanting
    },
    {
        path: '/agroforestry/plantations/view/:code',
        component: PlantationView
    },
    //PEPINIERE
    {
        path: '/agroforestry/nursery/plant',
        component: YoungPlant
    },
    {
        path: 'agroforestry/nursery/plant/new',
        component: NurseryAdd
    },
    {
        path: 'agroforestry/nursery/view/:code',
        component: NurseryManagement
    },
    {
        path: '/agroforestry/nursery/inventory',
        component: Inventory
    },
    {
        path: '/agroforestry/nursery/inventory/view', 
        component: InventoryView 
    },
    {
        path: '/agroforestry/nursery/inventory/edit/:id',
        component: InventoryView
    },
    {
        path: '/agroforestry/nursery/inventory/new',
        component: InventoryView
    },
    {
        path: '/agroforestry/nursery/supply',
        component: Supply
    },
    {
        path: '/agroforestry/nursery/supply/plant_material/edit/:id',
        component: PlantMaterialView
    },
    {
        path: '/agroforestry/nursery/supply/plant_material/:nurseryId',
        component: PlantMaterialView
    },
    {
        path: '/agroforestry/nursery/supply/recorged_pot/edit/:id',
        component: RecorgedPotView
    },
    {
        path: '/agroforestry/nursery/supply/recorged_pot/new',
        component: RecorgedPotView
    },
    {
        path: '/agroforestry/order',
        component: AgroCustomerOrder
    },
    {
        path: '/agroforestry/order/view/:id',
        component: OrderTrackingView
    },
    ///////////////////////////////////////////////MANGROVE////////////////////////////////////
    // PEPINIERE MANGROVE
    {
        path: '/mangrove/nursery/plat',
        component: NurseryMangrove
    },
    {
        path: '/mangrove/nursery/plat/new',
        component: PlatDrainView
    },
    {
        path: '/mangrove/nursery/plat/edit/:id',
        component: PlatDrainView
    },
    // SUIVI DES JEUNES PLANTES
    {
        path: '/mangrove/nursery/tracking',
        component: PlatDrainTracking
    },
    {
        path: '/mangrove/nursery/tracking/new',
        component: PlatDrainTrackingView
    },
    {
        path: '/mangrove/nursery/tracking/edit/:id',
        component: PlatDrainTrackingView
    },
    //SORTIE EN PEPINERE
    {
        path: '/mangrove/nursery/outing',
        component: OutingMangrove
    },
    {
        path: '/mangrove/nursery/outing/new',
        component: OutingView
    },
    {
        path: '/mangrove/nursery/outing/edit/:id',
        component: OutingView
    },

    // TERRAIN MANGROVE
    {
        path: '/mangrove/land',
        component: Land
    },
    {
        path: '/mangrove/lands/view/:code',
        component: LandDetailsPage
    },
    {
        path: '/mangrove/lands/new',
        component: LandDetailsPage
    },

    // INFORMATION PLANTEUR
    {
        path: 'mangrove/planter',
        component: Planter
    },
    {
        path: 'mangrove/planter/new',
        component: PlanterAdd
    },
    {
        path: 'mangrove/planter/view/:id',
        component: InfoPlanterDetailsPage
    },
    {
        path: 'mangrove/planter/information/:code',
        component: PlanterPage
    },

    // PLANTATION
    {
        path: 'mangrove/planting',
        component: Planting
    },
    {
        path: '/mangrove/planting/new',
        component: PlantingView
    },
    {
        path: '/mangrove/planting/edit/:id',
        component: PlantingView
    },
    {
        path: '/mangrove/planting/tracking/new',
        component: PlantingTrackingView
    },
    {
        path: '/mangrove/order',
        component: MangroCustomerOrder
    },
    {
        path: '/mangrove/order/view/:id',
        component: OrderTrackingView
    },
    // COMMANDES CLIENTS
    {
        path: '/commercial/customer',
        component: CustomerOrder
    },
    {
        path: '/commercial/customer/new',
        component: CustomerOrderView
    },
    {
        path: '/commercial/customer/edit/:id',
        component: CustomerOrderView
    },
    // SUIVI COMMANDES
    {
        path: '/commercial/order',
        component: OrderTracking
    },
    {
        path: '/commercial/order/new',
        component: OrderTrackingView
    },
    {
        path: '/commercial/order/view/:id',
        component: OrderTrackingView
    },
    {
        path: '/configuration',
        component: Configuration
    },
    {
        path: '/calibration',
        component: Calibration
    },
    {
        path: '/historics',
        component: Historics
    }
]

export default routes
