import RecorgedPotService from "../services/recorged_pot/recorged_pot"

class PotAction {
    constructor() {
        this.state = "standBy"
    }

    async verifyNurseryPotWidth(nurseryId,width){
        const res = await RecorgedPotService.verifyPotWidth(nurseryId, width)
        return res
    }
}

export default PotAction