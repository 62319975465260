import React, { useState, useEffect } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts'
import CustomSelect from '../../../components/Entry/CustomSelect'
import PlotService from '../../plot/services/Plots'
import ProvinceService from '../../provinces/services/Provinces'

const RADIAN = Math.PI / 180
const COLORS = ['#54362B', '#14B67D', '#FF8042']

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
}

export default function PlotDashboard() {
    const plot_api = PlotService.load()

    const [plots, setPlots] = useState([])
    const [site, setSite] = useState([])
    const [values, setValues] = useState({
        site: '',
        year: new Date().getFullYear()
    })
    const [data, setData] = useState([
        { name: 'Surface disponible', value: 0 },
        { name: 'Surface totale', value: 0 }
    ])

    useEffect(() => {
        ProvinceService.getAgroSites().then((response) => {
            if (response?.data) {
                setSite(response?.data)
            }
        })
    }, [])

    useEffect(() => {
        plot_api
            .getAllPlots(values.site.toString())
            .then((users) => {
                if (users) {
                    const filteredPlots = users.filter(
                        (user) => new Date(user.created_date).getFullYear() == values.year
                    )
                    if (filteredPlots.length > 0) {
                        const totalSurface = filteredPlots.reduce((acc, plot) => acc + plot.surface, 0)
                        const surfacePlanted = filteredPlots.reduce((acc, plot) => acc + plot.surface_planted, 0)
                        const availableSurface = totalSurface - surfacePlanted
                        setData([
                            { name: 'Surface disponible', value: availableSurface || 0 },
                            { name: 'Surface totale', value: totalSurface || 0 }
                        ])
                    } else {
                        setData([
                            { name: 'Surface disponible', value: 0 },
                            { name: 'Surface totale', value: 1 }
                        ])
                    }
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [values.year, values.site])

    const getYears = () => {
        const currentYear = new Date().getFullYear()
        const options = []
        for (let i = currentYear - 4; i <= currentYear + 4; i++) {
            options.push(i)
        }
        return options
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }))
    }

    console.log('data', data)

    return (
        <div className="w-full h-[24rem] bg-white p-4 rounded-xl border border-gray-200 flex flex-col mt-[20px]">
            <div className="flex justify-between">
                <div>
                    <p className="font-semibold text-lg">Parcelles</p>
                </div>
                <div>
                    <select
                        className="bg-[#0FCB8F] w-24 h-8 rounded-md border-none text-white"
                        name="year"
                        id="year"
                        value={values.year}
                        onChange={handleChange}
                    >
                        {getYears().map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="flex gap-[40px] mb-[10px]  mt-[16px]">
                <div className="w-full">
                    <CustomSelect
                        label="Site"
                        options={site}
                        onChange={handleChange}
                        name="site"
                        value={values.site}
                        valueKey="id"
                        labelKey="name"
                    />
                </div>
            </div>
            <div className=" w-full flex-1 text-xs">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={300}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="45%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={85}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data.map((_, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}
