import React from 'react'
import { CircularProgress, Typography, Container, Paper } from '@mui/material'

function CircularPercent(props) {
    const { value = 0, label, size = 70 } = props
    const grey = 'rgba(0, 0, 0, 0.1)' // Couleur de fond grise
    const colorValue = '#13d8f3'

    return (
        <>
            <div style={{ position: 'relative', display: 'inline-flex', marginBottom: '10px' }}>
                {/* Progress actuel */}
                <CircularProgress
                    variant="determinate"
                    value={value}
                    sx={{ position: 'absolute' }}
                    size={size}
                    style={{ color: colorValue }}
                />

                {/* Arrière-plan gris */}
                <CircularProgress variant="determinate" value={100} size={size} style={{ color: grey }} />

                {/* Label de pourcentage */}
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Typography variant="h6" component="div" color="textSecondary">{`${Math.round(
                        value
                    )}%`}</Typography>
                </div>
            </div>
            <Container
                sx={{
                    padding: 1,
                    textAlign: 'center',
                    margin: 'auto',
                    color: '#748291',
                    fontSize: '0.68rem'
                }}
            >
                {label}
            </Container>
        </>
    )
}

export default CircularPercent
