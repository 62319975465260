import React, { createContext, useEffect, useState } from 'react'
import Loader from '../../../../../../components/Entry/Loading'
import InventoryService from '../../../../services/inventory/inventory'

export const context = createContext()

function InventoryContext(props) {
    const { children, data, needLoad, setNeedLoad } = props
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(data)
    const service = InventoryService.load()

    useEffect(() => {
        const getDataInvetoryByNurseryId = async () => {
            setLoading(true)
            const response = await service.getByNurseryIdDesignation({
                nurseryId: data?.inventory?.nursery?.id,
                designation: data?.designation
            })
            if (response) {
                console.log(response.data)
                setValue(response.data)
            }
            setLoading(false)
        }

        if (needLoad && data) {
            getDataInvetoryByNurseryId()
        }
    }, [needLoad, data, service])

    return (
        <>
            <Loader isLoading={loading} />
            <context.Provider value={value}>{children}</context.Provider>
        </>
    )
}

export default InventoryContext
