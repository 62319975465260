import { Container, Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Controls from '../../../../../../components/Entry/Controls'
import { Form, useForm } from '../../../../../../applications/hooks/UseForm'
import MultipleImageInput from '../../../../../../components/Entry/MultipleImageInput'
import { Context } from '../../../utils/PlantationContext'
import { addOrEdit } from '../../../../../../applications/UtilFunctions'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../../applications/hooks/UseAuth'
import PlantationsVisitService from '../../../../services/PlantationVisit'

const initialValues = {
    visit_date: new Date().toISOString(),
    observation: ''
}
function FollowUpVisitForm() {
    const plantationData = useContext(Context)
    const service = PlantationsVisitService.load()
    const navigate = useNavigate()
    const { user } = useAuth()
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        setErrors({
            ...temp
        })
        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialValues,
        true,
        validate
    )

    useEffect(() => {
        if (plantationData) {
            setValues({
                ...values,
                plantation_id: plantationData?.id,
                technicien: `${user?.firstname} ${user?.lastname}`
            })
        }
    }, [plantationData, setValues, values, user])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const result = await addOrEdit(values, resetForm, service, plantationData?.setNeedLoad, setUserHaveToPost, null)
        if (result) navigate(-1)
        else setUserHaveToPost(false)
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Container style={{ padding: '20px' }}>
                <Grid container>
                    <Grid item xs={6}>
                        <Container>
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                name="plantation_id"
                                value={plantationData?.code}
                                size="small"
                                label="Plantation"
                                onChange={handleInputChange}
                                error={errors?.plantation_id}
                                isDisabled={true}
                            />
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                name="observation"
                                value={values?.observation}
                                size="small"
                                label="Observation"
                                onChange={handleInputChange}
                                type="text"
                                error={errors?.observation}
                            />
                        </Container>
                    </Grid>
                    <Grid item xs={6}>
                        <Container>
                            <Controls.DatePicker
                                id="outlined-disabled"
                                label="Date de suivi"
                                // variant="outlined"
                                name="visit_date"
                                value={values?.visit_date || new Date()}
                                size="small"
                                onChange={handleInputChange}
                                type="date"
                                error={errors?.visit_date}
                                isRequired={true}
                            />
                        </Container>
                    </Grid>
                </Grid>
                <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Controls.Button
                        type="submit"
                        text="Enregistrer"
                        size={'large'}
                        haveSpin={true}
                        disabled={userHaveToPost}
                    />
                </Container>
            </Container>
        </Form>
    )
}

export default FollowUpVisitForm
