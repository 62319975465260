import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import ContentView from '../../../../components/PageView/Content'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import RecorgedPotService from '../../services/recorged_pot/recorged_pot'
import { RecorgedPotForm } from './RecorgedPotForm'
import RecorgedPotContext from './utils/hooks/RecorgedPotContext'

function RecorgedPotView(props) {
    const { addReturnButton, recordForEdit } = props
    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const recorged_potService = RecorgedPotService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(true)
    return (
        <>
            <CssBaseline />
            <RecorgedPotContext
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                nurseryId={location?.state?.nurseryId}
                recordForEdit={recordForEdit}
            >
                <ContentView
                    dataId={recordForEdit ? recordForEdit.id : dataId}
                    title={recordForEdit ? "": ` Pot rebouché`}
                    addReturnButton={addReturnButton}
                    service={recorged_potService}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={RecorgedPotForm}
                    needLoadCode={needLoadCode}
                />
            </RecorgedPotContext>
        </>
    )
}

export default RecorgedPotView
