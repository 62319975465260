import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class PlantationService extends Service {
    static messages = {
        update_success: 'Modification Paysans avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création Paysans avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/plantations')
        return this
    }

    static async getByCode(id) {
        return await Http.get(`${this.url}/${id}`, {})
    }

    static async updatePlantation(id, data) {
        try {
            const response = await Http.patch([this.url, id].join('/'), data)
            return response
        } catch (error) {
            console.log(error)
        }
    }
    static async getAllPlantation() {
        try {
            const response = await Http.get('api/plantations')
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des plantations :", error)
            throw error
        }
    }
}

export default PlantationService
