import React, { createContext, useEffect, useState } from 'react'

export const Context = createContext({})

function PlantingTrackingContext({ children, records }) {
    const [value, setValue] = useState(null)

    useEffect(() => {
        if (records) {
            setValue(records)
        }
    }, [records])

    return (
        <>
            <Context.Provider value={value}>
                {children}
            </Context.Provider>
        </>
    )
}

export default PlantingTrackingContext
