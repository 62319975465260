import React, { useRef, useEffect, useState } from 'react'
import { kml, gpx } from '@tmcw/togeojson'
import useFileReader, { GridImportApp } from '../../utils/application/GridImportApp'
import xmldom from '@xmldom/xmldom'
import { Container, Typography } from '@mui/material'
import { LoadScript } from '@react-google-maps/api'
import Map from '../../../../components/Entry/Map'
import TextViewer from '../../../../components/PageView/viewer/TextViewer'
import Controls from '../../../../components/Entry/Controls'
import Notification from '../../../../layouts/Notification'
import { removeTextPart } from '../../../../applications/UtilFunctions'

function GridImport(props) {
    const { entity, setNeedLoad = () => {}, setUserHaveToUpload } = props
    const inputRef = useRef()
    const [src, onChange] = useFileReader(inputRef)

    const [extractedCoordinates, setExtractedCoordinates] = useState(null)
    const [displayedGrid, setDisplayedGrid] = useState(null)
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    useEffect(() => {
        if (!src) return

        const [, data = ''] = src?.split(',') ?? []
        const decodedStr = atob(data)
        let dom
        const extractedCoordinates = []
        const fileType = inputRef.current.files[0].name.split('.').pop().toLowerCase()
        switch (fileType) {
            case 'gpx':
                try {
                    dom = new xmldom.DOMParser().parseFromString(
                        removeTextPart(/<extensions>.*?<\/extensions>/gs, '', decodedStr),
                        'text/xml'
                    )
                    const geoJsonGpx = gpx(dom)
                    console.log(geoJsonGpx)
                    geoJsonGpx.features.forEach((feature) => {
                        if (feature.geometry) {
                            extractedCoordinates.push(feature.geometry.coordinates)
                        }
                    })
                } catch (error) {
                    console.error(error)
                }
                break
            case 'kml':
                dom = new xmldom.DOMParser().parseFromString(decodedStr, 'text/xml')
                const geoJson = kml(dom)
                geoJson.features.forEach((feature) => {
                    if (feature.geometry) {
                        extractedCoordinates.push(feature.geometry.coordinates)
                    }
                })

                break
            default:
                break
        }
        try {
            const formatedCoordinantes = {
                coordinates: extractedCoordinates[0].map(([lng, lat]) => ({
                    lat,
                    lng
                }))
            }
            console.log(JSON.stringify(formatedCoordinantes))
            setExtractedCoordinates(formatedCoordinantes)
            setDisplayedGrid([
                {
                    id: 1,
                    coordinates: formatedCoordinantes.coordinates
                }
            ])
        } catch (error) {
            new Notification(`Erreur lors de l'importation.`, 0)
        }
    }, [src])

    useEffect(() => {
        if (userHaveToPost) {
            setNeedLoad(true)
        }
        if (!userHaveToPost) {
            setNeedLoad(false)
        }
    }, [setNeedLoad, userHaveToPost])

    const handleSubmit = () => {
        // console.log(entity)
        setUserHaveToPost(true)
        const gridApp = new GridImportApp()
        gridApp.setEntity(entity.entity)
        gridApp.setEntityId(entity.entity_id)
        gridApp.setGridData({ [entity.column_name]: JSON.stringify(extractedCoordinates) })
        try {
            gridApp
                .saveGridData()
                .then((res) => {
                    console.log(res)
                    new Notification(`Le quadrillage a été enregistré.`, 1)
                    setUserHaveToPost(false)
                    setUserHaveToUpload(false)
                })
                .catch((error) => {
                    throw error
                })
        } catch (error) {
            new Notification(`Erreur lors de l'importation.`, 0)
        }
    }

    const handleReset = () => {
        setDisplayedGrid(null)
        setExtractedCoordinates(null)
    }

    return (
        <div className="App" style={{ width: '600px' }}>
            <input
                ref={inputRef}
                type="file"
                accept=".kml, .gpx"
                onChange={onChange}
                style={{ display: 'none' }} // Masquer l'input de fichier d'origine
            />
            <Controls.Button
                text="Choisir le fichier"
                size="large"
                onClick={() => {
                    inputRef.current.click()
                    handleReset()
                }} // Ouvrir la boîte de dialogue de fichier
            />
            <br />
            <TextViewer
                title="coordonnées :"
                value={extractedCoordinates?.coordinates ? JSON.stringify(extractedCoordinates?.coordinates) : ''}
            />
            <Container>
                <LoadScript>{displayedGrid && <Map data={displayedGrid} zoom={13} />}</LoadScript>
                {!displayedGrid && (
                    <Typography style={{ textAlign: 'center' }}>(Aucun quadrillage importé.)</Typography>
                )}
            </Container>
            <Container>
                {displayedGrid && <Controls.Button text="Enregistrer" size="large" onClick={handleSubmit} />}
            </Container>
        </div>
    )
}

export default GridImport
