import React, { useState, useEffect, useContext } from 'react'

import { Form, useForm } from '../../../../applications/hooks/UseForm'
import Controls from '../../../../components/Entry/Controls'
import { useNavigate, useLocation } from 'react-router-dom'
import Notification from '../../../../layouts/Notification'
import DataSetsService from '../../../datasets/services/DataSets'

import PlantMaterialService from '../../services/plant_material/plant_material'
import moment from 'moment'
import SpeciesService from '../../../species/services/Species'
import ProvinceService from '../../../provinces/services/Provinces'
import { context } from './utils/hooks/PlantMaterialContext'
import { Card, Container, Grid, Paper, Typography } from '@mui/material'
import Subtitle from '../../../../components/Typography/Subtitle'
import { formatDate, numStr } from '../../../../applications/UtilFunctions'
import { FaTrashAlt } from 'react-icons/fa'
import CalibrationService from '../../../calibration/services/CalibrationService'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import Popup from '../../../../layouts/Popup'
import ObjectifModificationView from './popupView/ObjectifModificationView'

const { Input: TextField, Button } = Controls

const initialFValues = {
    date_purchase: new Date().toISOString(),
    species: '',
    objective: '',
    quantity: '',
    state: '',
    unity: '',
    supplier: '',
    price: '',
    equivalence: 0,
    purchase_state: ''
}

export const PlantMaterialForm = (props) => {
    const { records, setNeedLoad } = props

    const navigate = useNavigate()
    const location = useLocation()

    const dataset = DataSetsService.load()
    const calibration = CalibrationService.load()
    const plantMaterialService = PlantMaterialService.load()
    const plantMaterialData = useContext(context)

    // const [dataCategory, setDataCategory] = useState([])
    const [dataState, setDataState] = useState([])
    const [specie, setSpecie] = useState([])
    const [site, setSite] = useState([])
    const [activeSite, setActiveSite] = useState(null)
    const [centers, setCenters] = useState([])
    const [unity, setUnity] = useState([])
    const [supplier, setSupplier] = useState([])
    const [purchase_state, setPurchaseState] = useState([])
    const [userHaveToAdd, setUserHaveToAdd] = useState(false)
    const [nurseryId, setNurseryId] = useState(null)
    const [userHaveToModify, setUserHaveToModify] = useState(false)
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [plantMaterialDatas, setPlantMaterialDatas] = useState([])
    const [dataCalibration, setDataCalibration] = useState(null)
    const [seedQuantity, setSeedQuantity] = useState(0)
    const [userHaveToAddSpecie, setUserHaveToAddSpecie] = useState(false)
    const [userHaveToModifyObjectif, setUserHaveToModifyObjectif] = useState(false)
    const { user } = useAuth()

    const nurseryIdAdd = location.state?.nurseryId || null

    const [selectedPlantMaterialItem, setSelectedPlantMaterialItem] = useState(null)

    //////data plant material context //////
    useEffect(() => {
        if (selectedPlantMaterialItem) {
            console.log(selectedPlantMaterialItem)
            setValues((prevFormData) => ({
                ...prevFormData,
                unity: selectedPlantMaterialItem?.unity || '',
                supplier: selectedPlantMaterialItem?.supplier || '',
                quantity: selectedPlantMaterialItem?.quantity || 0,
                date_purchase: selectedPlantMaterialItem?.date_purchase || new Date().toISOString(),
                price: selectedPlantMaterialItem?.unit_price || 0,
                id: selectedPlantMaterialItem?.id,
                plant_material_id: selectedPlantMaterialItem?.plant_material_id,
                purchase_state: selectedPlantMaterialItem?.purchase_state || ''
            }))
        }
    }, [selectedPlantMaterialItem])

    /////if there is no specie selected :
    useEffect(() => {
        if (plantMaterialData?.userHaveToAdd) {
            resetForm()
            setUserHaveToAdd(plantMaterialData?.userHaveToAdd)
            setNurseryId(plantMaterialData?.nurseryId)
        }
    }, [plantMaterialData.userHaveToAdd])

    useEffect(() => {
        if (records) {
            setValues((prevFormData) => ({
                ...prevFormData,
                date_purchase: records?.date_purchase || new Date().toISOString(),
                species: records?.species?.id || '',
                objective: records?.objective || '',
                quantity: records?.quantity || 0,
                price: records?.price || 0,
                number_purchased: records?.number_purchased || 0,
                state: records?.state || '-',
                unity: records?.unity || '',
                supplier: records?.supplier || '',
                site: records?.site?.id || '',
                center: records?.center?.id || '',
                purchase_state: records?.purchase_state || ''
            }))
        }
    }, [records])

    useEffect(() => {
        calibration.getAll().then((response) => {
            if (response) {
                console.log(response)
                setDataCalibration(response?.data)
            }
        })
        dataset
            .getDataCode('statut_supply')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setDataState(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
        dataset
            .getDataCode('unity_code')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setUnity(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })

        dataset
            .getDataCode('plant_material_purchase_status')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setPurchaseState(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })

        dataset
            .getDataCode('supplier')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setSupplier(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [])

    useEffect(() => {
        SpeciesService.getAllSpecies()
            .then((users) => {
                if (users) {
                    setSpecie(users)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [])

    useEffect(() => {
        ProvinceService.getVillages().then((response) => {
            if (response?.data) {
                setSite(response?.data)
            }
        })
    }, [])

    useEffect(() => {
        if (activeSite) {
            ProvinceService.getAgroCenters(activeSite, null, false).then((response) => {
                if (response?.data) {
                    const data = response?.data
                    //data.unshift({ id:0, name:'Centrale'})
                    setCenters(data)
                }
            })
        }
    }, [activeSite])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        // if ('species' in fieldValues) temp.species = fieldValues?.species?.length !== 0 ? '' : 'Ce champ est requis.'

        if (plantMaterialData?.specieId && 'unity' in fieldValues)
            temp.unity = fieldValues.unity.length !== 0 ? '' : 'Ce champ est requis.'
        if (plantMaterialData?.specieId && 'supplier' in fieldValues)
            temp.supplier = fieldValues.supplier.length !== 0 ? '' : 'Ce champ est requis.'
        //planting_date_begin
        if (plantMaterialData?.specieId && 'quantity' in fieldValues) {
            if (fieldValues.quantity) {
                temp.quantity = /^-?\d*[.,]?\d*$/.test(fieldValues.quantity) ? '' : 'Entrer un nombre'
            } else {
                temp.quantity = 'Ce champ est requis.'
            }
        }
        //planting_date_end
        // if (plantMaterialData?.specieId && 'objective' in fieldValues)
        //     temp.objective = fieldValues.objective ? '' : 'Ce champ est requis.'
        // setErrors({
        //     ...temp
        // })
        if (plantMaterialData?.specieId && 'date_purchase' in fieldValues)
            temp.date_purchase = fieldValues.date_purchase ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const validateItem = (fieldValues = values) => {
        let temp = { ...errors }

        if (plantMaterialData?.specieId && 'unity' in fieldValues)
            temp.unity = fieldValues.unity.length !== 0 ? '' : 'Ce champ est requis.'
        if ('supplier' in fieldValues) temp.supplier = fieldValues.supplier.length !== 0 ? '' : 'Ce champ est requis.'
        //planting_date_begin
        if ('quantity' in fieldValues) temp.quantity = fieldValues.quantity ? '' : 'Ce champ est requis.'
        //planting_date_end
        if ('price' in fieldValues) temp.price = fieldValues.price ? '' : 'Ce champ est requis.'
        if ('date_purchase' in fieldValues) temp.date_purchase = fieldValues.date_purchase ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })

        console.log('temp ', temp)

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialFValues,
        true,
        validate
    )

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('values', values)
        console.log('plantMaterialData', plantMaterialData)
        console.log('vaidate', validate())
        if (!plantMaterialData?.specieId && plantMaterialDatas.length <= 0) {
            new Notification('Veuillez ajouter les matières végétales dans la liste', 0)
        }
        if (!plantMaterialData?.specieId && validate() && plantMaterialDatas.length > 0) {
            console.log(values)
            try {
                const dataToSend = {
                    plantMaterials: values?.plantMaterials,
                    user: [user.firstname, user.lastname].join(' ')
                }
                ///// this code is commented because of the feature changement
                // setUserHaveToPost(true)
                // values.quantity = values.quantity.replace(',', '.')
                // const dataToSend = { ...values, quantity: parseFloat(values?.quantity) }
                // const quantityValue = parseFloat(dataToSend?.quantity)
                // const priceValue = parseInt(dataToSend?.price)
                // const numberPurchasedValue = parseInt(dataToSend?.number_purchased)
                // const specieId = parseInt(values?.species)
                // const siteId = parseInt(values?.site)
                // const center_id = values?.center === '0' ? null : parseInt(values?.center)

                // delete dataToSend?.species
                // delete dataToSend?.site
                // delete dataToSend?.speciesName
                // delete dataToSend?.center
                // console.log(dataToSend)
                // if (!isNaN(quantityValue)) {
                //     dataToSend.price = priceValue
                //     dataToSend.number_purchased = numberPurchasedValue
                //     dataToSend.specieId = specieId
                //     dataToSend.site_id = siteId
                dataToSend.nursery_id = nurseryId || nurseryIdAdd

                //     if (records?.id && !userHaveToAdd) {
                //         const result = await PlantMaterialService.updatePlantMaterial(records.id, dataToSend)
                //         if (result) {
                //             console.log('Enregistrement mis à jour avec succès : ', records)
                //             new Notification(PlantMaterialService.messages.update_success, 1)
                //             return result
                //         }
                //     } else {
                //         console.log('Enregistrement avec succès : ', dataToSend)
                //         const result = await PlantMaterialService.addPlantMaterial(dataToSend)
                //         if (result) {
                //             new Notification(PlantMaterialService.messages.create_success, 1)
                //             return result
                //         }
                //     }
                //     setUserHaveToPost(false)
                // } else {
                //     setUserHaveToPost(false)
                //     console.error('La valeur de la quantité doit être un nombre')
                // }
                setUserHaveToPost(true)
                console.log(dataToSend)
                const result = await PlantMaterialService.addPlantMaterial(dataToSend)
                if (result) {
                    new Notification(PlantMaterialService.messages.create_success, 1)
                    setUserHaveToPost(false)
                    return result
                }
            } catch (error) {
                setUserHaveToPost(false)
                console.error('Erreur lors de la soumission du formulaire : ', error)
                new Notification(PlantMaterialService.messages.create_error, 0)
            }
        }

        if (plantMaterialData?.specieId && validateItem()) {
            console.log('plant material data : ', plantMaterialData)
            console.log(values)
            const dataToSend = { ...values, user: [user.firstname, user.lastname].join(' ') }
            dataToSend.plantMaterialId = plantMaterialData?.id
            console.log('Data to send >>>>> ', dataToSend)
            dataToSend.quantity = dataToSend.quantity.replace(',', '.')
            await PlantMaterialService.addPlantMaterialItem(dataToSend)
            setNeedLoad(true)
            setUserHaveToAdd(false)
        }
    }

    const handleSaveData = async (e) => {
        const result = await handleSubmit(e)
        if (result) {
            navigate(-1)
        }
    }

    const handleSaveAndAddnew = async (e) => {
        const result = await handleSubmit(e)
        let specieId = values.species
        setValues((prevFormData) => ({
            ...prevFormData,
            date_purchase: new Date().toISOString(),
            species: specieId,
            objective: '',
            quantity: '',
            price: '',
            number_purchased: '',
            state: '-',
            unity: '',
            supplier: '',
            site: '',
            center: ''
        }))
        if (result) {
            setUserHaveToPost(false)
        }
    }

    const handleModify = async () => {
        try {
            setUserHaveToPost(true)
            setNeedLoad(true)
            console.log(values)
            values.quantity = typeof values.quantity === 'string' ? values.quantity.replace(',', '.') : values.quantity
            const response = await PlantMaterialService.updatePlantMaterialItem(values.id, {
                ...values,
                user: [user.firstname, user.lastname].join(' ')
            })
            if (response) {
                setUserHaveToModify(false)
                setNeedLoad(false)
                setUserHaveToPost(false)
                new Notification(PlantMaterialService.messages.update_success, 1)
            }
        } catch (error) {
            setUserHaveToPost(false)
            console.log(error)
            console.log(PlantMaterialService.messages.update_error, 0)
        }
    }

    const handleChange = (e) => {
        handleInputChange(e)

        if (e.target.name === 'species') {
            const selectedSpecies = specie.find((species) => species.id === parseInt(e.target.value))

            setValues((prevFormData) => ({
                ...prevFormData,
                species: e.target.value,
                speciesName: selectedSpecies?.name || ''
            }))
        } else {
            setValues((prevFormData) => ({
                ...prevFormData,
                [e.target.name]: e.target.value
            }))
            if (e.target.name === 'site') {
                setActiveSite(e.target.value)
            }
        }
    }

    useEffect(() => {
        if (plantMaterialData?.PlantMaterialItem) {
            setSelectedPlantMaterialItem(
                plantMaterialData?.PlantMaterialItem[plantMaterialData?.PlantMaterialItem.length - 1]
            )
        }
    }, [plantMaterialData?.PlantMaterialItem])

    useEffect(() => {
        if (values?.quantity && values?.unity) {
            console.log(values)
            let calibration = dataCalibration?.find(
                (item) => item?.specie_id === plantMaterialData?.specieId && item?.unity === values?.unity
            )
            console.log(calibration)
            if (calibration) {
                setValues({
                    ...values,
                    equivalence: values?.quantity?.toString().replace(',', '.') * calibration.value
                })
            } else {
                setValues({
                    ...values,
                    equivalence: 0
                })
                new Notification('Equivalence non enregistrée.', 3)
            }
        }
    }, [values?.quantity, values?.unity])

    const handleAddPlantMaterialItem = () => {
        console.log('plantMaterialData >>>> ', plantMaterialData)
        setValues((prevValues) => ({ ...prevValues, plant_material_id: plantMaterialData?.id }))
        setUserHaveToAdd(true)
        resetForm()
        setSelectedPlantMaterialItem(null)
    }

    const handleAddToPlantMaterialDatas = () => {
        if (plantMaterialDatas.find((item) => item.specieName === values.speciesName) === undefined) {
            if (values.speciesName !== '' && values.objective !== '') {
                setUserHaveToAddSpecie(true)
                plantMaterialService
                    .verifyIfSpecieExistInPlantMaterial(nurseryId, values?.species)
                    .then((res) => {
                        if (res) {
                            new Notification('Cette espèce existe déja dans la pépinière.', 0)
                            setUserHaveToAddSpecie(false)
                        } else {
                            setUserHaveToAddSpecie(false)
                            setPlantMaterialDatas([
                                ...plantMaterialDatas,
                                {
                                    key: plantMaterialDatas.length + 1,
                                    speciesName: values.speciesName,
                                    species: values.species,
                                    objective: values.objective
                                }
                            ])
                            setValues({
                                ...values,
                                species: null,
                                objective: '',
                                plantMaterials: [
                                    ...plantMaterialDatas,
                                    !plantMaterialDatas.find((item) => item.specieName === values.speciesName) && {
                                        key: plantMaterialDatas.length + 1,
                                        speciesName: values.speciesName,
                                        species: values.species,
                                        objective: values.objective
                                    }
                                ]
                            })
                        }
                    })
                    .catch((err) => console.log(err))
            } else {
                new Notification('Veuillez renseigner les champs obligatoires.', 0)
            }
        } else {
            new Notification('Cette espèce existe déjà dans la liste.', 0)
        }
    }

    const handleEditObjective = () => {
        console.log(plantMaterialData)
        setUserHaveToModifyObjectif(true)
    }

    return (
        <div>
            <Popup
                title="Modification objectif"
                openPopup={userHaveToModifyObjectif}
                setOpenPopup={setUserHaveToModifyObjectif}
            >
                <ObjectifModificationView objectiveParent='plantMaterial' data={plantMaterialData} setNeedLoad={setNeedLoad} />
            </Popup>
            <Form>
                <div>
                    <Form
                        sx={{
                            '& .MuiPaper-root': {
                                boxShadow: 'none'
                            }
                        }}
                    >
                        <div className="flex p-4">
                            {plantMaterialData?.specieId && (
                                <div className="w-[30%] max-w-[296px] p-4">
                                    <Paper
                                        style={{ padding: '10px 10px 10px 10px' }}
                                        sx={{
                                            backgroundColor: '#183136'
                                        }}
                                    >
                                        <Paper sx={{ padding: '5px 5px 5px 5px', backgroundColor: '#183136' }}>
                                            <Grid container>
                                                <Grid item sx={{ padding: '5px 5px 5px 5px' }}>
                                                    <Controls.CustomImageAvatar
                                                        size={50}
                                                        rounded={50}
                                                        src={plantMaterialData?.species?.photos}
                                                    ></Controls.CustomImageAvatar>
                                                </Grid>
                                                <Grid item>
                                                    <Paper
                                                        sx={{
                                                            backgroundColor: '#183136'
                                                        }}
                                                    >
                                                        <Subtitle
                                                            style={{
                                                                fontSize: '1rem',
                                                                color: 'white'
                                                            }}
                                                        >
                                                            {plantMaterialData?.species?.name}
                                                        </Subtitle>
                                                    </Paper>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        style={{ fontSize: '0.75rem', color: 'white' }}
                                                    >
                                                        <b>Objectif : </b>
                                                        {numStr(plantMaterialData?.objective, '.')} graines
                                                        <EditOutlinedIcon
                                                            fontSize="small"
                                                            onClick={() => {
                                                                handleEditObjective(true)
                                                            }}
                                                            sx={{
                                                                color: 'white',
                                                                marginLeft: '5px',
                                                                cursor: 'pointer',
                                                                alignSelf: 'center',
                                                                ':hover': { color: '#14b57c' }
                                                            }}
                                                        />
                                                        <br />
                                                        <b>Quantité achetée : </b>
                                                        {numStr(plantMaterialData?.quantity_purchased, '.')} graines
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        <Grid>
                                            <Card style={{ width: '100%' }} sx={{ backgroundColor: '#183136' }}>
                                                <Paper
                                                    sx={{
                                                        backgroundColor: '#183136',
                                                        padding: '16px',
                                                        display: 'flex',
                                                        gap: '10px',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Subtitle
                                                        style={{
                                                            fontSize: '1.0rem',
                                                            color: '#14b67d'
                                                        }}
                                                    >
                                                        Achats
                                                    </Subtitle>
                                                    <Controls.Button
                                                        text={'Ajouter'}
                                                        onClick={handleAddPlantMaterialItem}
                                                    />
                                                </Paper>
                                                {plantMaterialData?.PlantMaterialItem?.length > 0 &&
                                                    plantMaterialData?.PlantMaterialItem?.map((item, index) => {
                                                        return (
                                                            <Container
                                                                key={index}
                                                                sx={{
                                                                    ':hover': {
                                                                        backgroundColor: '#2b3640',
                                                                        cursor: 'pointer'
                                                                    },
                                                                    backgroundColor:
                                                                        selectedPlantMaterialItem === item
                                                                            ? '#2b4347'
                                                                            : 'none',
                                                                    borderRadius: '10px',
                                                                    '&:hover': {
                                                                        backgroundColor: '#2b3640',
                                                                        cursor: 'pointer'
                                                                    }
                                                                }}
                                                                onClick={() => {
                                                                    setSelectedPlantMaterialItem(item)
                                                                    setUserHaveToAdd(false)
                                                                }}
                                                            >
                                                                <div>
                                                                    <Grid container sx={{ alignItems: 'center' }}>
                                                                        <Grid>
                                                                            <Subtitle
                                                                                style={{
                                                                                    fontSize: '0.8rem',
                                                                                    color: 'white',
                                                                                    padding: '5px'
                                                                                }}
                                                                            >
                                                                                {formatDate(item.date_purchase)}
                                                                            </Subtitle>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Container>
                                                        )
                                                    })}
                                                {plantMaterialData?.PlantMaterialItem?.length === 0 && (
                                                    <Container>
                                                        <p>...</p>
                                                    </Container>
                                                )}
                                            </Card>
                                        </Grid>
                                    </Paper>
                                </div>
                            )}
                            {userHaveToAdd && !plantMaterialData?.specieId && (
                                <>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Container>
                                                <Controls.AutoCompleteSelect
                                                    label="Espèce"
                                                    name="species"
                                                    value={values?.species || ''}
                                                    onChange={handleChange}
                                                    options={specie}
                                                    labelKey="name"
                                                    valueKey="id"
                                                    error={errors?.species}
                                                    isDisabled={false}
                                                    isRequired={true}
                                                />
                                            </Container>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Container>
                                                <TextField
                                                    id="outlined-disabled"
                                                    label="Objectif"
                                                    // variant="outlined"
                                                    name="objective"
                                                    value={values?.objective}
                                                    size="small"
                                                    onChange={handleChange}
                                                    type="number"
                                                    error={errors?.objective}
                                                    isRequired={true}
                                                />
                                            </Container>
                                        </Grid>
                                        <Grid container>
                                            <Container
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Button
                                                    text="Ajouter"
                                                    onClick={() => {
                                                        if (
                                                            plantMaterialDatas.find(
                                                                (data) => data.species === values.species
                                                            ) === undefined
                                                        ) {
                                                            handleAddToPlantMaterialDatas()
                                                        } else {
                                                            new Notification(
                                                                'Cette espèce existe déjà dans la liste',
                                                                0
                                                            )
                                                        }
                                                    }}
                                                    color="primary"
                                                    haveSpin={true}
                                                    disabled={userHaveToAddSpecie}
                                                />
                                            </Container>
                                            <Container>
                                                <div>
                                                    <Paper style={{ padding: '10px', margin: '10px 0px 10px 0px' }}>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            style={{ marginBottom: '10px' }}
                                                            fontSize={12}
                                                        >
                                                            Matières végétales :
                                                        </Typography>
                                                        <div style={{ height: '200px', overflowY: 'scroll' }}>
                                                            {plantMaterialDatas
                                                                .map((data) => (
                                                                    <div key={data.id}>
                                                                        <Typography
                                                                            variant="p"
                                                                            component="div"
                                                                            style={{
                                                                                marginBottom: '10px',
                                                                                display: 'flex',
                                                                                gap: '10px'
                                                                            }}
                                                                            fontSize={12}
                                                                        >
                                                                            -
                                                                            <i>
                                                                                {data.speciesName} : {data.objective}
                                                                            </i>
                                                                            <FaTrashAlt
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    color: 'red',
                                                                                    alignSelf: 'center'
                                                                                }}
                                                                                onClick={() =>
                                                                                    setPlantMaterialDatas(
                                                                                        plantMaterialDatas.filter(
                                                                                            (v) => v.key !== data.key
                                                                                        )
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Typography>
                                                                    </div>
                                                                ))
                                                                .reverse()}
                                                        </div>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            style={{ marginBottom: '10px' }}
                                                            fontSize={12}
                                                        >
                                                            <b>Totale : {plantMaterialDatas.length}</b>
                                                        </Typography>
                                                    </Paper>
                                                </div>
                                            </Container>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {plantMaterialData?.specieId && (
                                <>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Controls.ModifyButton
                                                onClick={() => setUserHaveToModify(!userHaveToModify)}
                                                userHaveToModify={userHaveToModify}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Container>
                                                <Controls.DatePicker
                                                    label="Date"
                                                    name="date_purchase"
                                                    value={values?.date_purchase}
                                                    size="small"
                                                    onChange={handleChange}
                                                    type="date"
                                                    error={errors?.date_purchase}
                                                    isDisabled={!userHaveToAdd && !userHaveToModify ? true : false}
                                                    isRequired={true}
                                                />

                                                <TextField
                                                    id="outlined-disabled"
                                                    label="Quantité margée (30%)"
                                                    name="quantity"
                                                    value={values?.quantity}
                                                    size="small"
                                                    onChange={handleChange}
                                                    error={errors?.quantity}
                                                    isDisabled={!userHaveToAdd && !userHaveToModify ? true : false}
                                                    isRequired={true}
                                                />
                                                <TextField
                                                    id="outlined-disabled"
                                                    label="Prix unitaire (Ar)"
                                                    name="price"
                                                    value={values?.price}
                                                    size="small"
                                                    onChange={handleChange}
                                                    type="number"
                                                    error={errors?.price}
                                                    isDisabled={!userHaveToAdd && !userHaveToModify ? true : false}
                                                    isRequired={true}
                                                />
                                            </Container>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Container>
                                                <Controls.AutoCompleteSelect
                                                    label="Fournisseur"
                                                    name="supplier"
                                                    value={values.supplier || ''}
                                                    onChange={handleChange}
                                                    options={supplier}
                                                    labelKey="label"
                                                    valueKey="label"
                                                    error={errors?.supplier}
                                                    isDisabled={!userHaveToAdd && !userHaveToModify ? true : false}
                                                    isRequired={true}
                                                />
                                                <Controls.AutoCompleteSelect
                                                    label="Unité"
                                                    name="unity"
                                                    value={
                                                        // selectedPlantMaterialItem?.unity ||
                                                        values?.unity ||
                                                        // records?.unity ||
                                                        ''
                                                    }
                                                    onChange={handleChange}
                                                    options={unity}
                                                    labelKey="label"
                                                    valueKey="label"
                                                    error={errors?.unity}
                                                    isDisabled={!userHaveToAdd && !userHaveToModify ? true : false}
                                                    isRequired={true}
                                                />
                                                <TextField
                                                    id="outlined-disabled"
                                                    label="Equivalence (graine)"
                                                    name="price"
                                                    value={values?.equivalence}
                                                    size="small"
                                                    onChange={handleChange}
                                                    type="number"
                                                    isDisabled={true}
                                                />
                                                <Controls.AutoCompleteSelect
                                                    label="Etat de l'achat"
                                                    name="purchase_state"
                                                    value={values.purchase_state || ''}
                                                    onChange={handleChange}
                                                    options={purchase_state}
                                                    labelKey="label"
                                                    valueKey="label"
                                                    error={errors?.purchase_state}
                                                    isDisabled={!userHaveToAdd && !userHaveToModify ? true : false}
                                                    isRequired={true}
                                                />
                                            </Container>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {userHaveToModify && (
                                                <div className="flex justify-end items-end m-5 p-5">
                                                    <Button
                                                        text="Modifier"
                                                        onClick={handleModify}
                                                        haveSpin={true}
                                                        color="primary"
                                                        disabled={userHaveToPost}
                                                    />
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </div>
                        {userHaveToAdd && (
                            <div className="flex justify-end items-end m-5 p-5 gap-5">
                                {/* <Button
                                    text="Enregistrer et nouveau achat"
                                    onClick={handleSaveAndAddnew}
                                    color="primary"
                                    haveSpin={true}
                                    disabled={userHaveToPost}
                                /> */}
                                <Button
                                    text="Enregistrer"
                                    onClick={handleSaveData}
                                    color="primary"
                                    haveSpin={true}
                                    disabled={userHaveToPost}
                                />
                            </div>
                        )}
                    </Form>
                </div>
            </Form>
        </div>
    )
}
