import React, { useCallback, useEffect, useState } from 'react'
import { Button, Container, TextField, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Controls from './Controls'

const useStyles = makeStyles((theme) => ({
    labelInput: {
        fontSize: '12px',
        padding: `0 0 10px 0`
    },
    labelContainer: {
        padding: '0 0 0 0',
        marginBottom: '10px',
        width: '100%',
        display: 'block',
        '& .MuiFormControl-root': {
            margin: '0px',
            width: '100%'
        },
        '& .MuiContainer-root': {
            padding: '10px 0 0 0'
        }
    }
}))

function ImageInput(props) {
    const {
        onChange,
        label,
        onSave,
        error = null,
        other,
        name,
        valueImage,
        setValueImage,
        customizedButton,
        children,
        isRequired=false
    } = props
    const classes = useStyles()
    const [image, setImage] = useState(null)
    const [fileName, setFileName] = useState('')

    const handleImageChange = (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        setValueImage(null)

        reader.onloadend = () => {
            setImage(reader.result)
            setFileName(file.name)
            name && onChange({ target: { name, value: file.name, content: reader.result } })
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const handleSaveImage = useCallback(() => {
        console.log('Image sauvegardée:', image)
    }, [image])

    useEffect(() => {
        if (onSave) {
            handleSaveImage()
        }
    }, [onSave, image, handleSaveImage])

    return (
        <>
            <Container className={classes.labelContainer}>
                <input
                    name={name}
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="image-input"
                    type="file"
                    onChange={handleImageChange}
                    {...(error && { error: true, helperText: error })}
                    {...other}
                />
                <label htmlFor="image-input">
                    <Paper
                        className={classes.labelInput}
                        sx={{ background: 'none', border: 'none', boxShadow: 'none' }}
                    >
                        {label} {isRequired ? <b style={{color: 'red'}}>*</b> : ''}
                    </Paper>
                    {valueImage && <Controls.CustomImageAvatar src={valueImage} rounded={1} size={500} />}
                    <Container
                        sx={{
                            padding: '0px',
                            '& .MuiButtonBase-root': {
                                height: '100%',
                                width: '100%',
                                textAlign: 'center',
                                color: '#ffffff',
                                marginBottom: '15px'
                            }
                        }}
                    >
                        {!customizedButton && (
                            <Button variant="contained" component="span">
                                {valueImage ? "Changer l'image" : 'Choisir une image'}
                            </Button>
                        )}
                        {customizedButton && children}
                        {!customizedButton && !valueImage && (
                            <TextField
                                value={fileName} // Utilisation du nom du fichier
                                variant="outlined"
                                size="small"
                                sx={{ width: '100%' }}
                                disabled
                                label="Nom du fichier"
                            />
                        )}
                    </Container>
                </label>
                {!customizedButton && image && <img src={image} alt="Preview" style={{ maxWidth: '100%', marginTop: '10px' }} />}
            </Container>
        </>
    )
}

export default ImageInput
