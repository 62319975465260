import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import Masonry from '@mui/lab/Masonry'
import PageHeader from '../../components/Typography/PageHeader'
import ProvinceService from './services/Provinces'
import ConfirmDialog from '../../layouts/ConfirmDialog'
import CustomCard from './components/CustomCard'
import ConditionalCard from './components/CustomCardWithParent'
import { handleShowDistricts, handleShowFkt, handleShowTowns, handleShowVillage } from './utils/PovinceUtils'
import {
    handleKeyDownRegion,
    handleKeyDownDistrict,
    handleKeyDownTown,
    handleKeyDownFkt,
    handleKeyDownVillage
} from './utils/KeybordHandlersMangro'
import Notification from '../../layouts/Notification'
import Controls from '../../components/Entry/Controls'

export const Provinces = () => {
    const [regions, setRegions] = useState([{ name: '' }])
    const [districts, setDistricts] = useState()
    const [towns, setTowns] = useState()
    const [fkt, setFkt] = useState()
    const [village, setVillage] = useState()
    const [selectedVillageForGroup, setSelectedVillageForGroup] = useState(null)

    const [enabledRegion, setEnabledRegion] = useState(null)
    const [enabledDistrict, setEnabledDistrict] = useState(null)
    const [enabledTown, setEnabledTown] = useState(null)
    const [enabledFkt, setEnabledFkt] = useState(null)
    const [enabledVillage, setEnabledVillage] = useState(null)

    const [selectedRegion, setSelectedRegion] = useState(null)
    const [selectedDistrict, setSelectedDistrict] = useState(null)
    const [selectedTown, setSelectedTown] = useState(null)
    const [selectedFkt, setSelectedFkt] = useState(null)
    const [selectedVillage, setSelectedVillage] = useState(null)
    const [groupSaved, setGroupSaved] = useState(false)
    const [isLoadingDistrict, setIsLoadingDistrict] = useState(false)
    const [isLoadingCommune, setIsLoadingCommune] = useState(false)
    const [isLoadingFokontany, setIsLoadingFokontany] = useState(false)
    const [isLoadingVillage, setIsLoadingVillage] = useState(false)
    const [createNursery, setCreateNursery] = useState(false)

    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })

    useEffect(() => {
        ProvinceService.getRegions().then((response) => {
            if (response?.data) {
                setRegions(response?.data)
            }
        })
    }, [])

    useEffect(() => {
        if (selectedRegion) {
            handleShowDistricts(selectedRegion, setSelectedRegion, setEnabledRegion, setDistricts, setSelectedDistrict, setIsLoadingDistrict)
            setSelectedVillageForGroup(null)
        } else {
            setDistricts(null)
            setEnabledDistrict(null)
        }
    }, [selectedRegion])

    useEffect(() => {
        if (selectedDistrict && districts && districts.length > 0) {
            handleShowTowns(selectedDistrict, setSelectedDistrict, setEnabledDistrict, setTowns, setSelectedTown, setIsLoadingCommune)
            setSelectedVillageForGroup(null)
        } else {
            setTowns(null)
            setEnabledTown(null)
        }
    }, [selectedDistrict, districts])

    useEffect(() => {
        if (selectedTown && towns && towns.length > 0) {
            handleShowFkt(selectedTown, setSelectedTown, setEnabledTown, setFkt, setSelectedFkt, setIsLoadingFokontany)
            setSelectedVillageForGroup(null)
        } else {
            setFkt(null)
            setEnabledFkt(null)
        }
    }, [selectedTown, towns])

    useEffect(() => {
        if (selectedFkt && fkt && fkt.length > 0) {
            handleShowVillage(selectedFkt, setSelectedFkt, setEnabledFkt, setVillage, setSelectedVillage, setIsLoadingVillage)
            setSelectedVillageForGroup(null)
        } else {
            setVillage(null)
            setEnabledVillage(null)
        }
    }, [selectedFkt, fkt])

    useEffect(() => {
        if (groupSaved) {
            console.log('fkt : ', selectedFkt, ' fkt : >>', fkt, "group : ", groupSaved)
            setVillage(null)
            setEnabledVillage(null)
            setSelectedFkt(null)
            setGroupSaved(false)
            setSelectedVillageForGroup(null)
        }
    }, [groupSaved])

    const handleAddField = (setData, data, newData) => {
        setData([...data, newData])
    }

    const handleDoubleClick = (region) => {
        console.log('region', region)
        setEnabledRegion(region)
    }

    const handleChange = (setData, data, event, item) => {
        const updatedData = data.map((d) => {
            if (d.id === item.id) {
                return { ...d, name: event.target.value }
            }
            return d
        })
        setData(updatedData)
    }

    const handleClear = async (item, serviceFunction, setData) => {
        if (item.id) {
            const response = await serviceFunction(item.id)
            if (response?.status?.code === 200 || response?.status?.code === 201) {
                new Notification('Suppression effectuée.', 1)
            } else {
                new Notification('Erreur de suppression.', 0)
            }
        }
        setData((data) => data.filter((d) => d.id !== item.id))
        if (!item.id) {
            setData((data) => data.filter((d) => d.id !== item.id))
            new Notification('Suppression effectuée.', 1)
        }
    }

    const addAction = () => {}

    return (
        <div className={'card w-full bg-base-100'} style={{ paddingBottom: 20 }}>
            <PageHeader title={' Régions, Communes et Villages'} subTitle={'Gestion des Régions et villages'} />
            <div className="mt-[40px]">
                <Masonry columns={5} spacing={1} className="mt-[20px]">
                    <Paper elevation={8}>
                        <CustomCard
                            title="Régions"
                            items={regions}
                            enabledItem={enabledRegion}
                            selectedItem={selectedRegion}
                            setEnabledItem={setEnabledRegion}
                            setSelectedItem={setSelectedRegion}
                            handleAddField={() => handleAddField(setRegions, regions, { name: '' })}
                            handleDoubleClick={handleDoubleClick}
                            handleChange={(event, region) => handleChange(setRegions, regions, event, region)}
                            handleShowItems={(region) =>
                                handleShowDistricts(
                                    region,
                                    setSelectedRegion,
                                    setEnabledRegion,
                                    setDistricts,
                                    setSelectedDistrict
                                )
                            }
                            handleKeyDown={(event, region) =>
                                handleKeyDownRegion(event, region, setRegions, setSelectedRegion, setEnabledRegion)
                            }
                            handleClearItem={(region) => handleClear(region, ProvinceService.deleteRegions, setRegions)}
                        />
                    </Paper>

                    <Paper elevation={8} className="min-h-[530px]">
                        <ConditionalCard
                            title="districts"
                            parentName="Régions"
                            items={districts}
                            isLoading={isLoadingDistrict}
                            enabledItem={enabledDistrict}
                            selectedItem={selectedDistrict}
                            handleAddField={() =>
                                handleAddField(setDistricts, districts, { name: '', parent: selectedRegion?.id })
                            }
                            handleChange={(event, district) => handleChange(setDistricts, districts, event, district)}
                            handleShowItems={(district) =>
                                handleShowTowns(
                                    district,
                                    setSelectedDistrict,
                                    setEnabledDistrict,
                                    setTowns,
                                    setSelectedTown
                                )
                            }
                            handleKeyDown={(event, district) =>
                                handleKeyDownDistrict(event, district, selectedRegion, setDistricts, setEnabledDistrict, setIsLoadingDistrict)
                            }
                            handleClearItem={(district) =>
                                handleClear(district, ProvinceService.deleteDistrict, setDistricts)
                            }
                        />
                    </Paper>
                    <Paper elevation={8} className="min-h-[530px]">
                        <ConditionalCard
                            title="communes"
                            parentName={'district'}
                            items={towns}
                            isLoading={isLoadingCommune}
                            enabledItem={enabledTown}
                            selectedItem={selectedTown}
                            handleAddField={() =>
                                handleAddField(setTowns, towns, { name: '', parent: selectedDistrict?.id })
                            }
                            handleChange={(event, town) => handleChange(setTowns, towns, event, town)}
                            handleShowItems={(town) =>
                                handleShowFkt(town, setSelectedTown, setEnabledTown, setFkt, setSelectedFkt)
                            }
                            handleKeyDown={(event, town) =>
                                handleKeyDownTown(event, town, selectedDistrict, setTowns, setEnabledTown, setIsLoadingCommune)
                            }
                            handleClearItem={(town) => handleClear(town, ProvinceService.deleteCommune, setTowns)}
                        />
                    </Paper>

                    <Paper elevation={8} className="min-h-[530px]">
                        <ConditionalCard
                            title="fokontany"
                            parentName={'commune'}
                            items={fkt}
                            isLoading={isLoadingFokontany}
                            enabledItem={enabledFkt}
                            selectedItem={selectedFkt}
                            handleAddField={() => handleAddField(setFkt, fkt, { name: '', parent: selectedTown?.id })}
                            handleChange={(event, fk) => handleChange(setFkt, fkt, event, fk)}
                            handleShowItems={(fkt) =>
                                handleShowVillage(fkt, setSelectedFkt, setEnabledFkt, setVillage, setSelectedVillage)
                            }
                            handleKeyDown={(event, fkt) =>
                                handleKeyDownFkt(event, fkt, selectedTown, setFkt, setEnabledFkt, setIsLoadingFokontany)
                            }
                            handleClearItem={(fkt) => handleClear(fkt, ProvinceService.deleteFokontany, setFkt)}
                        />
                    </Paper>
                    <Paper elevation={8} className="min-h-[530px]">
                        <ConditionalCard
                            selectedVillageForGroup={selectedVillageForGroup}
                            title="villages"
                            parentName={'fokontany'}
                            createNursery={createNursery}
                            setCreateNursery={setCreateNursery}
                            items={village}
                            isLoading={isLoadingVillage}
                            setGroupSaved={setGroupSaved}
                            groupSaved={groupSaved}
                            enabledItem={enabledVillage}
                            selectedItem={selectedVillage}
                            handleAddField={() =>
                                handleAddField(setVillage, village, { name: '', parent: selectedFkt?.id })
                            }
                            handleChange={(event, ville) => handleChange(setVillage, village, event, ville)}
                            handleShowItems={(village) => {
                                console.log(village)
                                if (village.id) {
                                    setSelectedVillageForGroup({ ...village, groups: [] })
                                }
                            }}
                            handleKeyDown={(event, vl) =>
                                handleKeyDownVillage(event, vl, selectedFkt, setVillage, setEnabledVillage, setIsLoadingVillage, createNursery, setCreateNursery)
                            }
                            handleClearItem={(vl) => handleClear(vl, ProvinceService.deleteVillages, setVillage)}
                        />
                    </Paper>
                </Masonry>
                <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
            </div>
        </div>
    )
}
