import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
export default function InputPassword(props) {
  const { name, size, label, value, error = null, onChange, ...other } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <TextField
      size={size ? size : "small"}
      variant="outlined"
      type={showPassword ? "text" : "password"}
      label={label}
      name={name}
      value={value}
      onChange={(e) => onChange?.(e)}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
