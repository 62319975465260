import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { ShowPeasant } from './ShowPeasant'
import { useAuth } from '../../../applications/hooks/UseAuth'
import PeasantService from '../services/Peasant'
import ContentView from '../../../components/PageView/Content'
import { PeasantTab } from './PeasantTab'

function PeasantView(props) {
    const { addReturnButton, recordForEdit } = props

    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const peasantService = PeasantService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(true)

    return (
        <>
            <CssBaseline />

            <ContentView
                dataId={recordForEdit ? recordForEdit.code : dataId}
                title={recordForEdit ? '' : 'Détail du paysan'}
                addReturnButton={addReturnButton}
                service={peasantService}
                haveAccessTo={haveAccessTo}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
                Form={ShowPeasant}
                needLoadCode={needLoadCode}
            />
            <ContentView
                dataId={recordForEdit ? recordForEdit.code : dataId}
                // title={`Liste des suivies`}
                service={peasantService}
                haveAccessTo={haveAccessTo}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
                Form={PeasantTab}
                needLoadCode={needLoadCode}
            />
        </>
    )
}

export default PeasantView
