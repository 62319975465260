import React from 'react'
import Table from '../../../../components/DataTable/Table'

const headCells = [
    //{ id: 'site', label: 'Site' },
    { id: 'nursery', label: 'Pépinière' },
    { id: 'species', label: 'Espèces', type: 'object', cell: 'name' },
    { id: 'objective', label: 'Objectif', type: 'Integer' },
    { id: 'quantity', label: 'Quantité achetée', type: 'Integer' },
    // { id: 'quantity', label: 'Quantité', type: 'Integer' },
    // { id: 'unity', label: 'Unité' },
    // { id: 'date_purchase', label: "Date d'achat", type: 'date' },
    {
        id: 'actions',
        type: 'actions',
        label: 'Actions',
        disableSorting: true,
        showEdit: false,
        showRemove: true,
        editPath: '/app/agroforestry/nursery/supply/plant_material/edit'
    }
]

function PlantMaterialList(props) {
    const {
        haveAccessTo,
        service,
        needLoad,
        currentPage,
        Form,
        drawer,
        setNeedLoad,
        filterFromList,
        activeCenterFromList,
        activeCenterComFromList
    } = props

    //display activeCenterComFromList in the console with useEffect
    React.useEffect(() => {
        console.log(filterFromList)
    }, [filterFromList])

    return (
        <>
            <Table
                title="Liste des matières végétales"
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                Form={Form}
                drawer={drawer}
                hideFilterButton={true}
                showSiteButton={false}
                hideToolbar={false}
                filterFromList={filterFromList}
                activeCenterFromList={activeCenterFromList}
                activeCenterComFromList={activeCenterComFromList}
            />
        </>
    )
}

export default PlantMaterialList
