import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Chip, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ConfigEmailService from '../../../../../../../configuration/services/globalConfig'
import { extractValueFromObject } from '../../../../../../../../utils/Util'

const styles = {
    form_title: {
        backgroundColor: 'transparent',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    },
    date_navigate_container: {
        boxShadow: '0px 0px 27px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        marginBottom: '20px'
    },
    arrow: {
        fontSize: 15
    },
    arrow_container: {
        display: 'flex',
        justifyContent: 'end',
        padding: '10px 10px 10px 10px'
    },
    day_button: {
        width: '35px',
        height: '50px',
        fontWeight: 'bold',
        fontSize: '15px',
        justifyContent: 'center',
        '& .MuiChip-label': {
            padding: 0,
            margin: 'auto'
        }
    },
    day_button_container: {
        display: 'flex',
        paddingBottom: '20px',
        gap: '10px'
    }
}

function DateNavigate(props) {
    const {
        semisTrackingSelected,
        setSemisTrackingSelectedDate,
        dateSelected,
        setSpecificSemisTracking,
        specificSemisInitialValues,
        semisTrackingData,
        selectedSpecie,
        setDateSelected
    } = props

    // Obtenir la date d'aujourd'hui
    const today = new Date()

    useEffect(() => {
        if (semisTrackingData) {
            const trackingList = semisTrackingData[`${selectedSpecie}`]
            setTrackingDateList(trackingList)
        }
    }, [semisTrackingData, selectedSpecie])

    const [chipSelectedDate, setChipSelectedDate] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [nows, setNows] = useState()
    const [tracking, setTracking] = useState('')
    const [trackingDateList, setTrackingDateList] = useState(null)
    const [keySelected, setKeySelected] = useState(null)

    // Déterminer le premier jour de la semaine (dimanche)
    const firstDayOfWeek = new Date(today)
    firstDayOfWeek.setDate(today.getDate() - today.getDay())

    const [currentDate, setCurrentDate] = useState(firstDayOfWeek)

    useEffect(() => {
        ConfigEmailService.getByCode('agro_pepiniere')
            .then((users) => {
                if (users) {
                    setNows(users)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [])

    useEffect(() => {
        if (nows && nows.data && nows.data.data) {
            const jsonData = JSON.parse(nows.data.data)
            const value = jsonData['Pépinière'].suivi?.value?.toString()
            console.log('value', value)
            setTracking(value)
        }
    }, [nows])

    const goToPreviousWeek = () => {
        const previousWeek = new Date(currentDate)
        previousWeek.setDate(previousWeek.getDate() - 7)
        setCurrentDate(previousWeek)
    }

    const goToNextWeek = () => {
        const nextWeek = new Date(currentDate)
        nextWeek.setDate(nextWeek.getDate() + 7)
        setCurrentDate(nextWeek)
    }

    const formatDate = (date) => {
        return date.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })
    }

    return (
        <>
            <Container sx={styles.date_navigate_container}>
                <Grid container>
                    <Grid item xs={8}>
                        <Paper
                            sx={{
                                padding: '10px 10px 10px 10px'
                            }}
                        >
                            <Typography sx={styles.form_title}>{formatDate(currentDate)}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper sx={styles.arrow_container}>
                            <IconButton onClick={goToPreviousWeek}>
                                <ArrowBackIos style={styles.arrow} />
                            </IconButton>

                            <IconButton onClick={goToNextWeek}>
                                <ArrowForwardIos style={styles.arrow} />
                            </IconButton>
                        </Paper>
                    </Grid>
                </Grid>
                <Paper sx={styles.day_button_container}>
                    {[...Array(30)].map((_, index) => {
                        const day = new Date(currentDate)
                        day.setDate(day.getDate() + index)
                        const formattedDay = day.toISOString().split('T')[0]

                        const formattedDayName = day.toLocaleDateString('fr-FR', { weekday: 'long' })

                        const nowLowercase = tracking?.toLowerCase()

                        const isTrackingDate = formattedDayName.toLowerCase() === nowLowercase

                        let selected = false
                        let trackedDate = trackingDateList?.find(
                            (item) => item.tracking_date.split('T')[0] === formattedDay
                        )
                        if (formattedDay === selectedDate || trackedDate) {
                            selected = true
                        } else {
                            selected = false
                        }
                        return (
                            <>
                                {isTrackingDate || trackedDate ? (
                                    <Chip
                                        key={index}
                                        sx={{
                                            ...styles.day_button,
                                            border: isTrackingDate ? '4px solid #0fcb8f' : 'none',
                                            backgroundColor:
                                                isTrackingDate && formattedDay === chipSelectedDate
                                                    ? '#0fcb8f'
                                                    : selected
                                                    ? '#0fcb8f'
                                                    : '#ffffff',
                                            color:
                                                isTrackingDate && formattedDay === chipSelectedDate
                                                    ? '#ffffff'
                                                    : !isTrackingDate
                                                    ? '#677788'
                                                    : selected
                                                    ? '#ffffff'
                                                    : '#0fcb8f'
                                        }}
                                        label={day.getDate()}
                                        clickable
                                        onClick={() => {
                                            if (trackedDate) {
                                                setDateSelected(trackedDate)
                                                setKeySelected(index)
                                            } else {
                                                setDateSelected(formattedDay)
                                                setSpecificSemisTracking(specificSemisInitialValues)
                                            }
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </>
                        )
                    })}
                </Paper>
            </Container>
        </>
    )
}

export default DateNavigate
