export const villageModel = {
    label: 'Village',
    tableName: 'village',
    properties: [
        {
            label: 'Fokontany',
            id: 'fokontany',
            isForeignKey: true,
            column_name: 'name',
            table_column: 'parent',
            type: 'text',
            required: true,
        },
        {
            label: 'Nom',
            id: 'name',
            type: 'text',
            required: true,
        },
    ]
}