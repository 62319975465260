import { Http } from '../../../../applications/Http.js'
import Service from '../../../../applications/Service.js'

class InventoryService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/inventory')
        return this
    }

    static async getByCode(id) {
        return await Http.get([this.url, id].join('/'), {})
    }

    static async addInventory(data) {
        try {
            const response = await Http.put(this.url, data)
            return response.data
        } catch (error) {
            throw new Error("Erreur lors de l'ajout de l'inventaire.")
        }
    }

    static async updateInventory(id, data) {
        try {
            const response = await Http.patch([this.url, id].join('/'), data)
            return response.data
        } catch (error) {
            throw new Error("Erreur lors de la mise à jour de l'inventaire.")
        }
    }

    static async getByNurseryIdAndInventoryId(data) {
        try {
            const response = await Http.get([this.url, 'byNurseryId'].join('/'), {data})
            return response
        } catch (error) {
            throw new Error('Erreur lors du chargement des données.')
        }
    }

    static async takeInventory(data) {
        try {
            const response = await Http.put([this.url, 'take'].join('/'), data)
            return response
        } catch (error) {
            
        }
    }

    static async getByNurseryIdDesignation(data){
        try {
            const response = await Http.get([this.url, 'byNurseryIdDesignation'].join('/'), data)
            return response
        } catch (error) {
            throw new Error('Erreur lors du chargement des données.')
        }
    }
}

export default InventoryService
