import React, { useEffect, useRef, useState } from 'react'
import Controls from './Controls'

const containerStyle = {
    width: '100%',
    height: '300px'
}
const greenColor = '#14b67d'

const useStyles = {
    exportButton: {
        backgroundColor: 'white',
        borderColor: greenColor,
        color: greenColor,
        '&:hover': {
            backgroundColor: greenColor,
            color: 'white'
        },
        marginTop: '10px'
    }
}

export default function GpsCoordinatesInput({ data, zoom, inputUse = false, onChange }) {
    const mapRef = useRef(null)
    const classes = useStyles
    const [selectedCoordinates, setSelectedCoordinates] = useState(null)

    useEffect(() => {
        if (mapRef.current && data !== null) {
            const map = new window.google.maps.Map(mapRef.current, {
                center: { lat: data[0].coordinates[0].lat, lng: data[0].coordinates[0].lng },
                zoom: zoom
            })

            data.forEach((item) => {
                const coordinates = item.coordinates.map((coordinate) => ({ lat: coordinate.lat, lng: coordinate.lng }))

                const polyline = new window.google.maps.Polyline({
                    path: coordinates,
                    geodesic: true,
                    strokeColor: 'darkgreen',
                    strokeOpacity: 0.7,
                    strokeWeight: 4
                })
                polyline.setMap(map)

                const polygon = new window.google.maps.Polygon({
                    paths: coordinates,
                    strokeColor: 'darkgreen',
                    strokeOpacity: 0.7,
                    strokeWeight: 4,
                    fillColor: 'green',
                    fillOpacity: 0.35
                })
                polygon.setMap(map)

                item.coordinates.forEach((coordinate) => {
                    new window.google.maps.Marker({
                        position: { lat: coordinate.lat, lng: coordinate.lng },
                        map: map,
                        title: 'Point',
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            fillColor: 'lightgreen',
                            fillOpacity: 1,
                            strokeColor: 'white',
                            strokeWeight: 1,
                            scale: 6
                        }
                    })
                })
            })

            map.addListener('click', (event) => {
                const lat = event.latLng.lat()
                const lng = event.latLng.lng()
                setSelectedCoordinates({ lat, lng })
            })
        }
    }, [data])

    return (
        <div className="mt-[10px]">
            <div ref={mapRef} style={containerStyle}></div>
            {inputUse && (
                <div className="mt-[10px]">
                    <p>Latitude: {selectedCoordinates?.lat || ''}</p>
                    <p>Longitude: {selectedCoordinates?.lng || ''}</p>
                </div>
            )}
        </div>
    )
}
