import React, { useEffect } from 'react'
import Popup from '../../layouts/Popup'
import { Container, Grid } from '@mui/material'
import Controls from '../Entry/Controls'
import { LoadScript } from '@react-google-maps/api'
import Map from '../../components/Entry/Map'
import { useState } from 'react'
import { isValideJSON } from '../../applications/UtilFunctions'



function GridView(props) {
    const { title, openPopup, setOpenPopup, gridData } = props

    const [dataFromBase, setDataFromBase] = useState(null)

    useEffect(() => {
        if (gridData) {
            if (isValideJSON(gridData)) {
                setDataFromBase(JSON.parse(gridData))
            }
        }
    }, [gridData])

    const data = [
        {
            id: 1,
            coordinates: dataFromBase?.coordinates
        }
    ]

    return (
        <>
            <Popup title={title} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <Grid container sx={{ width: '700px' }}>
                    {/* <Grid item xs={5}>
                        <Container>
                            <Controls.Input label="Lieux ou adresse" isDisabled={true} />
                            <Controls.Input label="Latitude (x.y°)" isDisabled={true} />
                            <Controls.Input label="Longitude (x.y°)" isDisabled={true} />
                            <Controls.Input label="Altitude (m)" isDisabled={true} />
                            <Controls.Input label="Précision (m)" isDisabled={true} />
                        </Container>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Container>
                            {dataFromBase && (
                                <LoadScript>
                                    <Map data={data} zoom={10} />
                                </LoadScript>
                            )}
                            {!dataFromBase && (
                                <Container sx={{ textAlign: 'center' }}>
                                    <p>(Aucun quadrillage)</p>
                                </Container>
                            )}
                        </Container>
                    </Grid>
                </Grid>
            </Popup>
        </>
    )
}

export default GridView
