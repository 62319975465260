// import { Http } from '../../../applications/Http.js'
import { Http } from '../../../../applications/Http.js'
import Service from '../../../../applications/Service.js'

class TrackingSemisService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.',
        get_error: 'Erreur lors du chargement des données.'
    }
    static load() {
        this.setUrl('/api/nursery/agro/semis_tracking')
        return this
    }

    
}

export default TrackingSemisService
