import React, { useEffect, useState } from 'react'
import { createContext } from 'react'
import NurseryService from '../../../../services/Nursery'
import Controls from '../../../../../../components/Entry/Controls'

const {Loader} = Controls

export const Context = createContext({})

function NurseryDataContext({ children, dataCode, needLoad, setNeedLoad, recordForEdit}) {
    const nurseryService = NurseryService.load()
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState({})

    useEffect(() => {
        if (dataCode) {
            const getNurseryData = async () => {
                setLoading(true)
                const nurseryData = await nurseryService.getByCode(dataCode)
                if (nurseryData) {
                    setValue({...nurseryData, selectedSpecie: recordForEdit.specie_id})
                    console.log("données pépinières :", nurseryData)
                }
            }
            if (needLoad) {
                getNurseryData()
                setLoading(false)
                setNeedLoad(false)
            }
        }
    }, [dataCode, nurseryService, needLoad, setNeedLoad, recordForEdit])

    return (
        <>
            <Loader isLoading={loading} />
            <Context.Provider value={value}>{children}</Context.Provider>
        </>
    )
}

export default NurseryDataContext
