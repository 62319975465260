import { useState, useEffect } from "react";
import ImportService from "../../services/ImportService";

function useFileReader(inputRef) {
  const [result, setResult] = useState();
  const fileReader = new FileReader();

  useEffect(() => {
    const handler = () => {
      setResult(fileReader.result);
      fileReader.removeEventListener("load", handler);
    };
    fileReader.addEventListener("load", handler);
    return () => {
      fileReader.removeEventListener("load", handler);
    };
  });

  const changeHandler = () => {
    if (inputRef.current) {
      const file = inputRef.current.files[0];
      if (file) {
        fileReader.readAsDataURL(file);
      }
    }
  };

  return [result, changeHandler];
}

export default useFileReader;

export class GridImportApp {
  constructor() {
    this.entity = ''
    this.entity_id = 1
    this.grid_data = null
  }

  setEntity(entity) {
    this.entity = entity
  }

  setEntityId(id) {
    this.entity_id = id
  }

  setGridData(data) {
    this.grid_data = data
  }

  getEntity() {
    return this.entity
  }

  getEntityId() {
    return this.entity_id
  }

  getGridData() {
    return this.grid_data
  }

  async saveGridData() {
    console.log(this)
    const service = ImportService.load()
    const result = await service.importGrid(this)
    return result
  }
}