import { Container, Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Controls from '../../../../../../components/Entry/Controls'
import { Form, useForm } from '../../../../../../applications/hooks/UseForm'
import MultipleImageInput from '../../../../../../components/Entry/MultipleImageInput'
import { Context } from '../../../utils/PlantationContext'
import PlantationsMonitoring from '../../../../services/PlantationsMonitoring'
import { addOrEdit } from '../../../../../../applications/UtilFunctions'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../../applications/hooks/UseAuth'

const initialValues = {
    plantation_id: '',
    monitoring_date: new Date().toISOString(),
    number_living_tree: '',
    regarnish_to_do: '',
    maintenance_to_do: '',
    pedagogical_problem: '',
    pressions: '',
    maintenance_carried_out: '',
    photos: [],
    technicien: ''
}
function MonitoringPlantationForm() {
    const plantationData = useContext(Context)
    const service = PlantationsMonitoring.load()
    const navigate = useNavigate()
    const { user } = useAuth()
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('monitoring_date' in fieldValues)
            temp.monitoring_date = fieldValues.monitoring_date ? '' : 'Ce champ est requis.'
        if ('number_living_tree' in fieldValues)
            temp.number_living_tree = fieldValues.number_living_tree ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })
        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialValues,
        true,
        validate
    )

    useEffect(() => {
        if (plantationData) {
            setValues({
                ...values,
                plantation_id: plantationData?.id,
                technicien: `${user?.firstname} ${user?.lastname}`
            })
        }
    }, [plantationData, setValues, values, user])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validate()) {
            const result = await addOrEdit(
                values,
                resetForm,
                service,
                plantationData?.setNeedLoad,
                setUserHaveToPost,
                null
            )
            if (result) navigate(-1)
            else setUserHaveToPost(false)
        }
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Container style={{ padding: '20px' }}>
                <Grid container>
                    <Grid item xs={6}>
                        <Container>
                            {/* <Controls.AutoCompleteSelect
                                label="Plantation"
                                name="plantation_id"
                                value={''}
                                onChange={handleInputChange}
                                options={[]}
                                labelKey="label"
                                valueKey="label"
                                error={errors?.plantation_id}
                            /> */}
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                name="plantation_id"
                                value={plantationData?.code}
                                size="small"
                                label="Plantation"
                                onChange={handleInputChange}
                                error={errors?.plantation_id}
                                isDisabled={true}
                            />
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                name="number_living_tree"
                                value={values?.number_living_tree}
                                size="small"
                                label="Nombre d'arbre vivants"
                                onChange={handleInputChange}
                                type="number"
                                error={errors?.number_living_tree}
                                isRequired={true}
                            />
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                name="maintenance_to_do"
                                value={values?.maintenance_to_do}
                                size="small"
                                label="Entretien à faire"
                                onChange={handleInputChange}
                                type="text"
                                error={errors?.maintenance_to_do}
                            />
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                name="pedagogical_problem"
                                value={values?.pedagogical_problem}
                                size="small"
                                label="Problèmes pédologiques"
                                onChange={handleInputChange}
                                type="text"
                                error={errors?.pedagogical_problem}
                            />
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                name="maintenance_carried_out"
                                value={values?.maintenance_carried_out}
                                size="small"
                                label="Entretiens effectués"
                                onChange={handleInputChange}
                                type="text"
                                error={errors?.maintenance_carried_out}
                            />
                        </Container>
                    </Grid>
                    <Grid item xs={6}>
                        <Container>
                            <Controls.DatePicker
                                id="outlined-disabled"
                                label="Date de suivi"
                                // variant="outlined"
                                name="monitoring_date"
                                value={values?.monitoring_date || new Date()}
                                size="small"
                                onChange={handleInputChange}
                                type="date"
                                error={errors?.monitoring_date}
                                isRequired={true}
                            />
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                name="regarnish_to_do"
                                value={values?.regarnish_to_do}
                                size="small"
                                label="Regarnissage à faire"
                                onChange={handleInputChange}
                                type="number"
                                error={errors?.regarnish_to_do}
                            />
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                name="remarks"
                                value={values?.remarks}
                                size="small"
                                label="Remarque sur la parcelle"
                                onChange={handleInputChange}
                                type="text"
                                error={errors?.remarks}
                            />
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                name="pressions"
                                value={values?.pressions}
                                size="small"
                                label="Pressions"
                                onChange={handleInputChange}
                                type="text"
                                error={errors?.pressions}
                            />
                            <MultipleImageInput
                                label="Photos"
                                onChange={handleInputChange}
                                name="photos"
                                value={values?.photos}
                            />
                        </Container>
                    </Grid>
                </Grid>
                <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Controls.Button
                        type="submit"
                        text="Enregistrer"
                        size={'large'}
                        haveSpin={true}
                        disabled={userHaveToPost}
                    />
                </Container>
            </Container>
        </Form>
    )
}

export default MonitoringPlantationForm
