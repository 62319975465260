import moment from 'moment'
import React from 'react'

export default class Format {
    static formatDate(date) {
        return moment(date).format('DD/MM/YYYY')
    }

    static formatYear(date) {
        return moment(date).format('YYYY')
    }

    static calculAgeWithDob(date) {
        return moment().diff(date, 'years')
    }

    static formatMadagascarNumber(number) {
        
        console.log(number)
        let formatedNumber = ''
        number && (formatedNumber = number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
        return formatedNumber
  }
}
