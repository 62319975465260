import React from 'react'
import Table from '../../../../../../components/DataTable/Table'

const headCells = [
    { id: 'specie', label: 'Espèce' },
    {
        id: 'target',
        label: 'Objectif',
        type: 'Integer',
    },
    { id: 'sown', label: 'Semés' },
    { id: 'number_living_tree', label: 'Plants vivants' },
    { id: 'number_plant_ready', label: 'Plants reboisables' },
    { id: 'number_plant_output', label: 'Plants sortis' },
    { id: 'number_plant_remaining', label: 'Plants restants' },
    {
        id: 'actions',
        type: 'actions',
        label: 'Actions',
        disableSorting: true,
        showEdit: false,
        showRemove: true,
        identifyer: true,
        showView: '/app/agroforestry/nursery/view',
        supplementData: "specie_id",
    }
]
function NurseryList(props) {
    const {
        haveAccessTo,
        service,
        needLoad,
        currentPage,
        Form,
        drawer,
        setNeedLoad,
        filterFromList,
        activeCenterFromList,
        activeCenterComFromList
    } = props
    return (
        <>
            <Table
                title="Liste des pépinières"
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                drawer={drawer}
                Form={Form}
                hideFilterButton={true}
                hideToolbar={false}
                filterFromList={filterFromList}
                activeCenterFromList={activeCenterFromList}
                activeCenterComFromList={activeCenterComFromList}
            />
        </>
    )
}

export default NurseryList
