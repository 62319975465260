import React from 'react'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { Container, Paper } from '@mui/material'

const styles = {
    input: {
        width: '100%',
        height: '100%'
    },
    container: {
        width: '100%',
        height: '200px',
        border: 'none',
        borderRadius: '10px',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 27px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column'
    },

    labelInput: {
        fontSize: '12px',
        padding: `0 0 10px 0`
    },
    containerTextArea: {
        flex: '1',
        width: '100%',
        height: '100%',
        border: '1px solid #E5E5E5',
        borderRadius: '10px',
        overflow: 'hidden',
        '& textarea': {
            width: '100%',
            resize: 'none',
            border: 'none',
            outline: 'none',
            padding: '10px',
            boxSizing: 'border-box'
        }
    }
}

function TextInput(props) {
    const { label, value, isDisabled } = props
    return (
        <div style={styles.container}>
            <Container style={styles.labelInput}>{label}</Container>
            <Paper sx={styles.containerTextArea}>
                <TextareaAutosize onChange={() => {}} value={value} disabled={isDisabled} />
            </Paper>
        </div>
    )
}

export default TextInput
