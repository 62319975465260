import React, { useEffect, useState } from 'react'
import { useAuth } from '../../applications/hooks/UseAuth'
import { useNavigate } from 'react-router-dom'
import PageHeader from '../../components/Typography/PageHeader'
import { BottomNavigation, BottomNavigationAction, Toolbar as MuiToolbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ActionsService from './services/Actions'
import HistoriesList from './components/listView/HistoryList'
import HistoryService from './services/Historics'

const useStyles = makeStyles((theme) => ({
    siteChange: {
        '& .MuiBottomNavigation-root': {
            height: '50px',
            borderRadius: 10,
            borderColor: '#0FCB8F',
            borderWidth: 2,
            background: '#43D5A7'
        },
        '& .MuiBottomNavigationAction-root': {
            background: '#0FCB8F',
            minWidth: '18vw'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            borderRadius: 10,
            background: 'none'
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        }
    }
}))

const CURRENT_PAGE = 'administration_species'

function Historics() {
    const { haveAccessTo } = useAuth()
    const classes = useStyles()
    const navigate = useNavigate()
    const useDrawer = false
    const service = ActionsService
    const historyService = HistoryService
    const [value, setValue] = useState(1)
    const [filter, setFilter] = useState({})
    const [needLoad, setNeedLoad] = useState(true)
    const [actions, setActions] = useState([])

    useEffect(() => {
        service.load()
        service
            .getAll()
            .then((res) => {
                if (res) {
                    console.log(res.data)
                    setActions(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const handleChangePage = (event, newValue) => {
        console.log(newValue)
        setValue(newValue)
        setNeedLoad(true)
        // setFilter({ action_id: newValue })
        setFilter(actions.find((item) => item.id === newValue))
    }

    return (
        <>
            <PageHeader title={'Historiques'} subTitle={'Gestion des historiques'} />
            <MuiToolbar style={{ padding: 0, margin: '20px 0 0 0' }} className={classes.siteChange}>
                <BottomNavigation showLabels value={value} onChange={handleChangePage}>
                    {actions.map((action) => {
                        return <BottomNavigationAction key={action.id} label={action.name} value={action.id} />
                    })}
                </BottomNavigation>
            </MuiToolbar>
            <HistoriesList
                filter={filter}
                service={historyService}
                needLoad={needLoad}
                drawer={useDrawer}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                currentPage={CURRENT_PAGE}
            />
        </>
    )
}

export default Historics
