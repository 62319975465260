import { Http } from '../../../../applications/Http'
import Service from '../../../../applications/Service'
import moment from 'moment'

class PlatDrainTrackingService extends Service {
    static messages = {
        update_success: 'Modification Paysans avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création Paysans avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/plat_drain/tracking')
        return this
    }

    static async getPlatDrainById(id) {
        return await Http.get([this.url, 'byId', id].join('/'), [])
    }

    static async getByCode(id) {
        return await Http.get([this.url, id].join('/'), {})
    }

    static async addPlatDrainTracking(data) {
        try {
            data.date = moment(data.date).format()

            const response = await Http.put(this.url, data)
            return response.data
        } catch (error) {
            throw new Error("Erreur lors de l'ajout .")
        }
    }

    static async updatePlatDrainTracking(id, data) {
        try {
            data.date = moment(data.date).format()
            const response = await Http.patch([this.url, id].join('/'), data)
            return response.data
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour .')
        }
    }
}

export default PlatDrainTrackingService
