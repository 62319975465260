import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import ContentView from '../../../../components/PageView/Content'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import OutingService from '../services/outing'
import { OutingForm } from './OutingForm'
import MangroOutingContext from '../utils/hooks/MangroOutingContext'

function OutingView(props) {
    const { addReturnButton, recordForEdit } = props

    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const outingService = OutingService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(true)
    const [userHaveToAdd, setUserHaveToAdd] = useState(false)
    useEffect(() => {
        if (location?.state?.userHaveToAdd) setUserHaveToAdd(location?.state?.userHaveToAdd)
        else setUserHaveToAdd(false)
    }, [location])

    console.log('location', location)

    return (
        <>
            <CssBaseline />

            <MangroOutingContext userHaveToAdd={userHaveToAdd}>
                <ContentView
                    dataId={recordForEdit ? recordForEdit.id : dataId}
                    title={` Sortie en pépinière `}
                    service={outingService}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={OutingForm}
                    addReturnButton={addReturnButton}
                    needLoadCode={needLoadCode}
                />
            </MangroOutingContext>
        </>
    )
}

export default OutingView
