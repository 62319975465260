import React, { useState } from 'react'
import Controls from './Controls'
import { FaTrashAlt } from 'react-icons/fa'

const useStyles = {
    labelInput: {
        fontSize: '12px'
    },
    labelContainer: {
        marginBottom: '20px',
        width: '100%',
        display: 'block'
    },
    thumbnail: {
        width: '100px',
        height: '100px',
        objectFit: 'cover'
    },
    thumbnailContainer: {
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        height: '120px',
        border: '1px solid #ccc',
        padding: '10px',
        width: '100%',
        borderRadius: '5px',
        overflow: 'hidden',
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
        scrollbarColor: '#d0e0d7 #E7F2EE00',
        '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
            background: '#d0e0d7'
        }
    },
    removeButton: {
        display: 'block',
        marginTop: '5px',
        cursor: 'pointer'
    }
}

function MultipleImageInput({ label, onChange, name, setPhoto }) {
    const classes = useStyles
    const [photoValues, setPhotoValues] = useState([])

    const handleAddPhoto = (event) => {
        const files = Array.from(event.target.files)
        const newPhotoValues = []

        files.forEach((file) => {
            const reader = new FileReader()
            reader.onloadend = () => {
                newPhotoValues.push(reader.result)
                setPhotoValues((prevValues) => {
                    const updatedValues = [...prevValues, ...newPhotoValues]
                    setPhoto && setPhoto(updatedValues)
                    if (onChange) {
                        onChange({
                            target: {
                                name,
                                value: updatedValues
                            }
                        })
                    }
                    return updatedValues
                })
            }
            reader.readAsDataURL(file)
        })
    }

    const handleRemovePhoto = (index) => {
        const newPhotoValues = photoValues.filter((_, i) => i !== index)
        setPhotoValues(newPhotoValues)
        setPhoto && setPhoto(newPhotoValues)
        if (onChange) {
            onChange({
                target: {
                    name,
                    value: newPhotoValues
                }
            })
        }
    }

    return (
        <div style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <div style={classes.labelInput}>{label}</div>
                <input
                    type="file"
                    accept="image/*"
                    multiple
                    style={{ display: 'none' }}
                    id="photo-upload"
                    onChange={handleAddPhoto}
                />
                <label htmlFor="photo-upload">
                    <Controls.Button text="Ajouter" size="small" component="span" />
                </label>
            </div>
            <div style={classes.thumbnailContainer}>
                {photoValues.map((photo, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Controls.CustomImageAvatar src={photo} alt={`Preview ${index}`} size={70} />
                        <FaTrashAlt
                            style={{ cursor: 'pointer', color: 'red', alignSelf: 'center', marginTop: '3px' }}
                            onClick={() => handleRemovePhoto(index)}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MultipleImageInput
