import { makeStyles } from '@mui/styles'

const greenColor = '#14b67d'

export const UseStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        color: theme.palette.text.secondary,
        boxShadow: 'none'
    },

    paperTextField: {
        TextField: {
            backgroundColor: '#ffffff'
        }
    },
    titleGps: {
        padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0 `,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#677788',
        fontSize: '0.8rem'
    },
    dateTime: {
        padding: `${theme.spacing(3)}`,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        fontSize: '0.8rem'
    },
    exportButton: {
        backgroundColor: 'white',
        borderColor: greenColor,
        color: greenColor,
        '&:hover': {
            backgroundColor: greenColor,
            color: 'white'
        }
    },
    monitoringTable: {
        padding: `0 ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(2)}`,
        color: theme.palette.text.secondary,
        boxShadow: 'none'
    },
    titleMonitoring: {
        padding: `${theme.spacing(3)}`,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        fontSize: '0.8rem'
    },
    title: {
        padding: `${theme.spacing(2)}`,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    },
    vegetationContent: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        fontWeight: 'normal',
        fontSize: '10px'
    },
    photoContainer: {
        padding: `0 ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold',
        '& .MuiTypography-root': {
            padding: theme.spacing(2)
        },
        '& .MuiCardMedia-root': {
            padding: theme.spacing(2)
        }
    },
    coordinateContainer: {
        paddingTop: '20px',
        display: 'grid',
        '& .MuiButtonBase-root': {
            width: '150px',
            marginTop: '35px',
            marginLeft: 'auto'
        }
    },
    addPhoto: {
        width: 70,
        height: 70,
        borderRadius: '5px',
        color: '#c5c4c4',
        backgroundColor: '#ececec',
        '&:hover': {
            backgroundColor: '#c4c4c4',
            cursor: 'pointer',
        },
    }
}))
