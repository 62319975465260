import React from 'react'
import TableDetail from '../../../../../../../../components/DataTable/TableDetail'

const headCells = [
    { id: 'getout_date', label: 'date de plantation', type: 'date' },
    { id: 'quantity', label: 'Nombre de jeunes plants sorties' },
    {
        id: 'plot',
        label: "Parcelle d'affectation",
        link: 'true',
        showView: { link: '/app/agroforestry/plots/view', param: 'plot' }
    }
]

function NurseryTable(props) {
    const { semisDataGetOutSelected } = props
    const handleOnDataDelete = () => {}

    return (
        <>
            <TableDetail
                headCells={headCells}
                hideFilterButton={true}
                onDataDelete={handleOnDataDelete}
                data={semisDataGetOutSelected?.dataTable}
            />
        </>
    )
}

export default NurseryTable
