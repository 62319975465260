import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, useForm } from '../../../applications/hooks/UseForm'
import Controls from '../../../components/Entry/Controls'
import Notification from '../../../layouts/Notification'

import CustomerOrderService from '../services/CustomerOrder'
import DataSetsService from '../../datasets/services/DataSets'

const { Input: TextField } = Controls

const initialFValues = {
    firstname: '',
    lastname: '',
    state: '',
    email: '',
    place: '',
    phone: ''
}

export const AddCustomer = (props) => {
    const { setOpenPopup, setNeedLoad } = props

    const dataset = DataSetsService.load()

    const [dataState, setDataState] = useState([])
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    useEffect(() => {
        setNeedLoad(true)
        dataset
            .getDataCode('statut_customer')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    console.log(dataArray)
                    setDataState(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
            .finally(() => {
                setNeedLoad(false)
            })
    }, [])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('firstname' in fieldValues) temp.firstname = fieldValues.firstname ? '' : 'Ce champ est requis.'
        if ('state' in fieldValues) temp.state = fieldValues.state ? '' : 'Ce champ est requis.'
        if ('email' in fieldValues) temp.email = fieldValues.email ? '' : 'Ce champ est requis.'
        if ('place' in fieldValues) temp.place = fieldValues.place ? '' : 'Ce champ est requis.'
        if ('phone' in fieldValues) temp.phone = fieldValues.phone ? '' : 'Ce champ est requis.'

        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate)

    const handleSubmit = async (e) => {
        setUserHaveToPost(true)
        console.log(values)
        e.preventDefault()
        if (validate()) {
            const dataToSend = { ...values }
            let customer = null
            let message = ''
            customer = await CustomerOrderService.addCustomerOrder(dataToSend)
            message = CustomerOrderService.messages.create_success
            if (customer) {
                setUserHaveToPost(false)
                setOpenPopup(false)
                setNeedLoad(true)
                new Notification(message, 1)
            } else {
                setUserHaveToPost(false)
                new Notification(message, 0)
            }
        }
    }

    const handleChange = (e) => {
        handleInputChange(e)
        setValues((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <Form onSubmit={handleSubmit}>
            <div className="flex gap-4">
                <TextField
                    id="outlined-disabled"
                    label="Raison sociale"
                    name="firstname"
                    value={values?.firstname || ''}
                    size="small"
                    onChange={handleChange}
                    error={errors?.firstname}
                    isRequired={true}
                />
                <Controls.AutoCompleteSelect
                    name="state"
                    label="Statut"
                    options={dataState}
                    labelKey={'label'}
                    valueKey={'label'}
                    value={values.state}
                    error={errors.state}
                    isRequired={true}
                    onChange={handleChange}
                />
            </div>
            <div className="flex gap-4">
                <TextField
                    id="outlined-disabled"
                    label="Téléphone"
                    name="phone"
                    value={values?.phone || ''}
                    size="small"
                    onChange={handleChange}
                    error={errors?.phone}
                    isRequired={true}
                />
                <TextField
                    id="outlined-disabled"
                    label="Email"
                    name="email"
                    value={values?.email || ''}
                    size="small"
                    isRequired={true}
                    onChange={handleChange}
                    error={errors?.email}
                />
            </div>
            <div className="flex gap-4">
                <TextField
                    id="outlined-disabled"
                    label="Adresse / Pays"
                    name="place"
                    value={values?.place || ''}
                    size="small"
                    onChange={handleChange}
                    error={errors?.place}
                    isRequired={true}
                />
            </div>
            <Controls.Button type="submit" text="Enregistrer" haveSpin={true} disabled={userHaveToPost} />
        </Form>
    )
}
