import React, { useEffect, useState } from 'react'
import { Toolbar as MuiToolbar } from '@mui/material'
import Controls from '../../../components/Entry/Controls'
import { makeStyles } from '@mui/styles'
import ProvinceService from '../services/Provinces'
import { useAuth } from '../../../applications/hooks/UseAuth'
import UserService from '../../users/services/User'

const useStyles = {
    NavigateProvince: {
        borderRadius: 10,
        borderColor: '#0FCB8F',
        borderWidth: 2,
        background: '#FFF',
        width: '100%',
        fontSize: 15,
        padding: '3px 6px 2px 5px'
    },
    toolBarContainer: {
        marginBottom: '10px',
        minHeight: 'auto'
    }
}

function ProvinceFilter(props) {
    const { filter, setFilter, setNeedLoad, needSite = false, width = '40%', hideTitle = true } = props
    const { user } = useAuth()
    const classes = useStyles

    const userService = UserService.load()
    const province = ProvinceService.load()

    const [provinceData, setProvinceData] = useState(null)
    const [regionsList, setRegionsList] = useState(null)
    const [districtList, setDistrictList] = useState(null)
    const [communeList, setCommuneList] = useState(null)
    const [fktList, setFktList] = useState(null)
    const [villageList, setVillageList] = useState(null)

    const [selectedRegion, setSelectedRegion] = useState(null)
    const [selectedDistrict, setSelectedDistrict] = useState(null)
    const [selectedCommune, setSelectedCommune] = useState(null)
    const [selectedFkt, setSelectedFkt] = useState(null)
    const [selectedVillage, setSelectedVillage] = useState(null)
    const [isUserRegions, setIsUserRegions] = useState(false)

    useEffect(() => {
        userService.getUserRegion(user.id).then((response) => {
            // new Notification(`${JSON.stringify(response)}`, 1)
            if (response) {
                setProvinceData([response.region])
                setRegionsList([response.region])
                setDistrictList(response.region.District)
                setIsUserRegions(true)
                setCommuneList(null)
                setFktList(null)
                setVillageList(null)
                setSelectedRegion(response.region.id)
                setSelectedDistrict(null)
                setSelectedCommune(null)
                setSelectedFkt(null)
                setSelectedVillage(null)
            } else {
                province.getProvinces().then((response) => {
                    if (response?.data) {
                        setProvinceData(response.data)
                        setRegionsList(response.data.regions)
                        setDistrictList(null)
                        setCommuneList(null)
                        setFktList(null)
                        setVillageList(null)
                        setSelectedRegion(null)
                        setSelectedDistrict(null)
                        setSelectedCommune(null)
                        setSelectedFkt(null)
                        setSelectedVillage(null)
                    }
                })
            }
        })
        // Récupérer les sites
    }, [])

    useEffect(() => {
        delete filter.district
        delete filter.commune
        delete filter.fkt
        delete filter.village
        setCommuneList(null)
        setFktList(null)
        setVillageList(null)
        setSelectedDistrict(null)
        setSelectedCommune(null)
        setSelectedFkt(null)
        setSelectedVillage(null)

        if (selectedRegion)
            setFilter({
                ...filter,
                region: selectedRegion
            })
        else delete filter.region
        setNeedLoad(true)
    }, [districtList])

    useEffect(() => {
        delete filter.commune
        delete filter.fkt
        delete filter.village
        setFktList(null)
        setVillageList(null)
        setSelectedCommune(null)
        setSelectedFkt(null)
        setSelectedVillage(null)

        if (selectedDistrict)
            setFilter({
                ...filter,
                district: selectedDistrict
            })
        else delete filter.district
        setNeedLoad(true)
    }, [communeList])

    useEffect(() => {
        delete filter.fkt
        delete filter.village

        setVillageList(null)
        setSelectedFkt(null)
        setSelectedVillage(null)

        if (selectedCommune)
            setFilter({
                ...filter,
                commune: selectedCommune
            })
        else delete filter.commune
        setNeedLoad(true)
    }, [fktList])

    useEffect(() => {
        delete filter.village
        setSelectedVillage(null)

        if (selectedFkt)
            setFilter({
                ...filter,
                fkt: selectedFkt
            })
        else delete filter.fkt
        setNeedLoad(true)
    }, [villageList])

    useEffect(() => {
        if (selectedVillage)
            setFilter({
                ...filter,
                village: selectedVillage
            })
        else delete filter.village
        setNeedLoad(true)
    }, [selectedVillage])

    const handleSelectedRegion = (event) => {
        setDistrictList(provinceData.regions.find((row) => row.id === event.target.value)?.District || null)
        setSelectedRegion(event.target.value || null)
    }

    const handleSelectedDistrict = (event) => {
        setCommuneList(districtList.find((row) => row.id === event.target.value)?.Commune || null)
        setSelectedDistrict(event.target.value)
    }

    const handleSelectedCommune = (event) => {
        setFktList(communeList.find((row) => row.id === event.target.value)?.Fokontany || null)
        setSelectedCommune(event.target.value)
    }

    const handleSelectedFkt = (event) => {
        setVillageList(fktList.find((row) => row.id === event.target.value)?.Village || null)
        setSelectedFkt(event.target.value)
    }

    const handleSelectedVillage = (event) => {
        setSelectedVillage(event.target.value)
    }
    return (
        <MuiToolbar style={{ padding: 0, ...classes.toolBarContainer, width: width }}>
            <div className="flex flex-row gap-4" style={classes.NavigateProvince}>
                <Controls.AutoCompleteSelect
                    name="region"
                    label={'Régions'}
                    options={regionsList || []}
                    labelKey={'name'}
                    valueKey={'id'}
                    value={selectedRegion}
                    onChange={(e) => handleSelectedRegion(e)}
                    isDisabled={isUserRegions ? true : false}
                    hideTitle={hideTitle}
                />
                <Controls.AutoCompleteSelect
                    name="district"
                    label={'Districts'}
                    options={districtList || []}
                    labelKey={'name'}
                    valueKey={'id'}
                    value={selectedDistrict}
                    onChange={(e) => handleSelectedDistrict(e)}
                    isDisabled={districtList ? false : true}
                    hideTitle={hideTitle}
                />
                {needSite && (
                    <>
                        <Controls.AutoCompleteSelect
                            name="commune"
                            label={'Communes'}
                            options={communeList || []}
                            labelKey={'name'}
                            valueKey={'id'}
                            value={selectedCommune}
                            onChange={(e) => handleSelectedCommune(e)}
                            isDisabled={communeList ? false : true}
                            hideTitle={hideTitle}
                        />
                        <Controls.AutoCompleteSelect
                            name="fkt"
                            label={'Fokontany'}
                            options={fktList || []}
                            labelKey={'name'}
                            valueKey={'id'}
                            value={selectedFkt}
                            onChange={(e) => handleSelectedFkt(e)}
                            isDisabled={fktList ? false : true}
                            hideTitle={hideTitle}
                        />
                        <Controls.AutoCompleteSelect
                            name="village"
                            label={'Villages'}
                            options={villageList || []}
                            labelKey={'name'}
                            valueKey={'id'}
                            value={selectedVillage}
                            onChange={(e) => handleSelectedVillage(e)}
                            isDisabled={villageList ? false : true}
                            hideTitle={hideTitle}
                        />
                    </>
                )}
            </div>
        </MuiToolbar>
    )
}

export default ProvinceFilter
