import React, { useState } from 'react'
import { useAuth } from '../../../applications/hooks/UseAuth'
import { useLocation } from 'react-router-dom'
import ContentView from '../../../components/PageView/Content'
import ImportContent from './pageView/ImportContent'
import { dataModels } from '../utils/data/Data'

function DataImport() {
    const haveAccessTo = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const [addReturnButton, setAddReturnButton] = useState(false)
    return (
        <>
            <ContentView
                title={`Importation de données`}
                needLoad={needLoad}
                haveAccessTo={haveAccessTo}
                setNeedLoad={setNeedLoad}
                addReturnButton={addReturnButton}
                Form={ImportContent}
            />
        </>
    )
}

export default DataImport
