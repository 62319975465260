import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material'

export default function Select(props) {
    const { name, label, value, error = null, onChange, options, other } = props

    return (
        <FormControl variant="outlined" {...(error && { error: true })} fullWidth>
            <InputLabel>{label}</InputLabel>
            <MuiSelect label={label} name={name} value={value} size="small" onChange={onChange} {...other}>
                <MenuItem value="">Aucun</MenuItem>
                {options.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.title}
                    </MenuItem>
                ))}
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
