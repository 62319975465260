import React, { useEffect, useState } from 'react'
import { Typography, Paper, Grid, Container } from '@mui/material'
import Controls from '../../../../../../../components/Entry/Controls'

const styles = {
    container_info: {
        padding: '3px 3px 3px 3px'
    },
    list_param: { color: '#748291', fontSize: '0.6rem', paddingBottom: '0.5rem' },
    container_chart: {
        padding: '3px 3px 3px 3px',
        backgroundColor: '#f8f8f8',
        borderRadius: '0.5rem'
    }
}

const values = [
    { label: 'Brugiera gymnorrhiza', percentage: '10%' },
    { label: 'Sonneratia alba', percentage: '20%' },
    { label: 'Ceriops tagal', percentage: '40%' }
]

function CanopeCoverage(props) {
    const { transectData } = props
    const [data, setData] = useState({ values: [], moyenne: 0 })

    useEffect(() => {
        if (transectData) {
            console.log(transectData)
            setData(transectData)
        }
    }, [transectData, data])
    return (
        <>
            <Paper
                sx={{
                    padding: '0.4rem',
                    borderRadius: '0.5rem',
                    display: 'inline-flex',
                    width: '100%',
                    backgroundColor: '#f8f8f8'
                }}
            >
                <Grid container>
                    <Container style={styles.container_info}>
                        <Container style={{ ...styles.container_info, paddingBottom: '0.5rem' }}>
                            <Typography
                                variant="p"
                                sx={{ color: '#748291', fontSize: '0.65rem', fontWeight: 'initial' }}
                            >
                                Couverture canopé <i style={{ fontSize: '0.56rem', color: '#748291' }}>(%)</i>
                            </Typography>
                        </Container>
                        {data && (
                            <Container style={styles.container_info}>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Controls.CustomCircularPercentage
                                            value={parseFloat(data?.moyenne)}
                                            label={'Moyenne'}
                                            size={90}
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Grid container>
                                            {data?.values?.map((value, index) => (
                                                <Grid
                                                    item
                                                    xs={6}
                                                    style={{
                                                        borderRight:
                                                            index % 2 === 1
                                                                ? 'none'
                                                                : data.values.length !== 1
                                                                ? '1px solid #748291'
                                                                : 'none'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            ...styles.container_info,
                                                            paddingBottom: '0.5rem',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="p"
                                                            sx={{
                                                                color: '#748291',
                                                                fontSize: '0.65rem',
                                                                fontWeight: 'initial'
                                                            }}
                                                        >
                                                            {value.name}
                                                        </Typography>
                                                        <Container sx={{ paddingTop: '0.5rem' }}>
                                                            <Typography
                                                                variant="p"
                                                                sx={{
                                                                    color: '#748291',
                                                                    fontWeight: 'initial',
                                                                    fontSize: '0.8rem'
                                                                }}
                                                            >
                                                                {[value.percentage, '%'].join('')}
                                                            </Typography>
                                                        </Container>
                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                        )}
                    </Container>
                </Grid>
            </Paper>
        </>
    )
}

export default CanopeCoverage
