import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { useAuth } from '../applications/hooks/UseAuth'

const RequireAuth = () => {
    //const token = useSelector((state) => state.auth.token)
    const location = useLocation()
    // const reloadToken = true
    const { token, isLoggedIn } = useAuth(/* reloadToken */)
    return (
        token
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    )
}
export default RequireAuth