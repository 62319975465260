import React, { useState, useEffect } from 'react'
import { Container, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useForm, Form } from '../../../../applications/hooks/UseForm'
import Controls from '../../../../components/Entry/Controls'
import Notification from '../../../../layouts/Notification'
import PlanterService from '../../services/Planter'
import ProvinceService from '../../../provinces/services/Provinces'
import DataSetsService from '../../../datasets/services/DataSets'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import VillageAutoCompleteSection from '../../../provinces/components/VillageAutoCompleteSection'
import category from '../../../../applications/constants/data'

const { Input: TextField, Button, CustomSelect } = Controls

const initialFValues = {
    firstname: '',
    lastname: '',
    phone: '',
    genre: '',
    group_id: '',
    member_since: new Date().toISOString(),
    dob: new Date().toISOString(),
    collaboration_beginning: new Date().toISOString(),
    martial_status: '',
    activity: '',
    address: '',
    photo: '',
    cin_number: ''
}

const styles = {
    form_title: {
        padding: `10px 0px 20px 0px `,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    }
}

const PlanterForm = (props) => {
    const navigate = useNavigate()
    const initialOption = []
    const [regions, setRegions] = useState(initialOption)
    const [selectedRegion, setSelectedRegion] = useState(null)

    const [districts, setDistricts] = useState(initialOption)
    const [selectedDistrict, setSelectedDistrict] = useState(null)

    const [communes, setCommunes] = useState(initialOption)
    const [selectedCommune, setSelectedCommune] = useState(null)

    const [villages, setVillages] = useState(initialOption)
    const [selectedVillage, setSelectedVillage] = useState(null)

    const [fkts, setFkts] = useState(initialOption)
    const [selectedFkt, setSelectedFkt] = useState(null)

    const [sites, setSites] = useState(initialOption)
    const [selectedSite, setSelectedSite] = useState(null)

    const [groups, setGroups] = useState(initialOption)

    const [genreOption, setGenreOption] = useState([])
    const [activitiesOption, setActivitiesOption] = useState([])
    const [martialStatusOption, setMartialStatusOption] = useState([])
    const [planterCategoryOption, setPlanterCategoryOption] = useState([])
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [photo, setPhoto] = useState(null)
    const { user } = useAuth()

    const ProvinceApi = ProvinceService.load()
    const dataset = DataSetsService.load()

    useEffect(() => {
        ProvinceApi.getRegions().then((res) => {
            setRegions(res?.data)
        })
        dataset
            .getDataCode('genre')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setGenreOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
        dataset
            .getDataCode('peasant_activity')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setActivitiesOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
        dataset
            .getDataCode('martial_status')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setMartialStatusOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })

        dataset
            .getDataCode('planter_category')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setPlanterCategoryOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [])

    useEffect(() => {
        if (selectedRegion)
            ProvinceApi.getRegionsChild(selectedRegion).then((res) => {
                setDistricts(res?.data)
            })
    }, [selectedRegion])

    useEffect(() => {
        if (selectedDistrict)
            ProvinceApi.getDistrictChild(selectedDistrict).then((res) => {
                setCommunes(res?.data)
            })
    }, [selectedDistrict])

    useEffect(() => {
        if (selectedCommune)
            ProvinceApi.getCommuneChild(selectedCommune).then((res) => {
                setFkts(res?.data)
            })
    }, [selectedCommune])

    useEffect(() => {
        if (selectedFkt)
            ProvinceApi.getFokontanyChild(selectedFkt).then((res) => {
                setVillages(res?.data)
            })
    }, [selectedFkt])

    useEffect(() => {
        if (selectedVillage)
            ProvinceApi.getVillages(selectedVillage).then((res) => {
                setSites(res?.data)
            })
    }, [selectedVillage])

    useEffect(() => {
        if (selectedSite)
            ProvinceApi.getMangroGroupe(selectedSite).then((res) => {
                setGroups(res?.data)
            })
    }, [selectedSite])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('firstname' in fieldValues) temp.firstname = fieldValues.firstname ? '' : 'Ce champ est requis.'
        if ('lastname' in fieldValues) temp.lastname = fieldValues.lastname ? '' : 'Ce champ est requis.'
        if ('group_id' in fieldValues) temp.group_id = fieldValues.group_id ? '' : 'Ce champ est requis.'
        if ('genre' in fieldValues) temp.genre = fieldValues.genre ? '' : 'Ce champ est requis.'
        if ('cin_number' in fieldValues) temp.cin_number = fieldValues.cin_number ? '' : 'Ce champ est requis.'

        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialFValues,
        true,
        validate
    )

    useEffect(() => {
        if (photo) {
            setValues({ ...values, photo: photo })
        }
    }, [photo, setValues, values])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validate()) {
            setUserHaveToPost(true)
            const dataToSend = { ...values, user: [user.firstname, user.lastname].join(' ') }

            delete dataToSend.region
            delete dataToSend.district
            delete dataToSend.commune
            delete dataToSend.fkt
            delete dataToSend.village
            delete dataToSend.site
            delete dataToSend.id

            const res = await PlanterService.create(dataToSend)
            console.log('Données à envoyer : ', dataToSend)
            if (res) {
                new Notification(PlanterService.messages.create_success, 1)
                setUserHaveToPost(false)
                navigate(-1)
            } else {
                new Notification(PlanterService.messages.create_error, 0)
                setUserHaveToPost(false)
            }
        }
    }

    const handleSelectChange = (e) => {
        handleInputChange(e)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <div className="flex p-4">
                <div className="w-[100%]  p-2">
                    <VillageAutoCompleteSection
                        groupType={category.mangro}
                        values={values}
                        setValues={setValues}
                        errors={errors}
                        onChange={handleSelectChange}
                        isRequired={true}
                    />
                    <Typography sx={styles.form_title}>Informations personelles</Typography>
                    <Grid container>
                        <Grid item xs={4}>
                            <Container>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    name="firstname"
                                    label="Nom"
                                    value={values.firstname}
                                    onChange={handleInputChange}
                                    error={errors.firstname}
                                    isRequired={true}
                                />
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    name="phone"
                                    label="Téléphone"
                                    value={values.phone}
                                    onChange={handleInputChange}
                                    error={errors.phone}
                                />
                                <Controls.DatePicker
                                    label="Date de naissance"
                                    name="dob"
                                    onChange={handleInputChange}
                                    type="dob"
                                    value={values?.dob}
                                    error={errors?.dob}
                                />
                                <Controls.Input
                                    name="cin_number"
                                    label="Numero CIN"
                                    type="number"
                                    value={values.cin_number}
                                    onChange={handleInputChange}
                                    error={errors.cin_number}
                                    isRequired={true}
                                />
                            </Container>
                        </Grid>
                        <Grid item xs={4}>
                            <Container>
                                <Controls.Input
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    name="lastname"
                                    label="Prénoms"
                                    value={values.lastname}
                                    onChange={handleInputChange}
                                    error={errors.lastname}
                                    isRequired={true}
                                />
                                <Controls.AutoCompleteSelect
                                    label="Catégorie de planteur"
                                    name="category"
                                    value={values?.category || ''}
                                    options={planterCategoryOption}
                                    onChange={handleSelectChange}
                                    error={errors.category}
                                    labelKey="label"
                                    valueKey="label"
                                />
                                <Controls.DatePicker
                                    label="Membre depuis"
                                    name="member_since"
                                    onChange={handleInputChange}
                                    type="member_since"
                                    value={values?.member_since}
                                    error={errors?.member_since}
                                />
                            </Container>
                        </Grid>
                        <Grid item xs={4}>
                            <Container>
                                <Controls.AutoCompleteSelect
                                    label="Genre"
                                    name="genre"
                                    value={values?.genre || ''}
                                    options={genreOption || []}
                                    onChange={handleSelectChange}
                                    error={errors.genre}
                                    labelKey="label"
                                    valueKey="label"
                                    isRequired={true}
                                />
                                {/* <Controls.Input
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    name="activity"
                                    label="Activité"
                                    value={values.activity}
                                    onChange={handleInputChange}
                                    error={errors.activity}
                                /> */}
                                <Controls.AutoCompleteSelect
                                    label="Activité"
                                    name="activity"
                                    value={values?.activity || ''}
                                    options={activitiesOption || []}
                                    onChange={handleSelectChange}
                                    error={errors.activity}
                                    labelKey="label"
                                    valueKey="label"
                                />
                                <Controls.DatePicker
                                    label="Début de collaboration"
                                    name="collaboration_beginning"
                                    onChange={handleInputChange}
                                    type="collaboration_beginning"
                                    value={values?.collaboration_beginning}
                                    error={errors?.collaboration_beginning}
                                />
                            </Container>
                        </Grid>
                    </Grid>
                    <Container>
                        <Controls.SingleImageInput
                            label="Photo"
                            onChange={handleInputChange}
                            name="photo"
                            setPhoto={setPhoto}
                        />
                    </Container>
                </div>
            </div>
            <div className="flex justify-end items-end m-5 p-5">
                <Controls.Button
                    type="submit"
                    text="Enregistrer"
                    size="large"
                    haveSpin={true}
                    disabled={userHaveToPost}
                />
            </div>
        </Form>
    )
}

export default PlanterForm
