import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Container, TextField } from '@mui/material'

const useStyle = {
    labelInput: {
        fontSize: '12px',
        padding: `0 0 0 0`
    },
    labelContainer: {
        marginBottom: '20px',
        width: '100%'
    }
}

export default function DatePickerInput(props) {
    const {
        name,
        label,
        value = new Date().toISOString(),
        isRequired = false,
        isDisabled = false,
        onChange,
        fullWidth = false,
        getYearOnly = false
    } = props
    const classes = useStyle

    const convertToDefEventPara = (name, value) => ({
        target: {
            name,
            value
        }
    })

    return (
        <div style={classes.labelContainer}>
            <div style={classes.labelInput}>
                {label} {isRequired ? <b style={{ color: 'red' }}>*</b> : ''}
            </div>
            <div style={{ width: '100%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        style={{ width: '100%' }}
                        disabled={isDisabled}
                        value={dayjs(value)}
                        format={getYearOnly ? 'YYYY' : 'DD/MM/YYYY'}
                        name={name}
                        views={getYearOnly ? ['year'] : ['day', 'month', 'year']}
                        onChange={(date) => {
                            const isoDate = date?.toISOString()
                            onChange(convertToDefEventPara(name, isoDate))
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        slotProps={{ textField: { fullWidth: fullWidth, size: 'small' } }}
                    />
                </LocalizationProvider>
            </div>
        </div>
    )
}
