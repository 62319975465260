import React from 'react'
import { Container, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyle = {
    labelInput: {
        fontSize: '12px'
    },
    labelContainer: {
        marginBottom: '20px',
        width: '100%',
        display: 'block'
    }
}

export default function Input(props) {
    const {
        name,
        label,
        value,
        error = null,
        isDisabled = false,
        multiline = false,
        onChange,
        isRequired = false,
        style,
        type = 'text',
        ...other
    } = props
    const classes = useStyle

    const handleWheel = (event) => {
        if (type === 'number') {
            event.preventDefault()
            event.target.blur()
            const { valueAsNumber } = event.target
            if (valueAsNumber) {
                event.target.value = valueAsNumber
            }
        }
    }

    return (
        <div style={classes.labelContainer}>
            <div style={classes.labelInput}>
                {label} {isRequired ? <b style={{ color: 'red' }}>*</b> : ''}
            </div>
            <TextField
                disabled={isDisabled}
                size="small"
                name={name}
                value={value}
                onChange={onChange}
                onWheel={handleWheel}
                type={type}
                style={{ ...style, width: '100%' }}
                multiline={multiline}
                {...other}
                {...(error && { error: true, helperText: error })}
            />
        </div>
    )
}
