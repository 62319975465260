import React, { useState } from 'react'
import PageHeader from '../../components/Typography/PageHeader'
import PreregistryList from '../../pages/preregistry/components/preregistry/PreregistryList'
import { useAuth } from '../../applications/hooks/UseAuth'
import PreregistryService from '../../pages/preregistry/services/Preregistry'
import { Form, useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

import { Toolbar as MuiToolbar } from '@mui/material'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import ProvinceFilter from '../provinces/components/ProvinceFilter'

const CURRENT_PAGE = 'agroforestry_preregistry'
const useStyles = makeStyles((theme) => ({
    siteChange: {
        '& .MuiBottomNavigation-root': {
            height: '50px',
            borderRadius: 10,
            borderColor: '#0FCB8F',
            borderWidth: 2,
            background: '#43D5A7',
        },
        '& .MuiBottomNavigationAction-root': {
            background: '#0FCB8F',
            minWidth: '18vw',
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            borderRadius: 10,
            background: 'none',
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF',
        },
        '& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF',
        }
    }
}))

function Preregistry() {
    const classes = useStyles()

    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const [filter, setFilter] = useState({})

    const useDrawer = false
    const service = PreregistryService.load()

    const [value, setValue] = useState('preregistry')

    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        setValue(newValue)
        navigate('/app/agroforestry/' + newValue)
    }

    return (
        <div className={' w-full bg-base-100 font-sans font-bold'} style={{ paddingBottom: 20 }}>
            <PageHeader title={'Pré-registre - Paysans & Parcelles'} subTitle={''}>
                {/* <ToolbarButton
                    moduleTitle=""
                    service={service}
                    drawer={useDrawer}
                    haveAccessTo={haveAccessTo}
                    currentPage={CURRENT_PAGE}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                /> */}
            </PageHeader>
            <div style={{display:'flex', marginTop: 10, gap: 10}}>
            <ProvinceFilter setNeedLoad={setNeedLoad} filter={filter} setFilter={setFilter} />
            <MuiToolbar style={{ padding: 0,alignItems:'start' }} className={classes.siteChange}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={handleChange}
                >
                    <BottomNavigationAction key="1" label="Pré-registre" value="preregistry" />
                    <BottomNavigationAction key="2" label="Paysans enregistrés" value="peasants" />
                    <BottomNavigationAction key="2" label="Liste des parcelles" value="plots" />
                </BottomNavigation>
            </MuiToolbar>
            </div>
            <PreregistryList
                service={service}
                drawer={useDrawer}
                haveAccessTo={haveAccessTo}
                currentPage={CURRENT_PAGE}
                setNeedLoad={setNeedLoad}
                filterFromList={filter}
                needLoad={needLoad}
                Form={() =><Form />}
            />
        </div>
    )
}

export default Preregistry
