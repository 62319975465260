import React from 'react'
import Table from '../../../../components/DataTable/Table'

const headCells = [
    { id: 'code', label: 'ID platebande' },
    // { id: 'production_capacity', label: 'Capacité' },
    { id: 'sowing_date', label: 'Date de semis', type: 'date' },
    // { id: 'planting_date', label: 'Date de plantation', type: 'date' },
    { id: 'species', label: 'Espèces', type: 'species' },
    { id: 'quantity', label: 'Quantité', type: 'Integer' },
    // { id: 'reupholstry_date', label: 'Date de regarnissage', type: 'date' },
    { id: 'nbr_plant_replaced', label: 'Plants remplacés' },
    {
        id: 'actions',
        type: 'actions',
        label: 'Actions',
        disableSorting: true,
        showEdit: false,
        showRemove: true,
        showView: '/app/mangrove/nursery/plat/edit',
        forcePassId: true
    }
]

function PlatDrainList(props) {
    const { haveAccessTo, service, needLoad, currentPage, Form, setNeedLoad, drawer } = props
    return (
        <>
            <Table
                title="Détail de la plate bande"
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                Form={Form}
                drawer={drawer}
                hideFilterButton={true}
                showSiteButton={true}
            />
        </>
    )
}

export default PlatDrainList
