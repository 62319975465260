/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Paper, TableBody, TableRow, TableCell, Chip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import useTable from '../../applications/hooks/UseTable'
import Controls from '../Entry/Controls'
import ConfirmDialog from '../../layouts/ConfirmDialog'
import Notification from '../../layouts/Notification'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Drawer from '../../layouts/Drawer'

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: 10,
        backgroundColor: '#F2F8F6',
        '& .MuiPaper-root': {
            border: 'none',
            boxShadow: 'none'
        }
    },
    cellClass: {
        fontSize: 12,
        textAlign: 'center',
        '& .MuiTableSortLabel-root': {
            fontWeight: 'normal'
        }
    },
    filter: {
        '& .MuiInputBase-input': {
            height: 10,
            width: 50
        }
    },
    title: {
        padding: `${theme.spacing(2)}`,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    }
}))

const TablePaper = styled(Paper)(({ theme }) => ({
    boxShadow: '0px 5px 5px -3px #d2d2d2,0px 8px 10px 1px #d2d2d2,0px 3px 14px 2px #d2d2d2'
}))

function TableDetail(props) {
    const classes = useStyles()
    const navigate = useNavigate()

    const {
        headCells,
        service,
        data,
        Form,
        onDataDelete,
        title,
        showButton,
        onClickButton,
        needData = true,
        useDrawer,
        setNeedLoad,
        closeDrawer
    } = props
    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [codeItem, setCodeItem] = useState(null)

    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })

    const { TblContainer, TblHead } = useTable(records, headCells)

    const toggleDrawer = (drawerOpen, item = null) => {
        console.log(item)
        item && setRecordForEdit(item)
        setOpenDrawer(drawerOpen)
    }

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const data = await service.getAll()
            if (data) {
                setRecords(data?.data)
                setLoading(false)
            }
        }

        if (service) {
            getData()
            if (!data) {
                setLoading(true)
            } else {
                setLoading(false)
            }
        }
        if (!data) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [data, service])

    const onDelete = async (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        await service.delete(id)
        onDataDelete()
        new Notification(service.messages.delete_success, 1)
    }

    const formatData = (data, dataType) => {
        if (dataType === 'date') {
            return moment(data).format('DD/MM/YYYY')
        }

        if (dataType === 'year') {
            const date = new Date(data)
            const year = date.getFullYear()
            return year
        }

        return data
    }

    return (
        <>
            {title && (
                <div className="flex gap-4">
                    <h2 className={classes.title}>{title}</h2>
                    {showButton && (
                        <div className="space-x-2 padding-x-2 ml-[10px] mt-[5px]">
                            <Controls.Button text="Nouveau" variant="contained" size="large" onClick={onClickButton} />
                        </div>
                    )}
                </div>
            )}
            <Paper className={classes.pageContent} elevation={0} style={{ backgroundColor: '#F2F8F6' }}>
                <TablePaper style={{ padding: 20, borderRadius: 10 }} elevation={8}>
                    <TblContainer>
                        <TblHead classes={classes} />
                        <TableBody>
                            {data &&
                                data?.map((item) => (
                                    <TableRow key={item.id}>
                                        {headCells.map((headCell) =>
                                            !headCell?.type?.includes('actions') ? (
                                                <TableCell className={classes.cellClass}>
                                                    {headCell.link ? (
                                                        <Chip
                                                            onClick={() => {
                                                                if (headCell.showView) {
                                                                    navigate(
                                                                        `${headCell.showView.link}/${
                                                                            item[headCell.showView.param]
                                                                        }`,
                                                                        {
                                                                            state: {
                                                                                code: item[headCell.showView.param]
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                            }}
                                                            label={formatData(item[headCell.id], headCell.type)}
                                                        />
                                                    ) : headCell.viewOnDrawer ? (
                                                        <Chip
                                                            onClick={() => {
                                                                toggleDrawer(true, item)
                                                            }}
                                                            label={formatData(item[headCell.id], headCell.type)}
                                                        />
                                                    ) : 'render' in headCell ? (
                                                        headCell.render(
                                                            formatData(
                                                                item[headCell.id],
                                                                headCell?.type,
                                                                headCell,
                                                                headCell?.format || null
                                                            )
                                                        )
                                                    ) : (
                                                        formatData(item[headCell.id], headCell.type, headCell)
                                                    )}
                                                </TableCell>
                                            ) : (
                                                <TableCell className={classes.cellClass}>
                                                    {item?.system ||
                                                        (headCell.showRemove && (
                                                            <Controls.ActionButton
                                                                tooltip="Supprimer"
                                                                color="primary"
                                                                onClick={() => {
                                                                    setConfirmDialog({
                                                                        isOpen: true,
                                                                        title: 'Vous voulez vraiment le supprimer?',
                                                                        subTitle: 'Cette opération est irréversible.',
                                                                        onConfirm: () => {
                                                                            onDelete(item.id)
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                <CloseIcon fontSize="small" />
                                                            </Controls.ActionButton>
                                                        ))}
                                                    {item?.system ||
                                                        (headCell.showView && (
                                                            <Controls.ActionButton
                                                                tooltip="Voir"
                                                                color="primary"
                                                                onClick={() => {
                                                                    if (!headCell.forcePassId) {
                                                                        navigate(
                                                                            `${headCell?.showView}/${
                                                                                item?.code || item?.id
                                                                            }`,
                                                                            {
                                                                                state: { code: item.code || item?.id }
                                                                            }
                                                                        )
                                                                    } else {
                                                                        navigate(`${headCell?.showView}/${item?.id}`, {
                                                                            state: { code: item?.id }
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <VisibilityIcon fontSize="small" />
                                                            </Controls.ActionButton>
                                                        ))}
                                                    {headCell.customButtons &&
                                                        headCell.customButtons.map((Item) => (
                                                            <Item id={item.id} {...item} />
                                                        ))}
                                                </TableCell>
                                            )
                                        )}
                                    </TableRow>
                                ))}
                            {data?.length === 0 && needData && (
                                <>
                                    <td colSpan={40} style={{ textAlign: 'center' }}>
                                        Aucun résultat.
                                    </td>
                                </>
                            )}
                        </TableBody>
                    </TblContainer>
                </TablePaper>
            </Paper>
            {useDrawer && (
                <Drawer title={title} openDrawer={openDrawer} toggleDrawer={toggleDrawer} setNeedLoad={setNeedLoad}>
                    <Form
                        recordForEdit={recordForEdit}
                        // addOrEdit={addOrEdit}
                        addReturnButton={false}
                        codeItem={codeItem}
                        // activeCenterComFromList={activeCenterComFromList}
                        closeDrawer={closeDrawer}
                    />
                </Drawer>
            )}

            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
            {/* <Loader isLoading={loading} /> */}
        </>
    )
}

export default TableDetail
