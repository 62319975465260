/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Controls from '../../../../components/Entry/Controls'
import { useForm, Form } from '../../../../applications/hooks/UseForm'

const initialFValues = {
    id: 0,
    name: ''
}

export default function UserForm(props) {
    const { addOrEdit, recordForEdit } = props
    const [userHaveToPost, setUserHaveToPost] = useState(false)


    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('name' in fieldValues) temp.name = fieldValues.name ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialFValues,
        true,
        validate
    )

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm, setUserHaveToPost)
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.Input
                        name="name"
                        label="Rôle"
                        value={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                        isRequired={true}
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center', marginTop: '8px' }}>
                    <Controls.Button type="submit" text="Enregistrer" haveSpin={true} disabled={userHaveToPost}/>
                </Grid>
            </Grid>
        </Form>
    )
}
