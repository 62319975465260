import React, { useEffect } from 'react'
import { FormControl, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material'

const useStyles = {
    labelInput: {
        fontSize: '12px'
    },
    labelContainer: {
        marginBottom: '20px'
    }
}

export default function CustomSelect(props) {
    const { name, label, value, error = null, onChange, options, labelKey,isRequired = false, valueKey, isDisabled = false } = props
    const classes = useStyles

    useEffect(() => {
        //order label alphabetically
        if(options && labelKey) options.sort((a, b) => a[labelKey].localeCompare(b[labelKey]))
    }, [options, labelKey])

    return (
        <div style={classes.labelContainer}>
            <div style={classes.labelInput}>{label}  {isRequired ? <b style={{color: 'red'}}>*</b> : ''}</div>
            <FormControl {...(error && { error: true })} disabled={isDisabled} sx={{ width: '100%' }}>
                <MuiSelect name={name} value={value} onChange={onChange} size="small" sx={{ width: '100%' }}>
                    <MenuItem value="">Aucun</MenuItem>
                    {options &&
                        options?.map((item, index) => (
                            <MenuItem key={index} value={item[valueKey]}>
                                {item[labelKey]}
                            </MenuItem>
                        ))}
                </MuiSelect>
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </div>
    )
}
