import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Controls from '../components/Entry/Controls'
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'
import Notification from './Notification'

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '8rem'
        }
    }
}))

export default function ConfirmDialog(props) {
    const { confirmDialog, setConfirmDialog } = props
    const [disableButton, setDisableButton] = useState(true)
    const [timerValue, setTimerValue] = useState(null)
    const [timerLaunched, setTimerLaunched] = useState(false)
    const [processLaunched, setProcessLaunched] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        const timer = setTimeout(() => {
            if (timerValue > 1 && timerLaunched && confirmDialog.onConfirm) {
                setTimerValue(timerValue - 1)
            } else {
                setDisableButton(false)
                setTimerValue(5)
                clearTimeout(timer)
                setTimerLaunched(false)
                if(processLaunched){
                    new Notification(`${confirmDialog.successMessage}`, 1)
                    confirmDialog.onConfirm()
                    setProcessLaunched(false)
                }
            }
        }, 1000)
        return () => clearTimeout(timer)
    }, [timerValue, timerLaunched, confirmDialog, processLaunched])

    useEffect(() => {
        if (confirmDialog.isOpen) {
            setTimerValue(5)
        }
    }, [confirmDialog.isOpen])

    const handleConfirmDialog = () => {
        setProcessLaunched(true)
        setTimerLaunched(true)
    }

    const handleCancel = () => {
        setTimerLaunched(false)
        setProcessLaunched(false)
        new Notification('Processus annulé.', 0)
        setTimerValue(5)
    }

    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton disableRipple className={classes.titleIcon}>
                    <NotListedLocationIcon style={{ fontSize: '55px' }} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">{confirmDialog.title}</Typography>
                <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Controls.Button
                    text="Non"
                    color="primary"
                    onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                    disabled={timerLaunched ? true : false}
                    
                />
                <Controls.Button text={timerLaunched ? "Annuler" : "Oui"} onClick={timerLaunched ? handleCancel : handleConfirmDialog} disabled={disableButton} color="secondary" />
                {timerLaunched && <Typography variant="subtitle2">{timerValue}</Typography>}
            </DialogActions>
        </Dialog>
    )
}
