import Switch from '@mui/material/Switch'
import React, { useState } from 'react'
import Table from '../../../components/DataTable/Table'
import Controls from '../../../components/Entry/Controls'
import Drawer from '../../../layouts/Drawer'

// import AddModeratorOutlinedIcon from '@mui/icons-material/AddModeratorOutlined'
import { FaWpforms } from 'react-icons/fa'
import CalibrationForm from './CalibrationForm'
export default function CalibrationList(props) {
    const { haveAccessTo, currentPage, Form, drawer, service, needLoad, setNeedLoad } = props
    const [openDrawer, setOpenDrawer] = useState(false)
    const [formId, setFormId] = useState(null)
    const [titleForm, setTitleForm] = useState(null)

    const FormButton = (props) => {
        const { id, title } = props

        const handleForm = () => {
            setFormId(id)
            setOpenDrawer(true)
            setTitleForm(title)
        }

        return (
            <Controls.ActionButton
                disabled={!haveAccessTo(currentPage, 'permission')}
                tooltip="Gérer les formulaires"
                color="primary"
                onClick={handleForm}
            >
                <FaWpforms fontSize="medium" />
            </Controls.ActionButton>
        )
    }

    const SwitchButton = (props) => {
        const { id, system } = props
        return <Switch disabled checked={system} id={id} />
    }

    const headCells = [
        { id: 'specie', label: 'Espèce' },
        { id: 'unity', label: 'Unité' },
        { id: 'value', label: 'Valeur' },
        { id: 'actions2', type: 'actions', label: 'Actions', disableSorting: true, showEdit: true, showRemove: true }
    ]

    const toggleDrawer = (drawerOpen) => {
        setOpenDrawer(drawerOpen)
    }

    return (
        <>
            <Table
                title={'Modification formulaire'}
                headCells={headCells}
                service={service}
                Form={Form}
                drawer={drawer}
                keyToSearch={null}
                haveAccessTo={haveAccessTo}
                currentPage={currentPage}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
                hideFilterButton={true}
            />
            <Drawer title={'Gestion des formulaires'} openDrawer={openDrawer} toggleDrawer={toggleDrawer}>
                <CalibrationForm formId={formId} dataTitle={titleForm} toggleDrawer={toggleDrawer} />
            </Drawer>
        </>
    )
}
