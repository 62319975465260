import React from 'react'
import Table from '../../../../components/DataTable/Table'

function SpeciesList(props) {
    const { haveAccessTo,drawer, service, needLoad, currentPage, Form, setNeedLoad } = props
    const headCells = [
        { id: 'photos', label: 'Image', type:'photo' },
        { id: 'name', label: 'Nom' },
        {
            id: 'Actions',
            label: 'Actions',
            type: 'actions',
            showEdit: true,
            showRemove: true,
        }
    ]

    return (
        <>
            <Table
                title="Liste des espèces"
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                Form={Form}
                drawer={drawer}
                hideFilterButton={true}
                showSiteButton={false}
            />
        </>
    )
}

export default SpeciesList
