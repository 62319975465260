import React from 'react'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { Container, Paper } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'

const styles = {
    input: {
        width: '100%',
        height: '100%'
    },
    container: {
        width: '100%',
        border: 'none',
        borderRadius: '10px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column'
    },

    labelInput: {
        fontSize: '12px',
        padding: `0 0 10px 0`
    },
    containerTextArea: {
        flex: '1',
        width: '100%',
        height: '100%',
        border: '1px solid #dadada',
        borderRadius: '10px',
        overflow: 'hidden',
        '& textarea': {
            width: '100%',
            resize: 'none',
            border: 'none',
            outline: 'none',
            padding: '10px',
            boxSizing: 'border-box'
        }
    }
}

function TextInput(props) {
    const { name, label, value, isDisabled = false, placeholder, customStyle, haveIcon, onClick, onChange } = props
    return (
        <div style={{ ...styles.container, ...customStyle }}>
            <Container style={styles.labelInput}>{label}</Container>
            <Paper sx={styles.containerTextArea}>
                <TextareaAutosize name={name} onChange={onChange} value={value} disabled={isDisabled} placeholder={placeholder} />
                {haveIcon && (
                    <Container sx={{display: 'flex', justifyContent: 'flex-end', padding: "0 0 10px 0"}}>
                        <VisibilityIcon sx={{ color: 'gray', '& :hover': { color: '#0FCB8F', cursor: 'pointer' }}} onClick={onClick} />
                    </Container>
                )}
            </Paper>
        </div>
    )
}

export default TextInput
