import React, { useState } from 'react'
import Controls from './Controls'
import { FaTrashAlt } from 'react-icons/fa'
import Notification from '../../layouts/Notification'

const useStyles = {
    labelInput: {
        fontSize: '12px'
    },
    labelContainer: {
        marginBottom: '20px',
        width: '100%',
        display: 'block'
    },
    thumbnail: {
        width: '100px',
        height: '100px',
        objectFit: 'cover'
    },
    thumbnailContainer: {
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        height: '120px',
        padding: '10px',
        width: '100%',
        borderRadius: '5px'
    },
    removeButton: {
        display: 'block',
        marginTop: '5px',
        cursor: 'pointer'
    }
}

function SingleImageInput({ label, onChange, name, setPhoto }) {
    const classes = useStyles
    const [photoValue, setPhotoValue] = useState(null)

    const handleAddPhoto = async (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()

        reader.onloadend = () => {
            setPhotoValue(reader.result)
            setPhoto(reader.result)
            name && onChange({ target: { name, value: file.name, content: reader.result } })
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const handleRemovePhoto = () => {
        setPhotoValue(null)
        if (onChange) {
            onChange({
                target: {
                    name,
                    value: null,
                    content: null
                }
            })
        }
    }

    return (
        <div style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <div style={classes.labelInput}>{label}</div>
                <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="photo-upload"
                    onChange={handleAddPhoto}
                />
                <label htmlFor="photo-upload">
                    <Controls.Button text="Ajouter" size="small" component="span" />
                </label>
            </div>
            {photoValue && (
                <div style={classes.thumbnailContainer}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Controls.CustomImageAvatar src={photoValue} alt="Preview" size={70} />
                        <FaTrashAlt
                            style={{ cursor: 'pointer', color: 'red', alignSelf: 'center', marginTop: '3px' }}
                            onClick={handleRemovePhoto}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default SingleImageInput
