import React, { useContext, useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import { Container, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Loader from '../../../../../../components/Entry/Loading'
import Subtitle from '../../../../../../components/Typography/Subtitle'
import Controls from '../../../../../../components/Entry/Controls'
import { Context } from '../../../../utils/hooks/InfoPlanterContext'

const styles = {
    cardContainer: {
        backgroundColor: '#f8f8f8',
        border: 'none',
        boxShadow: 'none',
        width: 'auto',
        borderRadius: '10px',
        marginBottom: '20px'
    },
    card: {
        width: '100%',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '& .MuiGrid-root': {
            padding: '20px 5px 20px 5px'
        }
    },
    title: {
        paddingTop: '10px',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none'
    },
    subtitle: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none'
    },
    voirPhoto: {
        fontSize: '13px',
        textAlign: 'end',
        padding: '0 20px 0 20px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    cardContent: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        padding: '20px',
    }
}

function SpeciesCard() {
    const dataContext = useContext(Context)
    return (
        <>
            {dataContext && (
                <Grid container>
                    <Grid item={12}>
                        <Paper sx={styles.cardContainer}>
                            <Container>
                                <Paper sx={styles.title}>
                                    <Subtitle
                                        style={{
                                            fontSize: '0.8rem',
                                            color: '#677788'
                                        }}
                                    >
                                        Espèces plantées ({dataContext.species_data.length})
                                    </Subtitle>
                                </Paper>
                            </Container>
                            <Grid sx={styles.cardContent}>
                                {dataContext.species_data.map((specie, index) => {
                                    return (
                                        <Card key={index} sx={styles.card}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px'
                                                }}
                                            >
                                                <div>
                                                    <Controls.CustomImageAvatar
                                                        title={specie.specie_name}
                                                        src={specie.specie_photo}
                                                        rounded={50}
                                                        size={70}
                                                        // place={'right'}
                                                    />
                                                </div>
                                                <div>
                                                    <Paper sx={styles.subtitle}>
                                                        <Subtitle
                                                            style={{
                                                                fontSize: '0.8rem',
                                                                color: '#677788'
                                                            }}
                                                        >
                                                            {specie.specie_name}
                                                        </Subtitle>
                                                    </Paper>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: '0.7rem',
                                                            color: '#677788'
                                                        }}
                                                    >
                                                        Graine : {specie.specie_seeds}
                                                        <br />
                                                        Propagule : {specie.specie_propagules}
                                                        <br />
                                                        Jeune plant : {specie.specie_young}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Card>
                                    )
                                })}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default SpeciesCard
