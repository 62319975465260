import React, { useState } from 'react'
import Table from '../../../../components/DataTable/Table'
import Controls from '../../../../components/Entry/Controls'
import Drawer from '../../../../layouts/Drawer'

import AddModeratorOutlinedIcon from '@mui/icons-material/AddModeratorOutlined'
import SetPermissionPage from './SetPermission'

export default function Roles(props) {
    const { haveAccessTo, currentPage, Form, drawer, service, needLoad, setNeedLoad } = props
    const [openDrawer, setOpenDrawer] = useState(false)
    const [editedRoleId, setEditedRoleId] = useState(null)

    const AddPermissionButton = (props) => {
        const { id, system } = props

        const handleAddPermission = () => {
            setEditedRoleId(id)
            setOpenDrawer(true)
        }

        return !system && (
            <Controls.ActionButton
                disabled={!haveAccessTo(currentPage, 'permission')}
                tooltip="Gérer les permissions"
                color="primary"
                onClick={handleAddPermission}
            >
                <AddModeratorOutlinedIcon fontSize="small" />
            </Controls.ActionButton>
        )
    }

    const headCells = [
        { id: 'name', label: 'Rôle' },
        {
            id: 'actions',
            type: 'actions',
            label: 'Permissions',
            disableSorting: true,
            customButtons: [({ id, system }) => <AddPermissionButton id={id} system={system} />]
        },
        { id: 'actions2', type: 'actions', label: 'Actions', disableSorting: true, showEdit: true, showRemove: true }
    ]

    const toggleDrawer = (drawerOpen) => {
        setOpenDrawer(drawerOpen)
    }

    return (
        <>
            <Table
                title={'Modifier un rôle'}
                headCells={headCells}
                service={service}
                Form={Form}
                drawer={drawer}
                keyToSearch="name"
                haveAccessTo={haveAccessTo}
                currentPage={currentPage}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
                hideFilterButton={true}
            />
            <Drawer title="Gestion des permissions" openDrawer={openDrawer} toggleDrawer={toggleDrawer}>
                <SetPermissionPage roleId={editedRoleId} toggleDrawer={toggleDrawer} />
            </Drawer>
        </>
    )
}
