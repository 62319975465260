import { Container, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

function ContentListGroup(props) {
    const { label, number, key, isSelected, onClick } = props
    const [index, setIndex] = useState(null)

    useEffect(() => {
        if (key) {
            setIndex(key)
        }
    }, [key])

    return (
        <>
            <Paper
                sx={{
                    height: '45px'
                }}
            >
                <Typography
                    key={index}
                    variant="h6"
                    component="div"
                    sx={{
                        fontSize: '0.8rem',
                        color: '#6b7b8b',
                        textAlign: 'center',
                        paddingY: '5px',
                        ':hover': { cursor: 'pointer', backgroundColor: '#f5f3f0' },
                        borderRadius: '10px',
                        backgroundColor: isSelected ? '#f5f3f0' : 'white'
                    }}
                    onClick={() => onClick(index)}
                >
                    <strong>{label}</strong> ({number})
                </Typography>
            </Paper>
        </>
    )
}

export default ContentListGroup
