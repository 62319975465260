import React, { createContext, useEffect, useState } from 'react'
import Loader from '../../../../../../components/Entry/Loading'
import RecorgedPotService from '../../../../services/recorged_pot/recorged_pot'

export const context = createContext()

function RecorgedPotContext(props) {
    const { children, nurseryId, needLoad, setNeedLoad, recordForEdit } = props

    const recorgedPotService = RecorgedPotService.load()
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState({})

    useEffect(() => {
            const loadNursery = async () => {
                setLoading(true)
                const response = await recorgedPotService.getByNurseryId(nurseryId)
                if(response){
                    setValue(response)
                }
            }

            const loadRecorgedPot = async () => {
                setLoading(true)
                const response = await recorgedPotService.getById(recordForEdit.id)
                if(response){
                    console.log("réponse de recorged pot : ", response)
                    setValue({
                        isDrawerValue: true,
                        site_id: response.nursery.site_id,
                        id: response.nursery.id,
                        pot: response.pot,
                        RecorgedPotItem: response.RecorgedPotItem,
                        Nbr_empty_pot: response.Nbr_empty_pot,
                        total_accumulated: response.total_accumulated,
                        quantity_refilled: response.quantity_refilled,
                        Nbr_sown_pot: response.Nbr_sown_pot,
                        name: response.nursery.name,
                    })
                }
            }

            if (needLoad && nurseryId) {
                loadNursery()
                setLoading(false)
            }

            if (needLoad && recordForEdit) {
                loadRecorgedPot()
                setLoading(false)
                setNeedLoad(false)

            }

    }, [needLoad, setNeedLoad, nurseryId, recorgedPotService, recordForEdit])

    return (
        <>
            <Loader isLoading={loading} />
            <context.Provider value={value}>{children}</context.Provider>
        </>
    )
}

export default RecorgedPotContext
