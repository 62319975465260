export const districtModel = {
    label: 'District',
    tableName: 'district',
    properties: [
        {
            label: 'Région',
            id: 'regions',
            isForeignKey: true,
            column_name: 'name',
            table_column: 'parent',
            type: 'text',
            required: true,
        },
        {
            label: 'Nom',
            id: 'name',
            type: 'text',
            required: true,
        },
    ]
}