import React, { useState } from 'react'
import ContentView from '../../../../components/PageView/Content'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import { useLocation } from 'react-router-dom'
import PlanterService from '../../services/Planter'
import ShowPlanter from './content/form/ShowPlanter'

function PlanterPage() {
    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(false)

    return (
        <>
            {dataId && (
                <ContentView
                    title={`Planteur - Détails`}
                    dataId={dataId}
                    service={PlanterService}
                    needLoad={needLoad}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoadCode={needLoadCode}
                    setNeedLoadCode={setNeedLoadCode}
                    needCode={true}
                    Form={ShowPlanter}
                />
            )}
        </>
    )
}

export default PlanterPage
