import React, { createContext, useEffect, useState } from 'react'

export const Context = createContext({})

function PlantationContext(props) {
    const { children, data, setNeedLoad } = props
    const [value, setValue] = useState(null)
    useEffect(() => {
        if(data) {
            setValue({...data, setNeedLoad: setNeedLoad})
        }
    }, [data, setNeedLoad])
    return (
        <Context.Provider value={value} >
            {children}
        </Context.Provider>
    )
}

export default PlantationContext
