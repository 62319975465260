import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class ConfigEmailService extends Service {
    static messages = {
        update_success: 'Modification de la paramètre avec succés.',
        update_error: 'Erreur de modification de la paramètre.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression',
        create_success: 'Création de la paramètre avec succés',
        create_error: 'Erreur de création de paramètre'
    }
    static load() {
        this.setUrl('/api/configuration')
        return this
    }

    static async getAllConfig() {
        return await Http.get(this.url, {})
    }

    static async getByCode(code) {
        return await Http.get(['/api/configuration', 'byCode', code].join('/'), {})
    }

    static async updateConfig(params) {
        return await Http.put(this.url, { ...params })
    }
    static async updateConfigById(configId, params) {
        return await Http.put([this.url, configId].join('/'), { ...params })
    }
}

export default ConfigEmailService
