import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../applications/hooks/UseAuth'
import ContentView from '../../../components/PageView/Content'
import OrderTrackingService from '../services/OrderTracking'
import { OrderTrackingForm } from './OrderTrackingForm'
import { OrderTrackingFormMangrove } from './OrderTrackingFormMangrove'
import OrderTrackingFormAgro from './OrderTrackingFormAgro'

function OrderTrackingView(props) {
    const { addReturnButton, recordForEdit } = props
    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const orderTrackingService = OrderTrackingService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(true)

    const typeForm = location.pathname.split('/')[2]
    useEffect(() => {
        console.log('Record for edit : >>>', recordForEdit)
    }, [recordForEdit])
    return (
        <>
            <ContentView
                dataId={recordForEdit ? recordForEdit.id : dataId}
                title={recordForEdit ? '' : 'Nouveau commande'}
                service={orderTrackingService}
                haveAccessTo={haveAccessTo}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
                Form={
                    // recordForEdit.plantation_type === 'Agroforesterie'
                    //     ? OrderTrackingFormAgro
                    //     : OrderTrackingFormMangrove
                    OrderTrackingForm
                }
                addReturnButton={addReturnButton}
                needLoadCode={needLoadCode}
            />
        </>
    )
}

export default OrderTrackingView
