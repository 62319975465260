import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class PreregistryService extends Service {
    static messages = {
        update_success: 'Modification utilisateur avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création utilisateur avec succès.',
        create_error: 'Erreur de création.'
    }

    static load() {
        this.setUrl('/api/preregistry')
        return this
    }
}

export default PreregistryService
