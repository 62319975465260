// import { Http } from '../../../applications/Http.js'

import moment from 'moment'
import { Http } from '../../../../applications/Http'
import Service from '../../../../applications/Service'

class PlatDrainService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/plat_drain')
        return this
    }

    static async getByCode(id) {
        return await Http.get([this.url, id].join('/'), {})
    }

    static async addPlatDrain(data) {
        try {
            data.sowing_date = moment(data.sowing_date).format()

            const response = await Http.put(this.url, data)
            return response.data
        } catch (error) {
            throw new Error("Erreur lors de l'ajout .")
        }
    }

    static async updatePlatDrain(id, data) {
        try {
            data.date_purchase = moment(data.date_purchase).format()
            const response = await Http.patch([this.url, id].join('/'), data)
            return response.data
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour .')
        }
    }

    static async getAllPlatDRain() {
        try {
            const response = await Http.get('/api/plat_drain')
            console.log('response user', response.data)
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            throw error
        }
    }

    static async getBySiteId(siteId) {
        try {
            const response = await Http.get(['/api/plat_drain', 'site', siteId].join('/'))
            return response.data
        } catch (error) {
            console.error(
                `Une erreur s'est produite lors de la récupération des plat drains pour le site ${siteId} :`,
                error
            )
            throw error
        }
    }
}

export default PlatDrainService
