import { Chip, Container, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import OptionMenu from '../../../../../components/Entry/MenuItem'
import PlanterService from '../../../services/Planter'
import { useAuth } from '../../../../../applications/hooks/UseAuth'
import Notification from '../../../../../layouts/Notification'

function ContentListPlanter(props) {
    const { onClick, planterContent, actions, presenceContent, isGroupManagement, setNeedLoad, setOpenPopup } = props

    const service = PlanterService.load()
    const { user } = useAuth()

    const onDeletePlanter = (item) => {
        service.deletePlanter(
            new URLSearchParams({ code: item.code, userStory: [user.firstname, user.lastname].join(' ') })
        ).then((result) => {
            if(result.data){
                new Notification('Suppression du planteur avec succès.', 1)
            }
        })
        setNeedLoad(true)
        setOpenPopup(false)
    }

    return (
        <>
            {planterContent &&
                planterContent.map((item, index) => {
                    const presence = presenceContent.find((p) => p.planter_id === item.id)
                    const isPresent = presence ? presence.isPresent : false
                    return (
                        <Paper
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '45px',
                                borderRadius: 'inherit',
                                justifyContent: 'space-between',
                                backgroundColor: (index + 1) % 2 === 1 ? '#f5f3f0' : '#e4e4e4',
                                '& .MuiPaper-root': { backgroundColor: 'none' },
                                padding: '0px 10px 0px 10px'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', height: '45px' }}>
                                <Chip label={`${index + 1}`} sx={{ margin: '0px 10px 0px 10px' }} />
                                <Typography
                                    key={index}
                                    variant="p"
                                    component="div"
                                    sx={{
                                        borderRadius: '10px',
                                        fontSize: '13px'
                                    }}
                                >
                                    {[
                                        item?.firstname,
                                        ' ',
                                        item?.lastname ? item?.lastname[0] : '',
                                        item?.lastname ? '.' : ''
                                    ].join('')}
                                </Typography>
                            </div>
                            {!actions && (
                                <Typography
                                    key={index}
                                    variant="p"
                                    component="div"
                                    sx={{
                                        color: 'red',
                                        borderRadius: '10px',
                                        fontSize: '13px'
                                    }}
                                >
                                    {!isPresent && <i>absent</i>}
                                </Typography>
                            )}
                            {actions && !isGroupManagement && (
                                <div style={{ display: 'flex', alignItems: 'center', height: '45px' }}>
                                    <VisibilityIcon
                                        fontSize="small"
                                        position="end"
                                        color="disabled"
                                        sx={{ ':hover': { cursor: 'pointer', color: '#0FCB8F' } }}
                                        onClick={() => onClick(item.code)}
                                    />
                                    <OptionMenu onDelete={onDeletePlanter} item={item} />
                                </div>
                            )}
                        </Paper>
                    )
                })}
        </>
    )
}

export default ContentListPlanter
