import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Card, CardActions, CardContent, CardHeader, CircularProgress, TextField } from '@mui/material'
import { CiCirclePlus } from 'react-icons/ci'
import { MdClear } from 'react-icons/md'
import ConfirmDialog from '../../../layouts/ConfirmDialog'
import { IoSaveOutline } from 'react-icons/io5'

const useStyles = makeStyles((theme) => ({
    pageHeader: {
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'semibold',
        '& .MuiCardHeader-title': {
            fontSize: '1.1rem'
        }
    }
}))

const CustomCard = ({
    title,
    items,
    enabledItem,
    selectedItem,
    handleAddField,
    handleDoubleClick,
    handleChange,
    handleShowItems,
    handleKeyDown,
    handleClearItem
}) => {
    const classes = useStyles()
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if(items[0].id){
            setIsLoading(false)
        }
    }, [items])

    return (
        <Card className="min-h-[530px]">
            <CardHeader
                className={classes.pageHeader}
                title={
                    <div className="flex items-center justify-center gap-2">
                        {title}
                        {isLoading && <CircularProgress size={16} className="mr-2" />}
                    </div>
                }
            />
            <CardContent>
                <div className="h-96 overflow-y-auto rounded-md scrollbar-w-8">
                    {items &&
                        items.map((item, index) => (
                            <div className="flex" key={index}>
                                <div key={index} className="flex mr-[5px] relative">
                                    <TextField
                                        id={`outlined-basic-${index}`}
                                        variant="outlined"
                                        size="small"
                                        name="name"
                                        sx={{
                                            marginBottom: 1,
                                            input: {
                                                cursor: enabledItem === item ? 'text' : 'pointer'
                                            },
                                            backgroundColor: selectedItem === item ? '#0FCB8F' : 'inherit',
                                            flex: 1
                                        }}
                                        value={item.name}
                                        onChange={(event) => handleChange(event, item)}
                                        onDoubleClick={() => handleDoubleClick(item)}
                                        onClick={() => handleShowItems(item)}
                                        // onKeyDown={(event) => handleKeyDown(event, item)}
                                    />
                                    {item && (
                                        <MdClear
                                            className="cursor-pointer w-4 h-4 border-2 border-gray-300 rounded-full text-gray-500 bg-gray-300 mb-15 absolute right-1 top-1"
                                            onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: 'Vous voulez vraiment le supprimer?',
                                                    subTitle: 'Cette opération est irréversible.',
                                                    onConfirm: () => {
                                                        handleClearItem(item)
                                                        setConfirmDialog({ isOpen: false })
                                                    }
                                                })
                                            }}
                                        />
                                    )}
                                </div>
                                <div className=" mt-[12px]">
                                    <IoSaveOutline
                                        onClick={(event) => handleKeyDown(event, item)}
                                        className=" w-4 h-4 cursor-pointer"
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            </CardContent>
            <CardActions disableSpacing className="flex justify-center ">
                <CiCirclePlus className="w-8 h-8 cursor-pointer" onClick={() => handleAddField()} />
            </CardActions>
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </Card>
    )
}

export default CustomCard
