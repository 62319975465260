import React, { useState, useEffect, useContext } from 'react'

import { Form, useForm } from '../../../../applications/hooks/UseForm'
import Controls from '../../../../components/Entry/Controls'
import InventoryService from '../../services/inventory/inventory'
import { useNavigate } from 'react-router-dom'
import Notification from '../../../../layouts/Notification'
import DataSetsService from '../../../datasets/services/DataSets'

import UserService from '../../../users/services/User'
import moment from 'moment'
import { Card, Container, Grid, Paper, Typography, Button as MUIButton } from '@mui/material'
import InventoryActions from '../../application/InventoryActions'
import Loader from '../../../../components/Entry/Loading'
import { context } from './utils/hooks/InventoryContext'
import Subtitle from '../../../../components/Typography/Subtitle'
import { formatDate } from '../../../../applications/UtilFunctions'
import ProvinceFilter from '../../../provinces/components/ProvinceFilter'
import NurseryService from '../../services/Nursery'
import { GlobalContext } from '../../../../applications/hooks/UseContext'
import RoleService from '../../../users/services/Role'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import { FaImage } from 'react-icons/fa'
import Popup from '../../../../layouts/Popup'

const { Input: TextField, Button } = Controls

const initialFValues = {
    designation: '',
    category: '',
    assignment: '',
    quantity: '',
    state: 'Neuf',
    user: '',
    responsible: '',
    observation: '',
    date_purchased: new Date().toISOString(),
    potWidth: ''
}

export const InventoryForm = (props) => {
    const { records, setNeedLoad } = props

    const { user } = useAuth()
    const navigate = useNavigate()
    const dataInventory = useContext(context)
    const nurseryService = NurseryService.load()
    const dataset = DataSetsService.load()
    const [loading, setLoading] = useState(false)
    const [dataCategory, setDataCategory] = useState([])
    const [dataState, setDataState] = useState([])
    const [potWidthData, setPotWidthData] = useState([])
    const [users, setUsers] = useState([])
    const [showProvinceCard, setShowProvinceCard] = useState(true)
    const [site, setSite] = useState([])
    const [nurseryList, setNurseryList] = useState([])
    const [toolsUserOptions, setToolsUserOptions] = useState([])
    const [potInNursery, setPotInNursery] = useState(null)
    const [userHaveToModify, setUserHaveToModify] = useState(false)
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [filter, setFilter] = useState({})
    const [selectedInventoryItem, setSelectedInventoryItem] = useState(null)
    const [photo, setPhoto] = useState(null)
    const [designationOptions, setDesignationOptions] = useState([])
    const [openPopup, setOpenPopup] = useState(false)
    const globalContext = useContext(GlobalContext)

    useEffect(() => {
        if (records) {
            setValues((prevFormData) => ({
                ...prevFormData,
                site: records?.site?.id || '',
                designation: records?.designation || '',
                category: records?.category || '',
                assignment: records?.assignment || '',
                quantity: records?.quantity || '',
                state: records?.state || 'Neuf',
                user: records?.user || '',
                responsible: records?.responsible || '',
                observation: records?.observation || '',
                date_purchased: records?.date_purchased
                    ? moment(records.date_purchased).format('YYYY-MM-DD')
                    : new Date()
            }))
            if (records?.id) setShowProvinceCard(false)
        }
    }, [records])

    useEffect(() => {
        setNeedLoad(true)
        dataset
            .getDataCode('inventory_category')
            .then((response) => {
                console.log('response', response.data)
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setDataCategory([{ id: 0, label: 'Intrant pépinière' }, ...dataArray])
                    setNeedLoad(false)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
        dataset
            .getDataCode('statut_inventaire')
            .then((response) => {
                console.log('response', response.data)
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setDataState([...dataArray, { label: 'Neuf' }])
                    setNeedLoad(false)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })

        dataset.getDataCode('sown_pot').then((response) => {
            if (response?.data) {
                const dataArray = JSON.parse(response.data[0].database)
                setPotWidthData(dataArray)
                setNeedLoad(false)
            }
        })
        dataset.getDataCode('designation_inventory').then((response) => {
            if (response?.data) {
                const dataArray = JSON.parse(response.data[0].database)
                setDesignationOptions(dataArray)
                setNeedLoad(false)
            }
        })
        dataset.getDataCode('tools_user').then((response) => {
            if (response?.data) {
                const dataArray = JSON.parse(response.data[0].database)
                setToolsUserOptions(dataArray)
                setNeedLoad(false)
            }
        })
    }, [])

    useEffect(() => {
        setNeedLoad(true)
        const service = RoleService.load()
        service
            .getAll()
            .then((users) => {
                if (users) {
                    console.log(users)
                    setUsers(users.data)
                    setNeedLoad(false)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des fonction :", error)
                setNeedLoad(false)
            })
    }, [])

    useEffect(() => {
        if (dataInventory) {
            setSelectedInventoryItem(dataInventory ? dataInventory[0] : [])
        }
    }, [dataInventory])

    useEffect(() => {
        if (globalContext?.value?.activeCenterCom) {
            setValues({
                ...values,
                assignment: globalContext?.value?.activeCenterCom,
                date_purchased: new Date().toISOString()
            })
        }
    }, [globalContext])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        console.log(fieldValues)
        // if ('site' in fieldValues) temp.site = fieldValues.site.length !== 0 ? '' : 'Ce champ est requis.'
        //planting_date_begin
        if (fieldValues.category === 'Intrant pépinière') {
            if ('potWidth' in fieldValues) temp.potWidth = fieldValues.potWidth ? '' : 'Ce champ est requis.'
            // if ('designation' in fieldValues) temp.designation = fieldValues.designation ? '' : 'Ce champ est requis.'
        }
        if ('category' in fieldValues) temp.category = fieldValues.category ? '' : 'Ce champ est requis.'
        if ('assignment' in fieldValues) temp.assignment = fieldValues.assignment ? '' : 'Ce champ est requis.'
        if ('quantity' in fieldValues) temp.quantity = fieldValues.quantity ? '' : 'Ce champ est requis.'
        if ('state' in fieldValues) temp.state = fieldValues.state ? '' : 'Ce champ est requis.'
        if ('responsible' in fieldValues) temp.responsible = fieldValues.responsible ? '' : 'Ce champ est requis.'
        if ('date_purchased' in fieldValues)
            temp.date_purchased = fieldValues.date_purchased ? '' : 'Ce champ est requis.'
        console.log(temp)
        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate)

    useEffect(() => {
        if (selectedInventoryItem) {
            console.log(selectedInventoryItem)
            setValues({
                ...values,
                quantity: selectedInventoryItem.quantity,
                state: selectedInventoryItem.state,
                date_purchased: selectedInventoryItem.date_purchased,
                observation: selectedInventoryItem.observation,
                responsible: selectedInventoryItem.responsible,
                user: selectedInventoryItem.user,
                designation: selectedInventoryItem.designation,
                id: selectedInventoryItem.id,
                inventory_id: selectedInventoryItem.inventory_id
            })
        }
    }, [selectedInventoryItem])

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(values)
        console.log(validate())
        console.log(errors)
        if (validate()) {
            try {
                setUserHaveToPost(true)
                const dataToSend = { ...values, userStory: [user.firstname, user.lastname].join(' ') }
                delete dataToSend.site

                const quantityValue = parseInt(dataToSend.quantity)
                if (!isNaN(quantityValue)) {
                    dataToSend.quantity = quantityValue
                    dataToSend.photo = photo || null
                    dataToSend.date_purchased = moment(dataToSend.date_purchased).format()
                    console.log('dataToSend', dataToSend)
                    if (records?.id) {
                        await InventoryService.updateInventory(records.id, dataToSend)
                        new Notification(InventoryService.messages.update_success, 1)
                    } else {
                        await InventoryService.addInventory(dataToSend)
                        new Notification(InventoryService.messages.create_success, 1)
                    }
                    setUserHaveToPost(false)
                    navigate(-1)
                } else {
                    setUserHaveToPost(false)
                    new Notification('La valeur de la quantité doit être un nombre', 0)
                }
            } catch (error) {
                setUserHaveToPost(false)
                new Notification(InventoryService.messages.create_error, 0)
            }
        }
    }

    useEffect(() => {
        if (filter) {
            console.log('filter', filter)
            nurseryService.getAllNurseryFilter(filter).then((response) => {
                if (response) {
                    console.log(response)
                    setNurseryList(response)
                }
            })
        }
    }, [filter, nurseryService])

    const handleModify = async () => {
        try {
            setUserHaveToPost(true)
            setNeedLoad(true)
            console.log(values)
            const response = await InventoryService.updateInventory(values.id, {
                ...values,
                userStory: [user.firstname, user.lastname].join(' ')
            })
            if (response) {
                setUserHaveToModify(false)
                setNeedLoad(false)
                setUserHaveToPost(false)
                new Notification(InventoryService.messages.update_success, 1)
            }
        } catch (error) {
            setUserHaveToPost(false)
            new Notification(InventoryService.messages.update_error, 0)
        }
    }

    const handleChange = async (e) => {
        const { value, name } = e.target
        handleInputChange(e)
        setValues((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value
        }))

        if (name === 'site') {
            getNurseryList(value)
        }
        if (name === 'potWidth' && values.assignment) {
            verifyPotInNursery(values.assignment, value)
        }
    }

    const verifyPotInNursery = async (nurseryId, potWidth) => {
        setLoading(true)
        const app = new InventoryActions()
        const potInNursery = await app.verifyPotInNursery(nurseryId, potWidth)
        if (potInNursery) {
            setPotInNursery(potInNursery)
        } else {
            setPotInNursery(null)
        }
        setLoading(false)
    }

    const getNurseryList = async (siteId) => {
        setLoading(true)
        const app = new InventoryActions()
        const nurseryList = await app.getNurseryWithSiteId(siteId)
        if (nurseryList) {
            console.log('Nursery : ', nurseryList)
            setNurseryList(nurseryList)
        }
        setLoading(false)
    }

    const openImageViewer = (pictures) => {
        setOpenPopup(true)
    }

    return (
        <>
            <Popup title={'Photos'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <div style={{ width: '700px', height: '500px' }}>
                    {selectedInventoryItem?.picture && (
                        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                            {JSON.parse(selectedInventoryItem?.picture).map((photo, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign: 'center'
                                    }}
                                >
                                    <Controls.CustomImageAvatar key={index} src={photo} size={100} />
                                    <p>photo {index + 1}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Popup>
            <Loader isLoading={loading} />
            <Form>
                <Grid container sx={{ padding: '10px' }} spacing={2}>
                    {dataInventory && (
                        <Grid item xs={3}>
                            {/* <Controls.CustomImageAvatar src={'test'} size={100} /> */}
                            <div
                                style={{
                                    padding: '10px 10px 10px 10px',
                                    backgroundColor: '#183136',
                                    height: '100%',
                                    borderRadius: '0.5rem'
                                }}
                            >
                                <div style={{ padding: '5px 5px 5px 5px', backgroundColor: '#183136' }}>
                                    <Grid container>
                                        {/* <Grid item sx={{ padding: '5px 5px 5px 5px' }}>
                                        <Controls.CustomImageAvatar size={50} rounded={50}></Controls.CustomImageAvatar>
                                    </Grid> */}
                                        <Grid item>
                                            <div
                                                style={{
                                                    backgroundColor: '#183136'
                                                }}
                                            >
                                                <Subtitle
                                                    style={{
                                                        fontSize: '1rem',
                                                        color: 'white'
                                                    }}
                                                >
                                                    Pépinière : {selectedInventoryItem?.inventory?.nursery?.name}
                                                </Subtitle>
                                                <div style={{ fontSize: '0.75rem', color: 'white' }}>
                                                    <b>Site : </b>
                                                    {selectedInventoryItem?.inventory?.nursery?.site?.name}
                                                </div>
                                                <div style={{ fontSize: '0.75rem', color: 'white' }}>
                                                    <b>Catégorie : </b>
                                                    {selectedInventoryItem?.inventory?.category}
                                                </div>
                                                <div style={{ fontSize: '0.75rem', color: 'white' }}>
                                                    <b>Désignation : </b>
                                                    {selectedInventoryItem?.designation}
                                                </div>
                                                <div style={{ fontSize: '0.75rem', color: 'white' }}>
                                                    {/* <Controls.CustomImageAvatar
                                                        title={'Photo'}
                                                        place="left"
                                                        size={80}
                                                        src={`${selectedInventoryItem?.inventory?.picture}`}
                                                    /> */}
                                                    {selectedInventoryItem?.picture && (
                                                        <>
                                                            <MUIButton
                                                                style={{
                                                                    textTransform: 'none',
                                                                    color: '#fff'
                                                                }}
                                                                variant="contained"
                                                                component="label"
                                                                startIcon={<FaImage />}
                                                                onClick={() =>
                                                                    openImageViewer(selectedInventoryItem?.picture)
                                                                }
                                                            >
                                                                Voir photos
                                                            </MUIButton>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                style={{ fontSize: '0.75rem', color: 'white' }}
                                            ></Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Grid>
                                    <Card style={{ width: '100%' }} sx={{ backgroundColor: '#183136' }}>
                                        <div
                                            style={{
                                                backgroundColor: '#183136',
                                                paddingTop: '16px',
                                                display: 'flex'
                                            }}
                                        >
                                            <Subtitle
                                                style={{
                                                    fontSize: '1.0rem',
                                                    color: '#14b67d'
                                                }}
                                            >
                                                Achats
                                            </Subtitle>
                                            <div style={{ width: '10px' }}></div>
                                            {/* <Controls.Button text={'Ajouter'} onClick={() => {}} /> */}
                                        </div>
                                        <div
                                            style={{
                                                height: '400px',
                                                overflow: 'auto',
                                                '&::-webkit-scrollbar': {
                                                    width: '0.5em'
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    backgroundColor: 'darkgrey',
                                                    outline: '1px solid slategrey'
                                                }
                                            }}
                                        >
                                            {dataInventory?.length > 0 &&
                                                dataInventory?.map((item, index) => {
                                                    console.log(item)
                                                    return (
                                                        <Container
                                                            key={index}
                                                            sx={{
                                                                ':hover': {
                                                                    backgroundColor: '#2b3640',
                                                                    cursor: 'pointer'
                                                                },
                                                                backgroundColor:
                                                                    selectedInventoryItem === item ? '#2b4347' : 'none',
                                                                borderRadius: '10px',
                                                                '&:hover': {
                                                                    backgroundColor: '#2b3640',
                                                                    cursor: 'pointer'
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                console.log(item)
                                                                setSelectedInventoryItem(item)
                                                                // setUserHaveToAdd(false)
                                                            }}
                                                        >
                                                            <div>
                                                                <Grid container sx={{ alignItems: 'center' }}>
                                                                    <Grid>
                                                                        <Subtitle
                                                                            style={{
                                                                                fontSize: '10px',
                                                                                color: 'white',
                                                                                padding: '5px'
                                                                            }}
                                                                        >
                                                                            {formatDate(item?.date_purchased)}
                                                                        </Subtitle>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Container>
                                                    )
                                                })}
                                        </div>
                                        {/* {plantMaterialData?.PlantMaterialItem?.length === 0 && (
                                        <Container>
                                            <p>...</p>
                                        </Container>
                                    )} */}
                                    </Card>
                                </Grid>
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={dataInventory ? 9 : 12}>
                        {dataInventory && (
                            <Controls.ModifyButton
                                onClick={() => setUserHaveToModify(!userHaveToModify)}
                                userHaveToModify={userHaveToModify}
                            />
                        )}
                        {!dataInventory && (
                            <>
                                <h1 className="font-semibold mb-[20px] ml-[30px] mt-[20px]">
                                    Délimitation administrative
                                </h1>
                                {/* <ProvinceCard site={values?.site} onChange={handleChange} /> */}
                                <ProvinceFilter setNeedLoad={setNeedLoad} filter={filter} setFilter={setFilter} />
                            </>
                        )}
                        <h1 className="font-semibold mb-[20px] ml-[30px] mt-[20px]">
                            Informations matérielles & outils
                        </h1>
                        <Grid container>
                            <Grid item xs={6}>
                                <Container>
                                    {!dataInventory && (
                                        <Controls.AutoCompleteSelect
                                            label="Affectation matériels"
                                            name="assignment"
                                            value={
                                                dataInventory?.nursery?.id ||
                                                values.assignment ||
                                                globalContext?.value?.activeCenterCom ||
                                                ''
                                            }
                                            onChange={handleChange}
                                            options={nurseryList}
                                            labelKey="name"
                                            valueKey="id"
                                            error={errors?.assignment}
                                            isDisabled={dataInventory ? true : false}
                                            isRequired={true}
                                        />
                                    )}

                                    {values.category === 'Intrant pépinière' && !dataInventory && (
                                        <Controls.AutoCompleteSelect
                                            label="Taille"
                                            name="potWidth"
                                            value={values.potWidth || ''}
                                            onChange={handleChange}
                                            options={potWidthData}
                                            labelKey="label"
                                            valueKey="label"
                                            error={errors?.potWidth}
                                            isDisabled={dataInventory ? true : false}
                                            isRequired={true}
                                        />
                                    )}
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="quantity"
                                        value={dataInventory ? selectedInventoryItem?.quantity : values?.quantity}
                                        size="small"
                                        label="Quantité"
                                        onChange={handleChange}
                                        type="number"
                                        error={errors?.quantity}
                                        isDisabled={dataInventory ? true : false}
                                        isRequired={true}
                                    />
                                    <Controls.AutoCompleteSelect
                                        label="Utilisateur"
                                        name="user"
                                        value={values?.user}
                                        onChange={handleChange}
                                        options={toolsUserOptions}
                                        labelKey="label"
                                        valueKey="label"
                                        error={errors?.user}
                                        isDisabled={dataInventory && !userHaveToModify ? true : false}
                                    />
                                    {/* <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="user"
                                        value={values?.user}
                                        size="small"
                                        label="Utilisateur"
                                        onChange={handleChange}
                                        error={errors?.user}
                                        isDisabled={dataInventory && !userHaveToModify ? true : false}
                                    /> */}
                                    <Controls.DatePicker
                                        id="outlined-disabled"
                                        label="Date d'entrée"
                                        // variant="outlined"
                                        name="date_purchased"
                                        value={values?.date_purchased || new Date()}
                                        size="small"
                                        onChange={handleChange}
                                        type="date"
                                        error={errors?.date_purchased}
                                        isDisabled={dataInventory && !userHaveToModify ? true : false}
                                        isRequired={true}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        value={values?.observation}
                                        size="small"
                                        label="Observation"
                                        name="observation"
                                        multiline
                                        rows={4}
                                        onChange={handleChange}
                                        error={errors?.observation}
                                        isDisabled={dataInventory && !userHaveToModify ? true : false}
                                    />
                                </Container>
                            </Grid>
                            <Grid item xs={6}>
                                <Container>
                                    {!dataInventory && (
                                        <>
                                            <Controls.AutoCompleteSelect
                                                label="Catégorie"
                                                name="category"
                                                value={dataInventory?.category || values.category || ''}
                                                onChange={handleChange}
                                                options={dataCategory}
                                                labelKey="label"
                                                valueKey="label"
                                                error={errors?.category}
                                                isDisabled={dataInventory ? true : false}
                                                isRequired={true}
                                            />
                                            {!potInNursery && (
                                                <>
                                                    {values.potWidth && values.category === 'Intrant pépinière' && (
                                                        <TextField
                                                            id="outlined-basic"
                                                            variant="outlined"
                                                            name="objective"
                                                            value={values?.objective}
                                                            size="small"
                                                            label="Objectif de production pour cette taille de pot"
                                                            onChange={handleChange}
                                                            type="number"
                                                            error={errors?.objective}
                                                            isDisabled={dataInventory ? true : false}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {values.category !== 'Intrant pépinière' && (
                                                <Controls.AutoCompleteSelect
                                                    label="Désignation"
                                                    name="designation"
                                                    value={values?.designation || ''}
                                                    onChange={handleChange}
                                                    options={designationOptions}
                                                    labelKey="label"
                                                    valueKey="label"
                                                    error={errors?.designation}
                                                    isDisabled={dataInventory ? true : false}
                                                    isRequired={true}
                                                />
                                                // <TextField
                                                //     id="outlined-disabled"
                                                //     label="Désignation"
                                                //     // variant="outlined"
                                                //     name="designation"
                                                //     value={values?.designation}
                                                //     size="small"
                                                //     onChange={handleChange}
                                                //     error={errors?.designation}
                                                //     isDisabled={dataInventory && !userHaveToModify ? true : false}
                                                // />
                                            )}
                                        </>
                                    )}

                                    <Controls.AutoCompleteSelect
                                        label="Etat"
                                        name="state"
                                        value={values.state || ''}
                                        onChange={handleChange}
                                        options={dataState}
                                        labelKey="label"
                                        valueKey="label"
                                        error={errors?.state}
                                        isDisabled={dataInventory && !userHaveToModify ? true : false}
                                        isRequired={true}
                                    />
                                    <Controls.AutoCompleteSelect
                                        label="Fonction du responsable"
                                        name="responsible"
                                        value={values.responsible || ''}
                                        onChange={handleChange}
                                        options={users}
                                        labelKey="name"
                                        valueKey="name"
                                        error={errors?.responsible}
                                        isDisabled={dataInventory && !userHaveToModify ? true : false}
                                        isRequired={true}
                                    />
                                    {!dataInventory && (
                                        <Controls.MultipleImageInput
                                            label="Photo"
                                            onChange={handleInputChange}
                                            name="photo"
                                            setPhoto={setPhoto}
                                        />
                                    )}
                                </Container>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!dataInventory && (
                    <div className="flex justify-end items-end m-5 p-5">
                        <Button text="Enregistrer" onClick={handleSubmit} haveSpin={true} disabled={userHaveToPost} />
                    </div>
                )}
                {userHaveToModify && (
                    <div className="flex justify-end items-end m-5 p-5">
                        <Button text="Modifier" onClick={handleModify} haveSpin={true} disabled={userHaveToPost} />
                    </div>
                )}
            </Form>
        </>
    )
}
