import React, { useState, useEffect, useContext } from 'react'

import { Form, useForm } from '../../../../applications/hooks/UseForm'
import Controls from '../../../../components/Entry/Controls'
import { useNavigate } from 'react-router-dom'
import Notification from '../../../../layouts/Notification'

import CustomSelect from '../../../../components/Entry/CustomSelect'
import moment from 'moment'
import PlatDrainTrackingService from '../../plat_drain/services/plat_drain_tracking'
import PlatDrainService from '../../plat_drain/services/plat_drain'
import { Container, Grid } from '@mui/material'
import { context } from '../utils/hooks/PlatDrainTrackingContext'
import QrViewer from '../../../../components/Entry/QrViewer'
import Popup from '../../../../layouts/Popup'
import PopupTrackingForm from './Form/PopupTrackingForm'
import { useAuth } from '../../../../applications/hooks/UseAuth'

const { Input: TextField, Button } = Controls

const initialFValues = {
    code_plat_drain: '',
    date: new Date().toISOString(),
    nbr_death: '',
    nbr_refilled: '',
    average_number: '',
    average_height: '',
    responsable: '',
    platDrains: ''
}

export const PlatDrainTrackingForm = (props) => {
    const { records } = props

    const navigate = useNavigate()

    const [platDrain, setPlatDrain] = useState([])
    const [userHaveToAdd, setUserHaveToAdd] = useState(false)
    const [userHaveToTrackYoungPlant, setUserHaveToTrackYoungPlant] = useState(false)
    const [popupValues, setPopupValues] = useState({})
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const {user} = useAuth()

    const trackingContext = useContext(context)

    useEffect(() => {
        if (records) {
            console.log('dans le tracking : ', records)
            setValues((prevFormData) => ({
                ...prevFormData,
                code_plat_drain: records?.code_plat_drain || '',
                date: records?.date ? moment(records.date).format('YYYY-MM-DD') : '',
                nbr_refilled: records?.nbr_refilled || 0,
                average_number: records?.average_number || 0,
                average_height: records?.average_height || 0,
                responsable: records?.responsable || '',
                nbr_death: records?.nbr_death || '',
                platDrains: records?.platDrains?.id || ''
            }))
        }
    }, [records])

    useEffect(() => {
        PlatDrainService.getAllPlatDRain()
            .then((users) => {
                if (users) {
                    setPlatDrain(users)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('platDrains' in fieldValues)
            temp.platDrains = fieldValues.platDrains.length != 0 ? '' : 'Ce champ est requis.'

        //surface

        //planting_date_begin
        if ('date' in fieldValues) temp.date = fieldValues.date ? '' : 'Ce champ est requis.'
        //planting_date_end
        // if ('nbr_death' in fieldValues) temp.nbr_death = fieldValues.nbr_death ? '' : 'Ce champ est requis.'
        // if ('nbr_refilled' in fieldValues) temp.nbr_refilled = fieldValues.nbr_refilled ? '' : 'Ce champ est requis.'
        if ('average_number' in fieldValues)
            temp.average_number = fieldValues.average_number ? '' : 'Ce champ est requis.'
        if ('average_height' in fieldValues)
            temp.average_height = fieldValues.average_height ? '' : 'Ce champ est requis.'
        if ('responsable' in fieldValues) temp.responsable = fieldValues.responsable ? '' : 'Ce champ est requis.'

        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate)

    useEffect(() => {
        if (trackingContext) {
            setUserHaveToAdd(trackingContext?.userHaveToAdd)
            setValues(initialFValues)
        }
    }, [trackingContext])

    useEffect(() => {
        if (popupValues?.averageNumber && popupValues?.averageHeight) {
            setValues({
                ...values,
                average_number: popupValues?.averageNumber,
                average_height: popupValues?.averageHeight,
                nbr_refilled: popupValues?.nbr_refilled,
                nbr_death: popupValues?.nbr_death
            })
        }
    }, [popupValues])

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('valeur dans plat drain tracking : ', values)
        if (validate()) {
            setUserHaveToPost(true)
            try {
                const dataToSend = { ...values, user: [user.firstname, user.lastname].join(' ') }
                const platDrainId = parseInt(values.platDrains)
                delete dataToSend.platDrains

                const nbrDeathValue = parseInt(dataToSend.nbr_death)
                const nbr_refilledValue = parseInt(dataToSend.nbr_refilled)
                const average_numberValue = parseInt(dataToSend.average_number)
                const average_heightValue = parseInt(dataToSend.average_height)
                if (!isNaN(nbrDeathValue)) {
                    dataToSend.nbr_death = nbrDeathValue
                    dataToSend.nbr_refilled = nbr_refilledValue
                    dataToSend.average_number = average_numberValue
                    dataToSend.average_height = average_heightValue
                    dataToSend.platDrainId = platDrainId

                    if (records?.id) {
                        await PlatDrainTrackingService.updatePlatDrainTracking(records.id, dataToSend)
                        new Notification(PlatDrainTrackingService.messages.update_success, 1)
                        setUserHaveToPost(false)
                    } else {
                        console.log('data to send : ', dataToSend)
                        delete dataToSend.code_plat_drain
                        await PlatDrainTrackingService.addPlatDrainTracking(dataToSend)
                        new Notification(PlatDrainTrackingService.messages.create_success, 1)
                        setUserHaveToPost(false)
                    }
                    navigate(-1)
                } else {
                    setUserHaveToPost(false)
                    console.error('La valeur de la quantité doit être un nombre')
                }
            } catch (error) {
                setUserHaveToPost(false)
                console.error('Erreur lors de la soumission du formulaire : ', error)
                new Notification(PlatDrainTrackingService.messages.create_error, 0)
            }
        }
    }

    const handleChange = (e) => {
        handleInputChange(e)
        setValues((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value
        }))
    }

    const handleTracking = () => {
        setUserHaveToTrackYoungPlant(true)
    }

    return (
        <Form>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={!userHaveToAdd ? 8 : 12}>
                        <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                            <Grid xs={6}>
                                <Container>
                                    <TextField
                                        id="outlined-disabled"
                                        label="Responsable de suivis"
                                        name="responsable"
                                        value={values?.responsable}
                                        size="small"
                                        onChange={handleChange}
                                        error={errors?.responsable}
                                        isDisabled={userHaveToAdd ? false : true}
                                    />
                                    <Controls.DatePicker
                                        label="Date de suivi"
                                        name="date"
                                        onChange={handleChange}
                                        type="date"
                                        value={values?.date}
                                        error={errors?.date}
                                        isDisabled={userHaveToAdd ? false : true}
                                    />
                                </Container>
                            </Grid>
                            <Grid xs={6}>
                                <Container>
                                    <Controls.AutoCompleteSelect
                                        label="ID Plate bande"
                                        name="platDrains"
                                        value={values?.platDrains || ''}
                                        onChange={handleChange}
                                        options={platDrain}
                                        labelKey="label"
                                        valueKey="id"
                                        error={errors?.platDrains}
                                        isDisabled={userHaveToAdd ? false : true}
                                        isRequired={true}
                                    />
                                </Container>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                            <Grid item xs={12}>
                                {userHaveToAdd && (
                                    <div style={{ marginBottom: '20px' }}>
                                        <Controls.Button text="Suivi de chaque plant" onClick={handleTracking} />
                                    </div>
                                )}
                            </Grid>
                            <Grid xs={6}>
                                <Container>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="nbr_death"
                                        value={values?.nbr_death}
                                        size="small"
                                        label="Nombre de morts"
                                        onChange={handleChange}
                                        type="number"
                                        error={errors?.nbr_death}
                                        isDisabled={true}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="average_number"
                                        value={values?.average_number}
                                        size="small"
                                        label="Nombre moyenne de feuilles"
                                        onChange={handleChange}
                                        type="number"
                                        error={errors?.average_number}
                                        isDisabled={true}
                                    />
                                </Container>
                            </Grid>
                            <Grid xs={6}>
                                <Container>
                                    <TextField
                                        id="outlined-disabled"
                                        label="Nombre regarnies"
                                        // variant="outlined"
                                        name="nbr_refilled"
                                        value={values?.nbr_refilled}
                                        size="small"
                                        onChange={handleChange}
                                        type="number"
                                        error={errors?.nbr_refilled}
                                        isDisabled={true}
                                    />
                                    <TextField
                                        id="outlined-disabled"
                                        label="Hauteur moyenne des jeunes plants"
                                        // variant="outlined"
                                        name="average_height"
                                        value={values?.average_height}
                                        size="small"
                                        onChange={handleChange}
                                        type="number"
                                        error={errors?.average_height}
                                        isDisabled={true}
                                    />
                                </Container>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!userHaveToAdd && (
                        <Grid item xs={4} style={{ paddingTop: '20px' }}>
                            <Container>
                                <QrViewer value={records?.code_qr} size="50%" />
                            </Container>
                        </Grid>
                    )}
                </Grid>
            </Container>
            {userHaveToAdd && (
                <div className="flex justify-end items-end m-5 p-5">
                    <Button text="Enregistrer" onClick={handleSubmit} haveSpin={true} disabled={userHaveToPost} />
                </div>
            )}
            <Popup
                title="Suivi de chaque plant"
                openPopup={userHaveToTrackYoungPlant}
                setOpenPopup={setUserHaveToTrackYoungPlant}
            >
                <PopupTrackingForm
                    popupValues={popupValues}
                    setPopupValues={setPopupValues}
                    setUserHaveToTrackYoungPlant={setUserHaveToTrackYoungPlant}
                />
            </Popup>
        </Form>
    )
}
