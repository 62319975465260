import React, { useState } from 'react'
import Table from '../../../components/DataTable/Table'
import { ReactComponent as IconQuadrillage } from '../../../assets/icons/quadrillage.svg'
import PlanterGroup from '../../mangrove_planter/components/popupView/PlanterGroup'
import GridView from '../../../components/popupView/GridView'

function PlantingList(props) {
    const { haveAccessTo, service, needLoad, currentPage, Form, setNeedLoad, drawer } = props
    const [openPopup, setOpenPopup] = useState(false)
    const [records, setRecords] = useState(null)
    const [openPopupGrid, setOpenPopupGrid] = useState(false)
    const [gridData, setGridData] = useState(null)

    const headCells = [
        // { id: 'code', label: 'Code plantation' },
        {
            id: 'land_id',
            label: 'ID parcelle',
            link: 'true',
            showView: { link: '/app/mangrove/lands/view', param: 'land_id' }
        },
        { id: 'site', label: 'Site' },
        { id: 'species', label: 'Espèce plantée', type: 'species' },
        { id: 'origin', label: 'Provenance' },
        { id: 'customer', label: 'Client RSE / Partenaire' },
        { id: 'plantation_type', label: 'Type de plantation' },
        { id: 'surface', label: 'Superficie plantée (ha)', type: 'Integer_ha' },
        {
            id: 'grid',
            type: 'actions',
            label: 'Quadrillage fin de session',
            disableSorting: true,
            content: <IconQuadrillage fontSize="small" />,
            actionButton: true,
            actions: (item) => {
                setOpenPopupGrid(true)
                setGridData(item.grid)
            }
        },
        // {
        //     id: 'nbr_groupes',
        //     label: 'Nombre de groupe',
        //     type: 'actions',
        //     actionButton: true,
        //     actions: (item) => {
        //         setOpenPopup(true)
        //         setRecords(item)
        //     }
        // },
        {
            id: 'actions',
            type: 'actions',
            label: 'Actions',
            disableSorting: true,
            showEdit: false,
            showRemove: true,
            showView: '/app/mangrove/planting/edit',
            forcePassId: true
        }
    ]

    return (
        <>
            <Table
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                drawer={drawer}
                Form={Form}
                hideFilterButton={true}
                showSiteButton={true}
                title="Détail de la plantation"
            />
            {records && (
                <PlanterGroup
                    records={records}
                    setRecords={setRecords}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                />
            )}

            <GridView
                openPopup={openPopupGrid}
                setOpenPopup={setOpenPopupGrid}
                title="Polygone de quadrillage"
                gridData={gridData}
            />
        </>
    )
}

export default PlantingList
