import React, { useState } from 'react'
import PageHeader from '../../components/Typography/PageHeader'
// import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import UsersList from './components/users/UserList'
import { useAuth } from '../../applications/hooks/UseAuth'
import UserForm from './components/users/UserForm'
import UserService from '../../pages/users/services/User'
import ToolbarButton from '../../components/DataTable/ToolbarButton'

const CURRENT_PAGE = 'administration_users'

function Users() {
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const useDrawer = false
    const service = UserService.load()

    return (
        <div className={' w-full bg-base-100 font-sans font-bold'} style={{ paddingBottom: 20 }}>
            <PageHeader
                title={'Liste des utilisateurs'}
                subTitle={'Gestion des utilisateurs'}
                // icon={<ManageAccountsOutlinedIcon color="primary" fontSize="large" />}
            >
                <ToolbarButton
                    moduleTitle="d'un utilisateur"
                    service={service}
                    drawer={useDrawer}
                    haveAccessTo={haveAccessTo}
                    currentPage={CURRENT_PAGE}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={UserForm}
                />
            </PageHeader>
            <UsersList
                service={service}
                drawer={useDrawer}
                Form={UserForm}
                haveAccessTo={haveAccessTo}
                currentPage={CURRENT_PAGE}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
            />
        </div>
    )
}

export default Users
