import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'
//import UserService from '../../pages/users/services/User'
import { logOut, setToken } from '../reducers/AuthSlice'

export function useAuth(reloadToken = false) {
    const dispatch = useDispatch()
    let TOKEN = useSelector((state) => state.auth.token) || sessionStorage.getItem('token') || null
    let isLoggedIn = false
    let decodedToken = null

    // if (reloadToken && sessionStorage.getItem('sessID')) {
    //     UserService.getSession()
    //         .then((data) => {
    //             return data
    //         })
    //         .then((data) => {
    //             const session = testLoggedIn(data.token, isLoggedIn)
    //             TOKEN = session.token
    //             decodedToken = session.decodedToken
    //             isLoggedIn = TOKEN ? true : session.isLoggedIn

    //             dispatch(setToken({ token: TOKEN }))
    //         })
    //         .catch((err) => {
    //             console.log('err', err)
    //         })
    // } else {
    //     const session = testLoggedIn(TOKEN, isLoggedIn)
    //     TOKEN = session.token
    //     decodedToken = session.decodedToken
    //     isLoggedIn = session.isLoggedIn
    // }

    const session = testLoggedIn(TOKEN, isLoggedIn)
    TOKEN = session.token
    decodedToken = session.decodedToken
    isLoggedIn = session.isLoggedIn

    function testLoggedIn(TOKEN, isLoggedIn) {
        const currentDate = new Date()
        const isLoginPage = ['login'].some((r) => window.location.href.includes(r))

        let decodedToken = null

        if (TOKEN) {
            decodedToken = jwt_decode(TOKEN)
            // console.log(decodedToken)
        }

        if ((!TOKEN && !isLoginPage) || (decodedToken?.exp * 1000 < currentDate?.getTime() && !isLoginPage)) {
            isLoggedIn = false
        } else {
            if (!isLoginPage) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`
                isLoggedIn = true
            } else {
                isLoggedIn = false
            }
        }

        return { isLoggedIn, token: TOKEN, decodedToken }
    }

    const haveAccessTo = (route, valid, isParent = false) => {
        if (decodedToken) {
            if (isParent) {
                return (
                    decodedToken.role === 'Administrator' ||
                    Object.keys(decodedToken.permissions).some((key) => {
                        return key.includes(route) && decodedToken.permissions[key].includes('show')
                    })
                )
            } else {
                // console.log('Route : >>>>>>>>>>> ', route, ' valid : >>>>>>>>>>> ', decodedToken)
                // console.log("autorisation atoo : >>>>>>>>>>>> ",decodedToken?.permissions[route].includes(valid))
                return decodedToken.role === 'Administrator' || decodedToken?.permissions[route].includes(valid)
            }
        }
        return false
    }

    const handleLogOut = () => {
        if (dispatch(logOut())) sessionStorage.clear()
    }

    return {
        isLoggedIn: isLoggedIn,
        token: TOKEN,
        user: decodedToken?.user || {},
        role: decodedToken?.role || '',
        permissions: decodedToken?.permissions || [],
        haveAccessTo: haveAccessTo,
        handleLogOut: handleLogOut
    }
}
