import React, { useEffect, useState } from 'react'
import { Container, Grid } from '@mui/material'
import Subtitle from '../../../../../../components/Typography/Subtitle'
import DatePickerInput from '../../../../../../components/Entry/DatePicker'
import Controls from '../../../../../../components/Entry/Controls.js'

const { Input: TextField } = Controls

const useStyles = {
    photoContainer: {
        padding: '30px 30px 30px 30px',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000'
    },
    title: {
        padding: '30px 30px 30px 30px',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem'
    },
    cardContainer: {
        backgroundColor: '#f8f8f8',
        border: 'none',
        boxShadow: 'none'
    },
    container: {
        display: 'flex',
        backgroundColor: 'transparent',
        flexDirection: 'column',
        height: '100%',
        border: 'none',
        boxShadow: 'none',
        '& .MuiTextField-root': {
            marginBottom: '30px',
            marginLeft: '0px',
            '& .MuiOutlinedInput-root': {
                backgroundColor: '#ffffff'
            }
        }
    },
    column: {},
    titleContainer: {
        display: 'flex',
        padding: '0px',
        height: '60px',
        margin: 'auto',
        maxWidth: '100%',
        alignItems: 'center'
    },
    redBox: {
        backgroundColor: '#677788',
        width: '4px',
        height: '100%'
    },
    addPhoto: {
        width: 70,
        height: 70,
        borderRadius: '5px',
        color: '#c5c4c4',
        backgroundColor: '#ececec',
        '&:hover': {
            backgroundColor: '#c4c4c4',
            cursor: 'pointer'
        }
    }
}

function Form(props) {
    const { title, classes, records } = props
    const handleChange = () => {}

    const [plotPhotos, setPlotPhotos] = useState(null)

    const diffDate = (d1, d2) => {
        if (!(d1 instanceof Date)) d1 = new Date(d1)
        if (!(d2 instanceof Date)) d2 = new Date(d2)

        const diff = Math.abs(d2 - d1)
        const year = Math.floor(diff / (1000 * 60 * 60 * 24 * 365))
        const month = Math.floor((diff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30))

        if (year > 0) {
            return `Année ${year}`
        } else {
            return `M${month}`
        }
    }

    useEffect(() => {
        if (records && records?.photos) {
            console.log(JSON.parse(records?.photos))
            console.log(records?.photos)
            setPlotPhotos(JSON.parse(records?.photos))
        }
    }, [records])

    return (
        <>
            <div style={classes.titleContainer}>
                <div style={classes.redBox}></div>
                <div style={classes?.title}>
                    <Subtitle
                        style={{
                            fontSize: '0.8rem',
                            color: '#677788'
                        }}
                    >{`${title} - ${diffDate(records?.initial_planting_date, records?.monitoring_date)}`}</Subtitle>
                </div>
            </div>
            <div style={classes.container}>
                <Grid container>
                    <Grid item xs={4}>
                        <Container className="">
                            <Controls.DatePicker
                                isDisabled={true}
                                name="initial_planting_date"
                                label="Date plantation"
                                value={records?.initial_planting_date || ''}
                                onChange={handleChange}
                            />
                            <TextField
                                isDisabled={true}
                                size="small"
                                label="Nombre d'arbre vivant"
                                name="number_living_tree"
                                fullWidth
                                value={records?.number_living_tree || ''}
                            />
                            <TextField
                                isDisabled={true}
                                fullWidth
                                size="small"
                                label="Entretien à faire"
                                name="maintenance_to_do"
                                value={records?.maintenance_to_do || ''}
                            />

                            <TextField
                                isDisabled={true}
                                fullWidth
                                size="small"
                                name="pressions"
                                label="Pression"
                                value={records?.pressions || ''}
                            />
                        </Container>
                    </Grid>
                    <Grid item xs={4}>
                        <Container className="">
                            <TextField
                                isDisabled={true}
                                size="small"
                                label="Nombre d'arbres plantés"
                                name="initial_number_tree_planted"
                                fullWidth
                                value={records?.initial_number_tree_planted || ''}
                            />
                            <TextField
                                isDisabled={true}
                                fullWidth
                                size="small"
                                name="survival_rate"
                                label="Taux de survie (%)"
                                value={records?.survival_rate || ''}
                            />

                            <TextField
                                isDisabled={true}
                                fullWidth
                                size="small"
                                label="Remarque sur la parcelle"
                                name="remarks"
                                value={records?.remarks || ''}
                            />
                            <TextField
                                isDisabled={true}
                                fullWidth
                                size="small"
                                label="Entretiens effectués"
                                name="maintenance_carried_out"
                                value={records?.maintenance_carried_out || ''}
                            />
                        </Container>
                    </Grid>
                    <Grid item xs={4}>
                        <Container className="">
                            <Controls.DatePicker
                                isDisabled={true}
                                name="monitoring_date"
                                label="Date de suivi"
                                value={records?.monitoring_date || ''}
                                onChange={handleChange}
                                fullWidth={true}
                            />
                            <TextField
                                isDisabled={true}
                                fullWidth
                                size="small"
                                label="Regarnissage à faire"
                                name="regarnish_to_do"
                                value={records?.regarnish_to_do || ''}
                            />

                            <TextField
                                isDisabled={true}
                                fullWidth
                                size="small"
                                label="Problèmes pédologique"
                                name="pedagogical_problem"
                                value={records?.pedagogical_problem || ''}
                            />
                        </Container>
                    </Grid>
                </Grid>

                {records?.photos && (
                    <div style={classes?.photoContainer}>
                        <Subtitle
                            style={{
                                fontSize: '0.9rem',
                                color: '#677788'
                            }}
                        >
                            Photo
                        </Subtitle>

                        {plotPhotos && plotPhotos.length > 0 && (
                            <>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                    {plotPhotos?.map((photo, index) => (
                                        <Controls.CustomImageAvatar
                                            key={index}
                                            title="Photo de la parcelle"
                                            place="left"
                                            size={80}
                                            src={`${photo}`}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

function MonitoringForm(props) {
    const { records } = props
    const classes = useStyles
    const title = 'Monitoring'
    return (
        <>
            <Form title={title} classes={classes} records={records} />
        </>
    )
}

export default MonitoringForm
