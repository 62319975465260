import { Card, Container, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import Subtitle from '../../../../../../../../components/Typography/Subtitle'
import Controls from '../../../../../../../../components/Entry/Controls'
import { isEmptyObject } from '../../../../../../../../applications/UtilFunctions'
import PepImage from '../../../../../../../../assets/image/agro_nursery.png'
import { Avatar } from '@mui/material'

const useStyles = {
    cardContainer: {
        backgroundColor: '#183136',
        margin: '15px',
        border: 'none',
        borderRadius: '15px',
        boxShadow: 'none',
        width: 'auto',
        padding: '0 10px 0 10px'
    },
    card: {
        width: '100%',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '& .MuiGrid-root': {
            padding: '20px 5px 20px 5px'
        }
    },
    title: {
        padding: `20px 0 20px 0`,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        borderBottom: '1px solid white',
        borderRadius: '0'
    },
    subtitle: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '& .MuiTypography-root': {
            color: 'white'
        }
    },
    voirPhoto: {
        fontSize: '13px',
        textAlign: 'end',
        padding: '0 20px 0 20px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    cardContent: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    speciesNursery: {
        backgroundColor: 'transparent',
        width: '100%',
        border: 'none',
        boxShadow: 'none'
    },
    grid: {
        padding: '0 0 0 0'
    }
}

const stylePerso = {
    grid: {
        padding: '0 0 0 0'
    },
    card_espece_image: {
        padding: '0 10px 0 10px'
    },
    card_espece_list: {
        padding: '5px 5px 5px 5px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%'
    }
}
function NurserySiteCard({
    records,
    dataSpeciesNursery,
    selectedSpecie,
    setSelectedSpecie,
    setSupplementData,
    recorgedPotData
}) {
    const classes = useStyles
    const [dataSiteCard, setDataSiteCard] = useState({})
    const [dataNursery, setDataNursery] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (!isEmptyObject(records)) {
            setDataNursery(records)
            setDataSiteCard(records?.site)
            setLoading(false)
        }
    }, [records])

    return (
        <>
            <Controls.Loader isLoading={loading} />
            <div
                style={classes.cardContainer}
                //add 10px padding to the top and the bottom of the paper
                sx={{ padding: '10px 10px 10px 10px' }}
            >
                <div style={classes.title}>
                    <Grid container>
                        <Grid item sx={{ padding: '5px 5px 5px 5px' }}>
                            <Avatar src={PepImage} rounded="50" size={60} />
                        </Grid>
                        <Grid item>
                            <Paper style={classes.subtitle}>
                                <Subtitle
                                    style={{
                                        fontSize: '1rem',
                                        color: 'white'
                                    }}
                                >
                                    Pépinière {dataNursery?.center}
                                </Subtitle>
                            </Paper>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                style={{ fontSize: '0.75rem', color: 'white' }}
                            >
                                <b>Site : </b>
                                {dataSiteCard?.name}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                style={{ fontSize: '0.70rem', color: 'white' }}
                            >
                                Objectif de production : {dataNursery.target}{' '}
                                <i style={{ fontSize: '0.6rem' }}>(jeunes plants)</i>
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                style={{ fontSize: '0.70rem', color: 'white' }}
                            >
                                Pots rebouchés : <br />
                                {recorgedPotData?.map((recorgedPot) => {
                                    return <>{`- ${recorgedPot?.pot} : ${recorgedPot?.quantity_refilled}`} <br /></>
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Grid style={classes.cardContent}>
                    <div style={classes.card} sx={{ width: '100%' }}>
                        <div style={classes.subtitle}>
                            <Subtitle
                                style={{
                                    fontSize: '1.0rem',
                                    color: '#14b67d'
                                }}
                            >
                                Espèces
                            </Subtitle>
                        </div>
                        {dataSpeciesNursery.length > 0 &&
                            dataSpeciesNursery.map((item) => {
                                return (
                                    <Container
                                        style={stylePerso.card_espece_list}
                                        sx={{
                                            backgroundColor: selectedSpecie === item.id ? '#2b4347' : 'none',
                                            borderRadius: '10px',
                                            '&:hover': { backgroundColor: '#2b3640', cursor: 'pointer' },
                                            width: '100%'
                                        }}
                                        onClick={() => setSelectedSpecie(item.id)}
                                    >
                                        <div style={{width: '100%'}}>
                                            <Grid container style={stylePerso.grid} sx={{ alignItems: 'center' }}>
                                                <Grid xs={2} item style={stylePerso.card_espece_image}>
                                                    <Controls.CustomImageAvatar
                                                        title={`Photo : ${item?.name}`}
                                                        rounded="50"
                                                        size={30}
                                                        place="left"
                                                        src={item?.photos}
                                                    />
                                                </Grid>
                                                <Grid item xs={10} style={stylePerso.grid}>
                                                    <Subtitle
                                                        style={{
                                                            fontSize: '0.75rem',
                                                            color: 'white',
                                                            padding: '5px'
                                                        }}
                                                    >
                                                        {item?.name} ({item?.quantity})
                                                    </Subtitle>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Container>
                                )
                            })}
                        {dataSpeciesNursery.length === 0 && (
                            <Container style={stylePerso.card_espece_list}>
                                <p>...</p>
                            </Container>
                        )}
                    </div>
                </Grid>
            </div>
        </>
    )
}

export default NurserySiteCard
