import React from 'react'

export const DashboardInput = (props) => {
    const { title, showImg, src, value, content, textColor } = props
    return (
        <div className="w-full">
            <div className="font-semibold text-slate-500">{title}</div>
            <div className="border border-slate-200 rounded-xl p-4 max-h-[60px]">
                {showImg ? (
                    <div className="flex gap-[20px] w-full ">
                        <div>
                            <img src={src} className="w-[30px] h-[30px] " alt="img" />
                        </div>
                        <div className={`font-semibold ${textColor} text-lg`}>{value}</div>
                    </div>
                ) : (
                    <div>
                        <div className="text-slate-500">{content}</div>
                    </div>
                )}
            </div>
        </div>
    )
}
