import Service from '../../../applications/Service.js'

class CalibrationService extends Service {
    static messages = {
        update_success: 'Modification étallonage avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création étallonage avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/calibration')
        return this
    }

}

export default CalibrationService
