import React, { createContext, useEffect, useState } from 'react'
import ImportService from '../../services/ImportService.js'
import Loader from '../../../../components/Entry/Loading.js'

export const Context = createContext({})

function DataImportContext(props) {
    const { needLoad, setNeedLoad, children } = props
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(null)
    const service = ImportService.load()

    useEffect(() => {
        const getNurseryData = async () => {
            setLoading(true)
            const importData = await service.getDataImport()
            if (importData) {
                setValue(importData)
                console.log('données import :', importData)
            }
        }
        if (needLoad) {
            getNurseryData()
            setLoading(false)
            setNeedLoad(false)
        }
    }, [needLoad, setNeedLoad, service])

    return (
        <>
            <Loader isLoading={loading} />
            <Context.Provider value={value}>{children}</Context.Provider>
        </>
    )
}

export default DataImportContext
