import React, { useEffect, useState } from 'react'
import Controls from '../../Entry/Controls'

function ContentListViewer(props) {
    const { values, height = null, haveSearch = false, onClick = null, label = null } = props
    const [isHovered, setIsHovered] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    useEffect(() => {
        if (values) {
            console.log(values)
        }
    }, [values])
    return (
        <>
            {label && (
                <b
                    style={{
                        fontSize: '12px',
                        textDecoration: 'underline'
                    }}
                >
                    {label}
                </b>
            )}
            <div
                style={{
                    marginTop: '5px',
                    overflowY: 'scroll',
                    height: height,
                    maxHeight: '300px',
                    border: '1px solid #ccc',
                    padding: '10px',
                    borderRadius: '5px',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#d0e0d7 #E7F2EE',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                        height: '8px',
                        background: '#d0e0d7'
                    }
                }}
            >
                {haveSearch && (
                    <div
                        style={{
                            marginBottom: '10px',
                            border: '1px solid #ccc',
                            padding: '10px',
                            borderRadius: '5px'
                        }}
                    >
                        <b>Rechercher</b>
                    </div>
                )}
                {values?.length === 0 && <p style={{ textAlign: 'center' }}>(Aucun contenu disponible)</p>}
                {values?.map((item, key) => {
                    return (
                        <div
                            key={item?.id}
                            style={{
                                marginBottom: '10px',
                                border: '1px solid #ccc',
                                padding: '10px',
                                borderRadius: '5px',
                                cursor: onClick ? 'pointer' : 'default',
                                backgroundColor:
                                    onClick && isHovered && selectedItem?.id === item?.id ? '#badeaa' : '#fff',
                                transition: 'background-color 0.3s ease'
                            }}
                            onClick={onClick ? () => onClick(item) : null}
                            onMouseEnter={() => {
                                setIsHovered(true)
                                setSelectedItem(item)
                            }}
                            onMouseLeave={() => {
                                setIsHovered(false)
                                setSelectedItem(null)
                            }}
                        >
                            <b>{item?.title}</b>
                            {item?.contents?.map((content, index) => {
                                return (
                                    <div key={index}>
                                        {!content?.photo && (
                                            <>
                                                {content?.label} : {content?.value}{' '}
                                            </>
                                        )}
                                        {content?.photo && (
                                            <>
                                                <Controls.CustomImageAvatar
                                                    title={item?.title}
                                                    size={70}
                                                    rounded={10}
                                                    src={content?.photo || ''}
                                                />
                                            </>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default ContentListViewer
