import { Avatar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    photo: {
        marginRight: 'auto',
        width: 70,
        height: 70,
        borderRadius: '10%'
    }
}))

function ImageAvatar(props) {
    const { src, other } = props
    const classes = useStyles()
    const uploadRoute = [process.env.PUBLIC_URL, 'uploads'].join('/')
    const imageSrc = src ? [uploadRoute, src].join('/') : `${uploadRoute}/default.png`

    return (
        <>
            <Avatar className={classes.photo} src={imageSrc} {...other}  />
        </>
    )
}

export default ImageAvatar
