import React, { useState, useEffect, useContext } from 'react'

import { Form, useForm } from '../../../../applications/hooks/UseForm'
import Controls from '../../../../components/Entry/Controls'
import { useNavigate } from 'react-router-dom'
import Notification from '../../../../layouts/Notification'

import CustomSelect from '../../../../components/Entry/CustomSelect'
import moment from 'moment'
import PlatDrainService from '../../plat_drain/services/plat_drain'
import OutingService from '../services/outing'
import SpeciesService from '../../../species/services/Species'
import AutoCompleteSelect from '../../../../components/Entry/AutoCompleteSelect'
import PlantingService from '../../../mangrove_planting/services/Planting'
import { Box, Container, Grid } from '@mui/material'
import { context } from '../utils/hooks/MangroOutingContext'
import QrViewer from '../../../../components/Entry/QrViewer'
import { useAuth } from '../../../../applications/hooks/UseAuth'

const { Input: TextField, Button } = Controls

const initialFValues = {
    code_plat_drain: '',
    release_date: new Date().toISOString(),
    plat_drain_quantity: '',
    nbr_remaining: '',
    nbr_output: '',
    allocation_plot: '',
    plat_drains: '',
    species: '',
    planting: ''
}

export const OutingForm = (props) => {
    const { records } = props

    const navigate = useNavigate()

    const dataContext = useContext(context)

    const [userHaveToAdd, setUserHaveToAdd] = useState(false)
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const {user} = useAuth()

    useEffect(() => {
        if (dataContext) {
            console.log('outing context : ', dataContext)
            setUserHaveToAdd(dataContext?.userHaveToAdd)
        }
    }, [dataContext])

    const [platDrain, setPlatDrain] = useState([])
    const [specie, setSpecie] = useState([])
    const [plants, setPlants] = useState([])

    useEffect(() => {
        if (records) {
            console.log(records)
            setValues((prevFormData) => ({
                ...prevFormData,
                code_plat_drain: records?.code_plat_drain || '',
                release_date: records?.release_date
                    ? moment(records.release_date).format('YYYY-MM-DD')
                    : new Date().toISOString(),
                plat_drain_quantity: records?.plat_drain_quantity || 0,
                nbr_remaining: records?.nbr_remaining || 0,
                nbr_output: records?.nbr_output || 0,
                allocation_plot: records?.allocation_plot || '',
                plat_drains: records?.plat_drains?.id || '',
                species: records?.species?.id || '',
                planting: records?.planting?.id || '',
                quantity: records?.plat_drains?.quantity || 0,
            }))
        } else {
            setValues(initialFValues)
        }
    }, [records])

    useEffect(() => {
        PlatDrainService.getAllPlatDRain()
            .then((users) => {
                if (users) {
                    setPlatDrain(users)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [])

    useEffect(() => {
        SpeciesService.getAllSpecies()
            .then((users) => {
                if (users) {
                    setSpecie(users)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [])

    useEffect(() => {
        PlantingService.getAllPlanting()
            .then((users) => {
                if (users) {
                    setPlants(users)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('plat_drains' in fieldValues)
            temp.plat_drains = fieldValues.plat_drains.length != 0 ? '' : 'Ce champ est requis.'

        if ('planting' in fieldValues) temp.planting = fieldValues.planting.length != 0 ? '' : 'Ce champ est requis.'

        if ('release_date' in fieldValues) temp.release_date = fieldValues.release_date ? '' : 'Ce champ est requis.'
        if ('nbr_output' in fieldValues) temp.nbr_output = fieldValues.nbr_output ? '' : 'Ce champ est requis.'

        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validate()) {
            setUserHaveToPost(true)
            try {
                const dataToSend = { ...values, user: [user.firstname, user.lastname].join(' ') }
                const plat_drain_id = parseInt(values.plat_drains)
                const specie_id = parseInt(values.species)
                const planting_id = parseInt(values.planting)
                delete dataToSend.plat_drains
                delete dataToSend.species
                delete dataToSend.planting
                delete dataToSend.plat_drain_quantity
                delete dataToSend.quantity

                const nbr_remaining_value = parseInt(dataToSend.nbr_remaining)
                const nbr_output_value = parseInt(dataToSend.nbr_output)
                if (!isNaN(nbr_output_value)) {
                    dataToSend.nbr_remaining = nbr_remaining_value
                    dataToSend.nbr_output = nbr_output_value
                    dataToSend.plat_drain_id = plat_drain_id
                    dataToSend.specie_id = specie_id
                    dataToSend.planting_id = planting_id

                    if (records?.id) {
                        await OutingService.updateOuting(records.id, dataToSend)
                        new Notification(OutingService.messages.update_success, 1)
                        setUserHaveToPost(false)
                    } else {
                        await OutingService.addOuting(dataToSend)
                        setUserHaveToPost(false)
                        new Notification(OutingService.messages.create_success, 1)
                    }
                    navigate(-1)
                } else {
                    setUserHaveToPost(false)
                    console.error('La valeur de la quantité doit être un nombre')
                }
            } catch (error) {
                console.error('Erreur lors de la soumission du formulaire : ', error)
                new Notification(OutingService.messages.create_error, 0)
                setUserHaveToPost(false)
            }
        }
    }

    const handleChange = (e) => {
        handleInputChange(e)

        let selectedCode = values.code_plat_drain
        let selectedNbrRemaining = values.nbr_remaining
        let selectedQuantity = values.quantity
        let selectedSpecies = values.species

        if (e.target.name === 'plat_drains') {
            const selectedPlatDrain = platDrain.find((plat) => plat?.id === parseInt(e.target.value))
            if (selectedPlatDrain) {
                selectedCode = selectedPlatDrain.code
                selectedNbrRemaining = selectedPlatDrain.quantity_available
                selectedQuantity = selectedPlatDrain.quantity
                selectedSpecies = selectedPlatDrain.specieId
            }
        }

        setValues((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value,
            code_plat_drain: selectedCode,
            nbr_remaining: selectedNbrRemaining,
            quantity: selectedQuantity,
            species: selectedSpecies
        }))
    }

    return (
        <Form>
            <Container sx={{ paddingTop: '10px' }}>
                <Grid container>
                    <Grid item xs={userHaveToAdd ? 12 : 8}>
                        <Grid container style={{ paddingTop: '20px' }}>
                            <Grid item xs={6}>
                                <Container>
                                    <AutoCompleteSelect
                                        label="ID plate bande"
                                        name="plat_drains"
                                        value={values?.plat_drains || ''}
                                        onChange={handleChange}
                                        options={platDrain}
                                        labelKey="label"
                                        valueKey="id"
                                        error={errors.plat_drains}
                                        isDisabled={userHaveToAdd ? false : true}
                                        isRequired={true}
                                    />
                                    <Controls.DatePicker
                                        label="Date de sortie"
                                        name="release_date"
                                        onChange={handleChange}
                                        type="date"
                                        value={values?.release_date}
                                        error={values?.release_date}
                                        isDisabled={userHaveToAdd ? false : true}
                                        isRequired={true}
                                    />
                                    <TextField
                                        id="outlined-disabled"
                                        label="Nombre de sortie"
                                        // variant="outlined"
                                        name="nbr_output"
                                        value={values?.nbr_output}
                                        size="small"
                                        onChange={handleChange}
                                        type="number"
                                        error={errors?.nbr_output}
                                        isDisabled={userHaveToAdd ? false : true}
                                        isRequired={true}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="nbr_remaining"
                                        value={(values?.nbr_remaining || 0) || 0}
                                        size="small"
                                        label="Nombre de jeunes plants restants"
                                        onChange={handleChange}
                                        disabled
                                    />
                                </Container>
                            </Grid>
                            <Grid item xs={6}>
                                <Container>
                                    {/* <CustomSelect
                                label="Espèces"
                                name="species"
                                value={values?.species || ''}
                                onChange={handleChange}
                                options={specie}
                                labelKey="name"
                                valueKey="id"
                            /> */}
                                    <AutoCompleteSelect
                                        label="Espèces"
                                        name="species"
                                        value={values?.species || ''}
                                        onChange={handleChange}
                                        options={specie}
                                        labelKey="name"
                                        valueKey="id"
                                        error={errors.species}
                                        isDisabled={true}
                                    />
                                    <AutoCompleteSelect
                                        label="Affecter à une plantation"
                                        name="planting"
                                        value={values?.planting || ''}
                                        onChange={handleChange}
                                        options={plants}
                                        labelKey="label"
                                        valueKey="id"
                                        error={errors?.planting}
                                        isDisabled={userHaveToAdd ? false : true}
                                        isRequired={true}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="quantity"
                                        value={values?.quantity || 0}
                                        size="small"
                                        label="Nombre de jeunes plants produits"
                                        onChange={handleChange}
                                        disabled
                                    />
                                </Container>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!userHaveToAdd && (
                        <Grid item xs={4}>
                            <Box sx={{ paddingTop: '40px' }}>
                                <QrViewer value={records?.code_qr} size={'50%'} />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Container>
            {userHaveToAdd && (
                <div className="flex justify-end items-end m-5 p-5">
                    <Button text="Enregistrer" onClick={handleSubmit} haveSpin={true} disabled={userHaveToPost} />
                </div>
            )}
        </Form>
    )
}
