import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Controls from './Controls'

function MultipleSelect(props) {
    const { needNumber = false, onChange, name, label, options } = props
    const [values, setValues] = useState([
        { id: 1, label: 'Option 1', isChecked: false },
        { id: 2, label: 'Option 2', isChecked: false },
        { id: 3, label: 'Option 3', isChecked: false },
        { id: 4, label: 'Option 4', isChecked: false }
    ])

    const [valueToSelect, setValueToSelect] = useState([])

    const handleChangeNumber = (e) => {
        e.preventDefault()
        console.log(e)
        const newValues = [...values]
        newValues.find((item) => item.id === parseInt(e.target.name)).number = parseInt(e.target.value)
        setValues([...newValues])
    }

    const onChangeSearch = (e) => {
        const value = e
        const newValues = values.filter((item) => item.label.toLowerCase().includes(value.toLowerCase()))
        setValueToSelect([...newValues])
    }

    useEffect(() => {
        if (values) {
            onChange({
                target: {
                    name,
                    value: values
                        .filter((item) => item.isChecked)
                        .map((item) => {
                            return {
                                name: item.label,
                                number: item?.number || null
                            }
                        })
                }
            })
        }
    }, [values, onChange, name])

    useEffect(() => {
        if (options) {
            setValues(options)
            setValueToSelect(options)
        } else {
            setValueToSelect(values)
        }
    }, [options])

    return (
        <div sx={{ width: '100%', margin: '0px' }}>
            <FormLabel component="legend" sx={{ fontSize: '0.7rem', marginBottom: '10px' }}>
                {label}
            </FormLabel>
            {/* add search field */}
            <Controls.SearchField placeholder="Nom" sx={{ marginBottom: '10px' }} onChange={(e) => onChangeSearch(e)} />
            <FormGroup
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    height: '200px',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#d0e0d7 #E7F2EE00',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                        height: '8px',
                        background: '#d0e0d7'
                    }
                }}
            >
                {valueToSelect.length === 0 && <p style={{ textAlign: 'center', color: 'grey' }}>Aucun résultat</p>}
                {valueToSelect?.map((item, index) => (
                    <>
                        <FormControlLabel
                            key={index}
                            label={item.label}
                            control={
                                <Checkbox
                                    size="small"
                                    value={item.isChecked}
                                    checked={item.isChecked}
                                    onChange={() => {
                                        const newValues = [...values]
                                        const index = newValues.find((value) => value.id === item.id)
                                        index.isChecked = !index.isChecked
                                        setValues(newValues)
                                    }}
                                    name={item.label}
                                />
                            }
                        />
                        {item.isChecked && needNumber && (
                            <Controls.Input
                                key={index + 1}
                                variant="outlined"
                                size="small"
                                name={`${item.id}`}
                                label="Nombre"
                                type="number"
                                onChange={(item) => {
                                    handleChangeNumber(item)
                                }}
                            />
                        )}
                    </>
                ))}
            </FormGroup>
        </div>
    )
}

export default MultipleSelect
