import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class UserService extends Service {
    static messages = {
        update_success: 'Modification utilisateur avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création utilisateur avec succès.',
        create_error: 'Erreur de création.'
    }

    ///////////////////AUTH////////////////////////
    static async login(params) {
        return await Http.post('/api/auth/login', params)
    }

    static async setSession(params) {
        const { data, status } = await Http.put('/api/auth/session', params)
        if (status?.code === 200) {
            sessionStorage.setItem('sessID', data.sessID)
            return data
        }

        return null
    }

    static async getSession() {
        const sessID = sessionStorage.getItem('sessID')
        const { data, status } = await Http.get('/api/auth/session', { sessID })
        if (status?.code === 401) {
            sessionStorage.removeItem('sessID')
            return null
        }

        return data
    }

    static load() {
        this.setUrl('/api/users')
        return this
    }

    static async getAllUsers() {
        try {
            const response = await Http.get('/api/users')
            console.log('response user', response.data)
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            throw error
        }
    }

    static async getUserRegion(id){
        try {
            const response = await Http.get([`/api/users`, `region`, id].join('/'))
            return response?.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de la sélection des regions :", error)
            throw error
        }
    }
}

export default UserService
