import React from 'react'
import Chart from 'react-apexcharts'

const ApexChart = (props) => {
    const { series } = props
    /* pourcentage ny valeur */

    const options = {
        chart: {
            type: 'bar',
            height: 200
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top'
                }
            }
        },
        dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff']
            }
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
        },
        tooltip: {
            shared: true,
            intersect: false
        },
        xaxis: {
            categories: ['50 & +', '35 - 50', '25 - 35', '18 - 25'],
            labels: {
                show: false
            }
        },
        yaxis: {
            show: true
        },
        colors: ['#79AFFF', '#E2A794']
    }

    return (
        <div>
            <div id="chart">
                <Chart options={options} series={series} type="bar" height={430} />
            </div>
            <div id="html-dist"></div>
        </div>
    )
}

export default ApexChart
