import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class PlantationsVisit extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/plantations/visits')
        return this
    }

    static async getById() {
        try {
            return await Http.get([this.url].join('/'), {})
        } catch (error) {
            throw new Error('Erreur lors du chargement des données.')
        }
    }

    static async getByPlantationId(id) {
        try {
            return await Http.get([this.url, 'byPlantationId', id].join('/'), {})
        } catch (error) {
            throw new Error('Erreur lors du chargement des données.')
        }
    }
}

export default PlantationsVisit
