import React, { useEffect, useState } from 'react'
import { Typography, Paper, Grid, Container } from '@mui/material'

const styles = {
    container_info: {
        padding: '3px 3px 3px 3px'
    },
    list_param: { color: '#748291', fontSize: '0.6rem', paddingBottom: '0.5rem' },
    container_chart: {
        padding: '3px 3px 3px 3px',
        backgroundColor: '#f8f8f8',
        borderRadius: '0.5rem'
    }
}


function Faune(props) {
    const {existFaunaData} = props
    const [faunaData, setFaunaData] = useState(null)

    useEffect(() => {
        if(existFaunaData){
            setFaunaData(existFaunaData)
            //log exist fauna data
            console.log('existFaunaData',existFaunaData)
        }
    }, [existFaunaData])
    return (
        <>
            <Paper
                sx={{
                    padding: '0.4rem',
                    borderRadius: '0.5rem',
                    display: 'inline-flex',
                    width: '100%',
                    backgroundColor: '#f8f8f8'
                }}
            >
                <Grid container>
                    <Container style={styles.container_info}>
                        <Container style={{ ...styles.container_info, paddingBottom: '0.5rem' }}>
                            <Typography
                                variant="p"
                                sx={{ color: '#748291', fontSize: '0.8rem', fontWeight: 'initial' }}
                            >
                                Faune (espèces phares)
                            </Typography>
                        </Container>
                        <Container style={styles.container_info}>
                            <Container variant="p" sx={styles.list_param}>
                                {faunaData && faunaData.map((value) => (
                                    <div>
                                        <Typography variant="p" sx={{ fontWeight: 'initial' }}>
                                            {value.name}
                                        </Typography>
                                    </div>
                                ))}
                            </Container>
                        </Container>
                    </Container>
                </Grid>
            </Paper>
        </>
    )
}

export default Faune
