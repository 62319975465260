// ProvinceUtils.js

import ProvinceService from '../services/Provinces'

export const handleShowDistricts = async (
    region,
    setSelectedRegion,
    setEnabledRegion,
    setDistricts,
    setSelectedDistrict,
    setIsLoading
) => {
    setIsLoading && setIsLoading(true)
    setSelectedRegion(region)
    setEnabledRegion(null)
    setSelectedDistrict(null)
    await ProvinceService.getDistrict().then((response) => {
        if (response?.data) {
            const filteredDistricts = response?.data?.filter((district) => district?.parent === region?.id)
            setDistricts(filteredDistricts)
            setIsLoading && setIsLoading(false)
        }
    })
}

export const handleShowTowns = async (
    district,
    setSelectedDistrict,
    setEnabledDistrict,
    setTowns,
    setSelectedTown,
    setIsLoading
) => {
    setIsLoading && setIsLoading(true)
    setSelectedDistrict(district)
    setEnabledDistrict(null)
    setSelectedTown(null)
    await ProvinceService.getCommune().then((response) => {
        if (response?.data) {
            const filteredTowns = response?.data?.filter((town) => town?.parent === district?.id)
            setTowns(filteredTowns)
            setIsLoading && setIsLoading(false)
        }
    })
}

export const handleShowFkt = async (town, setSelectedTown, setEnabledTown, setFkt, setSelectedFkt, setIsLoading) => {
    setIsLoading && setIsLoading(true)
    setSelectedTown(town)
    setEnabledTown(null)
    setSelectedFkt(null)
    await ProvinceService.getFokontany().then((response) => {
        if (response?.data) {
            const filteredFkt = response?.data?.filter((fkt) => fkt?.parent === town?.id)
            setFkt(filteredFkt)
            setIsLoading && setIsLoading(false)
        }
    })
}

export const handleShowVillage = async (
    fkt,
    setSelectedFkt,
    setEnabledFkt,
    setVillage,
    setSelectedVillage,
    setIsLoading
) => {
    setIsLoading && setIsLoading(true)
    setSelectedFkt(fkt)
    setEnabledFkt(null)
    setSelectedVillage(null)
    await ProvinceService.getVillages().then((response) => {
        if (response?.data) {
            const filteredVillage = response?.data?.filter((village) => village?.parent === fkt?.id)
            console.log('filteredVillage test : ', filteredVillage)
            setVillage(filteredVillage)
            setIsLoading && setIsLoading(false)
        }
    })
}
