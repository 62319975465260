import React, { useState, useRef, useEffect } from 'react'
import { IconButton, Chip, Box, Container } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'

const styles = {
    chipNewSemi: {
        backgroundColor: '#f4f8fb',
        color: '#cdcfd1',
        borderRadius: 20,
        fontSize: 10,
        fontWeight: 'bold',
        border: '1px dashed #cdcfd1',
        textTransform: 'none',
        width: '130px',
        height: '25px'
    },
    chipSemi: {
        color: '#cdcfd1',
        borderRadius: 20,
        fontSize: 10,
        fontWeight: 'bold',
        border: '1px solid #cdcfd1',
        textTransform: 'none',
        width: '130px',
        height: '25px',
        margin: '0px 4px 0px 4px'
    },
    chipSelected: {
        backgroundColor: '#e3f7f5',
        color: '#29d09b',
        border: '1px solid #29d09b'
    },
    container: {
        overflowX: 'hidden',
        padding: '0px 0px 0px 0px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '89%'
    },
    box: {
        display: 'inline-flex',
        flexWrap: 'nowrap',
        gap: 1,
        margin: '0px 10px 0px 10px',
        transition: 'all 0.3s ease'
    }
}

const CustomDropdown = (props) => {
    const { data, setUserHavingToAdd, setDataSemisSelected, resetForm } = props
    const [selectedChipId, setSelectedChipId] = useState(null)
    const containerRef = useRef(null)

    const handleClickSemi = (item, k) => {
        setSelectedChipId(k)
        setDataSemisSelected(item)
        setUserHavingToAdd(false)
    }

    const handleAdd = () => {
        setUserHavingToAdd(true)
        setSelectedChipId(null)
        setDataSemisSelected(null)
        resetForm()
    }

    const handleScrollLeft = () => {
        if (containerRef.current) {
            const currentTransform = containerRef.current.style.transform || 'translateX(0px)'
            const currentPosition = parseInt(currentTransform.replace('translateX(', '').replace(')', ''))
            const newPosition = currentPosition + 100
            containerRef.current.style.transform = `translateX(${newPosition}px)`
        }
    }

    const handleScrollRight = () => {
        if (containerRef.current) {
            const currentTransform = containerRef.current.style.transform || 'translateX(0px)'
            const currentPosition = parseInt(currentTransform.replace('translateX(', '').replace(')', ''))
            const newPosition = currentPosition - 100
            containerRef.current.style.transform = `translateX(${newPosition}px)`
        }
    }

    useEffect(() => {
        const semisCount = data.length
        setDataSemisSelected(data.length > 0 ? data[semisCount - 1] : null)
        setSelectedChipId(semisCount)
    }, [data, setDataSemisSelected])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={handleScrollLeft}>
                <ArrowBackIos />
            </IconButton>
            <Container style={styles.container}>
                <Box sx={{ ...styles.box, zIndex: 1 }} ref={containerRef}>
                    {data.map((item, k) => (
                        <Chip
                            key={k + 1}
                            label={`Semis ${k + 1}`}
                            sx={{
                                ...styles.chipSemi,
                                ...(selectedChipId === k + 1 && styles.chipSelected)
                            }}
                            onClick={() => handleClickSemi(item, k + 1)}
                        />
                    ))}
                    <Chip key="newSemi" onClick={handleAdd} label="Nouveau Semi" sx={styles.chipNewSemi} />
                </Box>
            </Container>
            <IconButton onClick={handleScrollRight}>
                <ArrowForwardIos />
            </IconButton>
        </Box>
    )
}

export default CustomDropdown
