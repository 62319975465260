import React, { useEffect, useState } from 'react'
import { Form, useForm } from '../../../applications/hooks/UseForm'
import { Container } from '@mui/material'
import Controls from '../../../components/Entry/Controls'
import SectionTitle from '../../../components/Typography/SectionTitle'
import DataSetsService from '../../datasets/services/DataSets'
import ProvinceFilter from '../../provinces/components/ProvinceFilter'
import PlotService from '../../plot/services/Plots'
import OrderTrackingService from '../services/OrderTracking'
import Notification from '../../../layouts/Notification'
import Drawer from '../../../layouts/Drawer'
import PlotView from '../../plot/components/plot/PlotView'
import ContentListViewer from '../../../components/PageView/viewer/ContentListViewer'
import PlantationDetailsPage from '../../plantations/components/pageView/PlantationDetailsPage'

const initialValues = {
    customer_id: '',
    project_type: '',
    plantation_type: '',
    tree_ordered: '',
    location: '',
    state: '',
    plot: '',
    plantation_date: new Date(),
    surface: 0
}

function OrderTrackingFormAgro(props) {
    const dataset = DataSetsService.load()
    const orderApi = OrderTrackingService.load()
    const plotApi = PlotService.load()
    const { records, setNeedLoad } = props
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openDrawerPlantation, setOpenDrawerPlantation] = useState(false)
    const [selectedPlot, setSelectedPlot] = useState(null)
    const [selectedPlantation, setSelectedPlantation] = useState(null)
    const [operationalStateData, setOperationalStateData] = useState([])
    const [plots, setPlots] = useState([])
    const [filter, setFilter] = useState({})

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('customer_id' in fieldValues) temp.customer_id = fieldValues.customer_id ? '' : 'Ce champ est requis.'
        if ('project_type' in fieldValues) temp.project_type = fieldValues.project_type ? '' : 'Ce champ est requis.'
        if ('plantation_type' in fieldValues)
            temp.plantation_type = fieldValues.plantation_type ? '' : 'Ce champ est requis.'
        if ('tree_ordered' in fieldValues) temp.tree_ordered = fieldValues.tree_ordered ? '' : 'Ce champ est requis.'
        if ('location' in fieldValues) temp.location = fieldValues.location ? '' : 'Ce champ est requis.'
        if ('state' in fieldValues) temp.state = fieldValues.state ? '' : 'Ce champ est requis.'
        if ('plot' in fieldValues) temp.plot = fieldValues.plot ? '' : 'Ce champ est requis.'
        if ('plantation_date' in fieldValues)
            temp.plantation_date = fieldValues.plantation_date ? '' : 'Ce champ est requis.'

        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialValues, true, validate)

    useEffect(() => {
        if (records) {
            console.log('records', records)
            setValues({
                ...values,
                id: records?.id || '',
                idCustomer: records?.customers?.id || '',
                customer_id: records?.customers?.firstname || '',
                project_type: records?.project_type || '',
                plantation_type: records?.plantation_type || '',
                tree_ordered: records?.tree_ordered || '',
                location: records?.location || '',
                agro_type: records?.agro_type || '',
                state: records?.state || '',
                plot: records?.plot_id || '',
                plantation_date: records?.plantation_date || new Date(),
                surface: records?.surface || 0
            })
        }
    }, [records])

    useEffect(() => {
        if (filter) {
            setNeedLoad(true)
            plotApi
                .getAll(filter)
                .then((response) => {
                    if (response.data) {
                        console.log('Response : >>><', response)

                        setPlots(response.data)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    setNeedLoad(false)
                })
        }
    }, [filter])

    useEffect(() => {
        setNeedLoad(true)
        dataset
            .getDataCode('operational_state')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setOperationalStateData(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
            .finally(() => {
                setNeedLoad(false)
            })
    }, [setNeedLoad, dataset])

    useEffect(() => {
        if (values.plot) {
            setSelectedPlot(plots.find((plot) => plot.id === values.plot))
        }
    }, [values.plot, plots])

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('values eest : ', values)
        if (validate()) {
            setUserHaveToPost(true)
            const dataToPost = {
                plot_id: values.plot,
                plantation_date: values.plantation_date,
                state: values.state,
                surface: parseInt(values.surface)
            }
            try {
                const order = await orderApi.updateOrderTracking(values.id, dataToPost)
                if (order) {
                    console.log('order >>>>>>>><', order)
                    setUserHaveToPost(false)
                    new Notification('Validation avec success', 1)
                    setNeedLoad(true)
                }
            } catch (error) {
                new Notification('Erreur lors de la validation de la commande.', 0)
                console.log(error)
            } finally {
                setUserHaveToPost(false)
            }
        }
    }

    const toggleDrawer = (drawerOpen, item = null) => {
        setOpenDrawer(drawerOpen)
    }

    const handleViewPlot = () => {
        toggleDrawer(true, values.plot)
    }

    const toggleDrawerPlantation = (drawerOpen, item = null) => {
        setOpenDrawerPlantation(drawerOpen)
        setSelectedPlantation(item)
    }

    const handleViewPlantation = (plantation) => {
        toggleDrawerPlantation(true, plantation)
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Container style={{ padding: '20px 0px 20px 0px' }}>
                    {!records?.plot_id && (
                        <>
                            <SectionTitle>Localisation de plantation</SectionTitle>
                            <ProvinceFilter
                                setNeedLoad={setNeedLoad}
                                filter={filter}
                                setFilter={setFilter}
                                needSite={true}
                                width={'100%'}
                                hideTitle={false}
                            />
                        </>
                    )}
                    <SectionTitle>Information de plantation</SectionTitle>
                    <div className="flex gap-4">
                        <Controls.AutoCompleteSelect
                            name="state"
                            label="Etat opérationnel"
                            options={operationalStateData}
                            labelKey={'label'}
                            valueKey={'label'}
                            value={values?.state || records?.state}
                            error={errors?.state}
                            onChange={handleInputChange}
                            isRequired={true}
                        />
                        <Controls.AutoCompleteSelect
                            name="plot"
                            label="Parcelle attribuée"
                            options={plots}
                            labelKey={'label'}
                            valueKey={'id'}
                            value={values?.plot || records.plot_id}
                            error={errors?.plot}
                            onChange={handleInputChange}
                            isRequired={true}
                        />
                        {records?.plot_id && (
                            <div className="flex items-center">
                                <Controls.Button text="Parcelle" onClick={handleViewPlot} icon={'view'} />
                            </div>
                        )}
                    </div>
                    <div className="flex gap-4">
                        <Controls.DatePicker
                            label="Date de plantation"
                            name="plantation_date"
                            value={values.plantation_date || records.plantation_date}
                            onChange={handleInputChange}
                            error={errors.plantation_date}
                            isRequired={true}
                        />
                        <Controls.Input
                            id="outlined-disabled"
                            label="Surface (m²)"
                            name="surface"
                            type="number"
                            value={values?.surface || ''}
                            size="small"
                            onChange={handleInputChange}
                            error={errors?.surface}
                            // isRequired={true}
                        />
                    </div>
                    {!records.plot_id && (
                        <Controls.Button
                            type="submit"
                            text="Enregistrer"
                            haveSpin={true}
                            disabled={userHaveToPost}
                            icon={'save'}
                        />
                    )}
                </Container>
            </Form>
            <Drawer
                title={'Parcelle attribuée'}
                openDrawer={openDrawer}
                toggleDrawer={toggleDrawer}
                setNeedLoad={setNeedLoad}
            >
                <PlotView recordForEdit={selectedPlot} addReturnButton={false} />
            </Drawer>

            <Drawer
                title={'Parcelle attribuée'}
                openDrawer={openDrawerPlantation}
                toggleDrawer={toggleDrawerPlantation}
                setNeedLoad={setNeedLoad}
            >
                <PlantationDetailsPage recordForEdit={selectedPlantation} addReturnButton={false} />
            </Drawer>
            {records?.dataPlantations && records?.dataPlantations?.length > 0 && (
                <Container style={{ padding: '20px 0px 20px 0px' }}>
                    <SectionTitle>Historique de plantation</SectionTitle>
                    <ContentListViewer values={records?.dataPlantations} onClick={handleViewPlantation} />
                </Container>
            )}
        </>
    )
}

export default OrderTrackingFormAgro
