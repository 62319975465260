import React, { createContext, useEffect, useState } from 'react'

export const GlobalContext = createContext({})

function UseContext({ children }) {
    const [value, setValue] = useState({ rowsPerPage: 25, year: new Date().getFullYear().toString() })
    useEffect(() => {
        if (value) {
            console.log(value)
        }
    }, [value])
    return <GlobalContext.Provider value={{ value, setValue }}>{children}</GlobalContext.Provider>
}

export default UseContext
