// import { Http } from '../../../applications/Http.js'

import { Http } from '../../../../applications/Http'
import Service from '../../../../applications/Service'

class RecorgedPotService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/recorged_pot')
        return this
    }

    static async getByCode(id) {
        return await Http.get([this.url, id].join('/'), {})
    }

    static async addRecorgedPot(data) {
        try {
            // data.date_purchase = moment(data.date_purchase).format()

            const response = await Http.put(this.url, data)
            return response.data
        } catch (error) {
            throw new Error("Erreur lors de l'ajout ")
        }
    }

    static async updateRecorgedPot(id, data) {
        try {
            // data.date_purchase = moment(data.date_purchase).format()
            const response = await Http.patch([this.url, id].join('/'), data)
            return response?.data
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour')
        }
    }

    static async getByNurseryId(id) {
        try {
            const response = await Http.get([this.url, 'byNurseryId', id].join('/'), {})
            return response?.data
        } catch (error) {
            throw new Error('Erreur lors du chargement des données.')
        }
    }

    static async getById(id) {
        try {
            const response = await Http.get([this.url, id].join('/'), {})
            return response?.data
        } catch (error) {
            throw new Error('Erreur lors du chargement des données.')
        }
    }

    static async verifyPotWidth(idNursery, width) {
        try {
            const data = {
                nursery_id: idNursery,
                width: width
            }
            const response = await Http.get([this.url, 'verifyNurseryPotWidth'].join('/'), data)
            return response?.data
        } catch (error) {
            throw new Error('Erreur lors du chargement des données.')
        }
    }

    static async updateObjectiveRecorgedPot(data) {
        try {
            const response = await Http.patch([this.url, 'update'].join('/'), data)
            return response.data
        } catch (error) {
            throw new Error(this.messages.update_error)
        }
    }
}

export default RecorgedPotService
