import React, { useEffect, useState } from 'react'
import { createContext } from 'react'
import PlotService from '../../services/Plots'
import Controls from '../../../../components/Entry/Controls'

const {Loader} = Controls

export const Context = createContext({})

function PlotContext({ children, dataId, needLoad }) {
    const plotService = PlotService.load()
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState({})

    useEffect(() => {
        if (dataId) {
            const getPlotData = async () => {
                setLoading(true)
                const plotData = await plotService.getByCode(dataId)
                if (plotData) {
                    setValue(plotData?.data)
                }
                setLoading(false)
            }
            if (needLoad) {
                getPlotData()
            }
        }
    }, [dataId, plotService, needLoad])

    return (
        <>
            <Loader isLoading={loading} />
            <Context.Provider value={value}>{children}</Context.Provider>
        </>
    )
}

export default PlotContext
