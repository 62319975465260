import { LinearProgress } from '@mui/material'
import React from 'react'

function ProgressBar(props) {
    const { progress } = props
    return (
        <>
            <LinearProgress
                variant="determinate"
                color="primary"
                sx={{ width: '100%' }}
                size="small"
                valueBuffer={100}
                value={progress}
            />
        </>
    )
}

export default ProgressBar
