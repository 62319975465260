import React from 'react'
import { FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio } from '@mui/material'

export default function RadioGroup(props) {
    const { name, label, value, onChange, items, fontSize = '0.8rem', isDisabled } = props

    return (
        <FormControl sx={{ width: '100%' }}>
            <FormLabel sx={{ fontSize: '0.8rem' }}>{label}</FormLabel>
            <MuiRadioGroup row name={name} value={value} onChange={onChange}>
                {items.map((item, index) => (
                    <FormControlLabel
                        key={item.id}
                        value={item.id}
                        control={<Radio size="small" />}
                        label={item.title}
                        disabled={isDisabled ? isDisabled : false}
                        sx={{
                            '& .MuiFormControlLabel-label': {
                                fontSize: {fontSize} // Taille du texte dynamique
                            }
                        }}
                    />
                ))}
            </MuiRadioGroup>
        </FormControl>
    )
}
