// import { Http } from '../../../applications/Http.js'
import moment from 'moment'
import { Http } from '../../../../applications/Http.js'
import Service from '../../../../applications/Service.js'

class GetOutSemisService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.',
        get_error: 'Erreur lors du chargement des données.'
    }
    static load() {
        this.setUrl('/api/nursery/agro/semis_get_out')
        return this
    }

    static async addGetOut(semisData) {
        try {
            semisData.getout_date = moment(semisData.getout_date).format()
            const response = await Http.put('/api/nursery/agro/semis_get_out', semisData)
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'ajout de semis sortants :", error)
            throw error
        }
    }
}

export default GetOutSemisService
