import { Container, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Controls from '../../../components/Entry/Controls'
import ProvinceService from '../services/Provinces'
import Loader from '../../../components/Entry/Loading'

function VillageAutoCompleteSection(props) {
    const { groupType, setValues, isRequired = false, values, errors, onChange } = props

    const [loading, setLoading] = useState(false)
    const [selectedVillage, setSelectedVillage] = useState(null)
    const [groupOption, setGroupOption] = useState([])
    const [selectedVillageLabel, setSelectedVillageLabel] = useState(null)
    const [villageOption, setVillageOption] = useState([])
    const ProvinceApi = ProvinceService.load()

    useEffect(() => {
        setLoading(true)
        ProvinceApi.getAllVillages()
            .then((response) => {
                console.log(response.data)
                setVillageOption(response.data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        if (selectedVillage) {
            if (groupType === 'Mangroves') {
                console.log(selectedVillage?.MangroGroup)
                setGroupOption(selectedVillage?.MangroGroup)
            } else if (groupType === 'Agroforesterie') {
                setGroupOption(selectedVillage?.AgroGroup)
            }
        }
    }, [groupType, selectedVillage])

    const handleChangeVillage = (e) => {
        console.log(e)
        setSelectedVillageLabel(e.target.value)
        setSelectedVillage(villageOption?.find((item) => item.id === e.target.value) || null)
        onChange(e)
    }

    return (
        <div>
            <Loader isLoading={loading} />
            <Typography
                sx={{
                    padding: `10px 0px 20px 0px `,
                    backgroundColor: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    fontSize: '0.8rem',
                    fontWeight: 'bold'
                }}
            >
                Délimitation administrative
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Controls.Input
                        name="region"
                        label="Région"
                        value={selectedVillage?.fkt?.commune?.district?.regions?.name || ''}
                        isDisabled={true}
                    />
                    <Controls.Input
                        name="fkt"
                        label="Fokontany"
                        value={selectedVillage?.fkt?.name || ''}
                        isDisabled={true}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controls.Input
                        name="district"
                        label="District"
                        value={selectedVillage?.fkt?.commune?.district?.name || ''}
                        isDisabled={true}
                    />
                    <Controls.AutoCompleteSelect
                        label="Village"
                        name="site"
                        error={errors?.site}
                        value={selectedVillageLabel || ''}
                        options={villageOption}
                        onChange={handleChangeVillage}
                        labelKey="name"
                        valueKey="id"
                        isRequired={isRequired}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controls.Input
                        name="commune"
                        label="Commune"
                        value={selectedVillage?.fkt?.commune?.name || ''}
                        isDisabled={true}
                    />
                    {groupType && (
                        <Controls.AutoCompleteSelect
                            label="Groupe"
                            name="group_id"
                            value={values?.group_id || ''}
                            options={groupOption}
                            onChange={onChange}
                            labelKey="name"
                            valueKey="id"
                            error={errors?.group_id}
                            isRequired={isRequired}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

export default VillageAutoCompleteSection
