import React, { useState } from 'react'
import ContentView from '../../../../../components/PageView/Content'
import { useAuth } from '../../../../../applications/hooks/UseAuth'
import { useLocation } from 'react-router-dom'
import PlantationService from '../../../services/Plantation'
import MonitoringPlantationForm from './form/MonitoringPlantationForm'
import PlantationContext from '../../utils/PlantationContext'
import FollowUpVisitForm from './form/FollowUpVisitForm'

function PlantationVisitContent() {
    const haveAccessTo = useAuth()
    const location = useLocation()
    const dataCode = location.pathname.split('/').pop()
    const plantationService = PlantationService.load()
    const [needLoad, setNeedLoad] = useState(false)
    const [needLoadCode, setNeedLoadCode] = useState(false)
    return (
        <>
            <PlantationContext data={location.state ? location.state : null} setNeedLoadCode={setNeedLoadCode} >
                <ContentView
                    title={`Visite de suivi`}
                    dataId={dataCode}
                    service={plantationService}
                    needLoad={needLoad}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoadCode={needLoadCode}
                    setNeedLoadCode={setNeedLoadCode}
                    Form={FollowUpVisitForm}
                />
            </PlantationContext>
        </>
    )
}

export default PlantationVisitContent
