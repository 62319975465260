import React, { useEffect, useState } from 'react'
import { Typography, Paper, Grid, Container } from '@mui/material'
import { faIR } from '@mui/x-date-pickers'

const styles = {
    container_info: {
        padding: '3px 3px 3px 3px'
    },
    list_param: { color: '#748291', fontSize: '0.6rem', paddingBottom: '0.5rem' },
    container_chart: {
        padding: '3px 3px 3px 3px',
        backgroundColor: '#f8f8f8',
        borderRadius: '0.5rem'
    }
}

function Density(props) {
    const { densityData } = props
    const [densityValues, setDensityValues] = useState(null)

    useEffect(() => {
        if (densityData) {
            setDensityValues(densityData)
        }
    }, [densityData, densityValues])
    return (
        <>
            <Paper
                sx={{
                    padding: '0.4rem',
                    borderRadius: '0.5rem',
                    display: 'inline-flex',
                    width: '100%',
                    backgroundColor: '#fdfdfd',
                    border: '2px solid #f1f1f1'
                }}
            >
                <Grid container>
                    <Container style={styles.container_info}>
                        <Container style={{ ...styles.container_info, paddingBottom: '0.5rem' }}>
                            <Typography
                                variant="p"
                                sx={{ color: '#748291', fontSize: '0.8rem', fontWeight: 'initial' }}
                            >
                                Densité <i style={{ fontSize: '0.56rem' }}>(in/ha)</i>
                            </Typography>
                        </Container>
                        <Container style={styles.container_info}>
                            <Grid container>
                                {densityValues && densityValues.map((value, index) => (
                                    <Grid item xs={6} style={{ borderRight: index % 2 === 1 ? 'none' : densityValues.length !== 1 ? '1px solid #748291' : 'none'}}>
                                    <div
                                        style={{
                                            ...styles.container_info,
                                            paddingBottom: '0.5rem',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <Typography
                                            variant="p"
                                            sx={{ color: '#748291', fontSize: '0.8rem', fontWeight: 'initial' }}
                                        >
                                            {value?.type}
                                        </Typography>
                                        <Container sx={{ paddingTop: '0.5rem' }}>
                                            <Typography
                                                variant="p"
                                                sx={{ color: '#748291', fontWeight: 'initial', fontSize: '0.8rem' }}
                                            >
                                                {value?.number}
                                            </Typography>
                                        </Container>
                                    </div>
                                </Grid>
                                ))}
                            </Grid>
                        </Container>
                    </Container>
                </Grid>
            </Paper>
        </>
    )
}

export default Density
