import React from 'react'
import Table from '../../../../components/DataTable/Table'

function HistoriesList(props) {
    const { haveAccessTo, drawer, service, needLoad, currentPage, Form, setNeedLoad, filter } = props
    const headCells = [
        { id: 'role', label: "Rôle de l'utilisateur" },
        { id: 'name', label: 'Nom' },
        { id: 'date', label: "Date et heure d'action" },
        { id: 'category', label: 'Catégorie' },
        {
            id: 'info',
            label: 'Informations',
            type: 'hover',
            hover: (value) => {
                return <>{JSON.stringify(value?.info)}</>
            }
        },
        {
            id: 'Actions',
            label: 'Actions',
            type: 'actions',
            showEdit: false,
            showRemove: false
        }
    ]

    return (
        <>
            <Table
                filterParams={filter}
                title="Historiques"
                headCells={headCells}
                service={service}
                haveAccessTo={haveAccessTo}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                currentPage={currentPage}
                Form={Form}
                drawer={drawer}
                hideFilterButton={true}
                showSiteButton={false}
            />
        </>
    )
}

export default HistoriesList
