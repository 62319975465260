// import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class PeasantPlantingService extends Service {
    static messages = {
        update_success: 'Modification Paysans avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création Paysans avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/peasants/planting')
        return this
    }
}

export default PeasantPlantingService
