import React, { useEffect, useState } from 'react'
import { Typography, Container, Grid } from '@mui/material'
import DiagnosticPedologic from './parametersCard/DiagnosticPedologic'
import ExistantVegetal from './parametersCard/ExistantVegetal'
import Faune from './parametersCard/Faune'
import Density from './parametersCard/Density'
import CanopeCoverage from './parametersCard/CanopeCoverage'
import Controls from '../../../../../../components/Entry/Controls'
import AddFauna from '../popup/AddFauna'
import AddPedologicalDiag from '../popup/AddPedologicalDiag'
import AddVegetation from '../popup/AddVegetation'
import AddDensity from '../popup/AddDensity'
import AddTransect from '../popup/AddTransect'

function SavedParameters(props) {
    const { styles, values, errors, handleInputChange, setValues, dataAdd } = props
    const [pedologicalDiagData, setPedologicalDiagData] = useState(null)
    const [existVegetationData, setExistVegetationData] = useState([])
    const [existFaunaData, setExistFaunaData] = useState([])
    const [densityData, setDensityData] = useState([])
    const [transectData, setTransectData] = useState({ values: [], moyenne: 0 })

    const [openAddDiagPopup, setOpenAddDiagPopup] = useState(false)
    const [openAddFaunaPopup, setOpenAddFaunaPopup] = useState(false)
    const [openAddVegetationPopup, setOpenAddVegetationPopup] = useState(false)
    const [openAddDensityPopup, setOpenAddDensityPopup] = useState(false)
    const [openAddTransectPopup, setOpenAddTransectPopup] = useState(false)

    //Data add in popup
    const [speciesDataAdd, setSpeciesDataAdd] = useState([])
    const [faunasDataAdd, setFaunasDataAdd] = useState([])

    // set popup displayed on click
    const openAddPedologicalDiag = () => {
        setOpenAddDiagPopup(true)
    }

    const openAddFauna = () => {
        setOpenAddFaunaPopup(true)
    }

    const openAddVegetation = () => {
        setOpenAddVegetationPopup(true)
    }

    const openAddDensity = () => {
        setOpenAddDensityPopup(true)
    }

    const openAddTransect = () => {
        setOpenAddTransectPopup(true)
    }

    //display existVegetationData on console if existVegetationData is changed
    useEffect(() => {
        if (dataAdd) {
            setSpeciesDataAdd(dataAdd?.speciesDataAdd)
            setFaunasDataAdd(dataAdd?.faunaDataAdd)
        }
    }, [dataAdd])

    return (
        <>
            <AddPedologicalDiag
                openPopup={openAddDiagPopup}
                setOpenPopup={setOpenAddDiagPopup}
                onSave={setPedologicalDiagData}
                values={values}
                errors={errors}
                handleInputChange={handleInputChange}
            />
            <AddFauna
                faunasDataAdd={faunasDataAdd}
                openPopup={openAddFaunaPopup}
                setOpenPopup={setOpenAddFaunaPopup}
                dataFauna={existFaunaData}
                onSave={setExistFaunaData}
                values={values}
                errors={errors}
                handleInputChange={handleInputChange}
                setValues={setValues}
            />

            <AddVegetation
                speciesDataAdd={speciesDataAdd}
                openPopup={openAddVegetationPopup}
                setOpenPopup={setOpenAddVegetationPopup}
                dataVegetation={existVegetationData}
                onSave={setExistVegetationData}
                values={values}
                errors={errors}
                setValues={setValues}
                handleInputChange={handleInputChange}
            />

            <AddDensity
                openPopup={openAddDensityPopup}
                setOpenPopup={setOpenAddDensityPopup}
                dataVegetation={densityData}
                onSave={setDensityData}
                values={values}
                setValues={setValues}
            />

            <AddTransect
                openPopup={openAddTransectPopup}
                setOpenPopup={setOpenAddTransectPopup}
                dataVegetation={transectData}
                onSave={setTransectData}
                values={values}
                setValues={setValues}
            />

            <Typography sx={styles.form_title}>Paramètres enregistré</Typography>
            <Container>
                <Container sx={{ marginBottom: '0.4rem' }}>
                    {!pedologicalDiagData && (
                        <Controls.ChipButton onClick={openAddPedologicalDiag} title="Ajouter diagnostic pédologique" />
                    )}
                    {pedologicalDiagData && (
                        <Controls.ChipButton onClick={openAddPedologicalDiag} title="Modifier diagnostic pédologique" />
                    )}
                </Container>
                <Container sx={{ marginBottom: '0.4rem' }}>
                    {<Controls.ChipButton onClick={openAddFauna} title="Ajouter faune" />}
                </Container>
                <Container sx={{ marginBottom: '0.4rem' }}>
                    {<Controls.ChipButton onClick={openAddVegetation} title="Ajouter végétation" />}
                </Container>
                <Container sx={{ marginBottom: '0.4rem' }}>
                    {<Controls.ChipButton title="Ajouter densités" onClick={openAddDensity} />}
                </Container>
                <Container sx={{ marginBottom: '0.4rem' }}>
                    {<Controls.ChipButton title="Ajouter transects" onClick={openAddTransect} />}
                </Container>
            </Container>

            {pedologicalDiagData && (
                <>
                    <br />
                    <DiagnosticPedologic pedologicalDiagData={pedologicalDiagData} />
                </>
            )}

            <Grid container sx={{ paddingTop: '1.5rem' }}>
                <Grid item xs={4}>
                    {existVegetationData && <ExistantVegetal existVegetationData={existVegetationData} />}
                </Grid>
                <Grid item xs={5}>
                    <Container>{existFaunaData && <Faune existFaunaData={existFaunaData} />}</Container>
                </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: '1.5rem' }}>
                <Grid item xs={4}>
                    {densityData && <Density densityData={densityData} />}
                </Grid>
                <Grid item xs={6}>
                    <Container>{transectData && <CanopeCoverage transectData={transectData} />}</Container>
                </Grid>
            </Grid>
        </>
    )
}

export default SavedParameters
