import { Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import CustomSelect from '../../../components/Entry/CustomSelect'
import logo3 from '../../../assets/image/dashboard3.png'
import ApexChart from '../utils/DashboardChart'
import PeasantService from '../../peasants/services/Peasant'
import { calculateAverageAgeByGenderAndAgeRange } from '../helpers/calculateAge'

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: 10,
        backgroundColor: '#F2F8F6',
        '& .MuiPaper-root': {
            border: 'none',
            boxShadow: 'none'
        }
    }
}))

const TablePaper = styled(Paper)(({ theme }) => ({
    boxShadow: '0px 5px 5px -3px #d2d2d2,0px 8px 10px 1px #d2d2d2,0px 3px 14px 2px #d2d2d2'
}))

export const PeasantDashBord = () => {
    const classes = useStyles()

    const [peasants, setPeasants] = useState([])
    const [values, setValues] = useState({
        year: new Date().getFullYear()
    })

    const series = calculateAverageAgeByGenderAndAgeRange(peasants)

    useEffect(() => {
        PeasantService.getAllPeasant()
            .then((users) => {
                if (users) {
                    const filteredPeasants = users.filter(
                        (user) => new Date(user.member_since).getFullYear() == values.year
                    )
                    setPeasants(filteredPeasants)
                }
            })
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            })
    }, [values.year])

    const getYears = () => {
        const currentYear = new Date().getFullYear()
        const options = []
        for (let i = currentYear - 4; i <= currentYear + 4; i++) {
            options.push(i)
        }
        return options
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }))
    }

    return (
        <Paper className={classes.pageContent} elevation={0} style={{ backgroundColor: '#F2F8F6' }}>
            <TablePaper style={{ padding: 10, marginTop: 20, borderRadius: 10 }} elevation={8}>
                <div className="w-full">
                    <div className="flex justify-between">
                        <div>
                            <p className="font-semibold text-lg">Paysans Partenaire</p>
                        </div>
                        <div>
                            <select
                                className="bg-[#0FCB8F] w-24 h-8 rounded-md border-none text-white"
                                name="year"
                                id="year"
                                value={values.year}
                                onChange={handleChange}
                            >
                                {getYears().map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="flex gap-[40px] mb-[15px] w-[50%] mt-[16px]">
                        <div className="w-full">
                            <CustomSelect label="Régions" />
                        </div>
                        <div className="w-full">
                            <CustomSelect label="Fokontany" />
                        </div>
                    </div>

                    <div className="flex gap-4">
                        <div className="h-[100px]">
                            <div className="flex border border-gray-200 bg-slate-200 rounded-xl ">
                                <div>
                                    <div className="flex items-center gap-2 px-1 py-3 blend-darken">
                                        <img src={logo3} className="w-[50px]" />
                                    </div>
                                </div>
                                <div className="text-center border-r-2 p-[10px] border-current m-[5px]">
                                    <p className="text-slate-500">Nombre Total</p>
                                    <p className="text-xl font-semibold text-green-500">{peasants?.length}</p>
                                </div>
                                <div className="text-center  p-[10px] m-[5px] ">
                                    <p className="text-slate-500">Leaders</p>
                                    <p className="text-xl font-semibold text-green-500">45</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-[60%] ">
                            <div className="text-slate-500 ml-[10px]">Age moyen des paysans par genre</div>
                            <ApexChart series={series} />
                        </div>
                    </div>
                </div>
            </TablePaper>
        </Paper>
    )
}
