import React, { useState } from 'react'
import Table from '../../../components/DataTable/Table'
import Controls from '../../../components/Entry/Controls'
import Switch from '@mui/material/Switch'

// import AddModeratorOutlinedIcon from '@mui/icons-material/AddModeratorOutlined'
import { FaDatabase } from 'react-icons/fa6'
import Popup from '../../../layouts/Popup'
import { CustomData } from './CustomData'
// import { CustomForm } from './CustomForm'

export default function DataSetsList(props) {
    const { haveAccessTo, currentPage, Form, drawer, service, needLoad, setNeedLoad } = props
    const [openModal, setOpenModal] = useState(false)
    const [dataId, setDataId] = useState(null)

    const FormButton = (props) => {
        const { id } = props

        const handleForm = () => {
            setDataId(id)
            setOpenModal(true)
        }

        return (
            <Controls.ActionButton
                disabled={!haveAccessTo(currentPage, 'permission')}
                tooltip="Gérer les Données de base"
                color="primary"
                onClick={handleForm}
            >
                <FaDatabase fontSize="medium" />
            </Controls.ActionButton>
        )
    }

    const SwitchButton = (props) => {
        const { id, system } = props
        return <Switch disabled checked={system} id={id} />
    }

    const headCells = [
        { id: 'title', label: 'Titre' },
        { id: 'code', label: 'Code' },
        {
            id: 'actions',
            type: 'actions',
            label: 'Données de base',
            disableSorting: true,
            customButtons: [({ id, title }) => <FormButton id={id} title={title} />]
        },
        {
            id: 'system',
            type: 'actions',
            label: 'Système?',
            customButtons: [({ id, system }) => <SwitchButton id={id.toString()} system={system} />]
        },
        { id: 'actions2', type: 'actions', label: 'Actions', disableSorting: true, showEdit: true, showRemove: true }
    ]

    const toggleDrawer = (drawerOpen) => {
        setOpenModal(drawerOpen)
    }

    return (
        <>
            <Table
                title={'Modification donnée de base'}
                headCells={headCells}
                service={service}
                Form={Form}
                drawer={drawer}
                keyToSearch={null}
                haveAccessTo={haveAccessTo}
                currentPage={currentPage}
                setNeedLoad={setNeedLoad}
                needLoad={needLoad}
                hideFilterButton={true}
            />
            <Popup title={'Gestion des données de base '} openPopup={openModal} setOpenPopup={toggleDrawer}>
                <CustomData dataId={dataId} toggleDrawer={toggleDrawer} />
            </Popup>
        </>
    )
}
