import React, { useState } from 'react'
import { Form, useNavigate } from 'react-router-dom'

import Controls from '../../components/Entry/Controls'
import PageHeader from '../../components/Typography/PageHeader'
import CustomerOrderList from './components/CustomerOrderList'
import CustomerOrderService from './services/CustomerOrder'
import { useAuth } from '../../applications/hooks/UseAuth'
import ToolbarButton from '../../components/DataTable/ToolbarButton'
import CustomerOrderView from './components/CustomerOrderView'
import { AddCustomer } from './components/addCustomer'

const CURRENT_PAGE = 'commercial_client'

function CustomerOrder() {
    const service = CustomerOrderService.load()
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const useDrawer = true

    return (
        <>
            <PageHeader title={'Gestion des clients'} subTitle={'Gestion des clients'}>
                <ToolbarButton
                    moduleTitle="d'un client"
                    service={service}
                    drawer={false}
                    haveAccessTo={haveAccessTo}
                    currentPage={CURRENT_PAGE}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={AddCustomer}
                />
            </PageHeader>

            <CustomerOrderList
                service={service}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                Form={CustomerOrderView}
                drawer={useDrawer}
                currentPage={CURRENT_PAGE}
            />
        </>
    )
}

export default CustomerOrder
