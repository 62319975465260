import React, { useEffect, useState } from 'react'
import { Typography, Grid, Paper } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls'
import NaturalRegeneration from '../card/NaturalRegeneration'

function Topography(props) {
    const { styles, topographyData, naturalRegenerationData } = props
    const [topography, setTopography] = useState(null)

    useEffect(() => {
        if (topographyData) {
            setTopography(topographyData)
        }
    }, [topographyData, topography])

    return (
        <>
            <Typography sx={{...styles.form_title, color: '#919ca9'}}>Topographie</Typography>
            <Grid container>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        <Controls.Input label="Temps de submersion (h)" name="submers_time" value={topography && topography?.submers_time} isDisabled={true} />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={styles.paperInput}>
                        <Controls.Input label="Nombre de jours de submersion par mois" name="mare_frequency" value={topography && topography?.mare_frequency} isDisabled={true} />
                    </Paper>
                </Grid>
            </Grid>
            <NaturalRegeneration naturalRegenerationData={naturalRegenerationData} />
        </>
    )
}

export default Topography
