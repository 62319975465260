import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class DataSetsService extends Service {
    static messages = {
        update_success: 'Modification données de base avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création données de base avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/database')
        return this
    }

    static async getAllData() {
        return await Http.get([this.url], {})
    }

    static async getDataByTitle(title) {
        return await Http.get([this.url, 'byTitle', title].join('/'), {})
    }

    static async setDataForm(id, params) {
        return await Http.post([this.url, 'database_custom', id].join('/'), params)
    }

    static async getDataForm(id) {
        return await Http.get([this.url, 'database_custom', id].join('/'), {})
    }
    static async getDataCode(code) {
        return await Http.get([this.url, 'byCode', code].join('/'), {})
    }
}

export default DataSetsService
