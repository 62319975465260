import React, { useState } from 'react'
import { useAuth } from '../../applications/hooks/UseAuth'

import { Form, useNavigate } from 'react-router-dom'
import PageHeader from '../../components/Typography/PageHeader'
import Controls from '../../components/Entry/Controls'
import PlanterList from './components/listView/PlanterList'
import PlanterService from './services/Planter'
import InfoPlanterDetailsPage from './components/pageView/InfoPlanterDetailsPage'

const CURRENT_PAGE = 'agroforestry_plantation'

function Planter() {
    const service = PlanterService.load()
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const useDrawer = true

    const navigate = useNavigate()

    const addAction = () => {
        navigate('/app/mangrove/planter/new')
    }

    return (
        <div className={'card w-full bg-base-100 font-sans font-bold'} style={{ paddingBottom: 20 }}>
            <PageHeader title={'Information sur les planteurs'} subTitle={'Gestion planteurs'}>
                <div className="space-x-2 padding-x-2 ml-[10px]">
                    <Controls.Button
                        disabled={!haveAccessTo(CURRENT_PAGE, 'add')}
                        text="Enregistrer un planteur"
                        variant="contained"
                        size="large"
                        onClick={addAction}
                    />
                </div>
            </PageHeader>
            <PlanterList
                service={service}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                Form={InfoPlanterDetailsPage}
                drawer={useDrawer}
                currentPage={CURRENT_PAGE}
            />
        </div>
    )
}

export default Planter
