import React, { useState } from 'react'
import { NurseryDashboard } from './components/NurseryDashboard'
import { PLantingDashBord } from './components/PlantingDashboard'
import PlotDashboard from './components/PLotDashboard'
import { PeasantDashBord } from './components/PeasantDashBoard'
import AgroDashboardContext from './utils/hooks/AgroDashboardContext'

export default function Dashboard() {
    const [needLoad, setNeedLoad] = useState(false)
    return (
        <AgroDashboardContext needLoad={needLoad} setNeedLoad={setNeedLoad} >
            <div className="flex flex-col gap-4">
                <NurseryDashboard setNeedLoad={setNeedLoad} needLoad={needLoad} />
                <div className="flex gap-4">
                    <div className="w-[70%]">
                        <PLantingDashBord setNeedLoad={setNeedLoad} needLoad={needLoad} />
                    </div>
                    <div className="w-[30%] mr-[10px]">
                        <PlotDashboard setNeedLoad={setNeedLoad} needLoad={needLoad} />
                    </div>
                </div>
                <div className="w-full">
                    <PeasantDashBord setNeedLoad={setNeedLoad} needLoad={needLoad} />
                </div>
            </div>
        </AgroDashboardContext>
    )
}
