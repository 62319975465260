// import { useEffect } from 'react'
import PageContent from './Content'
import Sidebar from './Sidebar'
import { CssBaseline, createTheme, ThemeProvider } from '@mui/material'
import Header from './Header'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { frFR } from '@mui/material/locale'

export default function Layout() {
    const theme = createTheme(
        {
            palette: {
                primary: {
                    main: '#0FCB8F',
                    light: '#ffffff'
                },
                secondary: {
                    main: '#f83245',
                    light: '#f8324526'
                },
                background: {
                    default: '#f4f5fd'
                },
                white: {
                    default: '#ffffff'
                }
            },
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '--TextField-brandBorderColor': '#E0E3E7',
                            '--TextField-brandBorderHoverColor': '#B2BAC2',
                            '--TextField-brandBorderFocusedColor': '#6F7E8C',
                            '& label.Mui-focused': {
                                color: 'var(--TextField-brandBorderFocusedColor)'
                            }
                        }
                    }
                }
            },
            overrides: {
                MuiAppBar: {
                    root: {
                        transform: 'translateZ(0)'
                    }
                }
            },
            props: {
                MuiIconButton: {
                    disableRipple: true
                }
            }
        },
        frFR
    )

    return (
        <>
            <ThemeProvider theme={theme}>
                <div
                    className="bg-neutral-100 h-screen w-screen overflow-hidden flex flex-row"
                    style={{ backgroundColor: '#F2F8F6' }}
                >
                    <Sidebar />
                    <div className="flex flex-col flex-1" style={{ marginLeft: '25px', backgroundColor: '#F2F8F6' }}>
                        <Header />
                        <div
                            className="flex-1 min-h-0 overflow-auto"
                            style={{ margin: '1.5rem', backgroundColor: '#F2F8F6' }}
                        >
                            <PageContent />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <CssBaseline />
            </ThemeProvider>
        </>
    )
}
