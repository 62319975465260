import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import ContentView from '../../../../components/PageView/Content'
import PlanterService from '../../services/Planter'
import InfoPlanterDetailsContent from './content/InfoPlanterDetailsContent'
import InfoPlanterContext from '../../utils/hooks/InfoPlanterContext'

function InfoPlanterDetailsPage(props) {
    const { addReturnButton, recordForEdit } = props

    const haveAccessTo = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const planterService = PlanterService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(false)
    return (
        <>
            <InfoPlanterContext
                service={planterService}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                dataId={recordForEdit ? recordForEdit.id : dataId}
            >
                <ContentView
                    // title={`Information sur les groupes du site`}
                    // dataId={dataCode}
                    service={planterService}
                    needLoad={needLoad}
                    addReturnButton={addReturnButton}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoadCode={needLoadCode}
                    setNeedLoadCode={setNeedLoadCode}
                    Form={InfoPlanterDetailsContent}
                    needCode={false}
                />
            </InfoPlanterContext>
        </>
    )
}

export default InfoPlanterDetailsPage
