import React, { useState, useEffect, useContext } from 'react'

import { Form, useForm } from '../../../../applications/hooks/UseForm'
import Controls from '../../../../components/Entry/Controls'
import { useNavigate } from 'react-router-dom'
import Notification from '../../../../layouts/Notification'
import DataSetsService from '../../../datasets/services/DataSets'
import moment from 'moment'
import RecorgedPotService from '../../services/recorged_pot/recorged_pot'
import SelectDataSet from '../../../../components/Entry/SelectDataSet'
import CustomSelect from '../../../../components/Entry/CustomSelect'
import ProvinceService from '../../../provinces/services/Provinces'
import { Card, Container, Grid, Paper, Typography } from '@mui/material'
import { context } from './utils/hooks/RecorgedPotContext'
import PotAction from '../../application/PotActions'
import Subtitle from '../../../../components/Typography/Subtitle'
import { formatDate, numStr } from '../../../../applications/UtilFunctions'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import InventoryService from '../../services/inventory/inventory'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import Popup from '../../../../layouts/Popup'
import ObjectifModificationView from '../plant_material/popupView/ObjectifModificationView'

const { Input: TextField, Button } = Controls

const initialFValues = {
    date_purchase: new Date().toISOString(),
    pots: '',
    objective: '',
    quantity: '',
    Nbr_empty_pot: 0,
    Nbr_sown_pot: 0,
    site: ''
}

export const RecorgedPotForm = (props) => {
    const { records, setNeedLoad } = props

    const navigate = useNavigate()
    const dataset = DataSetsService.load()
    const nursery = useContext(context)
    const { user } = useAuth()

    // const [dataCategory, setDataCategory] = useState([])
    const [site, setSite] = useState([])
    const [activeSite, setActiveSite] = useState(null)
    const [centers, setCenters] = useState([])
    const [data, setData] = useState([])
    const [objectiveIsDisabled, setObjectiveIsDisabled] = useState(false)
    const [canAddRecorgedPot, setCanAddRecorgedPot] = useState(null)
    const [canAddPot, setCanAddPot] = useState(null)
    const [userHaveToModify, setUserHaveToModify] = useState(false)
    const [selectedRecorgedPotItem, setSelectedRecorgedPotItem] = useState(null)
    const [userHaveToModifyObjectif, setUserHaveToModifyObjectif] = useState(false)
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    useEffect(() => {
        if (nursery.isDrawerValue) {
            setSelectedRecorgedPotItem(nursery?.RecorgedPotItem[0])
        }
    }, [nursery, setSelectedRecorgedPotItem])

    useEffect(() => {
        if (records) {
            console.log(records)
            setValues((prevFormData) => ({
                ...prevFormData,
                date_purchase: records?.date_purchase ? moment(records.date_purchase).format('YYYY-MM-DD') : new Date(),
                pots: nursery?.pot ? nursery.pot : records?.pots || '',
                objective: records?.objective || '',
                quantity: records?.quantity || '',
                Nbr_empty_pot: records?.Nbr_empty_pot || 0,
                Nbr_sown_pot: records?.Nbr_sown_pot || 0,
                site: nursery?.site_id ? nursery?.site_id : records?.site || '',
                center: nursery?.id ? nursery?.id : records?.center?.id || ''
            }))
        }
    }, [records])

    useEffect(() => {
        dataset
            .getDataCode('sown_pot')
            .then((response) => {
                console.log('response', response.data)
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setData(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [])

    useEffect(() => {
        ProvinceService.getVillages().then((response) => {
            if (response?.data) {
                setSite(response?.data)
            }
        })
    }, [])

    useEffect(() => {
        if (nursery?.site_id) {
            setActiveSite(nursery?.site_id)
            setCenters([{ id: nursery?.id, name: nursery?.name }])
            setValues((prevFormData) => ({ ...prevFormData, site: nursery?.site_id }))
            setValues((prevFormData) => ({ ...prevFormData, center: nursery?.id }))
        }
    }, [nursery?.site_id])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('site' in fieldValues) temp.site = fieldValues.site.length !== 0 ? '' : 'Ce champ est requis.'

        //surface

        //planting_date_begin
        // if ('Nbr_sown_pot' in fieldValues) temp.Nbr_sown_pot = fieldValues.Nbr_sown_pot ? '' : 'Ce champ est requis.'
        // if ('Nbr_empty_pot' in fieldValues) temp.Nbr_empty_pot = fieldValues.Nbr_empty_pot ? '' : 'Ce champ est requis.'
        // if ('quantity' in fieldValues) temp.quantity = fieldValues.quantity ? '' : 'Ce champ est requis.'
        // if ('objective' in fieldValues) temp.objective = fieldValues.objective ? '' : 'Ce champ est requis.'
        //planting_date_end
        if ('pots' in fieldValues) temp.pots = fieldValues.pots ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })
        if ('date_purchase' in fieldValues) temp.date_purchase = fieldValues.date_purchase ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate)

    useEffect(() => {
        if (userHaveToModify) {
            console.log('coucou')
            setValues({
                ...values,
                quantity: selectedRecorgedPotItem?.refilled_number,
                recorged_pot_id: selectedRecorgedPotItem?.recorged_pot_id,
                id: selectedRecorgedPotItem?.id
            })
        }
    }, [userHaveToModify])

    useEffect(() => {
        if (selectedRecorgedPotItem) {
            setValues({
                ...values,
                quantity: selectedRecorgedPotItem?.refilled_number
            })
        }
    }, [selectedRecorgedPotItem])

    const handleSubmit = async (e) => {
        e.preventDefault()
        // if (canAddRecorgedPot === false)
        //     return new Notification(
        //         `Le pot - ${values.pots} doit être ajouter à la pépinière dans matériels & outils.`,
        //         0
        //     )
        console.log(values)
        if (validate() && canAddRecorgedPot === true) {
            try {
                setUserHaveToPost(true)
                const dataToSend = { ...values, user: [user.firstname, user.lastname].join(' ') }
                const quantityValue = parseInt(dataToSend.quantity)
                // const emptyValue = parseInt(dataToSend.Nbr_empty_pot)
                // const sownValue = parseInt(dataToSend.Nbr_sown_pot)
                const siteId = parseInt(values.site)
                const center_id = values.center === '0' ? null : parseInt(values.center)

                delete dataToSend.site
                delete dataToSend.center

                if (!isNaN(quantityValue)) {
                    dataToSend.quantity = quantityValue
                    // dataToSend.Nbr_empty_pot = emptyValue
                    // dataToSend.Nbr_sown_pot = sownValue
                    dataToSend.site_id = siteId
                    dataToSend.nursery_id = center_id
                    dataToSend.date_purchase = moment(dataToSend.date_purchase).format()

                    if (records?.id) {
                        await RecorgedPotService.updateRecorgedPot(records.id, dataToSend)
                        new Notification(RecorgedPotService.messages.update_success, 1)
                    } else {
                        console.log('id recorged pot : ', values?.recorged_pot_id)
                        console.log(dataToSend)
                        await RecorgedPotService.addRecorgedPot(dataToSend)
                        new Notification(RecorgedPotService.messages.create_success, 1)
                    }
                    setUserHaveToPost(false)
                    navigate(-1)
                } else {
                    console.error('La valeur de la quantité doit être un nombre')
                    setUserHaveToPost(false)
                }
            } catch (error) {
                setUserHaveToPost(false)
                console.error('Erreur lors de la soumission du formulaire : ', error)
                new Notification(RecorgedPotService.messages.create_error, 0)
            }
        }
        if (canAddPot) {
            setUserHaveToPost(true)
            console.log(user)
            const dataToSend = {
                designation: '',
                category: 'Intrant pépinière',
                assignment: values?.center,
                quantity: values?.quantity !== '' ? parseInt(values?.quantity) : 0,
                state: 'Neuf',
                responsible: user?.firstname,
                observation: '',
                date_purchased: values?.date_purchase,
                potWidth: values?.pots,
                objective: values?.objective,
                photo: null,
                userStory: [user.firstname, user.lastname].join(' ')
            }
            const inventoryService = InventoryService.load()
            inventoryService
                .addInventory(dataToSend)
                .then((response) => {
                    if (response) {
                        setUserHaveToPost(false)
                        new Notification(inventoryService.messages.create_success, 1)
                        navigate(-1)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setUserHaveToPost(false)
                    new Notification(inventoryService.messages.create_error, 0)
                })
        }
    }

    const handleModify = async () => {
        console.log(values)
        setNeedLoad(true)
        const response = await RecorgedPotService.updateRecorgedPot(values.id, values)
        if (response) {
            setUserHaveToModify(false)
            // setNeedLoad(false)
            new Notification(RecorgedPotService.messages.update_success, 1)
        }
    }

    const handleChange = async (e) => {
        handleInputChange(e)
        setValues((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value
        }))
        if (e.target.name === 'site') {
            setActiveSite(e.target.value)
        }

        if (e.target.name === 'pots') {
            setNeedLoad(true)
            const app = new PotAction()
            const recorged_pot = await app.verifyNurseryPotWidth(nursery?.id, e.target.value)

            if (recorged_pot) {
                setValues((prevFormData) => ({
                    ...prevFormData,
                    recorged_pot_id: recorged_pot?.id,
                    objective: recorged_pot?.objective
                }))
                setCanAddRecorgedPot(true)
                setObjectiveIsDisabled(true)
                setCanAddPot(false)
            } else {
                setObjectiveIsDisabled(false)
                setValues((prevFormData) => ({
                    ...prevFormData,
                    objective: ''
                }))
                setCanAddRecorgedPot(false)
                setCanAddPot(true)
            }

            setNeedLoad(false)
        }
    }

    const handleEditObjective = () => {
        console.log(values)
        setUserHaveToModifyObjectif(true)
    }

    return (
        <>
            <Popup
                title="Modification objectif"
                openPopup={userHaveToModifyObjectif}
                setOpenPopup={setUserHaveToModifyObjectif}
            >
                <ObjectifModificationView objectiveParent="recorgedPot" data={records} setNeedLoad={setNeedLoad} />
            </Popup>
            <Grid container spacing={2} style={{ padding: '10px 10px 10px 10px' }}>
                {nursery.isDrawerValue && (
                    <Grid item xs={4}>
                        <Paper
                            style={{ padding: '10px 10px 10px 10px' }}
                            sx={{
                                backgroundColor: '#183136',
                                height: '100%'
                            }}
                        >
                            <Paper sx={{ padding: '5px 5px 5px 5px', backgroundColor: '#183136' }}>
                                <Grid container>
                                    {/* <Grid item sx={{ padding: '5px 5px 5px 5px' }}>
                                        <Controls.CustomImageAvatar size={50} rounded={50}></Controls.CustomImageAvatar>
                                    </Grid> */}
                                    <Grid item>
                                        <Paper
                                            sx={{
                                                backgroundColor: '#183136'
                                            }}
                                        >
                                            <Subtitle
                                                style={{
                                                    fontSize: '1rem',
                                                    color: 'white'
                                                }}
                                            >
                                                Pot du pépinière
                                            </Subtitle>
                                        </Paper>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            style={{ fontSize: '0.75rem', color: 'white' }}
                                        >
                                            <b>Objectif : </b> {numStr(values?.objective, '.')}
                                            <EditOutlinedIcon
                                                fontSize="small"
                                                onClick={() => {
                                                    handleEditObjective(true)
                                                }}
                                                sx={{
                                                    color: 'white',
                                                    marginLeft: '5px',
                                                    cursor: 'pointer',
                                                    alignSelf: 'center',
                                                    ':hover': { color: '#14b57c' }
                                                }}
                                            />
                                            <br />
                                            <b>Totale accumulé : </b> {numStr(nursery?.total_accumulated, '.')}
                                            <br />
                                            <b>Reste pot vide : </b> {numStr(nursery?.Nbr_empty_pot, '.')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Grid>
                                <Card style={{ width: '100%' }} sx={{ backgroundColor: '#183136' }}>
                                    <Paper
                                        sx={{
                                            backgroundColor: '#183136',
                                            padding: '16px',
                                            display: 'flex'
                                        }}
                                    >
                                        <Subtitle
                                            style={{
                                                fontSize: '1.0rem',
                                                color: '#14b67d',
                                                marginBottom: '8px' // Ajoutez un espace entre le titre et le bouton
                                            }}
                                        >
                                            Rebouchage
                                        </Subtitle>
                                        <div style={{ width: '10px' }}></div>
                                        {/* <Controls.Button text={'Ajouter'} onClick={() => {}} /> */}
                                    </Paper>
                                    <Container
                                        sx={{
                                            height: '400px',
                                            overflow: 'auto',
                                            '&::-webkit-scrollbar': {
                                                width: '0.5em'
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: 'darkgrey',
                                                outline: '1px solid slategrey'
                                            }
                                        }}
                                    >
                                        {nursery?.RecorgedPotItem.length > 0 &&
                                            nursery?.RecorgedPotItem?.map((item, index) => {
                                                return (
                                                    <Container
                                                        key={index}
                                                        sx={{
                                                            ':hover': {
                                                                backgroundColor: '#2b3640',
                                                                cursor: 'pointer'
                                                            },
                                                            backgroundColor:
                                                                selectedRecorgedPotItem === item ? '#2b4347' : 'none',
                                                            borderRadius: '10px',
                                                            '&:hover': {
                                                                backgroundColor: '#2b3640',
                                                                cursor: 'pointer'
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            console.log('recorged pot item : ', item)
                                                            setSelectedRecorgedPotItem(item)
                                                            // setUserHaveToAdd(false)
                                                        }}
                                                    >
                                                        <div>
                                                            <Grid container sx={{ alignItems: 'center' }}>
                                                                <Grid>
                                                                    <Subtitle
                                                                        style={{
                                                                            fontSize: '0.8rem',
                                                                            color: 'white',
                                                                            padding: '5px'
                                                                        }}
                                                                    >
                                                                        {formatDate(item.date_purchase)}
                                                                    </Subtitle>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Container>
                                                )
                                            })}
                                    </Container>
                                    {/* {plantMaterialData?.PlantMaterialItem?.length === 0 && (
                                        <Container>
                                            <p>...</p>
                                        </Container>
                                    )} */}
                                </Card>
                            </Grid>
                        </Paper>
                    </Grid>
                )}
                <Grid item xs={nursery.isDrawerValue ? 8 : 12}>
                    {nursery.isDrawerValue && (
                        <Controls.ModifyButton
                            onClick={() => setUserHaveToModify(!userHaveToModify)}
                            userHaveToModify={userHaveToModify}
                        />
                    )}
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Container>
                                    <Controls.AutoCompleteSelect
                                        label="Site"
                                        name="site"
                                        value={values?.site || ''}
                                        onChange={handleChange}
                                        options={site}
                                        labelKey="name"
                                        valueKey="id"
                                        error={errors.site}
                                        isDisabled={nursery?.site_id ? true : false}
                                        isRequired={true}
                                    />
                                    <Controls.AutoCompleteSelect
                                        label="Taille"
                                        value={values.pots || ''}
                                        onChange={handleChange}
                                        name="pots"
                                        labelKey="label"
                                        valueKey="label"
                                        options={data}
                                        error={errors.pots}
                                        isDisabled={nursery?.isDrawerValue ? true : false}
                                        isRequired={true}
                                    />
                                    <Controls.Input
                                        label={canAddPot ? 'Quantité acheté' : 'Quantité rebouché'}
                                        name="quantity"
                                        value={values?.quantity}
                                        size="small"
                                        onChange={handleInputChange}
                                        type="number"
                                        error={errors?.quantity}
                                        isDisabled={nursery?.isDrawerValue && !userHaveToModify ? true : false}
                                        // isRequired={true}
                                    />
                                </Container>
                            </Grid>
                            <Grid item xs={6}>
                                <Container>
                                    <Controls.AutoCompleteSelect
                                        label="Pépinière"
                                        name="center"
                                        value={values?.center || ''}
                                        onChange={handleChange}
                                        options={centers}
                                        labelKey="name"
                                        valueKey="id"
                                        error={errors.center}
                                        isDisabled={nursery?.site_id ? true : false}
                                    />
                                    {!nursery.isDrawerValue && (
                                        <TextField
                                            id="outlined-disabled"
                                            label="Objectif"
                                            // variant="outlined"
                                            name="objective"
                                            value={values?.objective}
                                            size="small"
                                            onChange={handleChange}
                                            type="number"
                                            error={errors?.objective}
                                            isDisabled={
                                                objectiveIsDisabled && !userHaveToModify
                                                    ? true
                                                    : nursery?.isDrawerValue
                                                    ? userHaveToModify
                                                        ? false
                                                        : true
                                                    : false
                                            }
                                        />
                                    )}
                                    <Controls.DatePicker
                                        label="Date"
                                        // variant="outlined"
                                        name="date_purchase"
                                        value={
                                            nursery.isDrawerValue
                                                ? selectedRecorgedPotItem?.date_purchase
                                                : values?.date_purchase
                                        }
                                        size="small"
                                        onChange={handleChange}
                                        type="date"
                                        error={errors?.date_purchase}
                                        isDisabled={nursery?.isDrawerValue && !userHaveToModify ? true : false}
                                        isRequired={true}
                                    />
                                </Container>
                            </Grid>
                        </Grid>

                        {!nursery.isDrawerValue && (
                            <div className="flex justify-end items-end m-5 p-5">
                                <Button
                                    text="Enregistrer"
                                    onClick={handleSubmit}
                                    haveSpin={true}
                                    disabled={userHaveToPost}
                                />
                            </div>
                        )}
                        {userHaveToModify && (
                            <div className="flex justify-end items-end m-5 p-5">
                                <Button
                                    text="Modifier"
                                    onClick={handleModify}
                                    haveSpin={true}
                                    disabled={userHaveToPost}
                                />
                            </div>
                        )}
                    </Form>
                </Grid>
            </Grid>
        </>
    )
}
