import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ProvinceService from '../../../../provinces/services/Provinces'
import DataSetsService from '../../../../datasets/services/DataSets'
import SpeciesService from '../../../../species/services/Species'
import { useAuth } from '../../../../../applications/hooks/UseAuth'
import { Form, useForm } from '../../../../../applications/hooks/UseForm'
import PeasantService from '../../../../peasants/services/Peasant'
import { Container, Grid, Typography } from '@mui/material'
import Controls from '../../../../../components/Entry/Controls'

const { Input: TextField } = Controls

const initialFValues = {
    firstname: '',
    photos: '',
    topo: '',
    statut_foncier: '', 
    texture: '',
    use_actually: '',
    estimated_number_plant: '',
}

const styles = {
    form_title: {
        padding: `10px 0px 20px 0px `,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    }
}

const AddPlotForm = (props) => {
    const { addOrEdit, setNeedLoad, userHaveToModify, userData = null, setUserHaveToModify } = props
    const navigate = useNavigate()
    const initialOption = []
    const [regions, setRegions] = useState(initialOption)
    const [selectedRegion, setSelectedRegion] = useState(null)
    const [photos, setPhoto] = useState(null)
    const [contract_file, setContract_file] = useState(null)

    const [districts, setDistricts] = useState(initialOption)
    const [selectedDistrict, setSelectedDistrict] = useState(null)

    const [communes, setCommunes] = useState(initialOption)
    const [selectedCommune, setSelectedCommune] = useState(null)

    const [villages, setVillages] = useState(initialOption)
    const [selectedVillage, setSelectedVillage] = useState(null)

    const [fkts, setFkts] = useState(initialOption)
    const [selectedFkt, setSelectedFkt] = useState(null)

    const [sites, setSites] = useState(initialOption)
    const [selectedSite, setSelectedSite] = useState(null)

    const [species, setSpecies] = useState(null)
    const [statusFoncierOption, setStatusFoncierOption] = useState([])
    const [textureOption, setTextureOption] = useState([])
    const [speciesOptions, setSpeciesOptions] = useState([])
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [topoOption, setTopoOption] = useState([])

    const ProvinceApi = ProvinceService.load()
    const dataset = DataSetsService.load()
    const specieService = SpeciesService.load()

    const { user } = useAuth()

    useEffect(() => {
        setNeedLoad(true)

        if (!userHaveToModify) {
            ProvinceApi.getRegions().then((res) => {
                setRegions(res?.data)
            })
        }

        dataset
            .getDataCode('statut_foncier')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setStatusFoncierOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })

        dataset
            .getDataCode('toposequence')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setTopoOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })

        dataset
            .getDataCode('texture')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setTextureOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
        specieService
            .getAllSpecies()
            .then((res) => {
                if (res) {
                    setSpecies(res)
                }
            })
            .catch((error) => {
                console.log(error)
            })

        setNeedLoad(false)
    }, [userHaveToModify])

    useEffect(() => {
        if (!userHaveToModify) {
            if (selectedRegion) setNeedLoad(true)
            ProvinceApi.getRegionsChild(selectedRegion).then((res) => {
                setDistricts(res?.data)
            })
        }
    }, [selectedRegion])

    useEffect(() => {
        if (!userHaveToModify) {
            if (selectedDistrict) setNeedLoad(true)
            ProvinceApi.getDistrictChild(selectedDistrict).then((res) => {
                setCommunes(res?.data)
            })
        }
    }, [selectedDistrict])

    useEffect(() => {
        if (!userHaveToModify) {
            if (selectedCommune) setNeedLoad(true)
            ProvinceApi.getCommuneChild(selectedCommune).then((res) => {
                setFkts(res?.data)
            })
        }
    }, [selectedCommune])

    useEffect(() => {
        if (!userHaveToModify) {
            if (selectedFkt) setNeedLoad(true)
            ProvinceApi.getFokontanyChild(selectedFkt).then((res) => {
                setVillages(res?.data)
            })
        }
    }, [selectedFkt])

    useEffect(() => {
        if (!userHaveToModify) {
            if (selectedVillage) setNeedLoad(true)
            ProvinceApi.getVillages(selectedVillage).then((res) => {
                setSites(res?.data)
            })
        }
    }, [selectedVillage])

    useEffect(() => {
        if (species) {
            console.log('espèces : ', species)
            setSpeciesOptions(
                species.map((item) => {
                    return {
                        id: item.id,
                        label: item.name,
                        isChecked: false
                    }
                })
            )
        }
    }, [species])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('firstname' in fieldValues) temp.firstname = fieldValues.firstname ? '' : 'Ce champ est requis.'
        // if ('lastname' in fieldValues) temp.lastname = fieldValues.lastname ? '' : 'Ce champ est requis.'
        if (!userHaveToModify) {
            if ('site' in fieldValues) temp.site = fieldValues.site.length !== 0 ? '' : 'Ce champ est requis.'
        }

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialFValues,
        true,
        validate
    )

    useEffect(() => {
        if (userData) {
            const {
                group,
                group_id,
                contract_file,
                species_requested,
                responsible_technician,
                code_qr,
                photo,
                age,
                ...rest
            } = userData
            console.log(rest)
            setValues({ ...rest })
        }
    }, [userData])

    useEffect(() => {
        if (photos) {
            setValues({ ...values, photos: photos })
        }
    }, [photos, setValues, values])

    useEffect(() => {
        if (contract_file) {
            setValues({ ...values, photos: contract_file })
        }
    }, [setValues, values, contract_file])

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(validate())
        console.log(values)
        if (validate()) {
            const dataToSend = {
                ...values,
                user: [user?.firstname, user?.lastname].join(' ')
            }

            delete dataToSend.region
            delete dataToSend.district
            delete dataToSend.commune
            delete dataToSend.fkt


            setNeedLoad(true)
            addOrEdit(dataToSend, () => {}, setUserHaveToPost)
            console.log('données envoyées : ', dataToSend)
        }
    }

    const handleModify = async (e) => {
        e.preventDefault()
        try {
            setNeedLoad(true)
            setUserHaveToModify(false)
            const service = PeasantService.load()
            await service.update(values.id, values)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectChange = (e) => {
        handleInputChange(e)
        if (e.target.name === 'region') {
            setSelectedRegion(e.target.value)
            setCommunes(initialOption)
            setFkts(initialOption)
            setVillages(initialOption)
            setSites(initialOption)
        } else if (e.target.name === 'district') {
            setSelectedDistrict(e.target.value)
            setFkts(initialOption)
            setVillages(initialOption)
            setSites(initialOption)
        } else if (e.target.name === 'commune') {
            setSelectedCommune(e.target.value)
            setVillages(initialOption)
            setSites(initialOption)
        } else if (e.target.name === 'fkt') {
            setSelectedFkt(e.target.value)
            setSites(initialOption)
        } else if (e.target.name === 'village') {
            setSelectedVillage(e.target.value)
        } else if (e.target.name === 'site') {
            setSelectedSite(e.target.value)
        }
    }

    return (
        <Form onSubmit={userHaveToModify ? handleModify : handleSubmit}>
            <div className="w-[100%]  p-2">
                {!userHaveToModify && (
                    <>
                        <Typography sx={styles.form_title}>Délimitation administrative</Typography>
                        <Grid container>
                            <Grid item xs={4}>
                                <Container>
                                    <Controls.AutoCompleteSelect
                                        label="Région"
                                        name="region"
                                        value={values?.region || ''}
                                        options={regions || []}
                                        onChange={handleSelectChange}
                                        error={errors.region}
                                        labelKey="name"
                                        valueKey="id"
                                    />
                                    <Controls.AutoCompleteSelect
                                        label="Fokontany"
                                        name="fkt"
                                        value={values?.fkt || ''}
                                        options={fkts || []}
                                        onChange={handleSelectChange}
                                        error={errors.fkt}
                                        labelKey="name"
                                        valueKey="id"
                                    />
                                </Container>
                            </Grid>
                            <Grid item xs={4}>
                                <Container>
                                    <Controls.AutoCompleteSelect
                                        label="District"
                                        name="district"
                                        value={values?.district || ''}
                                        options={districts || []}
                                        onChange={handleSelectChange}
                                        error={errors.district}
                                        labelKey="name"
                                        valueKey="id"
                                    />
                                    <Controls.AutoCompleteSelect
                                        label="Village"
                                        name="site"
                                        value={values?.site || ''}
                                        options={villages || []}
                                        onChange={handleSelectChange}
                                        error={errors.site}
                                        labelKey="name"
                                        valueKey="id"
                                        isRequired={true}
                                    />
                                </Container>
                            </Grid>
                            <Grid item xs={4}>
                                <Container>
                                    <Controls.AutoCompleteSelect
                                        label="Commune"
                                        name="commune"
                                        value={values?.commune || ''}
                                        options={communes || []}
                                        onChange={handleSelectChange}
                                        error={errors.commune}
                                        labelKey="name"
                                        valueKey="id"
                                    />
                                </Container>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Typography sx={styles.form_title}>Informations sur la parcelle</Typography>
                <Grid container>
                    <Grid item xs={4}>
                        <Container>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                name="firstname"
                                label="Désignation"
                                value={values.firstname}
                                onChange={handleInputChange}
                                error={errors.firstname}
                                isRequired={true}
                            />
                            <Controls.AutoCompleteSelect
                                label="Toposéquence"
                                name="topo"
                                value={values?.topo || ''}
                                options={topoOption || []}
                                onChange={handleSelectChange}
                                error={errors.topo}
                                labelKey="label"
                                valueKey="label"
                            />
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                name="estimated_number_plant"
                                label="Estimation nombres d arbres"
                                value={values.estimated_number_plant}
                                onChange={handleInputChange}
                                error={errors.estimated_number_plant}
                                type="number"
                            />
                        </Container>
                    </Grid>
                    <Grid item xs={4}>
                        <Container>
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                name="surface"
                                label="Surface"
                                type="number"
                                value={values.surface}
                                onChange={handleInputChange}
                                error={errors.surface}
                            />
                            <Controls.AutoCompleteSelect
                                label="Texture"
                                name="texture"
                                value={values?.texture || ''}
                                options={textureOption || []}
                                onChange={handleSelectChange}
                                error={errors.texture}
                                labelKey="label"
                                valueKey="label"
                            />
                        </Container>
                    </Grid>
                    <Grid item xs={4}>
                        <Container>
                            <Controls.AutoCompleteSelect
                                label="Statut foncier"
                                name="statut_foncier"
                                value={values?.statut_foncier || ''}
                                options={statusFoncierOption || []}
                                onChange={handleInputChange}
                                error={errors.statut_foncier}
                                labelKey="label"
                                valueKey="label"
                            />
                            <Controls.Input
                                id="outlined-basic"
                                variant="outlined"
                                name="use_actually"
                                label="Utilisation actuelle de la parcelle"
                                value={values.use_actually}
                                onChange={handleInputChange}
                                error={errors.use_actually}
                            />
                        </Container>
                    </Grid>
                </Grid>
                {!userHaveToModify && (
                    <>
                        <Grid container>
                            <Grid item xs={4}>
                                <Container>
                                    <Controls.MultipleSelect
                                        needNumber={false}
                                        onChange={handleInputChange}
                                        name="vegetations"
                                        label="Végétations présentes sur la parcelle"
                                        options={speciesOptions || null}
                                    />
                                </Container>
                            </Grid>
                            <Grid item xs={8}>
                                <Container>
                                    <Controls.MultipleFileInput
                                        label="Photo"
                                        onChange={handleInputChange}
                                        name="photos"
                                        setValues={setContract_file}
                                    />
                                </Container>
                            </Grid>
                        </Grid>
                    </>
                )}
            </div>
            <div className="flex justify-end items-end m-5 p-5">
                <Controls.Button
                    type="submit"
                    text={userHaveToModify ? 'Modifier' : 'Enregistrer'}
                    size="large"
                    haveSpin={true}
                    disabled={userHaveToPost}
                />
            </div>
        </Form>
    )
}

export default AddPlotForm
