// KeyboardHandlers.js

import Notification from '../../../layouts/Notification'
import ProvinceService from '../services/Provinces'

export const handleKeyDownRegion = async (event, region, setRegions, setEnabledRegion) => {
    // if (event.key === 'Enter') {
    if (region.id) {
        const response = await ProvinceService.updateRegions(region.id, { name: region.name })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Modification  avec succès.', 1)
        } else {
            new Notification('Erreur lors de la modification.', 0)
        }
    } else {
        const response = await ProvinceService.addRegions({ name: region.name })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Création  avec succès.', 1)
        } else {
            new Notification("Erreur lors de l'ajout.", 0)
        }
    }
    setEnabledRegion(null)
    ProvinceService.getRegions().then((response) => {
        if (response?.data) {
            setRegions(response.data)
        }
    })
    // }
}
export const handleKeyDownDistrict = async (
    event,
    district,
    selectedRegion,
    setDistricts,
    setEnabledDistrict,
    setIsLoading
) => {
    // if (event.key === 'Enter') {
    setIsLoading && setIsLoading(true)
    if (district.id) {
        const response = await ProvinceService.updateDistrict(district.id, {
            name: district.name,
            parent: selectedRegion.id
        })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Modification  avec succès.', 1)
            setIsLoading && setIsLoading(false)
        } else {
            new Notification('Erreur lors de la modification.', 0)
            setIsLoading && setIsLoading(false)
        }
    } else {
        const response = await ProvinceService.addDistrict({ name: district.name, parent: selectedRegion.id })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Création  avec succès.', 1)
            setIsLoading && setIsLoading(false)
        } else {
            new Notification("Erreur lors de l'ajout.", 0)
            setIsLoading && setIsLoading(false)
        }
    }
    setEnabledDistrict(null)
    ProvinceService.getDistrict().then((response) => {
        if (response?.data) {
            const filteredtown = response?.data?.filter((town) => town?.parent === selectedRegion?.id)
            setDistricts(filteredtown)
            setIsLoading && setIsLoading(false)
        }
    })
    // }
}

export const handleKeyDownTown = async (event, town, selectedDistrict, setTowns, setEnabledTown, setIsLoading) => {
    // if (event.key === 'Enter') {
    setIsLoading && setIsLoading(true)
    if (town.id) {
        const response = await ProvinceService.updateCommune(town.id, {
            name: town.name,
            parent: selectedDistrict.id
        })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Modification  avec succès.', 1)
            setIsLoading && setIsLoading(false)
        } else {
            new Notification('Erreur lors de la modification.', 0)
            setIsLoading && setIsLoading(false)
        }
    } else {
        const response = await ProvinceService.addCommune({ name: town.name, parent: selectedDistrict.id })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Création  avec succès.', 1)
            setIsLoading && setIsLoading(false)
        } else {
            new Notification("Erreur lors de l'ajout.", 0)
            setIsLoading && setIsLoading(false)
        }
    }
    setEnabledTown(null)
    ProvinceService.getCommune().then((response) => {
        if (response?.data) {
            const filteredtown = response?.data?.filter((town) => town?.parent === selectedDistrict?.id)
            setTowns(filteredtown)
            setIsLoading && setIsLoading(false)
        }
    })
    // }
}
export const handleKeyDownFkt = async (event, fkt, selectedTown, setFkt, setEnabledFkt, setIsLoading) => {
    // if (event.key === 'Enter') {
    setIsLoading && setIsLoading(true)
    if (fkt.id) {
        const response = await ProvinceService.updateFokontany(fkt.id, {
            name: fkt.name,
            parent: selectedTown.id
        })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Modification  avec succès.', 1)
            setIsLoading && setIsLoading(false)
        } else {
            new Notification('Erreur lors de la modification.', 0)
            setIsLoading && setIsLoading(false)
        }
    } else {
        const response = await ProvinceService.addFokontany({ name: fkt.name, parent: selectedTown.id })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Création  avec succès.', 1)
            setIsLoading && setIsLoading(false)
        } else {
            new Notification("Erreur lors de l'ajout.", 0)
            setIsLoading && setIsLoading(false)
        }
    }
    setEnabledFkt(null)
    ProvinceService.getFokontany().then((response) => {
        if (response?.data) {
            const filteredtown = response?.data?.filter((town) => town?.parent === selectedTown?.id)
            setFkt(filteredtown)
            setIsLoading && setIsLoading(false)
        }
    })
    // }
}

export const handleKeyDownVillage = async (event, ville, selectedFkt, setVillage, setEnabledVillage, setIsLoading, createNursery, setCreateNursery) => {
    // if (event.key === 'Enter') {
    setIsLoading && setIsLoading(true)
    if (ville.id) {
        const response = await ProvinceService.updateVillages(ville.id, {
            name: ville.name,
            parent: selectedFkt.id
        })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Modification  avec succès.', 1)
            setIsLoading && setIsLoading(false)
        } else {
            new Notification('Erreur lors de la modification.', 0)
            setIsLoading && setIsLoading(false)
        }
    } else {
        const response = await ProvinceService.addVillages({ name: ville.name, parent: selectedFkt.id, createNursery: createNursery })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Création avec succès.', 1)
            setIsLoading && setIsLoading(false)
            setCreateNursery(false)
        } else {
            new Notification("Erreur lors de l'ajout.", 0)
            setIsLoading && setIsLoading(false)
            setCreateNursery(false)
        }
    }
    setEnabledVillage(null)
    ProvinceService.getVillages().then((response) => {
        if (response?.data) {
            const filteredVillage = response?.data?.filter((vil) => vil?.parent === selectedFkt?.id)
            setVillage(filteredVillage)
            setIsLoading && setIsLoading(false)
        }
    })
    // }
}
export const handleKeyDownSites = async (event, ste, selectedVillage, setSites, setEnabledSite, setIsLoading) => {
    // if (event.key === 'Enter') {
    setIsLoading && setIsLoading(true)
    if (ste.id) {
        const response = await ProvinceService.updateMangroSites(ste.id, { name: ste.name, parent: selectedVillage.id })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Modification  avec succès.', 1)
            setIsLoading && setIsLoading(false)
        } else {
            new Notification('Erreur lors de la modification.', 0)
            setIsLoading && setIsLoading(false)
        }
    } else {
        const response = await ProvinceService.addMangroMangroSites({ name: ste.name, parent: selectedVillage.id })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Création  avec succès.', 1)
            setIsLoading && setIsLoading(false)
        } else {
            new Notification("Erreur lors de l'ajout.", 0)
            setIsLoading && setIsLoading(false)
        }
    }
    setEnabledSite(null)
    ProvinceService.getMangroSites().then((response) => {
        if (response?.data) {
            const filteredSites = response?.data?.filter((st) => st?.parent === selectedVillage?.id)
            setSites(filteredSites)
            setIsLoading && setIsLoading(false)
        }
    })
    // }
}

export const handleKeyDownGroups = async (event, grp, selectedSites, setGroups, setEnabledGroups, setIsLoading) => {
    // if (event.key === 'Enter') {
    setIsLoading && setIsLoading(true)
    if (grp.id) {
        const response = await ProvinceService.updateMangroGroups(grp.id, {
            code: grp.name,
            site_id: selectedSites.id
        })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Modification  avec succès.', 1)
            setIsLoading && setIsLoading(false)
        } else {
            new Notification('Erreur lors de la modification.', 0)
            setIsLoading && setIsLoading(false)
        }
    } else {
        const response = await ProvinceService.addMangroGroups({ code: grp.name, site_id: selectedSites.id })
        if (response?.status?.code === 200 || response?.status?.code === 201) {
            new Notification('Création  avec succès.', 1)
            setIsLoading && setIsLoading(false)
        } else {
            new Notification("Erreur lors de l'ajout.", 0)
            setIsLoading && setIsLoading(false)
        }
    }
    setEnabledGroups(null)
    ProvinceService.getMangroGroups().then((response) => {
        if (response?.data) {
            const filteredGroup = response?.data?.filter((gp) => gp?.parent === selectedSites?.id)
            setGroups(filteredGroup)
            setIsLoading && setIsLoading(false)
        }
    })
    // }
}
