import React, { useEffect, useState } from 'react'
import { createContext } from 'react'
import Controls from '../../../../components/Entry/Controls'
import CustomerOrderService from '../../services/CustomerOrder'
import Notification from '../../../../layouts/Notification'

const { Loader } = Controls

export const Context = createContext({})

function CustomerOrderContext({ children, dataId, needLoad }) {
    const [value, setValue] = useState(null)
    const [loading, setLoading] = useState(false)
    const customerService = CustomerOrderService.load()

    useEffect(() => {
        if (dataId) {
            console.log('data id : ', dataId)
            setLoading(true)
            customerService
                .getByCode(dataId)
                .then((res) => {
                    if (res.data) {
                        console.log("res dans le context : ",res.data)
                        setValue(res.data)
                    } else {
                        new Notification('Erreur lors de la récupération du client.', 0)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    new Notification('Erreur lors de la récupération du client.', 0)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [dataId, customerService])

    return (
        <>
            <Loader isLoading={loading} />
            <Context.Provider value={value}>{children}</Context.Provider>
        </>
    )
}

export default CustomerOrderContext
