import React, { useState } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import DescriptionIcon from '@mui/icons-material/Description' // Icône générique pour représenter un fichier
import Stack from '@mui/material/Stack'
import FileViewer from './FileViewer'
import { Container } from '@mui/material'

function CustomFileView(props) {
    const { fileName, file } = props
    const [openPopup, setOpenPopup] = useState(false)

    const handleClick = () => {
        setOpenPopup(true)
    }
    return (
        <>
            <div
                style={{
                    width: '150px',
                    height: '150px',
                    padding: 0,
                    margin: 0,
                    display: 'flex',
                    '&:hover': { color: '#0FCB8F', cursor: 'pointer' },
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                onClick={() => window.open(file)}
            >
                <Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
                    <DescriptionIcon sx={{ color: 'gray', fontSize: 'large', width: '100%', height: '80%' }} />
                    <Typography variant="body2" sx={{ color: 'gray' }}>
                        {fileName}
                    </Typography>
                </Stack>
            </div>

            <FileViewer filePath={file} openPopup={openPopup} setOpenPopup={setOpenPopup} />
        </>
    )
}

export default CustomFileView
