import React from 'react'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Tooltip from '@mui/material/Tooltip'

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 0,
        margin: theme.spacing(0.5),
        alignItems: 'center'
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
        '& .MuiButton-label': {
            color: theme.palette.secondary.main
        }
    },
    primary: {
        backgroundColor: theme.palette.primary.light,
        '& .MuiButton-label': {
            color: theme.palette.primary.main
        }
    }
}))

export default function ActionButton(props) {
    const { tooltip, color, children, onClick, ...other } = props
    const classes = useStyles()

    return (
        <Tooltip title={tooltip || ''}>
            <Button className={`${classes.root} ${classes[color]}`} onClick={onClick} {...other}>
                {children}
            </Button>
        </Tooltip>
    )
}
