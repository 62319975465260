import { Chip, Container } from '@mui/material'
import React from 'react'

function ChipButton(props) {
    const { onClick, title, other } = props
    return (
        <>
                <Chip
                    style={{
                        border: '1px solid #677788',
                        color: '#677788',
                        fontSize: '0.7rem',
                        height: '1.3rem',
                    }}
                    {...other}
                    label={title}
                    clickable
                    onClick={onClick}
                />
        </>
    )
}

export default ChipButton
