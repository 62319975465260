import { Http } from '../../../applications/Http'
import Service from '../../../applications/Service'

class PlotService extends Service {
    static messages = {
        update_success: 'Modification de la parcelle avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création utilisateur avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/plots')
        return this
    }

    // static async getById(id) {
    //     return await Http.get([this.url, id].join('/'), {})
    // }

    static async getByCode(code) {
        return await Http.get([this.url, 'byCode', code].join('/'), {})
    }

    static async getCategoryById(category, id) {
        this.url = '/api/plots/' + category
        return await Http.get([this.url, id].join('/'), {})
    }

    // static async getPlotsByPeasantCode(peasantCode) {
    //     return await Http.get(`/api/plots/byPeasant/${peasantCode}`, {})
    // }

    static async getPlotsByPeasantCode(peasantCode) {
        return await Http.get(['/api/plots/byPeasant', peasantCode].join('/'), {})
    }

    static async getAllPlots(site) {
        try {
            const response = await Http.get(['/api/plots'].join('/'), site ? { site: site } : {})
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des parcelles :", error)
            throw error
        }
    }

    static async importGPX(data) {
        try {
            const response = await Http.post(['/api/plots', 'gpx'].join('/'), data)
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'importation du GPX :", error)
            throw error
        }
    }
}

export default PlotService
