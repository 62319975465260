import React, { useState } from 'react'
import Popup from '../../../../../../layouts/Popup'
import AutoCompleteSelect from '../../../../../../components/Entry/AutoCompleteSelect'
import { Container } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls'

function AddTech(props) {
    const { openPopup, setOpenPopup, techData, setAddedTech } = props

    const [selected, setSelected] = useState(null)
    const onChange = (e) => {
        setSelected(e.target.value)
    }

    const handleClick = () => {
        setOpenPopup(false)
        setAddedTech(selected)
    }

    return (
        <>
            <Container>
                <Popup
                    title={'Ajouter technicien'}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    sx={{ width: '400px' }}
                >
                    <AutoCompleteSelect
                        label={'Choisir un technicien'}
                        options={techData}
                        labelKey={'name'}
                        valueKey={'id'}
                        onChange={onChange}
                        value={selected}
                    />
                    <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Controls.Button
                            onClick={handleClick}
                            text="Ajouter"
                            size={'small'}
                        ></Controls.Button>
                    </Container>
                </Popup>
            </Container>
        </>
    )
}

export default AddTech
