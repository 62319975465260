import React, { useEffect, useState } from 'react'
import { Typography, Paper, Grid, Container } from '@mui/material'

const styles = {
    container_info: {
        padding: '3px 3px 3px 3px'
    },
    list_param: { color: '#748291', fontSize: '0.6rem', paddingBottom: '0.5rem' },
    container_chart: {
        padding: '3px 3px 3px 3px',
        backgroundColor: '#f8f8f8',
        borderRadius: '0.5rem'
    }
}


function ExistantVegetal(props) {
    const { existVegetationData } = props
    const [dataExistingVegetation, setDataExistingVegetation] = useState(null)

    useEffect(() => {
        if (existVegetationData) {
            console.log(existVegetationData);
            setDataExistingVegetation(existVegetationData)
        }
    }, [existVegetationData, dataExistingVegetation])
    return (
        <>
            <Paper
                sx={{
                    padding: '0.4rem',
                    borderRadius: '0.5rem',
                    display: 'inline-flex',
                    width: '100%',
                    backgroundColor: '#f8f8f8'
                }}
            >
                <Grid container>
                    <Container style={styles.container_info}>
                        <Container style={{ ...styles.container_info, paddingBottom: '0.5rem' }}>
                            <Typography
                                variant="p"
                                sx={{ color: '#748291', fontSize: '0.8rem', fontWeight: 'initial' }}
                            >
                                Végétation existantes
                            </Typography>
                        </Container>
                        <Container style={styles.container_info}>
                            <Container variant="p" sx={styles.list_param}>
                                {dataExistingVegetation &&
                                    dataExistingVegetation?.map((value) => (
                                        <div>
                                            <Typography variant="p" sx={{ fontWeight: 'initial' }}>
                                                {value.name}{' '}
                                                <i style={{ fontSize: '0.56rem' }}>({value.percentage}%)</i>
                                            </Typography>
                                        </div>
                                    ))}
                            </Container>
                        </Container>
                    </Container>
                </Grid>
            </Paper>
        </>
    )
}

export default ExistantVegetal
