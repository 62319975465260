// import { Http } from '../../../applications/Http.js'

import { Http } from '../../../applications/Http'
import Service from '../../../applications/Service'

class CustomerService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/customers')
        return this
    }

    static async getAllCustomers() {
        try {
            const response = await Http.get('/api/customers')
            console.log('response user', response.data)
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            throw error
        }
    }
}

export default CustomerService
