import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Layout from './layouts'
import Login from './pages/users/Login'
import RequireAuth from './layouts/RequireAuth'
//import checkAuth from './applications/Auth'
import initializeApp from './applications/Init'
import UseContext from './applications/hooks/UseContext'

initializeApp()

//const token = checkAuth()

function App() {
    return (
        <UseContext>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />

                    {/* Place new routes over this */}
                    {/* protected routes */}
                    <Route element={<RequireAuth />}>
                        <Route path="/app/*" element={<Layout />} />
                    </Route>
                    <Route path="*" element={<Navigate to={'/app/dashboard'} replace />} />
                </Routes>
            </Router>
        </UseContext>
    )
}

export default App
