import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import ContentView from '../../../../components/PageView/Content'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import { PlatDrainTrackingForm } from './PLatDrainTrackingForm'
import PlatDrainTrackingService from '../../plat_drain/services/plat_drain_tracking'
import PlatDrainTrackingContext from '../utils/hooks/PlatDrainTrackingContext'

function PlatDrainTrackingView(props) {
    const { addReturnButton, recordForEdit } = props

    const { haveAccessTo } = useAuth()
    const location = useLocation()
    const dataId = location.pathname.split('/').pop()
    const platDrainTrackingService = PlatDrainTrackingService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [needLoadCode, setNeedLoadCode] = useState(true)
    const [userHaveToAdd, setUserHaveToAdd] = useState(null)

    useEffect(() => {
        if (location?.state?.userHaveToAdd) {
            setUserHaveToAdd(location?.state?.userHaveToAdd)
        }
    }, [location])

    return (
        <>
            <CssBaseline />

            <PlatDrainTrackingContext userHaveToAdd={userHaveToAdd} >
                <ContentView
                    dataId={recordForEdit ? recordForEdit.id : dataId}
                    title={recordForEdit ? '' : 'Détail suivi - plate bande'}
                    service={platDrainTrackingService}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    addReturnButton={addReturnButton}
                    needLoad={needLoad}
                    Form={PlatDrainTrackingForm}
                    needLoadCode={needLoadCode}
                />
            </PlatDrainTrackingContext>
        </>
    )
}

export default PlatDrainTrackingView
