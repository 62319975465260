import { Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Controls from '../../../../../components/Entry/Controls'
import { numStr } from '../../../../../applications/UtilFunctions'
import Notification from '../../../../../layouts/Notification'
import PlantMaterialService from '../../../services/plant_material/plant_material'
import RecorgedPotService from '../../../services/recorged_pot/recorged_pot'

function ObjectifModificationView(props) {
    const { data, setNeedLoad, objectiveParent } = props
    const [actualObjective, setActualObjective] = useState(null)
    const [objectifValue, setObjectifValue] = useState('')
    const [userHaveToModifyObjectif, setUserHaveToModifyObjectif] = useState(false)
    const [userHaveToAddObjectif, setUserHaveToAddObjectif] = useState(false)
    const [value, setValue] = useState(null)

    useEffect(() => {
        if (data) {
            console.log(data)
            setValue({ ...value, id: data.id })
            setActualObjective(data.objective)
        }
    }, [data])

    const handleModifyObjectif = () => {
        setUserHaveToModifyObjectif(true)
        if (value && value.objective > 0) {
            const dataToSend = { ...value }
            console.log(dataToSend)
            handleSubmit(dataToSend)
                .then((data) => {
                    if (data) {
                        new Notification('Objectif modifié avec succès', 1)
                        setValue(null)
                        setUserHaveToModifyObjectif(false)
                        setNeedLoad(true)
                    }
                })
                .catch((error) => {
                    new Notification('Une erreur est survenue', 0)
                    setNeedLoad(true)
                    console.log(error)
                    setUserHaveToModifyObjectif(false)
                })
        } else {
            setUserHaveToModifyObjectif(false)
            new Notification('Veuillez entrer une nouvelle valeur', 0)
        }
    }

    const handleAddObjective = () => {
        if (value.objective && value.objective >= 0) {
            console.log(value)
            setUserHaveToAddObjectif(true)
            setValue({ ...value, objective: value.objective + actualObjective })
            const dataToSend = { ...value, objective: value.objective + actualObjective }
            console.log(dataToSend)
            handleSubmit(dataToSend)
                .then((data) => {
                    if (data) {
                        new Notification('Objectif additionné avec succès', 1)
                        setValue(null)
                        setUserHaveToAddObjectif(false)
                        setNeedLoad(true)
                    }
                })
                .catch((error) => {
                    new Notification('Une erreur est survenue', 0)
                    console.log(error)
                    setUserHaveToAddObjectif(false)
                    setNeedLoad(true)
                })
        } else {
            new Notification('Veuillez entrer une nouvelle valeur', 0)
        }
    }

    const handleSubmit = async (data) => {
        if (objectiveParent === 'plantMaterial') {
            const service = PlantMaterialService.load()
            return await service.updatePlantMaterial(data)
        } else if (objectiveParent === 'recorgedPot') {
            const service = RecorgedPotService.load()
            return await service.updateObjectiveRecorgedPot(data)
        }
    }

    return (
        <div className="p-4" style={{ width: '400px' }}>
            <Typography variant="body2" style={{ fontSize: '0.75rem', color: 'black', marginBottom: '10px' }}>
                <b>Objectif actuel : </b>
                {numStr(actualObjective, '.')} graines
            </Typography>
            <Controls.Input
                label="Nouvelle valeur"
                name="objective"
                type="number"
                value={value?.objective || ''}
                onChange={(e) => {
                    setValue({ ...value, [e.target.name]: parseInt(e.target.value) || 0 })
                }}
            />
            <div className="flex gap-2">
                <Controls.Button
                    haveSpin={true}
                    text="Modifier l'objectif actuel"
                    onClick={handleModifyObjectif}
                    disabled={userHaveToModifyObjectif}
                />
                <Controls.Button
                    haveSpin={true}
                    disabled={userHaveToAddObjectif}
                    text="Additionner à l'objectif"
                    onClick={handleAddObjective}
                />
            </div>
        </div>
    )
}

export default ObjectifModificationView
