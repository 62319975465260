import React from 'react'
import CircularPercent from '../../../components/Entry/CircularPercentDashboard'

export const DashboardPercent = (props) => {
    const { text, value } = props
    return (
        <div className="flex justify-between bg-slate-200 border rounded-xl p-4 w-full h-[85px] font-semibold">
            <div className="mt-[15px] text-slate-500">{text}</div>
            <div>
                <CircularPercent value={value} size={60} />
            </div>
        </div>
    )
}
