import { useNavigate } from 'react-router-dom'
import Notification from '../layouts/Notification'
import { useAuth } from './hooks/UseAuth'

export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0
}

export const isArray = (arr) => {
    return Array.isArray(arr)
}

export const addOrEdit = async (data, resetForm, service, setNeedLoad, setUserHaveToPost = null, otherFunction) => {
    //if other function exist, call it
    console.log('data to send : ', data)
    setUserHaveToPost && setUserHaveToPost(true)
    const response = await service.create(data)
    if (response?.status?.code === 200 || response?.status?.code === 201) {
        new Notification(service.messages.create_success, 1)
        console.log('response >>>>>>>>>>>> ', response)
        setNeedLoad && setNeedLoad(true)
        resetForm && resetForm()
        return response.data
    } else {
        console.error(response)
        new Notification(service.messages.create_error, 0)
    }
    setUserHaveToPost && setUserHaveToPost(false)
    otherFunction && otherFunction()
    return response
}

export const update = async (data, service, setNeedLoad) => {
    console.log('data to send : ', data)
    const response = await service.update(data.id, data)
    console.log(response)
    if (response?.status?.code === 200 || response?.status?.code === 201) {
        new Notification(service.messages.update_success, 1)
        setNeedLoad && setNeedLoad(true)
    } else {
        new Notification(service.messages.update_error, 0)
    }
}

export const notification = (message, type) => {
    new Notification(message, type)
}

export const isValideJSON = (str) => {
    try {
        JSON.parse(str)
        return true
    } catch (error) {
        return false
    }
}

//thousand separator number
export function numStr(numbre, separator) {
    numbre = '' + numbre
    separator = separator || '.'
    var c = '',
        d = 0
    while (numbre.match(/^0[0-9]/)) {
        numbre = numbre.substr(1)
    }
    for (var i = numbre.length - 1; i >= 0; i--) {
        c = d !== 0 && d % 3 === 0 ? numbre[i] + separator + c : numbre[i] + c
        d++
    }
    return c
}

//export function who formate iso8601 to dd/mm/yyyy
export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [day, month, year].join('/')
}

export function convertHaToM2(ha) {
    return parseFloat(ha.replace(',', '.')) * 10000
}

export function convertM2ToHa(m2) {
    return m2 / 10000
}

export function removeTextPart(regex, replacement, text){
    return text.replace(regex, replacement)
}
