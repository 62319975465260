import React, { useState, useEffect } from 'react'
import TableDetail from '../../../components/DataTable/TableDetail'
import { useNavigate } from 'react-router-dom'

const headCellsTraining = [
    // { id: 'polygon_grid', label: 'Polygone de quadrillage' },
    { id: 'technician', label: 'Technicien' },
    { id: 'tracking_point', label: 'Point de suivi' },
    { id: 'tracking_date', label: 'Date de suivi', type: 'date' },
    // { id: 'number_refilled', label: 'Nombre regarnies' },
    { id: 'average_number', label: 'Nombre moyennes de feuille' },
    { id: 'average_height', label: 'Hauteurs moyennes des jeunes plants' }
    // {
    //     id: 'actions',
    //     type: 'actions',
    //     label: 'Actions',
    //     disableSorting: true,
    //     showEdit: false,
    //     showRemove: false,
    //     showView: '/app/mangrove/nursery/tracking/edit',
    //     forcePassId: true
    // }
]

export const PlantingTrackingList = (props) => {
    const { records } = props

    const navigate = useNavigate()

    const addAction = () => {
        console.log('record data : ', records)
        navigate('/app/mangrove/planting/tracking/new', { state: records })
    }

    return (
        <div>
            <TableDetail
                headCells={headCellsTraining}
                // service={platDrainTrackingService}
                hideFilterButton={true}
                data={records?.planting_trackings}
                title={'Suivi'}
                onClickButton={addAction}
                showButton={true}
            />
        </div>
    )
}
