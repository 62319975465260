import React from 'react'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'

export default function SearchField(props) {
    const {placeholder = "Rechercher", sx, onChange} = props
    return (
        <InputBase
            sx={{ width: '100%', borderBottom: '1px solid #ccc', ...sx  }}
            size="small"
            inputProps={{ 'aria-label': 'recherche' }}
            placeholder= {placeholder}
            endAdornment={
                <SearchIcon
                    sx={{ color: 'gray', ':hover': { cursor: 'pointer' }}}
                    position="end"
                    onClick={() => {
                        console.log('coucou')
                    }}
                />
            }
            position="end"
            onChange={(e) => onChange(e.target.value)}
        />
    )
}
