// import { Http } from '../../../applications/Http.js'

import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class CustomerOrderService extends Service {
    static messages = {
        update_success: 'Modification avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création  avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/customer_order')
        return this
    }

    static async getByCode(id) {
        try {
            return await Http.get([this.url,"code", id].join('/'), {})
        } catch (error) {
            console.log(error)
            throw new Error("Une erreur s'est produite lors de la récupération du client.")
        }
    }

    static async addCustomerOrder(data) {
        try {
            const response = await Http.put('/api/customer_order', data)
            return response.data
        } catch (error) {
            throw new Error("Erreur lors de l'ajout .")
        }
    }

    static async updateCustomerOrder(id, data) {
        try {
            const response = await Http.patch(['/api/customer_order', id].join('/'), data)
            return response.data
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour .')
        }
    }

    static async getCustomerOrder() {
        try {
            const response = await Http.get('/api/customer_order')
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            throw error
        }
    }
}

export default CustomerOrderService
