import React, { useEffect, useState } from 'react'
import { Typography, Paper, Grid, Container } from '@mui/material'
import Controls from '../../../../../../../components/Entry/Controls'

const styles = {
    container_info: {
        padding: '3px 3px 3px 3px'
    },
    list_param: { color: '#748291', fontSize: '0.7rem', paddingBottom: '0.5rem' },
    container_chart: {
        padding: '3px 3px 3px 3px',
        backgroundColor: '#f8f8f8',
        borderRadius: '0.5rem'
    }
}

function DiagnosticPedologic(props) {
    const { pedologicalDiagData } = props
    const [dataDiag, setDataDiag] = useState(null)

    useEffect(() => {
        if (pedologicalDiagData) {
            setDataDiag({
                substratColor: pedologicalDiagData.substrat_color,
                depth: pedologicalDiagData.depth,
                texture: pedologicalDiagData.soil_texture,
                salinity: pedologicalDiagData.salinity
            })
        }
    }, [pedologicalDiagData])
    return (
        <>
            <Paper
                sx={{
                    padding: '0.4rem',
                    borderRadius: '0.5rem',
                    display: 'inline-flex',
                    width: '60%',
                    backgroundColor: '#f1f1f1'
                }}
            >
                <Grid container>
                    <Grid item xs={7}>
                        <Container style={styles.container_info}>
                            <Container style={{ ...styles.container_info, paddingBottom: '0.5rem' }}>
                                <Typography variant="p" sx={{ color: '#748291', fontSize: '0.68rem' }}>
                                    Diagnostic pédologique
                                </Typography>
                            </Container>
                            <Container style={styles.container_info}>
                                <Typography variant="p" sx={styles.list_param}>
                                    <b>Couleur substrat :</b> {dataDiag?.substratColor || ''}
                                    <br />
                                    <b>Profondeur :</b> {dataDiag?.depth || ''}
                                    <br />
                                    <b>Texture de sol :</b> {dataDiag?.texture || ''} <br />
                                </Typography>
                            </Container>
                        </Container>
                    </Grid>
                    <Grid item xs={5} sx={{ padding: 1 }}>
                        <Container style={styles.container_chart}>
                            <Controls.CustomCircularPercentage
                                unit="‰"
                                value={dataDiag?.salinity || 0}
                                label={'Salinité'}
                                size={100}
                            />
                        </Container>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default DiagnosticPedologic
