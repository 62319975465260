import React, { useEffect, useState } from 'react'
import { Form, useForm } from '../../../applications/hooks/UseForm'
import { Container, FormControl, Grid, MenuItem, Select } from '@mui/material'
import Controls from '../../../components/Entry/Controls'
import DataSetsService from '../../datasets/services/DataSets'

const initialFValues = {
    category: '',
    title: '',
    code: ''
}

export const AddForm = (props) => {
    const { addOrEdit, recordForEdit } = props
    const dataset = DataSetsService.load()

    const [data, setData] = useState([])
    const [userHaveToPost, setUserHaveToPost] = useState(false)
    const [parents, setParents] = useState([])

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    useEffect(() => {
        dataset
            .getDataCode('form_category')
            .then((response) => {
                console.log('response', response.data)
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setData(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })

        dataset
            .getDataCode('form_parent')
            .then((response) => {
                console.log('response', response.data)
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setParents(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('category' in fieldValues) temp.category = fieldValues.category ? '' : 'Ce champ est requis.'
        if ('title' in fieldValues) temp.title = fieldValues.title ? '' : 'Ce champ est requis.'
        if ('code' in fieldValues) temp.code = fieldValues.code ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })

        return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
        initialFValues,
        true,
        validate
    )

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm, setUserHaveToPost)
        }
    }

    console.log('data', data)

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <Container>
                        <Controls.AutoCompleteSelect
                            label="Catégorie"
                            name="category"
                            value={values?.category || ''}
                            options={data}
                            onChange={handleInputChange}
                            labelKey="label"
                            valueKey="label"
                            isRequired={true}
                        />
                        <Controls.AutoCompleteSelect
                            label="Parent"
                            name="parent"
                            value={values?.parent || ''}
                            options={parents}
                            onChange={handleInputChange}
                            labelKey="label"
                            valueKey="label"
                        />
                        <Controls.Input
                            name="title"
                            label="Titre"
                            value={values.title}
                            onChange={handleInputChange}
                            error={errors?.title}
                            isRequired={true}
                        />
                        <Controls.Input
                            name="code"
                            label="Clé"
                            value={values.code}
                            onChange={handleInputChange}
                            error={errors?.code}
                            isRequired={true}
                        />
                    </Container>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center', marginTop: '8px' }}>
                    <Controls.Button type="submit" text="Enregistrer" haveSpin={true} disabled={userHaveToPost} />
                </Grid>
            </Grid>
        </Form>
    )
}

export default AddForm
