import { Typography } from '@mui/material'
import React from 'react'

function SectionTitle(props) {
    const { children } = props
    return (
        <div style={{ marginBottom: '0.5rem' }}>
            <Typography
                variant="p"
                style={{
                    fontWeight: 'bold',
                    fontSize: '0.9rem'
                }}
            >
                {children}
            </Typography>
        </div>
    )
}

export default SectionTitle
