/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// import Avatar from '@mui/material/Avatar'
// import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Checkbox from '@mui/material/Checkbox'
// import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
// import Container from '@mui/material/Container'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ErrorText from '../../components/Typography/ErrorText'
import UserService from './services/User'
import { setToken } from '../../applications/reducers/AuthSlice'
import { useAuth } from '../../applications/hooks/UseAuth'
import logo_sidebar from '../../assets/image/acceuil.png'
import background from '../../assets/image/loginImg.png'
import { CircularProgress } from '@mui/material'
import Controls from '../../components/Entry/Controls'
// function Copyright(props) {
//     return (
//         <Typography variant="body2" color="text.secondary" align="center" {...props}>
//             {'Copyright © '}
//             <Link color="inherit" href="#">
//                 SIRH
//             </Link>{' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     )
// }

export default function SignIn() {
    const emailRef = useRef()
    const errRef = useRef()
    const { token } = useAuth()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [onLoad, setOnLoad] = useState(true)
    const [spinner, setSpinner] = useState(false)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        if (onLoad && token) {
            navigate('/app/dashboard')
            setOnLoad(false)
        }
    })

    useEffect(() => {
        emailRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMsg('')
    }, [email, password])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSpinner(true)

        try {
            const user = await UserService.login({ email, password })
            dispatch(setToken({ token: user.data.access_token }))

            // session storage or database (for session)
            sessionStorage.setItem('token', user.data.access_token)
            // await UserService.setSession({ token: user.data.access_token })

            setEmail('')
            setPassword('')
            setSpinner(false)
            navigate('/app/dashboard')
        } catch (err) {
            if (!err?.originalStatus) {
                // isLoading: true until timeout occurs
                setErrMsg('Aucune réponse du serveur')
            } else if (err.originalStatus === 400) {
                setErrMsg('Utilisateur ou mot de passe manquant')
            } else if (err.originalStatus === 401) {
                setErrMsg('Non autorisé')
            } else {
                setErrMsg('La connexion a échoué')
            }
            errRef?.current?.focus()
            setSpinner(false)
        }
    }

    const handleEmailInput = (e) => setEmail(e.target.value)

    const handlePasswordInput = (e) => setPassword(e.target.value)

    return (
        <div className="bg-black  flex justify-center items-center h-[100vh] w-[100vw] overflow-hidden">
            <div
                className=" w-full h-full flex justify-center items-center"
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                <Box
                    sx={{
                        // marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                    className=" border-4 border-green-200 rounded-xl bg-white w-[30%] mx-auto "
                >
                    <div className="p-10">
                        <div
                            className="flex items-center gap-2 px-1  blend-darken"
                            style={{ justifyContent: 'center' }}
                        >
                            <img src={logo_sidebar} alt="Logo bôngy" className="w-[110px]" />
                        </div>
                        <Typography variant="h8" className="pt-5 flex justify-center items-center">
                            Se connecter
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                ref={emailRef}
                                value={email}
                                onChange={handleEmailInput}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handlePasswordInput}
                                value={password}
                            />

                            {/* <div ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live="assertive">
                                {errMsg && <ErrorText styleClass="mt-8">{errMsg}</ErrorText>}
                            </div> */}
                            <button type="submit" className="w-full bg-[#2e5933] pt-2 pb-2 rounded-md mt-5 " style={{ color: 'white' }}>
                                <div className="flex  gap-4" style={{justifyContent: 'center', alignItems: 'center'}} >
                                    <p>Se connecter</p>
                                    {spinner && (
                                        <div>
                                            <CircularProgress size="15px" color="inherit" />
                                        </div>
                                    )}
                                </div>
                            </button>
                        </Box>
                    </div>
                </Box>
                {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </div>
    )
}
