import React, { useEffect } from 'react'
import TableDetail from '../../../../../../components/DataTable/TableDetail'
import { useNavigate } from 'react-router-dom'

const headCells = [
    { id: 'monitoring_date', label: 'Date', type: 'date', disableSorting: true },
    {
        id: 'regarnissage',
        label: 'Type',
        disableSorting: true,
        render: (value) => (value === 1 ? 'Regarnissage' : 'Monitoring')
    },
    // { id: 'number_tree_planted', label: 'Arbres plantés', type: 'Integer', disableSorting: true },
    //{ id: 'number_living_tree', label: 'Arbres vivants', disableSorting: true },
    { id: 'number_living_tree', label: 'Arbres vivants', type: 'Integer', disableSorting: true },
    { id: 'survival_rate', label: 'Taux de survie', disableSorting: true },
    { id: 'technicien', label: 'Technicien', disableSorting: true }
]

function MonitoringTable(props) {
    const { data, records } = props
    const title = 'Monitoring'
    const navigate = useNavigate()
    const handleOnDataDelete = () => {}

    const onClickButton = () => {
        navigate('/app/agroforestry/plantations/monitoring/new', { state: records })
    }

    return (
        <>
            <TableDetail
                showButton={true}
                headCells={headCells}
                hideFilterButton={true}
                title={title}
                onClickButton={onClickButton}
                onDataDelete={handleOnDataDelete}
                data={data}
            />
        </>
    )
}

export default MonitoringTable
