import Notification from '../layouts/Notification'

class ErrorHandler {
    static handle(error) {
        switch (error.response?.status) {
            case 401:
                if (!['login'].some((r) => window.location.href.includes(r))) {
                    sessionStorage.clear()
                    window.location.href = '/login'
                }
                new Notification(ErrorHandler.translateErrorMessage(error), 0) // Affiche une notification d'erreur
                break
            default:
                new Notification(ErrorHandler.translateErrorMessage(error), 0) // Affiche une notification d'erreur
                break
        }
    }

    static translateErrorMessage(error) {
        const errorMessage = error?.response?.data?.status?.message
        if (errorMessage?.trim().toLowerCase() === 'user or password not valid.') {
            return 'Utilisateur ou mot de passe non valide.'
        }
        return errorMessage
    }
}

export default ErrorHandler
