import React from 'react'
import { Form, useForm } from '../../../../../../../../applications/hooks/UseForm'
import Controls from '../../../../../../../../components/Entry/Controls'
import { Container, Grid } from '@mui/material'

const defaultOptions = [{ id: '1', name: 'option1' }]
function AddSemisGetOut(props) {
    const { addOrEdit, objectRelated } = props

    const initialValues = {
        quantity: 0,
        plot_id: 1,
        getout_date: new Date(),
        nursery_semis_id: objectRelated.nurserySemisId
    }
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('quantity' in fieldValues) temp.quantity = fieldValues.quantity ? '' : 'Ce champ est requis.'
        if ('plot_id' in fieldValues) temp.plot_id = fieldValues.plot_id ? '' : 'Ce champ est requis.'
        if ('getout_date' in fieldValues) temp.getout_date = fieldValues.getout_date ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })
        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, errors, setErrors, handleInputChange, resetForm } = useForm(initialValues, true, validate)
    const handleSubmit = (e) => {
        e.preventDefault()
        if (validate())
            addOrEdit(
                {
                    ...values,
                    getout_date: values?.tracking_date || new Date()
                },
                resetForm
            )
    }
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item sx={6}>
                        <Container>
                            <Controls.Input
                                label="Quantité"
                                name="quantity"
                                value={values.quantity}
                                onChange={handleInputChange}
                                error={errors.quantity}
                                isRequired={true}
                            />
                        </Container>
                    </Grid>
                    <Grid item sx={6}>
                        <Container>
                            <Controls.CustomSelect
                                name="plot_id"
                                label="Parcelle d'affectation"
                                options={objectRelated?.plotData || defaultOptions}
                                labelKey={'code'}
                                valueKey={'id'}
                                value={values.plot_id}
                                error={errors.plot_id}
                                onChange={handleInputChange}
                                isRequired={true}
                            />
                            <Controls.DatePicker
                                label="Date de sortie"
                                name="getout_date"
                                value={values?.getout_date}
                                onChange={handleInputChange}
                                error={errors.getout_date}
                                isRequired={true}
                            />
                        </Container>
                    </Grid>
                </Grid>
                <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Controls.Button type="submit" text="Enregistrer" />
                </Container>
            </Form>
        </>
    )
}

export default AddSemisGetOut
