/* eslint-disable react-hooks/exhaustive-deps */
import { Paper } from '@mui/material'
import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import { FcBullish } from 'react-icons/fc'
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'
import { useAuth } from '../applications/hooks/UseAuth'
import { ReactComponent as QuitIcon } from '../assets/icons/quit.svg'
import logo_sidebar from '../assets/image/acceuil.png'
import { DASHBOARD_SIDEBAR_LINKS /*DASHBOARD_SIDEBAR_BOTTOM_LINKS*/ } from '../routes/menu'
import { GlobalContext } from '../applications/hooks/UseContext'

const linkClass =
    'poppins-regular flex items-center gap-2 px-3 py-2 hover:bg-white hover:no-underline active:-bg-white rounded-sm text-base'

export default function Sidebar() {
    const navigate = useNavigate()
    // const { user, role, handleLogOut } = useAuth()
    const { haveAccessTo, handleLogOut } = useAuth()
    // const userName = `${user.firstname} ${user.lastname}`
    // const userRole = role

    function logoutUser() {
        handleLogOut()
        navigate('/login')
    }

    return (
        <Paper elevation={12} style={{ background: '#E7F2EE' }}>
            <div className="bg-[#E7F2EE] w-80 p-3 flex flex-col">
                <div className="flex items-center gap-2 px-1 pt-2 pb-4  blend-darken" style={{ justifyContent: 'center' }}>
                    <img src={logo_sidebar} alt="Logo bôngy" className="w-[110px]" />
                </div>
                <div
                    className="flex flex-col gap-0.5"
                    style={{
                        overflowY: 'scroll',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#d0e0d7 #E7F2EE',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                            height: '8px',
                            background: '#d0e0d7'
                        },
                        //configurer l'hauteur du div pour que son hauteur soit le 50% de l'écran de l'ordinateur utilisé par l'utilisateur
                        height: '60vh'
                    }}
                >
                    {DASHBOARD_SIDEBAR_LINKS.map((link) => (
                        <SidebarLink haveAccessTo={haveAccessTo} key={link.key} link={link} />
                    ))}
                </div>
                <div className="flex flex-col gap-0.5 pt-2 border-neutral-700">
                    {/*DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link) => (
                        <SidebarLink key={link.key} link={link} />
                    ))*/}
                    <div onClick={logoutUser} className={classNames(linkClass, 'cursor-pointer ')}>
                        <span className="text-xl">
                            <QuitIcon />
                        </span>
                        Quitter
                    </div>
                </div>
            </div>
        </Paper>
    )
}

function SidebarLink({ haveAccessTo, link }) {
    const { pathname } = useLocation()
    const globalContext = useContext(GlobalContext)

    // useEffect(() => {

    return (
        <>
            {link.submenu ? (
                <SidebarSubmenu haveAccessTo={haveAccessTo} parent={link.key} {...link} />
            ) : (
                <>
                    {haveAccessTo(link.key, 'show') && (
                        <Link
                        onClick={() => {
                            globalContext?.setValue({...globalContext.value, path: link.path})
                        }}
                            key={link.key}
                            to={link.path}
                            className={classNames(
                                pathname === link.path ? 'bg-white text-[#14B67D]' : 'text-[#54362B]',
                                linkClass
                            )}
                        >
                            <span className="text-xl">{link.icon}</span>
                            {link.label}
                        </Link>
                    )}
                </>
            )}
        </>
    )
}

function SidebarSubmenu({ haveAccessTo, submenu, label, icon, parent, path }) {
    const location = useLocation()
    const [isExpanded, setIsExpanded] = useState(false)
    const globalContext = useContext(GlobalContext)

    /** Open Submenu list if path found in routes, this is for directly loading submenu routes  first time */
    useEffect(() => {
        if (
            submenu.filter((m) => {
                return m.path === location?.pathname
            })[0]
        )
            setIsExpanded(true)
    }, [])

    return (
        <div className={`flex-col font-sans font-bold `}>
            {/** Route header */}
            {haveAccessTo(parent, 'show', true) && (
                <div className="w-full flex " onClick={() => setIsExpanded(!isExpanded)}>
                    <Link
                        key={path}
                        className={classNames(
                            location.pathname === path ? 'bg-white text-[#14B67D]' : 'text-[#54362B]',
                            linkClass
                        )}
                        style={{ width: '100%' }}
                    >
                        <span className="text-xl">{icon}</span>
                        <span style={{ width: '70%' }}>{label}</span>
                        <ChevronDownIcon
                            className={
                                'w-5 h-5 mt-1 float-right delay-400 duration-500 transition-all  ' +
                                (isExpanded ? 'rotate-180' : '')
                            }
                        />
                    </Link>
                </div>
            )}

            {/** Submenu list */}
            <div className={` w-full ` + (isExpanded ? '' : 'hidden')}>
                {submenu.map((m, k) => {
                    return (
                        <>
                            {haveAccessTo(`${parent}_${m.key}`, 'show') && (
                                <Link
                                    onClick={() => {
                                        globalContext?.setValue({...globalContext.value, path: m.path})
                                    }}
                                    key={k}
                                    to={m.path}
                                    className={classNames(
                                        globalContext?.value?.path === m.path ? 'bg-white text-[#14B67D]' : 'text-[#54362B]',
                                        linkClass
                                    )}
                                    style={{ paddingLeft: '35px' }}
                                >
                                    <span className="text-xl">{m.icon}</span>
                                    {m.label}
                                </Link>
                            )}
                        </>
                    )
                })}
            </div>
        </div>
    )
}
