import React from 'react'
import Subtitle from '../../Typography/Subtitle'
import { chipClasses, Container } from '@mui/material'
import Controls from '../../Entry/Controls'

function ImportationContent(props) {
    const { title, children } = props

    return (
        <Container>
            <Subtitle>{title}</Subtitle>
            {children}
        </Container>
    )
}

export default ImportationContent
