import React, { useEffect, useState } from 'react'
import Popup from '../../../../../../layouts/Popup'
import { Container, Grid } from '@mui/material'
import Controls from '../../../../../../components/Entry/Controls'
import DataSetsService from '../../../../../datasets/services/DataSets'

function AddDensity(props) {
    const { openPopup, setOpenPopup, onSave, dataVegetation = [], setValues, values } = props

    const [data, setData] = useState({})
    const [densityTypeOption, setDensityTypeOption] = useState([])
    const dataset = DataSetsService.load()

    const handleSaveData = () => {
        onSave([...dataVegetation, data])
        setValues({ ...values, density: [...dataVegetation, data] })
        setOpenPopup(false)
    }

    useEffect(() => {
        dataset
            .getDataCode('mangrove_density')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setDensityTypeOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [])
    return (
        <>
            <Popup title={'Densité'} sx={{ width: '400px' }} setOpenPopup={setOpenPopup} openPopup={openPopup}>
                <Grid container>
                    <Container>
                        <Controls.AutoCompleteSelect
                            label="Type"
                            name="type"
                            value={data?.type || ''}
                            options={densityTypeOption || []}
                            onChange={(e) => {
                                setData({ ...data, type: e.target.value })
                            }}
                            labelKey="label"
                            valueKey="label"
                        />
                        {/* <Controls.Input
                            label="Type"
                            name="Type"
                            onChange={(e) => setData({ ...data, type: e.target.value })}
                        /> */}
                        <Controls.Input
                            label="Nombre"
                            type="number"
                            name="number"
                            onChange={(e) => setData({ ...data, number: e.target.value })}
                        />
                    </Container>
                    <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Controls.Button onClick={handleSaveData} text="Ajouter" size={'small'} />
                    </Container>
                </Grid>
            </Popup>
        </>
    )
}

export default AddDensity
