import React, { useState } from 'react'
import PageHeader from '../../components/Typography/PageHeader'
import FaunaList from './components/listView/FaunasList'
import FaunaService from './services/Species'
import { useAuth } from '../../applications/hooks/UseAuth'
import AddFauna from './components/popupView/AddFauna'
import ToolbarButton from '../../components/DataTable/ToolbarButton'
import { Toolbar as MuiToolbar } from '@mui/material'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
    siteChange: {
        '& .MuiBottomNavigation-root': {
            height: '50px',
            borderRadius: 10,
            borderColor: '#0FCB8F',
            borderWidth: 2,
            background: '#43D5A7'
        },
        '& .MuiBottomNavigationAction-root': {
            background: '#0FCB8F',
            minWidth: '18vw'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            borderRadius: 10,
            background: 'none'
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        }
    }
}))

const CURRENT_PAGE = 'administration_species'

function Fauna() {
    const { haveAccessTo } = useAuth()
    const navigate = useNavigate()
    const classes = useStyles()

    const useDrawer = false
    const service = FaunaService.load()
    const [needLoad, setNeedLoad] = useState(true)
    const [value, setValue] = useState('fauna')

    const handleChangePage = (event, newValue) => {
        console.log(newValue)
        setValue(newValue)
        navigate('/app/species/' + newValue)
    }

    return (
        <>
            <PageHeader title={'Les faunes'} subTitle={'Gestion des espèces faunes'}>
                <ToolbarButton
                    moduleTitle="faune"
                    service={service}
                    drawer={useDrawer}
                    haveAccessTo={haveAccessTo}
                    currentPage={CURRENT_PAGE}
                    setNeedLoad={setNeedLoad}
                    needLoad={needLoad}
                    Form={AddFauna}
                />
            </PageHeader>
            <MuiToolbar style={{ padding: 0, margin: '20px 0 0 0' }} className={classes.siteChange}>
                <BottomNavigation showLabels value={value} onChange={handleChangePage}>
                    <BottomNavigationAction key="1" label="Faunes" value="fauna" />
                    <BottomNavigationAction key="2" label="Flores" value="flora" />
                </BottomNavigation>
            </MuiToolbar>

            <FaunaList
                service={service}
                needLoad={needLoad}
                drawer={useDrawer}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                Form={AddFauna}
                currentPage={CURRENT_PAGE}
            />
        </>
    )
}

export default Fauna
