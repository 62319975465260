import Table from '../../../../components/DataTable/Table'

const headCells = [
    { id: 'firstname', label: 'Nom' },
    { id: 'lastname', label: 'Prénom' },
    { id: 'email', label: 'Email' /*disableFilter: true*/ },
    { id: 'roles', type: 'select', label: 'Rôle' },
    { id: 'actions', type: 'actions', label: 'Actions', disableSorting: true, showEdit: true, showRemove: true }
]

export default function UsersList(props) {
    const { haveAccessTo, currentPage, Form, drawer, service, needLoad, setNeedLoad } = props

    return (
        <Table
            title="Modification d'un utilisateur"
            headCells={headCells}
            service={service}
            Form={Form}
            drawer={drawer}
            keyToSearch="lastname"
            haveAccessTo={haveAccessTo}
            currentPage={currentPage}
            setNeedLoad={setNeedLoad}
            needLoad={needLoad}
            hideFilterButton={true}
        />
    )
}
