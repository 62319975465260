import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { Form, useNavigate } from 'react-router-dom'
import { useAuth } from '../../applications/hooks/UseAuth'
import PageHeader from '../../components/Typography/PageHeader'

import { Toolbar as MuiToolbar } from '@mui/material'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Controls from '../../components/Entry/Controls'
import InventoryList from './components/inventory/InventoryList'
import InventoryService from './services/inventory/inventory'
import InventoryView from './components/inventory/ContentForm'
import ProvinceFilter from '../provinces/components/ProvinceFilter'
import ToolbarButton from '../../components/DataTable/ToolbarButton'
import TakeInventoryForm from './components/inventory/TakeInventoryForm'

const CURRENT_PAGE = 'agroforestry_pepiniere'

const useStyles = makeStyles((theme) => ({
    siteChange: {
        '& .MuiBottomNavigation-root': {
            height: '50px',
            borderRadius: 10,
            borderColor: '#0FCB8F',
            borderWidth: 2,
            background: '#43D5A7'
        },
        '& .MuiBottomNavigationAction-root': {
            background: '#0FCB8F',
            minWidth: '18vw'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            borderRadius: 10,
            background: 'none'
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        },
        '& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label': {
            fontSize: 15,
            color: '#FFFFFF'
        }
    }
}))

function Inventory() {
    const classes = useStyles()

    const service = InventoryService.load()
    const { haveAccessTo } = useAuth()
    const [needLoad, setNeedLoad] = useState(true)
    const [filter, setFilter] = useState({})
    const useDrawer = true

    const [value, setValue] = useState('inventory')

    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        console.log(newValue)
        setValue(newValue)
        navigate('/app/agroforestry/nursery/' + newValue)
    }

    const addAction = () => {
        navigate('/app/agroforestry/nursery/inventory/new')
    }

    const handleDoInventory = () => {}

    return (
        <div className={'card w-full bg-base-100 font-sans font-bold'} style={{ paddingBottom: 20 }}>
            <PageHeader title={'Matériels & outils'} subTitle={'Matériels & outils'}>
                <div className="flex gap-4 w-full">
                    <Controls.Button
                        disabled={!haveAccessTo(CURRENT_PAGE, 'add')}
                        text="Nouveau"
                        variant="contained"
                        size="large"
                        onClick={addAction}
                    />
                    <ToolbarButton
                        label="Faire l'inventaire"
                        moduleTitle="inventaire"
                        service={service}
                        drawer={!useDrawer}
                        haveAccessTo={haveAccessTo}
                        currentPage={CURRENT_PAGE}
                        setNeedLoad={setNeedLoad}
                        needLoad={needLoad}
                        Form={TakeInventoryForm}
                    />
                </div>
            </PageHeader>
            <div style={{ display: 'flex', gap: 10, marginTop: 10 }}>
                <ProvinceFilter setNeedLoad={setNeedLoad} filter={filter} setFilter={setFilter} />
                <MuiToolbar style={{ padding: 0, alignItems: 'start' }} className={classes.siteChange}>
                    <BottomNavigation showLabels value={value} onChange={handleChange}>
                        <BottomNavigationAction key="1" label="Approvisionnement" value="supply" />
                        <BottomNavigationAction key="2" label="Production de jeunes plants" value="plant" />
                        <BottomNavigationAction key="3" label="Matériels & Outils" value="inventory" />
                    </BottomNavigation>
                </MuiToolbar>
            </div>
            <InventoryList
                service={service}
                needLoad={needLoad}
                setNeedLoad={setNeedLoad}
                haveAccessTo={haveAccessTo}
                filterFromList={filter}
                Form={InventoryView}
                drawer={useDrawer}
                currentPage={CURRENT_PAGE}
            />
        </div>
    )
}

export default Inventory
