import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class SpeciesService extends Service {
    static messages = {
        update_success: 'Modification espèce avec succès.',
        update_error: 'Erreur de modification.',
        delete_success: 'Suppression effectuée.',
        delete_error: 'Erreur de suppression.',
        create_success: 'Création éspèce avec succès.',
        create_error: 'Erreur de création.'
    }
    static load() {
        this.setUrl('/api/species')
        return this
    }

    static async getByCode(id) {
        return await Http.get(`${this.url}/${id}`, {})
    }

    static async getAllSpecies() {
        try {
            const response = await Http.get('/api/species')
            console.log('response user', response.data)
            return response.data
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des utilisateurs :", error)
            throw error
        }
    }

    static async addSpecies(data) {
        return await Http.post(this.url, data)
    }
}

export default SpeciesService
