import dayjs from "dayjs";
import React, { useState } from "react";
import CustomAccordion from "../../../components/Accordion/CustomAccordion";
import Controls from "../../../components/Entry/Controls";


// config components
const ConfigComponent = ({
  name,
  type,
  value,
  label,
  onChange,
  options,
}) => {
  const handleChange = (
    event
  ) => {
    onChange(event.target.value);
  };

  const newOPtions = options?.map((option) => {
    return {
      id: option,
      title: option,
    };
  });
  switch (type) {
    case "date":
      return (
        <Controls.DatePicker
          label={label}
          name={name}
          onChange={handleChange}
          value={dayjs(value, "DD/MM/YYYY")}
        />
      );
    case "password":
      return (
        <div className="flex flex-col gap-1 w-full">
          <Controls.InputPassword
            label={label}
            value={value}
            name={name}
            fullWidth
            onChange={handleChange}
          />
        </div>
      );
    case "text":
    case "number":
    case "email":
      return (
        <div className="flex flex-col gap-1 w-full">
          <Controls.Input
            label={label}
            type={type}
            value={value}
            name={name}
            fullWidth
            onChange={handleChange}
          />
        </div>
      );
    case "select":
      return (
        <div className="flex flex-col gap-1 w-full">
          <Controls.Select
            label={label}
            value={value}
            name={name}
            onChange={handleChange}
            options={options ? newOPtions : []}
          />
        </div>
      );
    default:
      return <div className="flex flex-col gap-1 w-full"></div>;
  }
};

const Config = ({ data, setGlobalData }) => {
  const [configs, setConfigs] = useState(data);

  const handleConfigChange = (path, newValue) => {
    const pathParts = path.split(".");
    const data = { ...configs.data };
    let currentPart = data;
    for (let i = 0; i < pathParts.length; i++) {
      const currentItem = currentPart[pathParts[i]] ;
      if (currentPart && typeof currentPart === "object") {
        if (i === pathParts.length - 1) {
          // change the dayJs into date string
          if (currentItem.type === "date") {
            currentItem.value = dayjs(newValue).format("DD/MM/YYYY");
          } else {
            currentItem.value = newValue;
          }
        } else {
          currentPart = currentItem;
        }
      }
    }
    setConfigs((prev) => ({ ...prev, data: data }));

    setGlobalData((prevData) => {
      return (
        prevData &&
        prevData.map((item) => {
          if (item.id === configs.id) {
            return { ...item, data: configs.data };
          }
          return item;
        })
      );
    });
  };
  const renderConfigComponents = (data, parentKey) => {
    const components = [];
    for (const key in data) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof data[key] === "object" && !data[key].type) {
        components.push(
          <div key={`${newKey}-header`} className="flex flex-col  w-full">
            {key !== "other" && (
              <h3 className="mb-2 text-base font-semibold">{key}</h3>
            )}
            <div
              key={`${newKey}-group`}
              className="flex flex-row items-center justify-center gap-6 w-full"
            >
              {renderConfigComponents(data[key], newKey)}
            </div>
          </div>
        );
      } else {
        components.push(
          <ConfigComponent
            key={newKey}
            name={newKey}
            type={data[key].type}
            value={data[key].value}
            label={data[key].label}
            options={data[key].options}
            onChange={(newValue) => handleConfigChange(newKey, newValue)}
          />
        );
      }
    }

    return components;
  };
  return (
    <CustomAccordion
      defaultExpanded={false}
      title={configs.title}
      name={`panel${configs.id}`}
    >
      <section className="flex flex-col gap-8 w-full">
        {renderConfigComponents(configs.data)}
      </section>
    </CustomAccordion>
  );
};

export default Config;
