import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../../applications/hooks/UseAuth'
import ContentView from '../../../../components/PageView/Content'
import LandDetailsContent from './content/LandDetailsContent'
import LandService from '../../services/Land'
import LandDataContext from '../utils/hooks/LandDataContext'
import AddLandDetailsContent from '../FormAdd/content/AddLandDetailsContent'

function LandDetailsPage(props) {
    const { addReturnButton, recordForEdit } = props
    const { haveAccessTo, user } = useAuth()
    const location = useLocation()
    const dataCode = location.pathname.split('/').pop()
    const [needLoad, setNeedLoad] = useState(false)
    const [needLoadCode, setNeedLoadCode] = useState(false)
    useEffect(() => {
        if (dataCode !== 'new') {
            setNeedLoadCode(true)
            setNeedLoad(true)
        } else {
            setNeedLoadCode(false)
            setNeedLoad(false)
        }
    }, [dataCode, setNeedLoad, setNeedLoadCode])
    return (
        <>
            {dataCode !== 'new' && (
                <LandDataContext
                    dataCode={recordForEdit ? recordForEdit.code : dataCode}
                    needLoad={needLoad}
                    setNeedLoad={setNeedLoad}
                >
                    <ContentView
                        title={recordForEdit ? '' : 'détails'}
                        dataId={recordForEdit ? recordForEdit.code : dataCode}
                        service={LandService}
                        addReturnButton={addReturnButton}
                        needLoad={needLoad}
                        haveAccessTo={haveAccessTo}
                        setNeedLoad={setNeedLoad}
                        needLoadCode={needLoadCode}
                        setNeedLoadCode={setNeedLoadCode}
                        Form={LandDetailsContent}
                    />
                </LandDataContext>
            )}

            {dataCode === 'new' && (
                <ContentView
                    title={recordForEdit ? '' : 'Nouveau terrain à restaurer'}
                    dataId={recordForEdit ? recordForEdit.code : dataCode}
                    service={LandService}
                    needLoad={needLoad}
                    haveAccessTo={haveAccessTo}
                    setNeedLoad={setNeedLoad}
                    needLoadCode={needLoadCode}
                    setNeedLoadCode={setNeedLoadCode}
                    Form={AddLandDetailsContent}
                    action={'add'}
                    user={user}
                />
            )}
        </>
    )
}

export default LandDetailsPage
