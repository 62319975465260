import React, { useEffect } from 'react'
import { FormControl, FormHelperText, TextField, Autocomplete, IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

export default function AutoCompleteSelect(props) {
    const {
        name,
        label,
        value,
        error = null,
        onChange,
        options,
        labelKey,
        valueKey,
        isRequired = false,
        isDisabled = false,
        disableSorting = false,
        hideTitle = false,
        useDeleteOption = false,
        onDelete = () => {}
    } = props

    const useStyles = {
        labelInput: {
            fontSize: '12px'
        },
        labelContainer: {
            marginBottom: hideTitle ? '1px' : '20px',
            width: '100%',
            display: 'block'
        },
        clearButton: {
            padding: '0',
            marginLeft: '8px',
            color: 'red'
        },
        autocomplete: {
            display: 'flex',
            alignItems: 'center'
        },
        textField: {
            width: '100%'
        },
        optionContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        }
    }

    const classes = useStyles

    useEffect(() => {
        //order label alphabetically
        if (options && labelKey && !disableSorting) options.sort((a, b) => a[labelKey].localeCompare(b[labelKey]))
    }, [options, labelKey, disableSorting])

    const handleClearOption = (option) => {
        console.log(option)
        onDelete(option.id)
    }

    return (
        <div style={classes.labelContainer}>
            {!hideTitle && (
                <div style={classes.labelInput}>
                    {label} {isRequired ? <b style={{ color: 'red' }}>*</b> : ''}
                </div>
            )}
            <Autocomplete
                name={name}
                value={options.find((option) => option[valueKey] === value) || null}
                onChange={(event, newValue) => {
                    onChange({ target: { name, value: newValue ? newValue[valueKey] : '' } })
                }}
                onInputChange={(event, newInputValue) => {
                    options.filter((option) => option[labelKey].toLowerCase().includes(newInputValue.toLowerCase()))
                }}
                options={options}
                getOptionLabel={(option) => option[labelKey]}
                renderOption={(props, option) => (
                    <li {...props} style={classes.optionContainer}>
                        {option[labelKey]}
                        {useDeleteOption && (
                            <IconButton
                                style={classes.clearButton}
                                onClick={() => handleClearOption(option)}
                                disabled={isDisabled}
                            >
                                <ClearIcon />
                            </IconButton>
                        )}
                    </li>
                )}
                renderInput={(params) => <TextField {...params} style={classes.textField} variant="outlined" />}
                size="small"
                disabled={isDisabled}
                {...(error && { error: true, helperText: error })}
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
        </div>
    )
}
